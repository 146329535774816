import { instance } from '../instance';

export const listWorker = (param) => instance.post('worker/listworker', param);

export const updateWorker = (param) => instance.post('worker/update', param);

export const deleteWorker = (param) => instance.post('worker/delete', param);

export const downLoadClientUrl = (param) => instance.post('worker/downloadclienturl', param);

export const clientCoreConfig = (param) => instance.post('worker/clientcoreconfig', param);

export const updateAlertConfig = (param) => instance.post('worker/updatealertconfig', param);

export const waitToOffline = (param) => instance.post('worker/waittooffline', param);

export const waitToOnline = (param) => instance.post('worker/waittoonline', param);

export const createSelfMaintainWorker = (param) => instance.post('worker/createselfmaintainworker', param);

export const queryWorkerById = (param) => instance.post('worker/queryworkerbyid', param);

export const checkWorkerWhiteIp = (param) => instance.post('worker/checkworkerwhiteip', param);

export const addWorkerWhiteListIp = (param) => instance.post('worker/addworkerwhitelistip', param);

export const updateMemOverSoldPercent = (param) => instance.post('worker/updatememoversoldpercent', param);

export const queryOperateProcess = (param) => instance.post('/worker/queryprocess')

export const createandInstall = (param) => instance.post('allworker/createandinstall', param);

export const checkWorkerConnection = (param) => instance.post('/allworker/checkconnection', param);

export const operateWorkersClient = (param) => instance.post('/allworker/operateworkersclient', param);

export const stopRollback = (param) => instance.post('/allworker/stoprollback', param)

export const stopUpgrade = (param) => instance.post('/allworker/stopupgrade', param)
