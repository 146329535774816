<template>
    <div>
        <PageHeader :title="$t('jian-kong-xiang-qing')"></PageHeader>
        <div class="container-wrapper">
            <div class="clearfix" style="margin-bottom:10px;position: relative">
                <p style="font-size: 18px;font-weight: 500;display: inline-block;margin-left: 10px;">{{jobData.dataJobName}} <span v-if="jobData.dataJobDesc">-</span> {{jobData.dataJobDesc}}</p>
                <div style="float: right">
<!--                    <pd-button @click="handleInterval">{{hasInterval?"取消10s定时刷新":"10s定时刷新"}}</pd-button>-->
                                        <Timepicker style="display: inline-block;margin-right: 10px" ref="timepicker" :time="time"></Timepicker>
                    <pd-button @click="handleRefresh">
                        <Icon type="md-refresh"/>
                        {{ $t('shua-xin') }}
                    </pd-button>
                </div>
                <div style="margin-top: 30px">
                    <div class="panel">
                        <div class="panel-header">
                            <div style="float: right">
                            </div>

                            {{metric.title}}
                        </div>
                        <div class="panel-body">
                            <div style="height: 400px">
                                <Metric ref="metric" :model="metric" :time="time" :filters="filters"
                                        :selected="metric.selected" :filterOthers="filterOthers"></Metric>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { Config } from '@components/function/monitor/config';
import _ from 'lodash';
import { queryJobById } from '@services/api/job';
import PageHeader from '../../components/function/PageHeader';
import Timepicker from '../../components/function/monitor/metric/timepicker';
import Metric from '../../components/function/monitor/metric/metric';
import Time from '../../components/function/monitor/utils/time';

export default {
  components: {
    PageHeader,
    Timepicker,
    Metric
  },
  created() {
    const that = this;

    setTimeout(() => {
      that.init();
    }, 200);
  },
  mounted() {
    if (this.$route.query.jobId) {
      this.jobId = this.$route.query.jobId;
      this.taskId = this.$route.query.taskId;
      this.filters.jobId = this.jobId;
      this.filters.taskId = this.taskId;
      queryJobById({ jobId: this.jobId }).then((response) => {
        if (response.data.code === '1') {
          this.jobData = response.data.data;
        }
      });
    }
    this.$refs.timepicker.$on('refresh', () => {
      this.handleRender();
    });
    this.time.events.on('changed', () => {
      this.$refs.timepicker.$emit('to_refresh');
    });
    const that = this;

    setTimeout(() => {
      that.handleRender();
    }, 200);
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
  },
  data() {
    return {
      jobData: {},
      taskId: 0,
      hasInterval: false,
      metrics: [],
      metric: {},
      jobList: [],
      time: new Time(),
      filters: {
        instance: '',
        jobId: '',
        taskId: ''
      },
      panelType: 'basic',
      configFile: Config.mysql,
      dbs: [],
      db: this.$t('quan-bu'),
      env: 'prod',
      filterOthers: {
        filterInstances: [],
        dbOnInstance: {},
        database: this.$t('quan-bu')
      }
    };
  },
  methods: {
    init() {
      this.panelType = this.$route.params.type;
      const metrics = this.configFile[this.panelType].config;

      this.metric = {};
      _.forEach(metrics, (metric) => {
        metric.favorite = false;
        metric.loading = '';
        if (this.panelType !== 'system') {
          try {
            metric.queries = JSON.parse(metric.options).queries;
          } catch (e) {
            metric.queries = metric.options.queries;
          }
        }
        if (this.filters.instance && this.filters.jobId) {
          metric.queries.map((query) => {
            query.metric = query.metric.split('{')[0];
            query.metric += `{instance="${this.filters.instance}",jobId="${this.filters.jobId}"}`;
            return null;
          });
        } else if (!this.filters.instance && this.filters.jobId) {
          metric.queries.map((query) => {
            if (query.metric.indexOf('sum') > -1) {
              const a = _.cloneDeep(query.metric.split('{')[0]);
              const b = _.cloneDeep(query.metric.split('by')[1]);

              query.metric = `${a}{job_id="${this.filters.jobId}",task_id="${this.taskId}"}) by${b}`;
            } else if (query.metric.indexOf('increase') > -1) {
              query.metric = `${query.metric.split('[')[0].split('{')[0]}{job_id="${this.filters.jobId}",task_id="${this.taskId}"}[${query.metric.split('[')[1]}`;
            } else {
              query.metric = query.metric.split('{')[0];
              query.metric += `{job_id="${this.filters.jobId}",task_id="${this.taskId}"}`;
            }
            return null;
          });
        }
      });

      this.metrics = metrics;
      const query = this.$route.query.metric;

      if (query) {
        this.metrics.map((item) => {
          if (item.id === query) {
            this.metric = item;
          }
          return null;
        });
      } else {
        this.metric = null;
      }
    },
    handleRender() {
      this.init();
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric) {
          that.$refs.metric.$emit('render');
        } else {
          _.forEach(that.$refs.metrics, (m) => {
            m.$emit('render');
          });
        }
      }, 100);
    },
    handleJobChange(data) {
      if (data) {
        this.filters.jobId = data.toString();
        this.handleRender();
      }
    },
    handleInterval() {
      const that = this;

      if (this.hasInterval) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.handleRender();
        }, 10000);
      }
    },
    handleRefresh() {
      this.handleRender();
    }
  }
};
</script>
