import {instance} from '../instance';

export const createJob = (param) => instance.post('datajob/create', param);

export const queryJobById = (param) => instance.post('datajob/queryjob', param);

export const queryJobSchemaById = (param) => instance.post('datajob/queryjobschemabyid', param);

export const getJobList = (param) => instance.post('datajob/list', param);

export const getJobListByPage = (param) => instance.post('datajob/listbypage', param)

export const preCheckStepOne = (param) => instance.post('datajob/precheck/steptwo', param);

export const finalCheck = (param) => instance.post('datajob/precheck/final', param);

export const restartJob = (param) => instance.post('datajob/restart', param);

export const deleteJob = (param) => instance.post('datajob/delete', param);

export const startJob = (param) => instance.post('datajob/start', param);

export const stopJob = (param) => instance.post('datajob/stop', param);

export const restartJobBatch = (param) => instance.post('datajob/restartbatch', param);

export const deleteJobBatch = (param) => instance.post('datajob/deletebatch', param);

export const startJobBatch = (param) => instance.post('datajob/startbatch', param);
export const activeFsmBatch = (param) => instance.post('datajob/activefsmbatch', param);
export const stopJobBatch = (param) => instance.post('datajob/stopbatch', param);

export const resetPosition = (param) => instance.post('position/reset', param);

export const resetPosTocurrent = (param) => instance.post('position/resetpostocurrent', param);

export const updateIncrePos = (param) => instance.post('position/updateincrepos', param);

export const getTaskConfig = (param) => instance.post('datajob/config/get', param);

export const getConfigTagList = (param) => instance.post('datajob/config/list/tag', param);

export const getConfigByName = (param) => instance.post('datajob/config/listconfigs', param);

export const updateTaskConfig = (param) => instance.post('datajob/config/update', param);

export const manualSchedule = (param) => instance.post('schedule/manualschedule', param);

export const pauseJobTimeSchedule = (param) => instance.post('datajob/pausetimeschedule', param);

export const resumeJobTimeSchedule = (param) => instance.post('datajob/resumetimeschedule', param);

export const startTimeScheduleJob = (param) => instance.post('datajob/starttimeschedulejob', param);

export const updateJob = (param) => instance.post('datajob/updatedesc', param);

export const activeFsm = (param) => instance.post('datajob/activefsm', param);

export const listSpecifications = (param) => instance.post('spec/listspecs', param);

export const tailTaskMainLog = (param) => instance.post('logview/tail/tasklog', param);

export const tailWorkerLog = (param) => instance.post('logview/tail/workerlog', param);

export const updateTransferObject = (param) => instance.post('datajob/updatetransferobject', param);

export const replayJob = (param) => {
    return instance.post('datajob/replay', param);
};

export const listPackages = (param) => instance.post('jarpackage/list', param);

export const activePackages = (param) => instance.post('jarpackage/active', param);

export const deletePackages = (param) => instance.post('jarpackage/delete', param);

export const updatePkgInfo = (param) => instance.post('jarpackage/update', param);

export const detachIncreTask = (param) => instance.post('datajob/detachincretask', param);

export const attachIncreTask = (param) => instance.post('datajob/attachincretask', param);

export const getRestartList = (param) => instance.post('/taskrestarthistory/list', param);

export const followedJob = (param) => instance.post('/datajob/getfollowedjob', param);

export const listCheckTasks = (params) => instance.post('/datajob/listchecktasks', params);

export const preFirstCheck = () => instance.post('/datajob/precheck/first')

export const canAddReviseTask = (params) => instance.post('/datajob/canaddrevisetask', params)

export const addrevisetask = (params) => instance.post('/datajob/addrevisetask', params)

export const listSimpleForSearch = (params) => instance.post('/datajob/listsimpleforsearch', params)

