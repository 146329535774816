import DataSourceGroup from '../views/dataSourceGroup';

export const isDb2 = (type) => DataSourceGroup.db2.includes(type);
export const isOb = (type) => DataSourceGroup.ob.includes(type);
export const isHudi = (type) => DataSourceGroup.hudi.includes(type);
export const isTunnel = (type) => DataSourceGroup.tunnel.includes(type);
export const isMQ = (type) => DataSourceGroup.mq.includes(type);
export const isPG = (type) => DataSourceGroup.pg.includes(type);

export const isGP = (type) => DataSourceGroup.gp.includes(type);
export const isPolar = (type) => DataSourceGroup.polar.includes(type);
export const isMySQL = (type) => DataSourceGroup.mysql.includes(type);
export const isOracle = (type) => DataSourceGroup.oracle.includes(type);
export const isHasSchema = (type) => DataSourceGroup.hasSchema.includes(type);
export const isMongoDB = (type) => DataSourceGroup.mongo.includes(type);
export const isStarRocks = (type) => DataSourceGroup.starrocks.includes(type);
export const isTidDB = (type) => DataSourceGroup.tidb.includes(type);
export const isSQLServer = (type) => DataSourceGroup.sqlServer.includes(type);
export const isES = (type) => DataSourceGroup.es.includes(type);
export const isKudu = (type) => DataSourceGroup.kudu.includes(type);
export const isRedis = (type) => DataSourceGroup.redis.includes(type);
export const isCk = (type) => DataSourceGroup.ck.includes(type);
export const isKafka = (type) => DataSourceGroup.kafka.includes(type);

export const isRocketMQ = (type) => DataSourceGroup.rocketMq.includes(type);
export const separatePort = (type) => !['Kudu', 'Redis', 'ClickHouse', 'ElasticSearch', 'RabbitMQ', 'RocketMQ', 'Kafka', 'MongoDB', 'PolarDbX', 'StarRocks', 'Doris', 'SelectDB'].includes(type);

export const showActiveLicense = (status) => [550, 555, 556, 557, 558, 559, 560, 561, 562, 563, 564, 565, 566].includes(status);

export const isSchemaMapping = (type) => DataSourceGroup.schemaMapping.includes(type);
