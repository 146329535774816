import React from 'react';
import Vue from 'vue';
import Card from './card';
import router from '../../../../router';

export default class Panel extends React.Component {
  render() {
    return React.createElement('div', {
      ref: 'main'
    });
  }

  componentDidMount() {
    new Vue({
      el: this.refs.main,
      template: '<Card :model="model" :time="time" :emitter="emitter" :filters="filters" :panel="panel" :filterOthers="filterOthers" :resourceType="resourceType" :dataJobId="dataJobId" :dataTaskId="dataTaskId" :workerId="workerId" :consoleId="consoleId"/>',
      data: {
        model: this.props.model,
        time: this.props.time,
        emitter: this.props.emitter,
        panel: this.props.panel,
        filters: this.props.filters,
        filterOthers: this.props.filterOthers,
        resourceType: this.props.resourceType,
        dataJobId: this.props.dataJobId,
        dataTaskId: this.props.dataTaskId,
        workerId: this.props.workerId,
        consoleId: this.props.consoleId
      },
      components: {
        Card
      },
      router
    });
  }
}
