<template>
  <div>
    <Tabs :value="currentTab" :animated="false">
      <TabPane :label="$t('xin-zeng-de-ku-biao')" name="name1" :disabled="Object.keys(this.newData).length === 0">
        <div class="edit-info-container">
          <div v-for="(value,key) in newData" :key="key">
            <div class="task-db-mapping-title">
              <i class="iconfont iconshujuku"></i>{{ key }}<span
              v-if="value.sourceSchema">.{{ value.sourceSchema }}</span>
              <span v-if="value.targetDb"><Icon type="md-arrow-forward"/><i
                class="iconfont iconshujuku"></i><span><span>{{ value.targetDb }}</span><span
                v-if="value.targetSchema">.{{ value.targetSchema }}</span></span>
                            </span>
              <span class="task-db-mapping-info">
                                            {{ $t('yi-xuan-zhong') }}<span
                :class="getSelectedInfo(value).count<sourceTableList.length?'warn-count':''">{{
                  getSelectedInfo(value).count
                }}</span>{{ $t('zhang-biao-dai-chuang-jian') }}<span
                :class="getSelectedInfo(value).needToAutoCreated>0?'warn-count':''">{{
                  getSelectedInfo(value).needToAutoCreated
                }}</span>{{ $t('cun-zai-ying-she') }}<span
                :class="getSelectedInfo(value).mappingCount>0?'warn-count':''">{{
                  getSelectedInfo(value).mappingCount
                }}</span>
                                        </span>
            </div>
            <div class="task-db-mapping-content">
              <Collapse v-model="panelNum" v-if="dbPage[key]&&dbPage[key].showTableList">
                <Panel :name="`${key+i}`" v-for="(table,i) in dbPage[key].showTableList"
                       :key="key+i">
                                                <span><i
                                                  class="iconfont iconbiao"></i>{{
                                                    table.sourceTable
                                                  }} <span><Icon
                                                    type="md-arrow-forward"/></span><span
                                                    :class="table.needAutoCreated?'db-to-create':''"><i
                                                    :class="`iconfont ${table.needAutoCreated?'icondaijianBIAO':'iconbiao'}`"></i>
                                                  <span>{{
                                                      table.targetTable === 'cloudCanal_default' &&
                                                      table.customSinkTable ? table.customSinkTable : table.targetTable
                                                    }}</span></span><i
                                                    v-if="table.targetTable&&!sameItem(table.targetTable,table.sourceTable)"
                                                    class="iconfont iconyingshe"></i>
                                                    <span
                                                      style="margin-left: 5px;position: absolute;right: 16px;top: 0">
                                                        {{ $t('yi-xuan-zhong') }}<span
                                                      :class="getSelectColumns(selectedColumnList[key],table.sourceTable).count<
                                                            selectedColumnList[key][table.sourceTable].length?'warn-count':''">
                                                      {{
                                                        getSelectColumns(selectedColumnList[key], table.sourceTable).count
                                                      }}</span>{{ $t('selectedcolumnlistkeytablesourcetablelength-ge-lie-dai-chuang-jian', [selectedColumnList[key][table.sourceTable].length]) }}
                                                        <span
                                                          :class="getSelectColumns(selectedColumnList[key],table.sourceTable).needToAutoCreated>0?'warn-count':''">
                                                          {{
                                                            getSelectColumns(selectedColumnList[key], table.sourceTable).needToAutoCreated
                                                          }}</span>
                                                        {{ $t('cun-zai-ying-she') }}
                                                        <span
                                                          :class="getSelectColumns(selectedColumnList[key],table.sourceTable).mappingCount>0?'warn-count':''">
                                                          {{
                                                            getSelectColumns(selectedColumnList[key], table.sourceTable).mappingCount
                                                          }}</span>
                                                    </span>
                                                </span>
                  <div slot="content">
                    <Table border stripe size="small" :columns="tableColumn"
                           :data="commonSelectColumns[key][table.sourceTable]"></Table>
                  </div>
                </Panel>
              </Collapse>
              <Page v-if="dbPage[key]" style="margin-top: 10px;text-align: center"
                    :total="dbPage[key].total" show-total
                    @on-change="handlePageChange(key,$event)"/>
            </div>
          </div>
        </div>
      </TabPane>
      <TabPane :label="$t('shan-chu-de-ku-biao')" name="name2" :disabled="Object.keys(this.reduceData).length === 0">
        <div class="edit-info-container">
          <div v-for="(value,key) in reduceData" :key="key">
            <div class="task-db-mapping-title">
              <i class="iconfont iconshujuku"></i>{{ key }}<span
              v-if="value.sourceSchema">.{{ value.sourceSchema }}</span>
              <Icon type="md-arrow-forward"/>
              <span><span>{{ value.targetDb }}</span><span
                v-if="value.targetSchema">.{{ value.targetSchema }}</span></span>
            </div>
            <div class="task-db-mapping-content">
              <div class="reduce-table-item" v-for="(table,i) in reduceData[key].tableList"
                   :key="key+i">
                                          <span><i
                                            class="iconfont iconbiao"></i>{{
                                              table.sourceTable
                                            }} <span><Icon
                                              type="md-arrow-forward"/></span><span
                                              :class="table.needAutoCreated?'db-to-create':''"><i
                                              :class="`iconfont ${table.needAutoCreated?'icondaijianBIAO':'iconbiao'}`"></i>
                                            <span>{{
                                                table.targetTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.targetTable
                                              }}</span></span><i
                                              v-if="table.targetTable&&!sameItem(table.targetTable,table.sourceTable)"
                                              class="iconfont iconyingshe"></i>
                                                </span>
              </div>
              <Page v-if="dbPage[key]" style="margin-top: 10px;text-align: center"
                    :total="dbPage[key].total" show-total
                    @on-change="handlePageChange(key,$event)"/>
            </div>
          </div>
        </div>
      </TabPane>
    </Tabs>
    <Modal v-model="showWhereCondition" :title="$t('where-tiao-jian')">
      <div style="word-break: break-all">
        {{ whereCondition }}
      </div>
    </Modal>
    <Modal v-model="showPartition" :title="$t('fen-qu-she-zhi')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80" v-if="partition.partition[0]">
          <FormItem :label="$t('fen-qu-jian')">
            {{ partition.partition[0].key }}
          </FormItem>
          <FormItem :label="$t('fen-qu-fang-fa')">
            {{ partition.partition[0].func }}
          </FormItem>
          <FormItem :label="$t('wen-jian-ge-shi')">
            {{ partition.fileFormat }}
          </FormItem>
          <FormItem :label="$t('ya-suo-fang-shi')">
            {{ partition.compressType }}
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import DataSourceGroup from '../../../../views/dataSourceGroup';

export default {
  props: {
    selectedColumnList: Object,
    newData: Object,
    sourceTableList: Array,
    reduceData: Object
  },
  created() {
    if (Object.keys(this.newData).length === 0 && Object.keys(this.reduceData).length) {
      this.currentTab = 'name2';
    }
    Object.keys(this.selectedColumnList)
      .map((item) => {
        this.initData(this.selectedColumnList[item], item);
        return null;
      });
  },
  data() {
    return {
      currentTab: 'name1',
      showWhereCondition: false,
      showPartition: false,
      structMigration: false,
      whereCondition: '',
      selectedColumns: {},
      selectedTables: {},
      panelNum: '',
      dbPage: {},
      partition: { partition: [] },
      filterTable: {},
      tableColumn: [
        {
          title: this.$t('yuan-zi-duan'),
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sourceType',
          width: 200
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('mu-biao-zi-duan'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sinkType',
          width: 200
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 120
        }
      ],
      commonSelectColumns: {}
    };
  },
  computed: {
    getShowTableList() {
      return (db) => {
        const list = [];

        this.dbPage[db].showTableList.map((table) => {
          list.push(table);
          return null;
        });
        return list;
      };
    },
    getTableActionStyle() {
      return (action, type) => {
        let formatAction = 'color:#c5c8ce';

        action.map((item) => {
          if (item === 'INSERT' && type === 'i') {
            formatAction = 'color:#19be6b';
          } else if (item === 'UPDATE' && type === 'u') {
            formatAction = 'color:#19be6b';
          } else if (item === 'DELETE' && type === 'd') {
            formatAction = 'color:#19be6b';
          }
          return null;
        });
        return formatAction;
      };
    },
    getSelectColumns() {
      return (obj, table) => {
        let count = 0;
        let mappingCount = 0;
        let
          needToAutoCreated = 0;

        if (obj) {
          Object.keys(obj)
            .forEach((item) => {
              if (item === table) {
                obj[item].map((i) => {
                  if (i.selected) {
                    count++;
                    if (i.needAutoCreated) {
                      needToAutoCreated++;
                    }

                    if (i.sinkColumn && i.sourceColumn !== i.sinkColumn) {
                      mappingCount++;
                    }
                  }
                  return null;
                });
              }
            });
        }
        return {
          count,
          needToAutoCreated,
          mappingCount
        };
      };
    },
    getSelectedInfo() {
      return (list) => {
        let count = 0;
        let mappingCount = 0;
        let
          needToAutoCreated = 0;

        if (list) {
          list.tableList.map((item) => {
            count++;
            if (item.needAutoCreated) {
              needToAutoCreated++;
            }
            if (item.sourceTable !== item.targetTable) {
              mappingCount++;
            }
            return null;
          });
        }
        return {
          count,
          needToAutoCreated,
          mappingCount
        };
      };
    },
    getTableCount() {
      return () => {
        let count = 0;

        this.taskInfo.dbMap.map((item) => {
          if (item.selectedTables) {
            item.selectedTables.map((table) => {
              if (table.selected) {
                count++;
              }
              return null;
            });
          }
          return null;
        });
        return count;
      };
    },
    sameItem() {
      return (sink, source) => {
        let hasSame = false;

        if (sink === source) {
          hasSame = true;
        }
        return hasSame;
      };
    }
  },
  methods: {
    handlePageChange(db, page) {
      this.dbPage[db].page = page;
      this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
      this.dbPage = { ...this.dbPage };
    },
    handleGetSelectedColumns(dbName) {
      const commonSelectEdColumns = {};

      if (this.selectedColumns[dbName]) {
        Object.keys(this.selectedColumns[dbName])
          .map((item) => {
            if (this.selectedColumns[dbName][item] instanceof Array) {
              this.selectedColumns[dbName][item].map((column, i) => {
                if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb]) {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                } else {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb] = {};
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                }
                return null;
              });
            }
            return null;
          });
      }
    },
    hasTheSameItem(column, columns) {
      let isSame = false;

      if (columns) {
        columns.map((item) => {
          if (item.columnName === column.columnName) {
            isSame = true;
          }
          if (DataSourceGroup.oracle.indexOf(this.sinkType) > -1) {
            if (item.columnName === column.columnName.toUpperCase()) {
              isSame = true;
            }
          }
          return null;
        });
      }
      return isSame;
    },
    initData(item, db) {
      let total = 0;

      const that = this;
      const
        selectedTables = [];

      if (item) {
        Object.keys(item)
          .map((selectedTable) => {
            this.newData[db].tableList.map((table) => {
              if (table.sourceTable === selectedTable) {
                total++;
                selectedTables.push(table);
              }
              return null;
            });
            return null;
          });
      }
      that.dbPage[db] = {
        total,
        page: 1,
        pageSize: 10,
        showTableList: selectedTables.slice(0, 10),
        filteredTableList: _.cloneDeep(selectedTables),
        tableList: selectedTables
      };
      that.commonSelectColumns[db] = {};

      Object.keys(item)
        .forEach((key) => {
          if (!that.commonSelectColumns[db][key]) {
            that.commonSelectColumns[db][key] = [];
          }
          const trueSelectedColumns = [];

          item[key].map((column) => {
            if (column.selected) {
              trueSelectedColumns.push(column);
            }
            return null;
          });
          that.commonSelectColumns[db][key] = trueSelectedColumns;
        });

      that.dbPage = { ...that.dbPage };
    },
    handleShowWhere(table, columns) {
      this.showWhereCondition = true;
      this.whereCondition = `select ${this.getColumns(table.sourceDb, table.sourceTable, columns)} from ${table.sourceTable} where ${table.whereCondition}`;
    },
    handleShowPartition(table) {
      this.showPartition = true;
      this.partition = table.partitionData;
    },
    getColumns(db, table, column) {
      let columns = '';

      column[table].map((item, index) => {
        if (index !== column[table].length - 1) {
          columns += `${item.sourceColumn},`;
        } else {
          columns += item.sourceColumn;
        }
        return null;
      });
      return columns;
    },
    handleTableFilter(db) {
      if (this.filterTable.filterTableName) {
        this.dbPage[db].filteredTableList = [];
        this.dbPage[db].tableList.map((item) => {
          if (item.sourceTable.indexOf(this.filterTable.filterTableName) > -1) {
            this.dbPage[db].filteredTableList.push(item);
          }
          return null;
        });
        this.dbPage[db].total = this.dbPage[db].filteredTableList.length;
        this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
        this.dbPage = { ...this.dbPage };
      } else {
        this.dbPage[db].filteredTableList = _.cloneDeep(this.dbPage[db].tableList);
        this.dbPage[db].total = this.dbPage[db].filteredTableList.length;
        this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
        this.dbPage = { ...this.dbPage };
      }
    }
  }
};
</script>
<style lang="less" scoped>
.task-info-db-map {
  .ivu-card-head {
    padding: 14px 16px 14px 0;
  }
}

.job-info-action {
  display: inline-block;
  width: 10px;
  font-weight: bold;
  text-align: center;
  color: #19be6b;
}

.task-info-title {
  background-color: #ECECEC;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Medium, serif;
  font-weight: 500;
  border-bottom: 1px solid #DADADA;
  padding-left: 20px;
}

.task-info-item-container {
  font-size: 0;
  border-bottom: 1px solid #DADADA;
}

.task-info-item {
  width: 20%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  /*font-size: 14px;*/

  .task-info-item-title {
    background-color: #F4F4F4;
    text-align: right;
    display: inline-block;
    font-weight: 400;
    width: 120px;
    border-right: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    padding-right: 10px;
  }

  .task-info-item-value {
    font-weight: 500;
    padding-left: 6px;
  }
}

.task-info-item-auto {
  width: 60%;
}

.task-db-mapping-title {
  background-color: #CCEFFF;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #DADADA;
  padding: 0 16px;

  .task-db-mapping-info {
    float: right;
  }
}

.task-db-mapping-content {
  padding: 10px;

  .reduce-table-item {
    background-color: #ECECEC;
    padding: 10px 14px;
    border-bottom: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    border-right: 1px solid #DADADA;

    &:first-child {
      border-top: 1px solid #DADADA;
    }
  }
}

.task-db-mapping-title .iconfont, .task-db-mapping-content .iconfont {
  margin-right: 10px;
}

.iconyingshe {
  margin-left: 6px;
  color: #0BB9F8;
}

.edit-info-container {
  height: 520px;
  overflow: auto;
  border: 1px solid #dadada;
  margin-top: 10px;
}
</style>
