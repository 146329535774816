<template>
  <div class="page-header-container">
    <Form ref="formInline" :model="searchKey" inline label-position="right"
          style="padding-right: 300px">
      <FormItem>
        <Select v-model="searchType" style="width:160px" @on-change="handleChangeSearchType">
          <Option value="paramName" :label="$t('can-shu-ming-cheng')">
            <span>{{ $t('can-shu-ming-cheng') }}</span>
          </Option>
          <Option value="labelType" :label="$t('can-shu-biao-qian')">
            <span>{{ $t('can-shu-biao-qian') }}</span>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='paramName'">
        <Input type="text" v-model="searchKey.key" :placeholder="$t('shu-ru-guan-jian-zi-jin-hang-mo-hu-sou-suo')" style="width: 280px" @on-enter="handleSearch(searchKey)"/>
      </FormItem>
      <FormItem v-if="searchType==='labelType'">
        <Select v-model="searchKey.tag" multiple style="width:260px">
          <Option v-for="item in tagList" :value="item" :key="item">{{ item }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <pd-button type="primary" @click="handleSearch(searchKey)">{{ $t('cha-xun') }}</pd-button>
      </FormItem>
    </Form>
    <div class="page-header-function">
      <pd-button type="primary" @click="handleShowConfirmEditParams(true)">{{ $t('sheng-xiao-pei-zhi') }}</pd-button>
    </div>
  </div>
</template>
<script>
import { getConfigTagList } from '@services/api/job';

export default {
  props: {
    handleSearch: Function,
    handleShowConfirmEditParams: Function
  },
  data() {
    return {
      searchType: 'paramName',
      searchKey: {
        key: '',
        tag: []
      },
      tagList: []
    };
  },
  mounted() {
    getConfigTagList()
      .then((res) => {
        if (res.data.code === '1') {
          this.tagList = res.data.data;
        }
      });
  },
  methods: {
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {
        key: '',
        tag: []
      };
    }
  }
};
</script>
<style lang="less" scoped>
.page-header-container {
  background: #ffffff;
  border: 1px solid #EDEDED;
  height: 60px;
  line-height: 54px;
  padding: 0 20px;
  position: relative;

  .ivu-form-inline .ivu-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .ivu-form-item {
    margin-bottom: 0;
  }

  .page-header-function {
    position: absolute;
    right: 20px;
    top: 2px;

    a {
      color: #333;
      margin-right: 10px;
    }

    button {
      margin-left: 8px;
    }

    .ivu-tooltip {
      margin-left: 8px;
    }
  }
}

.data-job-mode-switch {
  width: 32px;
  height: 32px;
  border: 1px solid #BABDC5;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  vertical-align: middle;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
</style>
