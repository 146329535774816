import axios from 'axios';
import { Modal, Spin } from 'view-design';
import { showActiveLicense } from '@utils';
import i18n from '../i18n';

axios.defaults.withCredentials = true;
const baseURL = `${window.location.protocol}//${window.location.host}/cloudcanal/console/api/v1/inner`;
// const baseURL = '/' + '/api/v1/';
const timeout = 600000;

const trimObj = (obj) => {
  if (!Array.isArray(obj) && typeof obj !== 'object') return obj;
  if (obj === null) {
    return obj;
  }
  if (typeof obj === 'string') {
    return obj.trim();
  }
  return Object.keys(obj)
    .reduce((acc, key) => {
      acc[key.trim()] = trimObj(obj[key]);
      return acc;
    }, Array.isArray(obj) ? [] : {});
};

const instance = axios.create({
  baseURL,
  timeout,
  transformRequest: [(data) => {
    if (!data) {
      return;
    }
    Object.keys(data)
      .map((key) => {
        if (!data[key] && data[key] !== false && data[key] !== 0) {
          data[key] = null;
        } else {
          try {
            data[key] = trimObj(data[key]);
          } catch (e) {
            console.log(e);
          }
        }
        return null;
      });
    return JSON.stringify(data);
  }],
  headers: {
    'Accept-Language': i18n.locale,
    Accept: 'application/json',
    'Content-Type': 'application/json'
    // 'Access-Control-Allow-Origin': '*'
  },
  withCredentials: true,
  credentials: 'include'
});

export { instance };

// 返回结果拦截器,处理默认的错误
instance.interceptors.response.use((response) => {
  // console.log('response', response, response.data);
  let parseData = {};

  if (response.config.data) {
    parseData = JSON.parse(response.config.data);
  }

  if (response.data.code !== '1') {
    if (response.data.code !== '6028' && response.data.code !== '6047' && response.data.code !== '0014' && response.data.code !== '0032') {
      if (response.data.msg && !parseData.noModal) {
        Modal.info({
          render: (h) => h('div', [
            h('div', {
              class: 'operation-error-title'
            }, [
              h('Icon', {
                props: {
                  type: 'md-close-circle'
                },
                style: {
                  fontSize: '24px',
                  float: 'left'
                }
              }),
              h('p', {
                class: 'preCheck-title-desc',
                style: {
                  marginLeft: '30px',
                  wordBreak: 'break-all'
                }
              }, i18n.t('cao-zuo-shi-bai')),
              h('p', {
                class: 'preCheck-info',
                style: {
                  marginLeft: '30px'
                }
              }, response.data.msg && JSON.parse(response.data.msg) && JSON.parse(response.data.msg) instanceof Array ? JSON.parse(response.data.msg)
                .join('/n') : response.data.msg)
            ])
          ]),
          width: 600,
          okText: i18n.t('zhi-dao-le'),
          closable: true
        });
      }
    }
  }
  // 正常的请求前拦截,在这里处理
  return response;
}, (error) => {
  // 非200请求时的错误处理'
  Spin.hide();
  if (error.response) {
    const res = error.response.data; // 请求data
    const status = error.response.status; // 请求状态吗

    if (status === 499) {
      window.location.href = res.url;
    } else if (status === 401) {
      if (window.location.href.indexOf('redirect') < 0) {
        window.location.href = `${window.location.protocol}//${window.location.host}/#/login`;
      }
      window.location.reload();
    } else if (status === 406) {
      if (window.location.href.split('#')[1] !== '/permission/denied' && window.location.href.split('#')[1] !== '/permission/exception') {
        localStorage.setItem('console_last_url', window.location.href.split('#')[1]);
      }
      window.location.href = `${window.location.protocol}//${window.location.host}/#/permission/denied`;
    } else if (status === 500) {
      if (window.location.href.split('#')[1] !== '/permission/denied' && window.location.href.split('#')[1] !== '/permission/exception') {
        localStorage.setItem('console_last_url', window.location.href.split('#')[1]);
      }
      window.location.href = `${window.location.protocol}//${window.location.host}/#/permission/exception`;
    } else if (res && !res.errors) {
      if (showActiveLicense(status)) {
        console.log(status);
        setTimeout(() => {
          Modal.confirm({
            title: 'ERROR',
            content: `${res.message || error.response.data}`,
            okText: i18n.t('qu-ji-huo'),
            onOk: () => {
              window.$bus.emit('showActiveLicenseModal');
            }
          });
        }, 300);
      } else {
        setTimeout(() => {
          Modal.error({
            title: 'ERROR',
            content: `${res.message || error.response.data}`
          });
        }, 300);
      }
    } else {
      setTimeout(() => {
        Modal.error({
          title: 'ERROR',
          content: `${res.errors[0].message}`
        });
      }, 300);
    }
  } else {
    Modal.error({
      title: 'ERROR',
      content: i18n.t('yi-chao-shi')
    });
  }
  return Promise.reject(error);
});
