u<template>
  <div>
    <div>
      <div>
          <Table border size="small" :columns="paramsColumn" :data="userConfigList"
                 max-height="600" stripe>
            <template slot-scope="{ row }" slot="configValue">
              <p style="position: relative;padding-right: 15px;">
                                    <span v-if="isJSON(row.configValue)"
                                          style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block">{{
                                        row.configValue
                                      }}
                                        <a style="position: absolute;right: 0;font-size: 14px"
                                           @click="handleShowJson(row)"><Icon
                                          type="md-arrow-dropdown"/></a>
                                    </span>
                <span v-if="!isJSON(row.configValue)">{{
                    row.configValue ? row.configValue : '-'
                  }}</span>
              </p>
            </template>
            <template slot-scope="{ row }" slot="paramName">
              <p style="position: relative">
                                    <span
                                      :style="`font-style: italic;font-size: 13px;font-weight: 500;color: ${row.needCreated ? '#ff6e0d' : ''}`">
                                      {{row.configName}}
                                      {{row.needCreated ? $t('dai-chuang-jian') : ''}}
                                    <Tooltip transfer :content="row.description" placement="right"
                                             style="position:absolute;right: 0;top: 0">
                                        <a><Icon style="font-size: 14px"
                                                 type="ios-information-circle"/></a>
                                    </Tooltip>
                                </span>
              </p>
            </template>
            <template slot-scope="{ row }" slot="currentValue">
              <div>
                <div v-if="!row.readOnly">
                      <span
                        style="margin-right: 16px;display: inline-block;width: 60px;">{{
                          row.currentCount
                        }}</span><span>
                                    <Poptip v-model="row.visible" transfer
                                            @on-popper-show="handlePopShow(row)"
                                            placement="right" width="250"><i
                                      class="iconfont iconbianji"></i>
                                <div slot="content">
                                    <p style="font-size: 12px;margin-bottom: 10px">{{ $t('xiu-gai-can-shu-wei') }}</p>
                                    <p style="margin-bottom: 10px"><Input size="small"
                                                                          style="width: 200px"
                                                                          v-model="currentValue"/></p>
                                    <p><pd-button style="margin-right: 5px" type="primary"
                                                  size="small"
                                                  @click="handleEditCurrent(row)">{{ $t('que-ding') }}</pd-button></p>
                                </div>
                            </Poptip>
                                <Tooltip transfer style="margin-left: 5px" :content="$t('che-xiao')"
                                         placement="right"
                                         v-if="row.currentCount"><a style="font-size: 16px;"
                                                                    @click="handleCancelEdit(row)"><Icon
                                  type="md-undo"/></a></Tooltip></span>
                </div>
                <div v-if="row.readOnly">
                  {{ $t('zhi-du-can-shu') }}
                </div>
              </div>
            </template>
            <template slot-scope="{ row }" slot="action">
              <pd-button ghost type="primary" size="small" style="margin-right: 5px"
                         @click="handleShowEditParams(row,'serviceCoreParamsData')">{{ $t('xiu-gai') }}
              </pd-button>
            </template>
          </Table>
        </div>
    </div>
    <Modal
      v-model="showParamsEditConfirm"
      :title="$t('que-ding-yao-sheng-xiao-yi-xia-pei-zhi')"
      footer-hide
      width="800px"
    >
      <div style="max-height: 500px;overflow: auto">
        <div id="elem"></div>
        <Table border size="small" :columns="editColumns" :data="editedParams">
          <template slot="configName" slot-scope="{ row }">
            <div :style="`color: ${row.needCreated ? '#ff6e0d' : ''}`">
              {{row.configName}}
              {{row.needCreated ? $t('dai-chuang-jian') : ''}}
            </div>
          </template>
          <template slot-scope="{ row }" slot="editedConfigValue">
            <div>
              <p style="position: relative;padding-right: 15px;">
                <a v-if="isJSON(row.configValue)" @click="handleShowJson(row,'read')"
                   style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block;color: #333;">
                  {{row.configValue }}
                  <a style="position: absolute;right: 0;font-size: 14px"
                     @click="handleShowJson(row,'read')">
                    <Icon type="md-arrow-dropdown"/>
                  </a>
                </a>
                <span v-if="!isJSON(row.configValue)">
                  {{row.configValue ? row.configValue : '-' }}
                </span>
              </p>
            </div>
          </template>
          <template slot-scope="{ row }" slot="editedCurrentCount">
            <div>
              <p style="position: relative;padding-right: 15px;">
                <a v-if="isJSON(row.currentCount)"
                   @click="handleShowJson(row,'read','currentCount')"
                   style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block;color: #333">{{
                    row.currentCount
                  }}
                  <a style="position: absolute;right: 0;font-size: 14px"
                     @click="handleShowJson(row,'read')">
                    <Icon type="md-arrow-dropdown"/>
                  </a>
                </a>
                <span v-if="!isJSON(row.currentCount)">{{
                    row.currentCount ? row.currentCount : '-'
                  }}</span>
              </p>
            </div>
          </template>
        </Table>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="updateUserConfig">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="hideUserConfigModal">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  getConfigTagList
} from '@services/api/job';
import { pick } from '@components/function/monitor/utils/colors';
import { getCurrUserConfigs, updateUserConfigs } from '@services/api/user';
import UtilJson from '../util';

export default {
  name: 'UserConfigParamsEdit',
  data() {
    return {
      userConfigList: [],
      currentTab: 'serviceCoreParamsData',
      tabLabels: [
        { name: 'serviceCoreParamsData', label: this.$t('ren-wu-he-xin-pei-zhi') },
        { name: 'sourceParamsData', label: this.$t('yuan-shu-ju-yuan-pei-zhi') },
        { name: 'targetParamsData', label: this.$t('mu-biao-shu-ju-yuan-pei-zhi') },
        { name: 'mappingParamsData', label: this.$t('ying-she-pei-zhi') }
      ],
      currentMode: 'view',
      tagList: [],
      ifRunning: false,
      sourceType: '',
      targetType: '',
      jobId: 0,
      jobInfo: '',
      selectedJson: {},
      editJson: {},
      selectedRow: '',
      showJson: false,
      showParamsEdit: false,
      currentValue: 0,
      selectedData: '',
      selectedParam: {},
      showParamsEditConfirm: false,
      showTagList: [],
      jobName: '',
      searchKey: {
        key: '',
        tag: []
      },
      paramsColumn: [
        {
          title: this.$t('can-shu-ming-cheng'),
          key: 'configName',
          slot: 'paramName',
          width: 300,
          sortable: true
        },
        {
          title: this.$t('can-shu-dang-qian-yun-hang-zhi'),
          key: 'configValue',
          width: 260,
          slot: 'configValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-can-shu-zhi'),
          key: 'currentCount',
          slot: 'currentValue',
          width: 160,
          filters: [
            {
              label: this.$t('ke-xiu-gai-can-shu'),
              value: 1
            },
            {
              label: this.$t('zhi-du-can-shu'),
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return !row.readOnly;
            }
            if (value === 2) {
              return row.readOnly;
            }
          }
        },
        {
          title: this.$t('ke-xiu-gai-fan-wei'),
          key: 'valueRange'
        },
        {
          title: this.$t('biao-qian'),
          width: 150,
          render: (h, params) => h('div', [
            h('Tooltip', {
              props: {
                content: UtilJson.tagInfo[params.row.userConfigTagType],
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('pd-button', {
                props: {
                  type: 'warning',
                  size: 'small',
                  ghost: true
                },
                style: {
                  color: pick(this.showTagList.indexOf(params.row.userConfigTagType)),
                  borderColor: pick(this.showTagList.indexOf(params.row.userConfigTagType))
                }
              }, params.row.userConfigTagType)
            ])
          ])
        }
      ],
      sourceParamsData: [],
      targetParamsData: [],
      mappingParamsData: [],
      serviceCoreParamsData: [],
      businessParamsData: [],
      sourceParamsDataMap: {},
      targetParamsDataMap: {},
      mappingParamsDataMap: {},
      serviceCoreParamsDataMap: {},
      businessParamsDataMap: {},
      editedParams: [],
      editColumns: [
        {
          title: this.$t('can-shu-ming'),
          key: 'configName',
          slot: 'configName',
          width: 300
        },
        {
          title: this.$t('dang-qian-yun-hang-zhi'),
          key: 'configValue',
          slot: 'editedConfigValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-zhi'),
          key: 'currentCount',
          slot: 'editedCurrentCount'
        }
      ]
    };
  },
  mounted() {
    this.getUserConfig();
    getConfigTagList()
      .then((res) => {
        if (res.data.code === '1') {
          this.tagList = res.data.data;
        }
      });
  },
  methods: {
    showUserConfigModal() {
      this.editedParams = [];
      this.userConfigList.forEach((item) => {
        if (((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue) || item.needCreated) {
          this.editedParams.push(item);
        }
      });

      if (this.editedParams.length === 0) {
        this.$Modal.warning({
          title: this.$t('cao-zuo-yi-chang'),
          content: this.$t('qing-xiu-gai-xu-yao-sheng-xiao-de-can-shu')
        });
      } else {
        this.showParamsEditConfirm = true;
      }
    },
    hideUserConfigModal() {
      this.showParamsEditConfirm = false;
    },
    async updateUserConfig() {
      const updateConfigs = {};
      const needCreateConfigs = {};
      this.editedParams.forEach((userConfig) => {
        if (userConfig.needCreated) {
          needCreateConfigs[userConfig.configName] = userConfig.currentCount || userConfig.defaultValue;
        } else {
          updateConfigs[userConfig.configName] = userConfig.currentCount;
        }
      });
      const res = await updateUserConfigs({ updateConfigs, needCreateConfigs });
      if (res.data.success) {
        this.$Message.success(this.$t('geng-xin-cheng-gong'));
        this.showParamsEditConfirm = false;
        await this.getUserConfig();
      }
    },
    async getUserConfig() {
      const res = await getCurrUserConfigs();
      if (res.data.success) {
        this.userConfigList = res.data.data;
      }
    },
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          const obj = JSON.parse(str);

          return Boolean(typeof obj === 'object' && obj);
        } catch (e) {
          return false;
        }
      }
    },
    handleShowJson(row, type, key) {
      if (type === 'edit') {
        this.currentMode = 'tree';
      } else {
        this.currentMode = 'view';
      }
      this.selectedRow = row;
      if (key) {
        if (!row[key]) {
          row[key] = row.configValue;
        }
        this.selectedJson = JSON.parse(row[key]);
        this.editJson = JSON.parse(row[key]);
      } else {
        this.selectedJson = JSON.parse(row.configValue);
        this.editJson = JSON.parse(row.configValue);
      }
      this.showJson = true;
    },
    handleReturn() {
      this.$router.push({
        path: `/data/job/${this.jobId}`
      });
    },
    handleShowEditParams(row, data) {
      row.currentCount = row.count;
      this.selectedParam = row;
      this.selectedData = data;
      this.showParamsEdit = true;
    },
    handleEditCurrent(row) {
      row.currentCount = this.currentValue;
      this.userConfigList.forEach((item) => {
        if (item.configName === row.configName) {
          item.currentCount = this.currentValue;
        }
      });
      this.currentValue = '';
      row.visible = false;
    },
    handlePopShow(row) {
      this.currentValue = row.count;
    },
    handleCancelEdit(row) {
      this.userConfigList.forEach((item) => {
        if (item.configName === row.configName) {
          item.currentCount = '';
        }
      });
      this.userConfigList = [...this.userConfigList];
    }
  }
};
</script>
<style lang="less" scoped>
.page-header {
  /*margin-top: 24px;*/
  /*margin-left: -16px;*/
  /*margin-right: -16px;*/

  .page-header-container {
    background: #fff;
    border-bottom: 1px solid #e8eaec;
  }

  .page-header-detail {
    display: flex;
  }

  .page-header-main {
    width: 100%;
    flex: 0 1 auto;

    .ivu-page-header-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .ivu-page-header-row {
    width: 100%;
  }

  .ivu-page-header-title {
    margin-bottom: 16px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    display: inline-block;
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
  }

  .ivu-page-header-content {
    margin-bottom: 16px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    font-size: 14px;
  }
}

.params-edit-wrapper {
  /*padding: 24px;*/
  /*background: #ffffff;*/
  margin-top: 10px;
  /*border: 1px solid #DADADA;*/
  /*overflow: auto;*/

  .ivu-tabs-nav-scroll {
    background-color: #ffffff;
    border-top: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    border-right: 1px solid #DADADA;
  }

  .iconfont {
    color: #8D95A6;
    cursor: pointer;
  }
}
</style>
