<template>
  <div>
    <div class="clean-data-search-container">
      <Select v-if="!DataSourceGroup.mq.includes(sourceType)" prefix="ios-search"
              @on-change="handleTableFilter('option',$event)"
              style="width:200px;margin-right: 10px" v-model="filterTable.tableFilterType">
        <Option value="hasWhere">{{ $t('yi-tian-jia-where-tiao-jian') }}</Option>
        <Option value="noWhere">{{ $t('wei-tian-jia-where-tiao-jian') }}</Option>
      </Select>
      <Input v-model="filterTable.filterTableName" :placeholder="$t('shai-xuan-biao')" style="width: 300px"
             @on-change="handleTableFilter('filter',$event)"/>
      <Dropdown style="float: right;margin-right: 20px;" v-if="!isMongoDB(sourceType)">
        <Button>
          {{ $t('pi-liang-cao-zuo') }}
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem v-if="isMySQL(sourceType)&&isMySQL(sinkType)"><span @click="handleShowTargetFilterAll">{{ $t('pi-liang-tian-jia-dui-duan-tiao-jian') }}</span></DropdownItem>
          <DropdownItem v-if="!DataSourceGroup.mq.includes(sourceType)"><span @click="handleShowFilterAll">{{ $t('pi-liang-tian-jia-shu-ju-guo-lv-tiao-jian') }}</span></DropdownItem>
          <DropdownItem v-if="DataSourceGroup.mysql.includes(sourceType)||DataSourceGroup.polar.includes(sourceType)"><span @click="handleSetTargetKey">{{ $t('pi-liang-she-zhi-mu-biao-zhu-jian') }}</span></DropdownItem>
<!--          <DropdownItem v-if="(DataSourceGroup.mysql.includes(sourceType)||DataSourceGroup.polar.includes(sourceType)||DataSourceGroup.oracle.includes(sourceType))&&DataSourceGroup.starrocks.includes(sinkType)">-->
<!--            <span @click="handleSetBucketKey">批量修改排序键和分桶键</span>-->
<!--          </DropdownItem>-->
          <DropdownItem v-if="taskInfo.processType!=='edit'"><span @click="handleShowOperateColumns">{{ $t('pi-liang-cai-jian-lie') }}</span></DropdownItem>
          <DropdownItem v-if="DataSourceGroup.redis.indexOf(sinkType)>-1"><span @click="handleBatchSetRedisKey">{{ $t('pi-liang-pei-zhi-redis-key') }}</span></DropdownItem>
          <DropdownItem v-if="DataSourceGroup.hive.indexOf(sinkType)>-1"><span @click="handleShowPartitionAll">{{ $t('pi-liang-pei-zhi-fen-qu-jian') }}</span></DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <pd-button style="float: right;margin-right: 20px" type="success" ghost
                 v-if="DataSourceGroup.hive.indexOf(sinkType)>-1&&taskInfo.partitionData.partition[0].func"
                 @click="handleShowPartitionAll">{{ $t('pei-zhi-fen-qu-jian-yi-pei-zhi') }}
      </pd-button>
      <Button v-if="taskInfo&&taskInfo.processType !== 'edit'&&(taskInfo.type==='SYNC'||taskInfo.type==='MIGRATION')&&!taskInfo.customPkgFile"
              style="float: right;margin-right: 16px;" @click="handleShowUploadPkg" v-permission="'jarPackage'">{{ $t('shang-chuan-zi-ding-yi-dai-ma') }}</Button>
      <Button type="success" ghost v-if="taskInfo&&taskInfo.customPkgFile"
              style="float: right;margin-right: 16px;" @click="handleShowUploadPkg">{{ $t('zi-ding-yi-dai-ma-cha-jian-yi-pei-zhi') }}</Button>
      <Button v-if="theMappingRule.length>1" style="float: right;margin-right: 20px" type="primary"
              ghost @click="handleShowMappingRule">{{ $t('mu-biao-ying-she-gui-ze') }}
      </Button>
      <div style="margin-left: 8px;display: inline-block" v-if="filterList.length>0">
        <span>{{ $t('jian-suo-xiang') }}</span>
        <span class="table-filter-filter-span" v-for="(filter,i) in filterList"
              :key="i">{{ Mapping.filterType[filter] }}<Icon type="md-close-circle"
                                                             @click="handleDeleteFilter(i)"/></span>
        <span class="table-filter-filter-clear" @click="handleClearFilter">{{ $t('qing-chu') }}</span>
      </div>
    </div>
    <Modal
      v-model="showUploadPkg"
      :title="$t('zi-ding-yi-dai-ma-chu-li-cha-jian')"
      footer-hide
    >
      <div>
        <Alert style="margin-bottom: 20px;font-size: 12px">{{ $t('zi-ding-yi-dai-ma-shi-shi-jia-gong-yun-xu-yong-hu-shi-yong-java-yu-yan-bian-xie-zi-ding-yi-de-shu-ju-hang-chu-li-luo-ji-shang-chuan-cloudcanal-ping-tai-hou-shu-ju-tong-bu-ren-wu-zhi-hang-quan-liang-zeng-liang-shi-hui-zi-dong-ying-yong-yong-hu-de-zi-ding-yi-chu-li-luo-ji-ran-hou-zai-xia-ru-dui-duan-shu-ju-yuan') }}<br><br>
          {{ $t('shi-li') }}<a target="_blank" :href="pkgUrl">{{pkgUrl}}</a>
        </Alert>
        <Form label-position="right" :label-width="80">
          <FormItem :label="$t('shang-chuan-cha-jian')">
        <span class="upload-btn" v-if="!customPkgFile">
                  <input style="margin-left: 10px" @change="handleFileChange" type = "file" name = "uploadfile" id = "uploadfile" />
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-LocalUpload"></use>
                  </svg>
                  {{ $t('dian-ji-zhe-li-shang-chuan-wen-jian') }}
          </span>
            <span v-if="customPkgFile" class="file-name-span">
            {{customPkgFile.name}}
            ({{(customPkgFile.size/(1024)).toFixed(1)}}KB)
            <Icon class="pkg-close" type="md-close" @click="handleRemovePkg"/>
<!--              <Icon class="pkg-upload-success" style="color: #00bc4a" type="md-checkmark" />-->
          </span>
          </FormItem>
          <FormItem :label="$t('miao-shu')" v-if="taskInfo">
            <Input style="width: 280px" v-model="taskInfo.pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpload">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { isMongoDB, isMySQL } from '@utils';
import Mapping from '@views/util';
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    handleShowMappingRule: Function,
    theMappingRule: Array,
    handleTableFilter: Function,
    filterTable: Object,
    handleShowFilterAll: Function,
    handleShowTargetFilterAll: Function,
    handleShowOperateColumns: Function,
    filterList: Array,
    handleDeleteFilter: Function,
    handleClearFilter: Function,
    handleGetColumnByTable: Function,
    selectedIndex: Number,
    getSelectedInfo: Function,
    getWhereCondition: Function,
    handleFilter: Function,
    handleSetPartition: Function,
    handleShowPartitionAll: Function,
    handleSetTargetKey: Function,
    handleSetBucketKey: Function,
    sourceType: String,
    sinkType: String,
    theType: String,
    handleBatchSetRedisKey: Function,
    taskInfo: Object,
    createReviseFromCheck: Boolean
  },
  data() {
    return {
      pkgUrl: 'https://gitee.com/clougence/cloudcanal-data-process',
      DataSourceGroup,
      Mapping,
      showUploadPkg: false,
      customPkgFile: null
    };
  },
  methods: {
    isMongoDB,
    isMySQL,
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: this.$t('wen-jian-guo-da'),
            content: this.$t('wen-jian-da-xiao-ying-bu-chao-guo-500m')
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleShowUploadPkg() {
      this.customPkgFile = this.taskInfo.customPkgFile;
      this.showUploadPkg = true;
    },
    handleConfirmUpload() {
      this.taskInfo.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
    },
    handleCancel() {
      this.showUploadPkg = false;
    },
    handleRemovePkg() {
      this.customPkgFile = null;
    }
  }

};
</script>
<style lang="less">
.clean-data-search-container {
  padding: 14px 20px;
}
.upload-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  vertical-align: middle;
  padding: 0 16px;
}
.upload-btn input {
  display: inline-block;
  position: absolute;
  font-size: 12px;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.file-name-span {
  height: 34px;
  line-height: 34px;
  background: #fafafa;
  padding: 8px 16px;
  .ivu-icon-md-close {
    cursor: pointer;
  }
}
</style>
