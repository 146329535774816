<template>
  <div class="confirm-account-body">
    <Form ref="resetPwdForm" :model="confirmData" label-position="right" :label-width="100"
          :rules="ruleValidate">
      <FormItem :label="$t('zhu-ce-shou-ji-hao-ma')" prop="phone">
        <Input v-model="confirmData.phone" style="width: 330px"></Input>
      </FormItem>
      <FormItem :label="$t('duan-xin-yan-zheng-ma')" prop="verifyCode">
        <Input style="width: 200px;" type="text" v-model="confirmData.verifyCode"
               :placeholder="!store.getters.isProductTrail?$t('qing-shu-ru-duan-xin-yan-zheng-ma'):$t('dian-ji-huo-qu-shu-ru-yan-zheng-ma')"/>
        <pd-button style="margin-left: 6px" :disabled="!sendcodeDisabled" type="primary" ghost
                   @click="handleVerify">
          {{ sendcodeDisabled ? $t('huo-qu-duan-xin-yan-zheng-ma') : $t('zai-ci-huo-qu-sendcodeagaintimes', [sendCodeAgainTime]) }}
        </pd-button>
        <span class="err-msg-content" style="color: #FF6E0D">{{ verifyError }}</span>
        <span class="err-msg-content" style="color: #FF6E0D">{{ errormsg }}</span>
      </FormItem>
      <FormItem :label="$t('xin-de-deng-lu-mi-ma')" prop="password">
        <Poptip trigger="focus" placement="right-start">
          <Input style="width: 330px" type="password" v-model="confirmData.password" password
                 :placeholder="$t('qing-she-zhi-mi-ma')" @on-change="handleCheckPassword"/>
          <div slot="content">
            <ul class="password-check">
              <li>
                <Icon :type="pwLength?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                <span>{{ $t('chang-du-wei-832-ge-zi-fu') }}</span></li>
              <li>
                <Icon :type="pwContain?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                <span>{{ $t('mi-ma-bu-neng-bao-han-shou-ji-hao') }}</span></li>
              <li>
                <Icon :type="pwFormat?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                <span>{{ $t('bi-xu-bao-han-ying-wen-he-shu-zi') }}</span></li>
            </ul>
          </div>
        </Poptip>
      </FormItem>
      <FormItem :label="$t('que-ren-mi-ma')" prop="passwordAgain">
        <Poptip trigger="focus" placement="right-start">
          <Input style="width: 330px" type="password" v-model="confirmData.passwordAgain" password
                 :placeholder="$t('qing-zai-ci-shu-ru-mi-ma')" @on-change="handleCheckPasswordAgain"/>
          <div slot="content">
            <div v-if="!pwConfirm" style="color: #ed4014;font-size: 12px;line-height: 20px">
              {{ $t('qing-zai-ci-shu-ru-xiang-tong-de-mi-ma') }}
            </div>
            <div v-if="pwConfirm" style="color: #19be6b;font-size: 12px;">
              <Icon type="md-checkmark" style="margin-right: 5px"/>
              {{ $t('qing-zai-ci-shu-ru-xiang-tong-de-mi-ma') }}
            </div>
          </div>
        </Poptip>
      </FormItem>
      <FormItem>
        <pd-button type="primary" style="width: 330px" @click="handleNext">{{ $t('xia-yi-bu') }}</pd-button>
      </FormItem>
    </Form>
    <div class="no-verify-tips">
      <p class="no-verify-tips-title">{{ $t('mei-shou-dao-duan-xin-yan-zheng-ma') }}</p>
      <p class="no-verify-tips-content">
        {{ $t('1-wang-luo-tong-xun-yi-chang-ke-neng-hui-zao-cheng-duan-xin-diu-shi-qing-zhong-xin-huo-qu-huo-shao-hou-zai-shi') }}
      </p>
      <p class="no-verify-tips-content">
        {{ $t('2-qing-he-shi-shou-ji-shi-fou-yi-qian-fei-ting-ji-huo-zhe-ping-bi-le-xi-tong-duan-xin') }}
      </p>
      <p class="no-verify-tips-content">
        {{ $t('3-ru-guo-shou-ji-yi-diu-shi-huo-ting-yong-qing-xuan-ze-qi-ta-yan-zheng-fang-shi') }}
      </p>
      <p class="no-verify-tips-content">
        {{ $t('4-nin-ye-ke-yi-chang-shi-jiang-sim-ka-yi-dong-dao-ling-yi-bu-shou-ji-ran-hou-zhong-shi') }}
      </p>
    </div>
  </div>
</template>
<script>
import { sendCode } from '@services/api/noError';
import { resetdo } from '@services/api/auth';
import store from '@store';

export default {
  props: {
    updateStep: Function,
    confirmData: Object
  },
  data() {
    return {
      sendCodeAgainTime: 60,
      sendcodeDisabled: true,
      verifyCodeType: 'RESET_PASSWORD',
      errormsg: '',
      verifyError: '',
      pwFormat: false,
      pwContain: false,
      pwLength: false,
      pwConfirm: false,
      store,
      ruleValidate: {
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          },
          {
            min: 8,
            message: this.$t('mi-ma-chang-du-wei-832'),
            trigger: 'blur'
          },
          {
            max: 32,
            message: this.$t('mi-ma-chang-du-wei-832'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                return callback(new Error(this.$t('qing-zai-ci-shu-ru-ni-de-mi-ma')));
              }
              if (!this.pwContain || !this.pwFormat) {
                return callback(new Error(this.$t('ge-shi-you-wu')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        passwordAgain: [
          {
            required: true,
            message: this.$t('que-ren-mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.confirmData.password) {
                return callback(new Error(this.$t('mi-ma-shu-ru-you-wu')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t('shou-ji-hao-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t('yan-zheng-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    handleVerify() {
      const that = this;
      const
        sendCodeAgain = setInterval(() => {
          if (that.sendCodeAgainTime > 0) {
            that.sendCodeAgainTime--;
          } else {
            clearInterval(sendCodeAgain);
            that.sendcodeDisabled = true;
          }
        }, 1000);

      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      sendCode({
        verifyType: 'SMS_VERIFY_CODE',
        email: '',
        phoneNumber: this.confirmData.phone,
        verifyCode: '',
        verifyCodeType: this.verifyCodeType
      })
        .then((res) => {
          this.errormsg = '';
          this.verifyError = '';
          if (res.data.code !== '1') {
            const msgList = res.data.msg.split('] ');

            if (msgList.length > 1) {
              this.verifyError = msgList[1];
            } else {
              this.verifyError = res.data.msg;
            }
            this.sendcodeDisabled = true;
            this.sendCodeAgainTime = 60;
            clearInterval(sendCodeAgain);
          }
        })
        .catch((e) => {
          this.errormsg = '';
          this.verifyError = e;
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(sendCodeAgain);
        });
    },
    handleNext() {
      this.$refs.resetPwdForm.validate((val) => {
        if (val) {
          resetdo({
            phone: this.confirmData.phone,
            verifyCode: this.confirmData.verifyCode,
            password: this.confirmData.password
          })
            .then((res) => {
              this.errormsg = '';
              this.verifyError = '';
              if (res.data.code === '1') {
                this.updateStep(1);
              } else {
                this.errormsg = res.data.msg;
              }
            })
            .catch((e) => {
              this.errormsg = e;
            });
        }
      });
    },
    handleCheckPassword() {
      this.pwLength = this.confirmData.password.length >= 8 && this.confirmData.password.length <= 32;
      this.pwContain = this.confirmData.password.indexOf(this.confirmData.phone) === -1;

      const pattern = /(?=.*[0-9])(?=.*[a-zA-Z])/;

      this.pwFormat = pattern.test(this.confirmData.password);
    },
    handleCheckPasswordAgain() {
      this.pwConfirm = this.confirmData.password && this.confirmData.password === this.confirmData.passwordAgain;
    }
  }
};
</script>
<style>
.confirm-account-body {
  width: 500px;
  margin: 0 auto;
  padding: 30px 0 220px 0;
  position: relative;
}

.no-verify-tips {
  position: absolute;
  bottom: 60px;
  left: 0;
  text-align: left;
}

.no-verify-tips-title {
  color: #888888;
  font-size: 14px;
  font-family: PingFangSC-Semibold, serif;
  font-weight: 500;
}

.no-verify-tips-content {
  color: #888888;
}

.err-msg-content {
  position: absolute;
  left: 330px;
  min-width: 300px;
}
</style>
