import { render, staticRenderFns } from "./DataJobDetail.vue?vue&type=template&id=6ab31b26&scoped=true&"
import script from "./DataJobDetail.vue?vue&type=script&lang=js&"
export * from "./DataJobDetail.vue?vue&type=script&lang=js&"
import style0 from "./DataJobDetail.vue?vue&type=style&index=0&id=6ab31b26&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab31b26",
  null
  
)

export default component.exports