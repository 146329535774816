<template>
  <Layout>
    <div class="header">
      <div class="header-content">
        <img :src="require('../assets/logo/loginHeader.png')" width="160" style="object-fit: cover;" />
        <span class="header-title">{{ showLogin ? $t("deng-lu")  : $t("zhu-ce") }}</span>
        <Dropdown @on-click="handleLangChange" transfer trigger="click">
          <a href="javascript:void(0)"  style="width: 100px;">
            {{LANG_I18N[$i18n.locale]}}
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem name="zh-CN" :selected="$i18n.locale==='zh-CN'">
              <div style="text-align: center">中文</div>
            </DropdownItem>
            <DropdownItem name="en-US" :selected="$i18n.locale==='en-US'">
              <div style="text-align: center">English</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div v-if="showLogin">
      <div class="login-container">
        <div class="login-content" :style="`background-image: url(${require('../assets/logo/loginBg.png')})`">
          <div class="home-login">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-box"></use>
            </svg>
            <div class="login-body">
              <div v-if="!globalSystemConfig.verify_code" style="width: 330px; margin-top: 30px">
                <div class="password-login-content">
                  <Alert type="error" show-icon v-if="!!errormsg">{{ errormsg }}</Alert>
                  <Alert type="error" show-icon v-if="verifyError">{{ verifyError }}</Alert>
                  <div>
                    <Input
                      style="width: 330px; margin-bottom: 20px"
                      type="text"
                      v-model="login.account"
                      :placeholder="$t('you-xiang-shou-ji-hao')"
                    />
                    <Input
                      style="width: 330px; margin-bottom: 20px"
                      type="password"
                      password
                      v-model="login.password"
                      @on-keydown="handleKeyDown"
                      :placeholder="$t('mi-ma')"
                    />
                    <pd-button
                      type="primary"
                      class="home-login-btn"
                      @click="handleLogin"
                      :loading="loading"
                      >{{ $t("deng-lu") }}</pd-button
                    >
                    <p style="margin-top: 20px; display: flex; justify-content: space-between">
                      <span v-if="!isCloudPrivate"
                        >{{ $t("mei-you-zhang-hao-qu") }}
                        <a @click="handleShowSignin">{{ $t("zhu-ce") }}</a></span
                      >
                      <a class="reset-password-btn" @click="handleReset">{{
                        $t("wang-ji-mi-ma")
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <Tabs
                v-else
                :value="login.loginType"
                style="width: 330px"
                :animated="false"
                @on-click="handleLoginType"
              >
                <TabPane :label="$t('zhang-hao-mi-ma-deng-lu')" name="LOGIN_PASSWORD">
                  <div class="password-login-content">
                    <Alert type="error" show-icon v-if="!!errormsg">{{ errormsg }}</Alert>
                    <Alert type="error" show-icon v-if="verifyError">{{ verifyError }}</Alert>
                    <div>
                      <Input
                        style="width: 330px; margin-bottom: 20px"
                        type="text"
                        v-model="login.account"
                        :placeholder="$t('you-xiang-shou-ji-hao')"
                      />
                      <Input
                        style="width: 330px; margin-bottom: 20px"
                        type="password"
                        password
                        v-model="login.password"
                        @on-keydown="handleKeyDown"
                        :placeholder="$t('mi-ma')"
                      />
                      <pd-button
                        type="primary"
                        class="home-login-btn"
                        @click="handleLogin"
                        :loading="loading"
                        >{{ $t("deng-lu") }}
                      </pd-button>
                      <p style="margin-top: 20px; display: flex; justify-content: space-between">
                        <span v-if="!isCloudPrivate"
                          >{{ $t("mei-you-zhang-hao-qu") }}
                          <a @click="handleShowSignin">{{ $t("zhu-ce") }}</a></span
                        >
                        <a class="reset-password-btn" @click="handleReset">{{
                          $t("wang-ji-mi-ma")
                        }}</a>
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane :label="$t('shou-ji-yan-zheng-ma-deng-lu')" name="LOGIN_VERIFY">
                  <div class="phone-login-content">
                    <Alert type="error" show-icon v-if="!!errormsg">{{ errormsg }}</Alert>
                    <Alert type="error" show-icon v-if="verifyError">{{ verifyError }}</Alert>
                    <div>
                      <Input
                        style="width: 330px; margin-bottom: 20px"
                        type="text"
                        v-model="login.account"
                        :placeholder="$t('shou-ji-hao')"
                      />
                      <Input
                        style="width: 207px; margin-bottom: 20px"
                        type="text"
                        v-model="login.verifyCode"
                        :placeholder="$t('qing-shu-ru-duan-xin-yan-zheng-ma')"
                      />
                      <pd-button
                        size="large"
                        style="margin-left: 6px; margin-bottom: 20px;height: 44px;"
                        :disabled="!sendcodeDisabled"
                        type="primary"
                        ghost
                        @click="handleVerify"
                      >
                        {{
                          sendcodeDisabled
                            ? $t("huo-qu-duan-xin-yan-zheng-ma")
                            : $t("zai-ci-huo-qu") + sendCodeAgainTime + "s)"
                        }}
                      </pd-button>
                      <pd-button
                        type="primary"
                        class="home-login-btn"
                        @click="handleLogin"
                        :loading="loading"
                        >{{ $t("deng-lu") }}
                      </pd-button>
                      <p style="margin-top: 10px" v-if="!isCloudPrivate">
                        {{ $t("mei-you-zhang-hao-qu") }}
                        <a @click="handleShowSignin">{{ $t("zhu-ce") }}</a>
                      </p>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <p style="margin-top: 16px; text-align: center">
        <span style="color: rgb(170, 170, 170)"
          >Copyright © 2020 ClouGence, Inc.<a
            href="http://www.miit.gov.cn/"
            target="_blank"
            style="color: rgb(170, 170, 170); margin-left: 10px"
            >浙ICP备20007605号-1</a
          ></span
        >
      </p>
    </div>
    <div class="home-signin" v-if="showSignin">
      <div class="home-signin-container">
        <div class="home-signin-content">
          <p class="welcome-title">{{ $t("huan-ying-zhu-ce-cloudcanal") }}</p>
          <div class="home-signin-hasAccount">
            {{ $t("yi-you-zhang-hao-qu")
            }}<a style="color: #0bb9f8" @click="handleLoginDireactly">{{ $t("deng-lu") }} </a>
          </div>
          <Alert type="error" show-icon v-if="errormsg">{{ errormsg }}</Alert>
          <Form
            ref="registerForm"
            :model="login"
            label-position="right"
            :label-width="100"
            :rules="ruleValidate"
          >
            <FormItem :label="$t('shou-ji-hao-ma')" prop="phone">
              <Input
                style="width: 380px"
                type="text"
                v-model="login.phone"
                :placeholder="$t('qing-shu-ru-shou-ji-hao')"
              />
            </FormItem>
            <FormItem :label="$t('duan-xin-yan-zheng-ma')" prop="verifyCode">
              <Input
                style="width: 244px"
                type="text"
                v-model="login.verifyCode"
                :placeholder="registerSmsPlaceholder"
              />
              <pd-button
                style="margin-left: 4px"
                :disabled="!sendcodeDisabled"
                @click="handleVerify"
                type="primary"
                ghost
              >
                {{
                  sendcodeDisabled
                    ? $t("huo-qu-duan-xin-yan-zheng-ma")
                    : "$t('zai-ci-huo-qu')" + sendCodeAgainTime + "s)"
                }}
              </pd-button>
              <!--                            <i v-if="verifyError" class="iconfont iconyichang" style="color: #FF6E0D;margin-left: 10px"></i>-->
              <span style="color: #ff6e0d">{{ verifyError }}</span>
            </FormItem>
            <FormItem :label="$t('you-xiang')" prop="email">
              <Input
                style="width: 380px"
                type="text"
                v-model="login.email"
                :placeholder="$t('qing-shu-ru-you-xiang')"
              />
            </FormItem>
            <FormItem :label="$t('mi-ma')" prop="password">
              <Poptip trigger="focus" placement="right-start">
                <Input
                  style="width: 380px"
                  type="password"
                  v-model="login.password"
                  password
                  :placeholder="$t('qing-she-zhi-mi-ma')"
                  @on-change="handleCheckPassword"
                />
                <div slot="content">
                  <ul class="password-check">
                    <li>
                      <Icon
                        :type="
                          pwLength ? 'ios-checkmark-circle-outline' : 'ios-close-circle-outline'
                        "
                      />
                      <span>{{ $t("chang-du-wei-832-ge-zi-fu") }}</span>
                    </li>
                    <li>
                      <Icon
                        :type="
                          pwContain ? 'ios-checkmark-circle-outline' : 'ios-close-circle-outline'
                        "
                      />
                      <span>{{ $t("mi-ma-bu-neng-bao-han-shou-ji-hao") }}</span>
                    </li>
                    <li>
                      <Icon
                        :type="
                          pwFormat ? 'ios-checkmark-circle-outline' : 'ios-close-circle-outline'
                        "
                      />
                      <span>{{ $t("bi-xu-bao-han-ying-wen-he-shu-zi") }}</span>
                    </li>
                  </ul>
                </div>
              </Poptip>
            </FormItem>
            <FormItem :label="$t('que-ren-mi-ma')" prop="passwordAgain">
              <Poptip trigger="focus" placement="right-start">
                <Input
                  style="width: 380px"
                  type="password"
                  v-model="login.passwordAgain"
                  password
                  :placeholder="$t('qing-zai-ci-shu-ru-mi-ma')"
                  @on-change="handleCheckPasswordAgain"
                />
                <div slot="content">
                  <div v-if="!pwConfirm" style="color: #ed4014; font-size: 12px; line-height: 20px">
                    {{ $t("qing-zai-ci-shu-ru-xiang-tong-de-mi-ma") }}
                  </div>
                  <div v-if="pwConfirm" style="color: #19be6b; font-size: 12px">
                    <Icon type="md-checkmark" style="margin-right: 5px" />
                    {{ $t("qing-zai-ci-shu-ru-xiang-tong-de-mi-ma") }}
                  </div>
                </div>
              </Poptip>
            </FormItem>
            <FormItem :label="$t('xing-ming')" prop="username">
              <Input
                style="width: 380px"
                type="text"
                v-model="login.username"
                :placeholder="$t('qing-shu-ru-xing-ming')"
              />
            </FormItem>
            <FormItem
              :label="$t('gong-si-ming-cheng')"
              v-if="deployMode === 'CLOUD_PUBLIC'"
              prop="company"
            >
              <Input
                style="width: 380px"
                type="text"
                v-model="login.company"
                :placeholder="$t('qing-shu-ru-gong-si-ming-cheng')"
              />
            </FormItem>
            <FormItem style="margin-bottom: 32px">
              <pd-button
                style="width: 380px"
                type="primary"
                class="home-signin-btn"
                :loading="loading"
                @click="handleSigninConfirm"
                >{{ $t("zhu-ce") }}
              </pd-button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div
        style="
          position: fixed;
          bottom: 0;
          z-index: 1;
          width: 100%;
          text-align: center;
          left: 0;
          padding: 20px 0 40px 0;
          background: #fff;
        "
      >
        <span style="color: rgb(170, 170, 170)"
          >Copyright © 2020 ClouGence, Inc.<a
            href="http://www.miit.gov.cn/"
            target="_blank"
            style="color: rgb(170, 170, 170); margin-left: 10px"
            >浙ICP备20007605号-1</a
          ></span
        >
      </div>
    </div>
  </Layout>
</template>
<script>
import { login, signin } from '@services/api/auth';
import { sendCode } from '@services/api/noError';
import { queryDeployMode, getGlobalSystemConfig } from '@services/api/constant';
import store from '@store';
import { LANG_I18N } from '../consts';

export default {
  name: 'Home',
  data() {
    return {
      store,
      globalSystemConfig: {},
      loading: false,
      minHeight: 0,
      showLogin: true,
      showSignin: false,
      sendcodeDisabled: true,
      errormsg: '',
      verifyError: '',
      routeName: '',
      sendCodeAgainTime: 60,
      verifyCodeType: 'LOGIN',
      login: {
        email: '',
        password: '',
        ifLogin: false,
        phone: '',
        username: '',
        company: '',
        code: '',
        verifyCode: '',
        account: '',
        loginType: 'LOGIN_PASSWORD',
        passwordAgain: '',
        inviteCode: ''
      },
      ruleValidate: {
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          },
          {
            min: 8,
            message: this.$t('mi-ma-chang-du-wei-832'),
            trigger: 'blur'
          },
          {
            max: 32,
            message: this.$t('mi-ma-chang-du-wei-832'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                return callback(new Error(this.$t('qing-zai-ci-shu-ru-ni-de-mi-ma')));
              }
              if (!this.pwContain || !this.pwFormat) {
                return callback(new Error(this.$t('ge-shi-you-wu')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        passwordAgain: [
          {
            required: true,
            message: this.$t('que-ren-mi-ma-bu-neng-wei-kong'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.login.password) {
                return callback(new Error(this.$t('mi-ma-shu-ru-you-wu')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: this.$t('you-xiang-bu-neng-wei-kong'), trigger: 'blur' },
          { type: 'email', message: this.$t('bu-zheng-que-de-you-xiang-ge-shi'), trigger: 'blur' }
        ],
        phone: [
          { required: true, message: this.$t('shou-ji-hao-bu-neng-wei-kong'), trigger: 'blur' }
        ],
        username: [
          { required: true, message: this.$t('yong-hu-ming-bu-neng-wei-kong'), trigger: 'blur' }
        ],
        company: [
          {
            required: true,
            message: this.$t('gong-si-ming-cheng-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        verifyCode: [
          { required: true, message: this.$t('yan-zheng-ma-bu-neng-wei-kong'), trigger: 'blur' }
        ]
      },
      pwLength: false,
      pwContain: false,
      pwFormat: false,
      pwConfirm: false,
      deployMode: 'CLOUD_PUBLIC'
    };
  },
  computed: {
    LANG_I18N() {
      return LANG_I18N;
    },
    isCloudPrivate() {
      return this.deployMode === 'CLOUD_PRIVATE';
    },
    registerSmsPlaceholder() {
      return !store.getters.isProductTrail
        ? this.$t('qing-shu-ru-duan-xin-yan-zheng-ma')
        : this.$t('dian-ji-huo-qu-shu-ru-yan-zheng-ma');
    }
  },
  mounted() {
    queryDeployMode().then((res) => {
      if (res.data.code === '1') {
        this.deployMode = res.data.data;
      }
    });
    getGlobalSystemConfig().then((res) => {
      if (res.data.code === '1') {
        this.globalSystemConfig = res.data.data;
        this.$store.commit('getGlobalConfig', res.data.data);
      }
    });
    this.minHeight = window.innerHeight;
    this.routeName = this.$route.name;
    // localStorage.removeItem("userInfo");
  },
  methods: {
    async handleLangChange(value) {
      await localStorage.setItem('lang', value);
      this.$router.go(0);
    },
    handleCheckPassword() {
      this.pwLength = this.login.password.length >= 8 && this.login.password.length <= 32;
      this.pwContain = this.login.password.indexOf(this.login.phone) === -1;

      const pattern = /(?=.*[0-9])(?=.*[a-zA-Z])/;

      this.pwFormat = pattern.test(this.login.password);
    },
    handleCheckPasswordAgain() {
      this.pwConfirm = Boolean(
        this.login.password && this.login.password === this.login.passwordAgain
      );
    },
    handleLogin() {
      if (
        this.login.loginType === 'LOGIN_PASSWORD'
        && (!this.login.account || !this.login.password)
      ) {
        this.verifyError = '';
        this.errormsg = this.$t('qing-shu-ru-wan-zheng-de-zhang-hao-mi-ma');
        return;
      }

      if (this.login.loginType === 'LOGIN_VERIFY') {
        if (!this.login.account) {
          this.verifyError = '';
          this.errormsg = this.$t('qing-shu-ru-shou-ji-hao');
          return;
        }

        if (!/^1[3456789]\d{9}$/.test(this.login.account)) {
          this.verifyError = '';
          this.errormsg = this.$t('qing-shu-ru-zheng-que-de-shou-ji-hao');
          return;
        }

        if (!this.login.verifyCode) {
          this.verifyError = '';
          this.errormsg = this.$t('qing-shu-ru-yan-zheng-ma');
          return;
        }
      }

      this.loading = true;
      login(this.login).then((res) => {
        if (res.data.code === '1') {
          if (decodeURIComponent(window.location.href).split('redirect=')[1]) {
            window.location.href = decodeURIComponent(window.location.href).split('redirect=')[1];
            window.location.reload();
          } else {
            this.$router.push({ path: '/data/job/list' });
            window.location.href = '/data/job/list';
            window.location.reload();
          }
        } else {
          const msgList = res.data.msg.split('] ');

          this.errormsg = '';
          this.verifyError = '';

          if (msgList.length > 1) {
            this.errormsg = msgList[1];
          } else {
            this.errormsg = res.data.msg;
          }
        }
        this.loading = false;
      });
    },
    handleLoginDireactly() {
      this.showSignin = false;
      this.showLogin = true;
      this.verifyCodeType = 'LOGIN';
      this.errormsg = '';
      this.verifyError = false;
      this.verifyError = '';
      this.login = {
        email: '',
        password: '',
        ifLogin: false,
        phone: '',
        username: '',
        company: '',
        code: '',
        verifyCode: '',
        account: '',
        loginType: 'LOGIN_PASSWORD',
        passwordAgain: '',
        inviteCode: ''
      };
    },
    handleSigninConfirm() {
      this.$refs.registerForm.validate((val) => {
        if (val) {
          this.loading = true;
          signin(this.login).then((res) => {
            this.loading = false;
            if (res.data.code === '1') {
              this.$Modal.success({
                title: this.$t('zhu-ce-cheng-gong'),
                content: this.$t(
                  'nin-yi-zhu-ce-cheng-gong-2s-hou-jiang-tiao-zhuan-deng-lu-ye-mian'
                ),
                onOk: () => {
                  this.handleLoginDireactly();
                }
              });
              this.errormsg = '';
              this.verifyError = '';
              const that = this;

              setTimeout(() => {
                that.$Modal.remove();
                that.handleLoginDireactly();
              }, 2000);
            } else {
              this.errormsg = res.data.msg;
            }
          });
        }
        // 表示校验成功或失败
      });
    },
    handleVerify() {
      let phonenumber = '';

      const that = this;
      const sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      if (this.verifyCodeType === 'LOGIN') {
        phonenumber = this.login.account;
      } else {
        phonenumber = this.login.phone;
      }
      // let p = new RegExp('^1(3([0-35-9]\\d|4[1-8])|4[14-9]\\d|5([0-35689]\\d|7[1-79])|66\\d|7[2-35-8]\\d|8\\d{2}|9[13589]\\d)\\d{7}$');
      //
      // if (!p.exec(phonenumber)){
      //     this.errormsg = '';
      //     this.verifyError = '请输入正确的手机号';
      // } else {
      this.errormsg = '';
      this.verifyError = '';

      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      sendCode({
        verifyType: 'SMS_VERIFY_CODE',
        email: '',
        phoneNumber: phonenumber,
        verifyCode: '',
        verifyCodeType: this.verifyCodeType
      })
        .then((res) => {
          if (res.data.code !== '1') {
            const msgList = res.data.msg.split('] ');

            this.errormsg = '';

            if (msgList.length > 1) {
              this.verifyError = msgList[1];
            } else {
              this.verifyError = res.data.msg
                && JSON.parse(res.data.msg)
                && JSON.parse(res.data.msg) instanceof Array
                ? JSON.parse(res.data.msg).join('/n')
                : res.data.msg;
            }
            this.sendcodeDisabled = true;
            this.sendCodeAgainTime = 60;
            clearInterval(sendCodeAgain);
          }
        })
        .catch((e) => {
          this.errormsg = '';
          this.verifyError = e;
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(sendCodeAgain);
        });
      // }
    },
    handleLoginType(name) {
      this.login.loginType = name;
      this.login.account = '';
      this.errormsg = '';
      this.verifyError = '';
    },
    handleInvitation() {
      this.$router.push({ path: '/home/invitation' });
      window.location.reload();
    },
    handleShowSignin() {
      this.showSignin = true;
      this.showLogin = false;
      this.verifyCodeType = 'REGISTER';
      this.errormsg = '';
      this.verifyError = false;
      this.verifyError = '';
      this.login = {
        email: '',
        password: '',
        ifLogin: false,
        phone: '',
        username: '',
        company: '',
        code: '',
        verifyCode: '',
        account: '',
        loginType: 'LOGIN_PASSWORD',
        passwordAgain: '',
        inviteCode: ''
      };
    },
    handleKeyDown(data) {
      if (data.keyCode === 13) {
        this.handleLogin();
      }
    },
    handleHome() {
      window.location.href = '/';
    },
    handleReset() {
      this.$router.push({
        path: '/login/reset'
      });
      window.location.reload();
    }
  }
};
</script>
<style lang="less">
.header {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 6px 1px 6px 0 rgba(0, 0, 0, 0.08);

  .header-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      font-size: 160px;
      display: inline-block;
      height: 80px;
      line-height: 80px;
      vertical-align: middle;
    }

    .header-title {
      font-size: 24px;
      color: #555555;
      vertical-align: middle;
      border-left: 1px solid #dadada;
      padding-left: 20px;
      margin-left: 20px;
      font-family: PingFangSC-Medium, serif;
      font-weight: 500;
    }
  }
}

.login-container {
  background-image: linear-gradient(30deg, #fef5ca, #4bb2bb);

  .login-content {
    width: 1440px;
    height: 545px;
    margin: 0 auto;
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 180px 60px;
    position: relative;

    .home-login {
      position: absolute;
      right: 160px;
      top: 60px;
      background: #ffffff;
      padding: 0 82px 64px 82px;

      .icon {
        position: absolute;
        right: 60px;
        font-size: 32px;
        top: -6px;
      }

      .ivu-input-prefix i,
      .ivu-input-suffix i {
        height: 44px;
        line-height: 44px;
        font-size: 20px;
      }

      .ivu-tabs-nav .ivu-tabs-tab {
        padding: 23px;
      }

      .login-title {
        font-size: 18px;
        margin-bottom: 16px;
        padding-left: 10px;
      }

      .password-login-content {
        padding-top: 46px;
        position: relative;

        .ivu-alert {
          position: absolute;
          top: 6px;
          width: 330px;
        }

        .reset-password-btn {
          //position: absolute;
          //right: 0;
          //top: 0;
          color: #333333;
          //font-size: 14px;
        }
      }

      .phone-login-content {
        padding-top: 46px;
        position: relative;

        .ivu-alert {
          position: absolute;
          top: 6px;
          width: 330px;
        }
      }

      .home-login-btn {
        width: 330px;
        height: 40px;
        line-height: 38px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, serif;
      }
    }
  }

  .ivu-input {
    height: 44px;
  }
}

.home-signin {
  padding-top: 60px;

  .home-signin-container {
    background: #ffffff;
    width: 1000px;
    margin: 0 auto;
    padding: 60px 0;
    position: relative;

    .ivu-form-item {
      margin-bottom: 16px;

      .ivu-form-item-content {
        overflow: visible;
        white-space: nowrap;
      }
    }

    .home-signin-hasAccount {
      position: absolute;
      right: -200px;
      top: 6px;
    }

    .home-signin-content {
      margin: 0 auto;
      width: 500px;
      position: relative;

      .welcome-title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 36px;
      }

      .ivu-alert {
        position: absolute;
        top: 28px;
        width: 480px;
      }
    }
  }

  .ivu-form-item-error-tip {
    position: relative;
    display: inline-block;
    color: #ff6e0d;
    margin-left: 8px;
  }
}
</style>
