<template>
  <div class="edit-db-container table-filter-nav">
    <div class="table-filter-nav-list">
      <div
        :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
        v-for="(sourceDb,index) in computedSourceSchema" :key="index"
        @click="handleChangeSelectDb(sourceDb,index)">
        <div class="table-filter-nav-item-arrow"></div>
        <div class="table-filter-nav-item-db">
          <i class="iconfont iconjiantou"></i>
          <p><i class="iconfont iconshujuku"></i>{{ sourceDb.db }}
            <span v-if="sourceDb.schemas">.{{ sourceDb.schemas[0].schema }}</span>
          </p>
          <p :class="sourceDb.targetAutoCreate?'db-to-create':''"><i
            :class="`iconfont ${sourceDb.targetAutoCreate?'icondaijianKU':'iconshujuku'}`"></i>
            <span>{{
                getTargetData(DataSourceGroup.hasSchema.indexOf(jobData.targetDsVO.dataSourceType) > -1 ? 'DB_SCHEMA'
                  :DataSourceGroup.hasSchema.includes(jobData.sourceDsVO.dataSourceType)?'SCHEMA_DB':'DB_DB', sourceDb)
              }}</span>
          </p>
        </div>
        <i class="iconfont icondel" @click.stop="handleDeleteDb(sourceDb)"></i>
      </div>
    </div>
    <div class="new-add-db-item" v-for="(db,i) in newAddDb" :key="i+db">
      <div v-if="DataSourceGroup.pg.indexOf(jobData.sourceDsVO.dataSourceType)===-1">
        <Select style="width: 252px" v-model="db.sourceDb" :placeholder="$t('qing-xuan-ze-yuan-shu-ju-ku')" transfer
                filterable>
          <Option :disabled="hasSelectedTheDb(sourceDb.dbName, 'source')"
                  v-for="(sourceDb,index1) in sourceDbList"
                  :value="sourceDb.dbName" :key="index1+sourceDb">{{ sourceDb.dbName }}
          </Option>
        </Select>
      </div>
      <div v-if="DataSourceGroup.pg.indexOf(jobData.sourceDsVO.dataSourceType)>-1">
        <Select style="width: 156px;margin-right: 6px" v-model="db.sourceDb" :placeholder="$t('qing-xuan-ze-yuan-shu-ju-ku')" transfer
                filterable>
          <Option :disabled="hasSelectedTheDb(sourceDb.dbName, 'source')"
                  v-for="(sourceDb,index1) in sourceDbList"
                  :value="sourceDb.dbName" :key="index1+sourceDb">{{ sourceDb.dbName }}
          </Option>
        </Select>
        <Select style="width: 90px" v-model="db.sourceSchema" placeholder="schema" transfer
                filterable>
          <Option v-for="(schema,index1) in getSchemaList(sourceDbList,db.sourceDb)" :value="schema"
                  :key="index1+schema">{{ schema }}
          </Option>
        </Select>
      </div>
      <div v-if="DataSourceGroup.hasSchema.indexOf(jobData.targetDsVO.dataSourceType)===-1">
        <Select style="width: 252px;margin-top: 10px" v-model="db.targetDb" :placeholder="$t('qing-xuan-ze-mu-biao-shu-ju-ku')"
                transfer
                filterable>
          <Option v-for="(targetDb,index1) in targetDbList"
                  :value="targetDb.dbName" :key="index1+targetDb">{{ targetDb.dbName }}
          </Option>
        </Select>
      </div>
      <div v-if="DataSourceGroup.hasSchema.indexOf(jobData.targetDsVO.dataSourceType)>-1">
        <Select style="width: 156px;margin-top: 10px;margin-right: 6px" v-model="db.targetDb" :placeholder="$t('qing-xuan-ze-mu-biao-shu-ju-ku')"
                transfer
                filterable>
          <Option v-for="(targetDb,index1) in targetDbList"
                  :value="targetDb.dbName" :key="index1+targetDb">{{ targetDb.dbName }}
          </Option>
        </Select>
        <Select style="width: 90px;margin-top: 10px" v-model="db.targetSchema" placeholder="schema"
                transfer
                filterable>
          <Option v-for="(schema,index1) in getSchemaList(targetDbList,db.targetDb)" :value="schema"
                  :key="index1+schema">{{ schema }}
          </Option>
        </Select>
      </div>
      <div style="margin-top: 10px">
        <Button style="margin-right: 10px" type="primary" @click="handleConfirmNewDb">{{ $t('que-ding') }}</Button>
        <Button @click="handleCancelAddDb">{{ $t('qu-xiao') }}</Button>
      </div>
    </div>
    <div class="add-db-item">
      <p><a @click="handleAddNewDb"><i class="iconfont iconadd"></i>{{ $t('zeng-jia-ku') }}</a></p>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    selectedIndex: Number,
    sourceSchema: Array,
    handleChangeSelectDb: Function,
    handleDeleteDb: Function,
    newAddDb: Array,
    handleAddNewDb: Function,
    reduceData: Object,
    jobData: Object,
    getTargetData: Function,
    handleConfirmNewDb: Function,
    handleCancelAddDb: Function,
    hasSelectedTheDb: Function,
    getSchemaList: Function,
    sourceDbList: Array,
    targetDbList: Array
  },
  data() {
    return {
      DataSourceGroup
    };
  },
  computed: {
    computedSourceSchema() {
      return this.sourceSchema.filter((sourceDb) => !(this.reduceData[sourceDb.db] && !this.reduceData[sourceDb.db].tableList));
    }
  }
};
</script>
<style lang="less" scoped>
.table-filter-nav {
  height: 573px;
  display: flex;
  flex-direction: column;

  .table-filter-nav-list {
    height: 528px;
    overflow: scroll;
  }

  .add-db-item {
    border-top: 1px solid #DADADA;
    border-bottom: none;
  }
  .new-add-db-item {
    border-top: 1px solid #DADADA;
    border-bottom: none;
  }

}
</style>
