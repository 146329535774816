<template>
  <div>
    <div @click="handleGetColumnByTable(table,index)"
         :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
         v-for="(table,index) in showTableList" :key="index">
      <div class="table-filter-nav-item-arrow"></div>
      <div class="table-filter-nav-item-db" v-if="type==='sink'">
        <p><i class="iconfont iconbiao"></i>{{ table.sourceDb }}.{{ table.sourceTable }}</p>
        <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow">
        <p style="word-break: break-all" :class="table.needAutoCreated?'db-to-create':''">
          <i :class="`iconfont ${table.needAutoCreated?'icontopic':'icontopic'}`"></i>
          <span>{{
              table.sinkTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.sinkTable || table.targetTable
            }}</span>
        </p>
        <div class="chosed-count" style="display: flex;justify-content: space-between;padding-right: 20px;">
          <div>
            {{ $t('yi-xuan-zhong') }}<span
            :class="getSelectedInfo(table.sourceDb,table.sourceTable).selectedCount<
                    (selectedColumns[table.sourceDb][table.sourceTable]?selectedColumns[table.sourceDb][table.sourceTable].length:0)?'warn-count':''"
            style="text-align: center;display: inline-block;">
                  {{ getSelectedInfo(table.sourceDb, table.sourceTable).selectedCount }}</span>
            /{{selectedColumns[table.sourceDb][table.sourceTable] ? selectedColumns[table.sourceDb][table.sourceTable].length : '0' }}{{ $t('ge-lie') }}
            <span style="margin-left: 10px"><span>{{ $t('fen-qu-shu') }}</span><span>{{ table.partitions }}</span>
                      <i v-if="table.needAutoCreated||taskInfo.processType==='edit'" style="margin-left: 8px;cursor: pointer"
                         @click="handleSetPartition(table)"
                         class="iconfont iconeditwhere"></i></span>
          </div>
          <Dropdown transfer trigger="click">
            <a href="javascript:void(0)">
              {{ $t('cao-zuo') }}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem v-if="(DataSourceGroup.mysql.includes(sourceType)||DataSourceGroup.polar.includes(sourceType))&&!DataSourceGroup.redis.includes(sinkType)">
                <a
                  style="color: #333333"
                  @click="handleChangePk(table)">
                  {{ $t('xiu-gai-mu-biao-zhu-jian') }}</a>
              </DropdownItem>
              <DropdownItem>
                <a style="color: #333"
                   :class="getWhereCondition(table)?'has-where-table':''"
                   @click="handleFilter(table)">
                   {{ $t('getwhereconditiontable-xiu-gai-tian-jia-where-tiao-jian', [getWhereCondition(table) ? $t('xiu-gai') : $t('tian-jia')]) }}</a>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div class="table-filter-nav-item-db" v-if="type==='source'">
        <p><i class="iconfont icontopic"></i>{{ table.sourceTable }}</p>
        <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow">
        <p style="word-break: break-all" :class="table.needAutoCreated?'db-to-create':''">
          <i :class="`iconfont ${table.needAutoCreated?'iconbiao':'iconbiao'}`"></i>
          <span><span
            v-if="table.sinkDb">{{
              table.sinkDb
            }}.</span>{{
              table.sinkTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.sinkTable
            }}</span>
        </p>
        <p class="chosed-count">{{ $t('yi-xuan-zhong') }}<span
          :class="getSelectedInfo(table.sinkDb,table.sinkTable).selectedCount<
                (selectedColumns[table.sinkDb][table.sinkTable]?selectedColumns[table.sinkDb][table.sinkTable].length:0)?'warn-count':''"
          style="text-align: center;display: inline-block;">
              {{ getSelectedInfo(table.sinkDb, table.sinkTable).selectedCount }}</span>
          /{{
            selectedColumns[table.sinkDb][table.sinkTable] ? selectedColumns[table.sinkDb][table.sinkTable].length : '0'
          }}{{ $t('ge-lie') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '@views/dataSourceGroup.json';

export default {
  props: {
    taskInfo: Object,
    handleGetColumnByTable: Function,
    selectedIndex: Number,
    showTableList: Array,
    getSelectedInfo: Function,
    getWhereCondition: {
      prop: Function,
      default: null
    },
    sourceType: String,
    sinkType: String,
    handleFilter: Function,
    selectedColumns: Object,
    handleSetPartition: Function,
    type: String,
    handleChangePk: Function,
    theType: String
  },
  data() {
    return {
      DataSourceGroup
    };
  }
};
</script>
<style lang="less" scoped>
.operation {
  &:hover {
    color: #0BB9F8!important;
  }
  position: absolute;
  right: 100px;
  color: #333333;
}
</style>
