<template>
  <div class="edit-db-container table-filter-nav">
    <div class="table-filter-nav-list">
      <div
      :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
      v-for="(sourceDb,index) in computedSourceSchema" :key="index"
      @click="handleChangeSelectDb(sourceDb,index)">
      <div class="table-filter-nav-item-db">
        <p
          v-if="DataSourceGroup.mq.indexOf(jobData.targetDsVO.dataSourceType)===-1&&DataSourceGroup.es.indexOf(jobData.targetDsVO.dataSourceType)===-1"
          :class="sourceDb.targetAutoCreate?'db-to-create':''"><i
          :class="`iconfont ${sourceDb.targetAutoCreate?'icondaijianKU':'iconshujuku'}`"></i>
          <span>{{ sourceDb.db }}</span>
        </p>
      </div>
      <i class="iconfont icondel" @click.stop="handleDeleteDb(sourceDb)"></i>
    </div>
    </div>
    <div class="new-add-db-item" v-for="(db,i) in newAddDb" :key="i+db">
      <div>
        <Select style="width: 252px;margin-top: 10px" v-model="db.targetDb" :placeholder="$t('qing-xuan-ze-mu-biao-shu-ju-ku')"
                transfer
                filterable>
          <Option :disabled="hasSelectedTheDb(targetDb.dbName)"
                  v-for="(targetDb,index1) in targetDbList"
                  :value="targetDb.dbName" :key="index1+targetDb">{{ targetDb.dbName }}
          </Option>
        </Select>
      </div>
      <div style="margin-top: 10px">
        <Button style="margin-right: 10px" type="primary" @click="handleConfirmNewDb">{{ $t('que-ding') }}</Button>
        <Button @click="handleCancelAddDb">{{ $t('qu-xiao') }}</Button>
      </div>
    </div>
    <div class="add-db-item">
      <p><a @click="handleAddNewDb"><i class="iconfont iconadd"></i>{{ $t('zeng-jia-ku') }}</a></p>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../../views/dataSourceGroup';

export default {
  props: {
    selectedIndex: Number,
    sourceSchema: Array,
    handleChangeSelectDb: Function,
    handleDeleteDb: Function,
    newAddDb: Array,
    handleAddNewDb: Function,
    reduceData: Object,
    jobData: Object,
    getTargetData: Function,
    handleConfirmNewDb: Function,
    handleCancelAddDb: Function,
    hasSelectedTheDb: Function,
    targetDbList: Array
  },
  data() {
    return {
      DataSourceGroup
    };
  },
  computed: {
    computedSourceSchema() {
      return this.sourceSchema.filter((sourceDb) => !(this.reduceData[sourceDb.db] && !this.reduceData[sourceDb.db].tableList));
    }
  }
};
</script>
<style lang="less" scoped>
.table-filter-nav {
  height: 573px;
  display: flex;
  flex-direction: column;

  .table-filter-nav-list {
    height: 528px;
    overflow: scroll;
  }

  .add-db-item {
    border-top: 1px solid #DADADA;
    border-bottom: none;
  }
  .new-add-db-item {
    border-top: 1px solid #DADADA;
    border-bottom: none;
  }
}
</style>
