import { render, staticRenderFns } from "./DataSourceHeader.vue?vue&type=template&id=aae06d26&scoped=true&"
import script from "./DataSourceHeader.vue?vue&type=script&lang=js&"
export * from "./DataSourceHeader.vue?vue&type=script&lang=js&"
import style0 from "./DataSourceHeader.vue?vue&type=style&index=0&id=aae06d26&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aae06d26",
  null
  
)

export default component.exports