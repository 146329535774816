<template>
  <div>
    <TaskInfoHeader :taskInfo="taskInfo" :structMigration="structMigration" v-if="taskInfo.processType === 'create'"/>
    <Tabs v-model="tab" @on-click="handleTabClick" v-else>
      <TabPane name="new" :label="$t('xin-zeng-de-ku-biao')" v-if="taskInfo.taskInfoNewData.length"></TabPane>
      <TabPane name="reduce" :label="$t('ti-chu-de-ku-biao')" v-if="taskInfo.taskInfoReduceData.length"></TabPane>
      <TabPane name="update" :label="$t('geng-xin-de-ku-biao')" v-if="taskInfo.taskInfoUpdateData.length"></TabPane>
    </Tabs>
    <div>
      <div v-for="(db) in taskInfoData" :key="db.sourceDb">
        <div class="task-db-mapping-title">
          <i class="iconfont iconshujuku"></i>{{ db.sourceDb }}<span
          v-if="DataSourceGroup.hasSchema.indexOf(taskInfo.sourceType)>-1">.{{
            db.sourceSchema
          }}</span>
          <Tooltip :content="taskInfo.sourceHostType === 'PRIVATE' ? taskInfo.sourcePrivateHost : taskInfo.sourcePublicHost" placement="right-start">
            （<span class="mapping-host">{{ taskInfo.sourceHostType === 'PRIVATE' ? taskInfo.sourcePrivateHost : taskInfo.sourcePublicHost}}</span>）
          </Tooltip>
          <Icon type="md-arrow-forward"/>
          <span :class="db.needAutoCreated||db.schemaAutoCreate?'db-to-create':''"><i
            :class="`iconfont ${db.needAutoCreated?'icondaijianKU':'iconshujuku'}`"></i><span><span
            v-if="db.sinkDb">{{ db.sinkDb }}</span> <span
            v-if="DataSourceGroup.hasSchema.indexOf(taskInfo.sinkType)>-1">.{{
              db.targetSchema
            }}</span>
            <Tooltip v-if="db.sinkDb" :content="taskInfo.targetHostType === 'PRIVATE' ? taskInfo.sinkPrivateHost : taskInfo.sinkPublicHost" placement="right-start">
            （<span class="mapping-host">{{taskInfo.targetHostType === 'PRIVATE' ? taskInfo.sinkPrivateHost : taskInfo.sinkPublicHost }}</span>）
          </Tooltip>
            <Tooltip v-if="!db.sinkDb" :content="taskInfo.sinkInstance" placement="left-start">
            （<span class="mapping-host">{{ taskInfo.sinkInstance }}</span>）
          </Tooltip></span></span><i
          v-if="db.sinkDb&&!sameItem(db.sinkDb,db.sourceDb)" class="iconfont iconyingshe"></i>
          <span class="task-db-mapping-info" v-if="!(taskInfo.processType === 'edit' && tab === 'reduce')">
                                            {{ $t('yi-xuan-zhong') }}<span
            :class="getSelectedInfo(db.selectedTables).count<db.selectedTables.length?'warn-count':''"
          >{{
              getSelectedInfo(db.selectedTables).count
            }}</span>{{ $t('dbselectedtableslength-zhang-biao-dai-chuang-jian', [db.selectedTables.length]) }}<span
            :class="getSelectedInfo(db.selectedTables).needToAutoCreated>0?'warn-count':''">{{
              getSelectedInfo(db.selectedTables).needToAutoCreated
            }}</span>{{ $t('dai-chuang-jian-0') }}<span
            :class="getSelectedInfo(db.selectedTables).mappingCount>0?'warn-count':''">{{
              getSelectedInfo(db.selectedTables).mappingCount
            }}</span>{{ $t('cun-zai-ying-she') }}
                                        </span>
        </div>
        <div class="task-db-mapping-content">
          <Collapse v-model="panelNum"
                    v-if="!taskInfo.showLoading[db.sourceDb]&&dbPage[db.sourceDb]&&dbPage[db.sourceDb].showTableList">
            <Panel :name="`${db.sourceDb+i}`" v-for="(table,i) in getShowTableList(db)"
                   :key="db.sourceDb+i">
                                                <span>
                                                  <i class="iconfont iconbiao"></i>
                                                  {{table.sourceTable }}
                                                  <span>
                                                    <Icon type="md-arrow-forward"/>
                                                  </span>
                                                  <span :class="table.needAutoCreated?'db-to-create':''">
                                                    <i :class="`iconfont ${taskInfo.sinkType==='RocketMQ'?'icontopic':table.needAutoCreated?'icondaijianBIAO':'iconbiao'}`"></i>
                                                    <span>{{table.sinkTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.sinkTable }}</span>
                                                    <Tooltip v-if="table.sinkTable !== table.originalSinkTable && taskInfo.processType === 'edit' && table.hasInJob"
                                                             style="margin-left: 10px;" transfer>
                                                      <Icon type="ios-information-circle-outline" />
                                                      <div slot="content">
                                                        {{table.originalSinkTable}}
                                                      </div>
                                                    </Tooltip>
                                                    <span v-if="table.suffixFields">
                                                      <span v-for="field in table.suffixFields" :key="field">
                                                        <span>:{</span>{{ field }}<span>}</span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <i v-if="table.sinkTable&&!sameItem(table.sinkTable,table.sourceTable)" class="iconfont iconyingshe"></i>
                                                    <span
                                                      style="margin-left: 5px;position: absolute;right: 16px;top: 0" v-if="!isEditAndReduce">
                                                        {{ $t('yi-xuan-zhong') }}
                                                      <span :class="db.selectedColumns&&getSelectColumns(db.selectedColumns,table).count<db.selectedColumns[table.sourceTable].length?'warn-count':''">
                                                        {{getSelectColumns(db.selectedColumns, table).count }}
                                                      </span>
                                                      {{ $t('dbselectedcolumnsdbselectedcolumnstablesourcetablelength0-ge-lie-dai-chuang-jian', [db.selectedColumns?db.selectedColumns[table.sourceTable].length:0]) }}
<!--                                                      <span :class="getSelectColumns(db.selectedColumns,table).needToAutoCreated>0?'warn-count':''">-->
<!--                                                        {{getSelectColumns(db.selectedColumns, table).needToAutoCreated }}-->
<!--                                                      </span>-->
                                                      <span :class="getSelectColumns(db.selectedColumns,table).mappingCount>0?'warn-count':''">
                                                        {{getSelectColumns(db.selectedColumns, table).mappingCount}}
                                                      </span>
                                                      {{ $t('cun-zai-ying-she') }}
                                                      <span v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&table.partitions">
                                                        {{ $t('fen-qu-shu-0') }}
                                                        <span class="warn-count">
                                                          {{table.partitions }}
                                                        </span>
                                                      </span>
                                                      <span v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1">
                                                        {{ $t('fen-pian-shu-0') }}
                                                        <span class="warn-count">
                                                          {{table.shards }}
                                                        </span>
                                                        {{ $t('fen-pian-fu-ben-shu-0') }}
                                                        <span class="warn-count">
                                                          {{table.replication }}
                                                        </span>
                                                      </span>
                                                      </span>
                                                      <span style="margin-left: 5px"
                                                            v-if="!(taskInfo.type==='CHECK'||taskInfo.type==='STRUCT_MIGRATION'||taskInfo.type==='MIGRATION'&&!taskInfo.mode.synchronize)">
                                                          <span class="job-info-action"
                                                                :style="getTableActionStyle(table.action,'i')">I</span>
                                                          <span class="job-info-action"
                                                                :style="getTableActionStyle(table.action,'u')">U</span>
                                                          <span class="job-info-action"
                                                                :style="getTableActionStyle(table.action,'d')">D</span>
                                                          <span class="job-info-action" v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)"
                                                                :style="getTableActionStyle(table.action,'c')">C</span>
                                                          <span class="job-info-action" v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)"
                                                                :style="getTableActionStyle(table.action,'a')">A</span>
                                                          <span class="job-info-action" v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)"
                                                                :style="getTableActionStyle(table.action,'r')">R</span>

                                                          <span class="job-info-action" v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)"
                                                                :style="getTableActionStyle(table.action,'t')">T</span>
                                                          <a style="margin-left: 10px"
                                                             :disabled="!table.whereCondition"
                                                             @click.stop="handleShowWhere(table,db.selectedColumns)">{{
                                                              !table.whereCondition ? $t('wei-she-zhi-shu-ju-guo-lv-tiao-jian') : $t('yi-she-zhi-shu-ju-guo-lv-tiao-jian')
                                                            }}</a>
                                                           <a style="margin-left: 10px"
                                                              :disabled="!table.targetWhereCondition"
                                                              @click.stop="handleShowTargetWhere(table,db.selectedColumns)">{{
                                                               !table.targetWhereCondition ? $t('wei-she-zhi-dui-duan-geng-xin-tiao-jian') : $t('yi-she-zhi-dui-duan-geng-xin-tiao-jian')
                                                             }}</a>
                                                          <a style="margin-left: 10px"
                                                             :disabled="!table.partitionData"
                                                             v-if="taskInfo.sinkType === 'Hive'"
                                                             @click.stop="handleShowPartition(table,db.selectedColumns)">{{
                                                              !table.partitionData ? $t('wei-she-zhi-fen-qu-jian') : $t('yi-she-zhi-fen-qu-jian')
                                                            }}</a>
                                                      </span>
                                                </span>
              <div slot="content">
                <Table border stripe size="small" :columns="getTableColumns"
                       :data="commonSelectColumns[db.sourceDb][table.sourceTable]" v-if="tab !== 'reduce'">
                  <template slot="source" slot-scope="{ row }">
                    <div>
                        <span
                          v-if="taskInfo.processType==='edit'"
                          :style="row.hasInJob && !row._checked ? 'color: red;font-weight: bold;' : !row.hasInJob && row._checked ? 'color: green;font-weight: bold' : 'color: black'">
                        {{row.sourceColumn}}
                      </span>
                      <span v-else>
                        {{row.sourceColumn}}
                      </span>
                      <Icon type="ios-key" style="margin-left: 5px;color: #ff9900" v-if="row.isPk"/>
                    </div>
                  </template>
                  <template slot="sinkColumn" slot-scope="{ row }">
                    <div style="display: flex;align-items: center">
                      <div style="flex: 1">
                        <div v-if="row.needAutoCreated" style="color: #FF6E0D">
                          {{row.sinkColumn}}
                        </div>
                        <div
                          style="color:#0BB9F8"
                          v-else-if="row.sinkColumn && !sameItem(row.sinkColumn, row.sourceColumn) && row.sinkColumn !== 'clougence_cloudcanal_filter_after_mapping_column'">
                          {{ $t('rowsinkcolumn-lie-cun-zai-ying-she', [row.sinkColumn]) }}
                        </div>
                        <div v-else>
                          {{mappingUtil.columnMapping[row.sinkColumn] || row.sinkColumn}}
                        </div>
                      </div>
                      <Tooltip v-if="row.sinkColumn !== row.originalSinkColumn && taskInfo.processType === 'edit' && row.originalSinkColumn" style="margin-left: 10px;" transfer>
                        <Icon type="ios-information-circle-outline" />
                        <div slot="content">
                          {{row.originalSinkColumn}}
                        </div>
                      </Tooltip>
                    </div>
                  </template>
                </Table>
                <div v-if="getVirtualColumns(`/${db.sinkDb}/${table.sinkTable}`).length>0">
                  <p
                    style="font-size: 14px;font-family: PingFangSC-Medium,serif;font-weight: 500;padding: 5px 16px">
                    {{ $t('xu-ni-lie-0') }}</p>
                  <Table border stripe size="small" :columns="virtualColumns"
                         :data="getVirtualColumns(`/${db.sinkDb}/${table.sinkTable}`)"></Table>
                </div>
              </div>
            </Panel>
          </Collapse>
          <Page v-if="dbPage[db.sourceDb]" style="margin-top: 10px;text-align: center"
                :total="dbPage[db.sourceDb].total" show-total
                @on-change="handlePageChange(db.sourceDb,$event)"/>
        </div>
      </div>
    </div>
    <Modal v-model="showWhereCondition" :title="$t('shu-ju-guo-lv-tiao-jian')">
      <div style="word-break: break-all">
        {{ whereCondition }}
      </div>
    </Modal>
    <Modal v-model="showTargetWhereCondition" :title="$t('dui-duan-geng-xin-tiao-jian')">
      <div style="word-break: break-all">
        {{ targetWhereCondition }}
      </div>
    </Modal>
    <Modal v-model="showPartition" :title="$t('fen-qu-she-zhi')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80" v-if="partition.partition[0]">
          <FormItem :label="$t('fen-qu-jian')">
            {{ partition.partition[0].key }}
          </FormItem>
          <FormItem :label="$t('fen-qu-fang-fa')">
            {{ partition.partition[0].func }}
          </FormItem>
          <FormItem :label="$t('wen-jian-ge-shi')">
            {{ partition.fileFormat }}
          </FormItem>
          <FormItem :label="$t('ya-suo-fang-shi')">
            {{ partition.compressType }}
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import mappingUtil from '@views/util';
import { editJobInitialSyncSwitch } from '@services/api/constant';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import TaskInfoHeader from './taskInfo/TaskInfoHeader';

export default {
  props: {
    taskInfo: Object
  },
  components: { TaskInfoHeader },
  created() {
    if (this.taskInfo.processType === 'edit') {
      this.editJobInitialSyncSwitch();
      this.taskInfoNewData = this.taskInfo.taskInfoNewData;
      this.taskInfoReduceData = this.taskInfo.taskInfoReduceData;
      this.taskInfoUpdateData = this.taskInfo.taskInfoUpdateData;

      if (this.taskInfo.taskInfoNewData.length) {
        this.tab = 'new';
        this.taskInfoData = this.taskInfoNewData;
      }

      if (this.taskInfo.taskInfoReduceData.length) {
        this.tab = 'reduce';
        this.taskInfoData = this.taskInfoReduceData;
      }

      if (this.taskInfo.taskInfoUpdateData.length) {
        this.tab = 'update';
        this.taskInfoData = this.taskInfoUpdateData;
      }
    } else {
      this.taskInfoData = this.taskInfo.dbMap;
    }

    this.generateDbPageData();
  },
  data() {
    return {
      mappingUtil,
      taskInfoData: [],
      tab: 'new',
      taskInfoNewData: [],
      taskInfoReduceData: [],
      taskInfoUpdateData: [],
      DataSourceGroup,
      showWhereCondition: false,
      showTargetWhereCondition: false,
      showPartition: false,
      structMigration: false,
      whereCondition: '',
      targetWhereCondition: '',
      selectedColumns: {},
      selectedTables: {},
      panelNum: '',
      dbPage: {},
      newDbPage: {},
      reduceDbPage: {},
      partition: { partition: [] },
      filterTable: {},
      tableColumn: [
        {
          title: this.$t('yuan-zi-duan'),
          slot: 'source'
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('mu-biao-zi-duan'),
          slot: 'sinkColumn'
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sinkType',
          width: 160
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 120
        }
      ],
      mqTableColumn: [
        {
          title: this.$t('yuan-zi-duan'),
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('xiao-xi-lie-ming-cheng'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        }
      ],
      esTableColumn: [
        {
          title: this.$t('yuan-zi-duan'),
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('mu-biao-suo-yin-zi-duan'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        },
        {
          title: this.$t('lei-xing'),
          key: 'sinkType',
          width: 120
        },
        {
          title: this.$t('shi-fou-suo-yin'),
          key: 'isIndex',
          width: 120,
          render: (h, params) => h('div', {}, params.row.isIndex ? this.$t('shi') : '')
        },
        {
          title: this.$t('fen-ci-qi'),
          key: 'analyzer',
          width: 120
        }
      ],
      commonSelectColumns: {},
      virtualColumns: [
        {
          title: this.$t('xu-ni-lie-ming-cheng'),
          key: 'fieldName'
        },
        {
          title: this.$t('xu-ni-lie-lei-xing'),
          key: 'typeName'
        },
        {
          title: this.$t('xu-ni-lie-chang-du'),
          key: 'length'
        },
        {
          title: this.$t('lie-zhi'),
          key: 'fieldValue'
        }
      ]
    };
  },
  computed: {
    isEditAndReduce() {
      return this.taskInfo.processType === 'edit' && (this.tab === 'reduce');
    },
    isEditAndUpdate() {
      return this.taskInfo.processType === 'edit' && (this.tab === 'update');
    },
    getShowTableList() {
      return (db) => {
        const list = [];

        this.dbPage[db.sourceDb].showTableList.forEach((table) => {
          if (table.selected || this.isEditAndReduce || this.isEditAndUpdate) {
            list.push(table);
          }
        });
        return list;
      };
    },
    getTableActionStyle() {
      return (action, type) => {
        let formatAction = 'color:#c5c8ce';

        action.forEach((item) => {
          if (item === 'INSERT' && type === 'i') {
            formatAction = 'color:#19be6b';
          } else if (item === 'UPDATE' && type === 'u') {
            formatAction = 'color:#19be6b';
          } else if (item === 'DELETE' && type === 'd') {
            formatAction = 'color:#19be6b';
          } else if (item === 'CREATE' && type === 'c') {
            formatAction = 'color:#19be6b';
          } else if (item === 'ALTER' && type === 'a') {
            formatAction = 'color:#19be6b';
          } else if (item === 'DROP' && type === 'dr') {
            formatAction = 'color:#19be6b';
          } else if (item === 'RENAME' && type === 'r') {
            formatAction = 'color:#19be6b';
          } else if (item === 'TRUNCATE' && type === 't') {
            formatAction = 'color:#19be6b';
          }
        });
        return formatAction;
      };
    },
    getSelectColumns() {
      return (obj, table) => {
        let count = 0;
        let mappingCount = 0;
        let
          needToAutoCreated = 0;

        if (obj) {
          Object.keys(obj)
            .forEach((item) => {
              if (item === table.sourceTable) {
                obj[item].forEach((i) => {
                  if (i.selected) {
                    count++;
                    if (i.needAutoCreated) {
                      needToAutoCreated++;
                    }

                    if (i.sinkColumn && i.sourceColumn !== i.sinkColumn) {
                      mappingCount++;
                    }
                  }
                });
              }
            });
        }
        return {
          count,
          needToAutoCreated,
          mappingCount
        };
      };
    },
    getSelectedInfo() {
      return (list) => {
        let count = 0;
        let mappingCount = 0;
        let
          needToAutoCreated = 0;

        if (list) {
          list.forEach((item) => {
            if (item.selected) {
              count++;
              if (item.needAutoCreated) {
                needToAutoCreated++;
              }
              if (this.taskInfo.targetCaseSensitive === 'false') {
                if (item.sinkTable && item.sourceTable.toUpperCase() !== item.sinkTable.toUpperCase()) {
                  mappingCount++;
                }
              } else if (this.taskInfo.commonRule === 'UpperCase') {
                if (item.sinkTable && item.sourceTable.toUpperCase() !== item.sinkTable.toUpperCase()) {
                  mappingCount++;
                }
              } else if (this.taskInfo.commonRule === 'LowerCase') {
                if (item.sinkTable && item.sourceTable.toLowerCase() !== item.sinkTable.toLowerCase()) {
                  mappingCount++;
                }
              } else if (item.sinkTable && item.sourceTable !== item.sinkTable) {
                mappingCount++;
              }
            }
          });
        }
        return {
          count,
          needToAutoCreated,
          mappingCount
        };
      };
    },
    sameItem() {
      return (sink, source) => {
        let hasSame = false;

        if (this.taskInfo.targetCaseSensitive === 'false') {
          if (sink.toUpperCase() === source.toUpperCase()) {
            hasSame = true;
          }
        } else if (this.taskInfo.commonRule === 'UpperCase') {
          if (sink.toUpperCase() === source.toUpperCase()) {
            hasSame = true;
          }
        } else if (this.taskInfo.commonRule === 'LowerCase') {
          if (sink.toLowerCase() === source.toLowerCase()) {
            hasSame = true;
          }
        } else if (sink === source) {
          hasSame = true;
        }
        return hasSame;
      };
    },
    getTableColumns() {
      if (DataSourceGroup.es.indexOf(this.taskInfo.sinkType) > -1) {
        return this.esTableColumn;
      }
      if (DataSourceGroup.mq.indexOf(this.taskInfo.sinkType) > -1) {
        return this.mqTableColumn;
      }
      return this.tableColumn;
    },
    getVirtualColumns() {
      return (path) => {
        let list = [];

        this.taskInfo.virtualColumns.forEach((item) => {
          if (item.fieldPathName === path) {
            list = item.customFieldList;
          }
        });
        return list;
      };
    }
  },
  methods: {
    editJobInitialSyncSwitch() {
      editJobInitialSyncSwitch({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      }).then((response) => {
        if (response.data.code === '1') {
          this.taskInfo.showChooseInitialSync = response.data.data;
        }
      });
    },
    handleTabClick(name) {
      switch (name) {
        case 'new':
          this.taskInfoData = this.taskInfoNewData;
          break;
        case 'update':
          this.taskInfoData = this.taskInfoUpdateData;
          break;
        case 'reduce':
          this.taskInfoData = this.taskInfoReduceData;
          break;
        default:
          break;
      }
      // this.taskInfoData = name === 'new' ? this.taskInfoNewData : this.taskInfoReduceData;
      this.generateDbPageData();
    },
    generateDbPageData() {
      this.dbPage = {};
      this.commonSelectColumns = {};
      this.taskInfoData.forEach((item) => {
        this.taskInfo.showLoading[item.sourceDb] = true;

        this.initData(item);

        this.taskInfoData.forEach((db) => {
          if (db.needAutoCreated) {
            this.structMigration = true;
          }
          db.selectedTables.forEach((table) => {
            if (table.selected) {
              if (table.needAutoCreated) {
                this.structMigration = true;
              }
              if (db.selectedColumns && db.selectedColumns[table.sourceTable]) {
                db.selectedColumns[table.sourceTable].forEach((column) => {
                  if (column.selected) {
                    if (column.needAutoCreated) {
                      this.structMigration = true;
                    }
                  }
                });
              }
            }
          });
        });
      });
    },
    handlePageChange(db, page) {
      this.dbPage[db].page = page;
      this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
      this.dbPage = { ...this.dbPage };
    },
    handleGetSelectedColumns(dbName) {
      const commonSelectEdColumns = {};

      if (this.selectedColumns[dbName]) {
        Object.keys(this.selectedColumns[dbName])
          .forEach((item) => {
            if (this.selectedColumns[dbName][item] instanceof Array) {
              this.selectedColumns[dbName][item].forEach((column, i) => {
                if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb]) {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                } else {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb] = {};
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                }
              });
            }
          });
      }
      this.taskInfo.dbMap.forEach((item) => {
        if (commonSelectEdColumns[item.sourceDb]) {
          item.selectedColumns = commonSelectEdColumns[item.sourceDb];
        }
      });
    },
    initData(item) {
      let total = 0;

      const that = this;
      const
        selectedTables = [];

      if (item.selectedTables) {
        item.selectedTables.forEach((selectedTable) => {
          if (selectedTable.selected || this.isEditAndReduce || this.isEditAndUpdate) {
            total++;
            selectedTables.push(selectedTable);
          }
        });
      }
      that.dbPage[item.sourceDb] = {
        total,
        page: 1,
        pageSize: 10,
        showTableList: selectedTables.slice(0, 10),
        filteredTableList: _.cloneDeep(selectedTables),
        tableList: selectedTables
      };
      that.commonSelectColumns[item.sourceDb] = {};

      if (item.selectedColumns) {
        Object.keys(item.selectedColumns)
          .forEach((key) => {
            if (!that.commonSelectColumns[item.sourceDb][key]) {
              that.commonSelectColumns[item.sourceDb][key] = [];
            }
            const trueSelectedColumns = [];

            item.selectedColumns[key].forEach((column) => {
              if ((column.selected && this.taskInfo.processType === 'create')
                || (!column.selected && column.edit
                || (this.taskInfo.processType === 'edit'
                    && this.isColumnEdited(column)))) {
                trueSelectedColumns.push(column);
              }
            });
            that.commonSelectColumns[item.sourceDb][key] = trueSelectedColumns;
          });
      }

      // that.dbPage = { ...that.dbPage };
      that.taskInfo.showLoading[item.sourceDb] = false;
    },
    isColumnEdited(column) {
      return (column.hasInJob && !column._checked)
        || (!column.hasInJob && column._checked)
      || (column._checked
          && ((column.sinkColumn !== column.originalSinkColumn) || (column.isPk !== column.originalIsPk))
      );
    },
    handleShowTargetWhere(table) {
      this.showTargetWhereCondition = true;
      this.targetWhereCondition = `update ... from ${table.sourceTable} where ${table.targetWhereCondition}`;
    },
    handleShowWhere(table, columns) {
      this.showWhereCondition = true;
      this.whereCondition = `select ${this.getColumns(table.sourceDb, table.sourceTable, columns)} from ${table.sourceTable} where ${table.whereCondition}`;
    },
    handleShowPartition(table) {
      this.showPartition = true;
      this.partition = table.partitionData;
    },
    getColumns(db, table, column) {
      let columns = '';

      column[table].forEach((item, index) => {
        if (index !== column[table].length - 1) {
          columns += `${item.sourceColumn},`;
        } else {
          columns += item.sourceColumn;
        }
      });
      return columns;
    }
  }
};
</script>
<style lang="less" scoped>
.task-info-db-map {
  .ivu-card-head {
    padding: 14px 16px 14px 0;
  }
}

.job-info-action {
  display: inline-block;
  width: 10px;
  font-weight: bold;
  text-align: center;
  color: #19be6b;
}
.job-info-action-wider {
  width: 16px;
}

.task-db-mapping-title {
  background-color: #CCEFFF;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #DADADA;
  padding: 0 16px;

  .task-db-mapping-info {
    float: right;
  }
  .mapping-host {
    max-width: 300px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.task-db-mapping-content {
  padding: 10px;
}

.task-db-mapping-title .iconfont, .task-db-mapping-content .iconfont {
  margin-right: 10px;
}

.iconyingshe {
  margin-left: 6px;
  color: #0BB9F8;
}
</style>
