<template>
  <div>
    <Table :row-class-name="rowClassName" stripe size="small" border :columns="virtualColumns"
           :data="virtualList" height="120">
      <template slot="targetVirtualName" slot-scope="{row}">
        {{ row.isCustom ? row.customName : row.targetVirtualName }}
      </template>
      <template slot="targetVirtualLength" slot-scope="{row}">
        {{
row.columnLength ? row.columnLength : row.targetVirtualLength && row.targetVirtualLength.match(/\((.+)\)/g)
            && row.targetVirtualLength.match(/\((.+)\)/g)[0]
            && row.targetVirtualLength.match(/\((.+)\)/g)[0].substr(1, row.targetVirtualLength.match(/\((.+)\)/g)[0].length - 2)
        }}
      </template>
      <template slot="targetVirtualType" slot-scope="{row,index}">
        <Select v-model="row.targetVirtualType" transfer
                @on-change="handleChangeRow(row,index,'targetVirtualType',$event)">
          <Option value="varchar">varchar</Option>
        </Select>
      </template>
      <template slot="columnValue" slot-scope="{row,index}">
        <Input v-model="row.columnValue"
               @on-change="handleChangeInputRow(row,index,'columnValue',$event)"/>
      </template>
      <template slot="action" slot-scope="{index}">
        <i style="color: #FF1815;cursor: pointer" class="iconfont iconDEL-R"
           @click="handleDeleteVirtual(index,selectedTable)"></i>
      </template>
    </Table>
  </div>
</template>
<script>
import store from '../../../../../store/index';

export default {
  props: {
    selectedTable: Object,
    virtualList: Array,
    handleDeleteVirtual: Function
  },
  data() {
    return {
      store,
      virtualColumns: [
        {
          title: this.$t('xu-ni-lie-ming-cheng'),
          slot: 'targetVirtualName'
        },
        {
          title: this.$t('xu-ni-lie-lei-xing'),
          key: 'targetVirtualType'
        },
        {
          title: this.$t('xu-ni-lie-chang-du'),
          slot: 'targetVirtualLength'
        },
        {
          title: this.$t('lie-zhi'),
          key: 'columnValue'
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action'
        }
      ]
    };
  },
  methods: {
    handleChangeRow(row, index, key, data) {
      this.virtualColumnData[index][key] = data;
    },
    handleChangeInputRow(row, index, key) {
      this.virtualColumnData[index][key] = row[key];
    },
    handleAddVirtualColumn() {
      this.virtualColumnData.push({
        targetVirtualName: '',
        targetVirtualType: '',
        columnValue: ''
      });
    },
    rowClassName(row) {
      if (row.isCustom) {
        return 'db-to-create';
      }
      return '';
    }
  }
};
</script>
