<template>
  <div>
    <div class="job-detail-state-container job-detail-no-metric">
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>{{ $t('jie-gou-qian-yi') }}
          <a @click="handleShowLog(task.dataTaskId)">{{ $t('cha-kan-ri-zhi') }}</a>
          <Tooltip placement="bottom" class="alarm-icon" transfer :content="$t('cun-zai-yi-chang-de-hou-tai-ren-wu-qing-dian-ji-chu-li')"
                   v-if="jobData.consoleTaskState==='FAILED'">
            <span style="display: block" @click="handleGoConsoleJob"><i
              class="iconfont iconyibuforce"></i></span>
          </Tooltip>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('BUILD_STRUCT')"
                    :percent="parseInt(parseFloat(JSON.parse(taskList['BUILD_STRUCT'].taskPosition).builtPercent) * 100, 10)"
                    :size="200">
            <span class="detail-Circle-inner"
                  style="font-size:50px">{{
                parseInt(parseFloat(JSON.parse(taskList['BUILD_STRUCT'].taskPosition).builtPercent) * 100, 10)
              }}%</span>
          </i-circle>
          <p style="margin-top: 32px">{{ $t('yi-chuang-jian') }} <span
            style="font-weight: 600">{{
              JSON.parse(taskList['BUILD_STRUCT'].taskPosition).built
            }}</span>{{ $t('zhang-biao') }}
          </p>
          <p style="margin-top: 8px">{{ $t('gong') }}<span
            style="font-weight: 600">{{
              JSON.parse(taskList['BUILD_STRUCT'].taskPosition).totalToBuild
            }}</span>{{ $t('zhang-biao') }}
          </p>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <p class="job-detail-state-progress-title"><span class="status-point"></span>{{ $t('suo-you-qian-yi-ku-biao') }}</p>
        <Table stripe style="margin-top: 20px" :border="false" size="small"
               :columns="structColumns" :data="showStructData"></Table>
        <Page size="small" style="margin-top:20px;text-align: center" :total="structData.length"
              show-total @on-change="handleStructPageChange"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    task: Object,
    handleShowLog: Function,
    jobData: Object,
    getCurrentStateColor: Function,
    taskList: Object,
    showStructData: Array,
    structData: Array,
    handleStructPageChange: Function
  },
  data() {
    return {
      structColumns: [
        {
          title: this.$t('shu-ju-ku'),
          key: 'schema'
        },
        {
          title: this.$t('biao'),
          key: 'table'
        }
      ]
    };
  },
  methods: {
    handleGoConsoleJob() {
      this.$router.push({ path: `/system/state/task/${this.jobData.consoleJobId}` });
    }
  }
};
</script>
