<template>
  <div>
    <Breadcrumb>
      <BreadcrumbItem to="/data/job/list">{{ $t('ren-wu-guan-li') }}</BreadcrumbItem>
      <BreadcrumbItem :to="`/data/job/${jobId}`">{{ $t('ren-wu-xiang-qing') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ $t('jobinfodatajobname-ren-wu-de-can-shu-xiu-gai', [jobInfo.dataJobName]) }}</BreadcrumbItem>
    </Breadcrumb>
    <ParamHeader style="margin-top: 10px" :handleSearch="handleSearch"
                 :handleShowConfirmEditParams="handleShowConfirmEditParams"></ParamHeader>
    <div class="params-edit-wrapper" style="position: relative">
      <div>
      </div>
      <div>
        <Tabs v-model="currentTab" :animated="false">
          <TabPane v-for="tab in tabLabels" :label="tab.label" :name="tab.name" :key="tab.name" />
        </Tabs>
        <div>
          <Table border size="small" :columns="paramsColumn" :data="getParamsData"
                 max-height="600" stripe>
            <template slot-scope="{ row }" slot="configValue">
              <p style="position: relative;padding-right: 15px;">
                                    <span v-if="isJSON(row.configValue)"
                                          style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block">{{
                                        row.configValue
                                      }}
                                        <a style="position: absolute;right: 0;font-size: 14px"
                                           @click="handleShowJson(row)"><Icon
                                          type="md-arrow-dropdown"/></a>
                                    </span>
                <span v-if="!isJSON(row.configValue)">{{
                    row.configValue ? row.configValue : '-'
                  }}</span>
              </p>
            </template>
            <template slot-scope="{ row }" slot="paramName">
              <p style="position: relative">
                                    <span :style="`font-style: italic;font-size: 13px;font-weight: 500;color: ${row.needCreate
                                     ? '#ff6e0d' : row.showType === AUTH_SHOW_TYPE.DIFF_AUTHED ? '#0087C7':''}`">
                                      {{row.configName }}
                                      {{row.needCreate ? $t('dai-chuang-jian') : ''}}
                                      <span>{{row.showType === AUTH_SHOW_TYPE.DIFF_AUTHED ? $t('shang-ye-ban') : ''}}</span>
                                      </span>
                                    <Tooltip transfer :content="row.description" placement="right"
                                             style="position:absolute;right: 0;top: 0">
                                        <a><Icon style="font-size: 14px"
                                                 type="ios-information-circle"/></a>
                                    </Tooltip>
              </p>
            </template>
            <template slot-scope="{ row }" slot="currentValue">
              <div>
                <div v-if="!row.readOnly">
                      <span
                        style="margin-right: 16px;display: inline-block;width: 60px;">{{
                          row.currentCount
                        }}</span><span>
                                    <Poptip v-model="row.visible" transfer
                                            @on-popper-show="handlePopShow(row)"
                                            placement="right" width="250"><i
                                      class="iconfont iconbianji"></i>
                                <div slot="content">
                                    <p style="font-size: 12px;margin-bottom: 10px">{{ $t('xiu-gai-can-shu-wei') }}</p>
                                    <p style="margin-bottom: 10px"><Input size="small"
                                                                          style="width: 200px"
                                                                          v-model="currentValue"/></p>
                                    <p><pd-button style="margin-right: 5px" type="primary"
                                                  size="small"
                                                  @click="handleEditCurrent(row)">{{ $t('que-ding') }}</pd-button></p>
                                </div>
                            </Poptip>
                                <Tooltip transfer style="margin-left: 5px" :content="$t('che-xiao')"
                                         placement="right"
                                         v-if="row.currentCount"><a style="font-size: 16px;"
                                                                    @click="handleCancelEdit(row)"><Icon
                                  type="md-undo"/></a></Tooltip></span>
                </div>
                <div v-if="row.readOnly">
                  {{ $t('zhi-du-can-shu') }}
                </div>
              </div>
            </template>
            <template slot-scope="{ row }" slot="action">
              <pd-button ghost type="primary" size="small" style="margin-right: 5px"
                         @click="handleShowEditParams(row,'serviceCoreParamsData')">{{ $t('xiu-gai') }}
              </pd-button>
            </template>
          </Table>
        </div>
      </div>
    </div>
    <Modal v-model="showJson"
           :title="$t('wan-zheng-can-shu-zhan-shi')"
           width="700px"
           footer-hide
    >
      <div style="position: relative">
                <span style="position: absolute; right: 20px;top: 20px">
                    <Icon class="copy-icon" type="ios-photos-outline"
                          @click="handleCopyJson(JSON.stringify(selectedJson))"/>
                </span>
        <div style="max-height: 500px;overflow: auto;">
          <pre>{{ selectedJson }}</pre>
          <!--                    <vue-json-editor v-model="selectedJson" :mode="currentMode" :show-btns="false" :expandedOnStart="true" @json-change="onJsonChange"></vue-json-editor>-->
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleEditJson">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelEditParams">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showParamsEdit"
      :title="$t('can-shu-xiu-gai')"
      footer-hide
    >
      <div>
        <Form label-position="right" :label-width="150">
          <FormItem :label="selectedParam.params">
            <Input v-model="selectedParam.currentCount"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleEditParams">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelEditParams">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showParamsEditConfirm"
      :title="$t('que-ding-yao-sheng-xiao-yi-xia-pei-zhi-bing-zhong-qi-ren-wu-ma')"
      footer-hide
      width="800px"
    >
      <div style="max-height: 500px;overflow: auto">
        <div id="elem"></div>
        <p style="margin-bottom: 10px">{{ $t('yi-xia-xiu-gai-de-can-shu-zhong-cun-zai-xu-yao-zhong-qi-ren-wu-cai-neng-gou-sheng-xiao-de-can-shu') }}</p>
        <Table border size="small" :columns="editColumns" :data="editedParams" height="400">
          <template slot-scope="{ row }" slot="configName">
            <div :style="`font-style: italic;font-size: 13px;font-weight: 500;color: ${row.needCreate ? '#ff6e0d' : ''}`">
              {{ row.configName }}
              {{ row.needCreate ? $t('dai-chuang-jian') : ''}}
            </div>
          </template>
          <template slot-scope="{ row }" slot="editedConfigValue">
            <div>
              <p style="position: relative;padding-right: 15px;">
                <a v-if="isJSON(row.configValue)" @click="handleShowJson(row,'read')"
                   style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block;color: #333">{{
                    row.configValue
                  }}
                  <a style="position: absolute;right: 0;font-size: 14px"
                     @click="handleShowJson(row,'read')">
                    <Icon type="md-arrow-dropdown"/>
                  </a>
                </a>
                <span v-if="!isJSON(row.configValue)">{{
                    row.configValue ? row.configValue : '-'
                  }}</span>
              </p>
            </div>
          </template>
          <template slot-scope="{ row }" slot="editedCurrentCount">
            <div>
              <p style="position: relative;padding-right: 15px;">
                <a v-if="isJSON(row.currentCount)"
                   @click="handleShowJson(row,'read','currentCount')"
                   style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block;color: #333">{{
                    row.currentCount
                  }}
                  <a style="position: absolute;right: 0;font-size: 14px"
                     @click="handleShowJson(row,'read')">
                    <Icon type="md-arrow-dropdown"/>
                  </a>
                </a>
                <span v-if="!isJSON(row.currentCount)">{{
                    row.currentCount ? row.currentCount : '-'
                  }}</span>
              </p>
            </div>
          </template>
        </Table>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditParams">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelEditParams">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  getConfigByName,
  getConfigTagList,
  getTaskConfig,
  queryJobById, restartJob, updateTaskConfig
} from '@services/api/job';
import { pick } from '@components/function/monitor/utils/colors';
import { handleCopy } from '@components/function/dataJob/createJob/util';
import _ from 'lodash';
import ParamHeader from '@components/function/dataJob/dataJobList/ParamHeader';
import { AUTH_SHOW_TYPE } from '@consts';
import UtilJson from '../util';

export default {
  name: 'ParamsEdit',
  components: {
    ParamHeader
  },
  data() {
    return {
      AUTH_SHOW_TYPE,
      currentTab: 'serviceCoreParamsData',
      tabLabels: [
        { name: 'serviceCoreParamsData', label: this.$t('ren-wu-he-xin-pei-zhi') },
        { name: 'sourceParamsData', label: this.$t('yuan-shu-ju-yuan-pei-zhi') },
        { name: 'targetParamsData', label: this.$t('mu-biao-shu-ju-yuan-pei-zhi') },
        { name: 'mappingParamsData', label: this.$t('ying-she-pei-zhi') }
      ],
      currentMode: 'view',
      tagList: [],
      ifRunning: false,
      sourceType: '',
      targetType: '',
      jobId: 0,
      jobInfo: '',
      selectedJson: {},
      editJson: {},
      selectedRow: '',
      showJson: false,
      showParamsEdit: false,
      currentValue: 0,
      selectedData: '',
      selectedParam: {},
      showParamsEditConfirm: false,
      showTagList: [],
      jobName: '',
      searchKey: {
        key: '',
        tag: []
      },
      paramsColumn: [
        {
          title: this.$t('can-shu-ming-cheng'),
          key: 'configName',
          slot: 'paramName',
          width: 280,
          sortable: true
        },
        {
          title: this.$t('shi-fou-zhong-qi'),
          key: 'dynamic',
          width: 140,
          render: (h, params) => h('div', {}, params.row.dynamic ? this.$t('fou') : this.$t('shi')),
          filters: [
            {
              label: this.$t('shi'),
              value: 1
            },
            {
              label: this.$t('fou'),
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return !row.dynamic;
            }
            if (value === 2) {
              return row.dynamic;
            }
          }
        },
        {
          title: this.$t('can-shu-mo-ren-zhi'),
          key: 'defaultValue',
          width: 150,
          render: (h, params) => h('div', {}, params.row.defaultValue ? params.row.defaultValue : '-')
        },
        {
          title: this.$t('can-shu-dang-qian-yun-hang-zhi'),
          key: 'configValue',
          slot: 'configValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-can-shu-zhi'),
          key: 'currentCount',
          slot: 'currentValue',
          width: 160,
          filters: [
            {
              label: this.$t('ke-xiu-gai-can-shu'),
              value: 1
            },
            {
              label: this.$t('zhi-du-can-shu'),
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return !row.readOnly;
            }
            if (value === 2) {
              return row.readOnly;
            }
          }
        },
        {
          title: this.$t('ke-xiu-gai-fan-wei'),
          key: 'valueRange'
        },
        {
          title: this.$t('biao-qian'),
          width: 150,
          render: (h, params) => h('div', [
            h('Tooltip', {
              props: {
                content: UtilJson.tagInfo[params.row.configTagType],
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('pd-button', {
                props: {
                  type: 'warning',
                  size: 'small',
                  ghost: true
                },
                style: {
                  color: pick(this.showTagList.indexOf(params.row.configTagType)),
                  borderColor: pick(this.showTagList.indexOf(params.row.configTagType))
                }
              }, params.row.configTagType)
            ])
          ])
        }
      ],
      sourceParamsData: [],
      targetParamsData: [],
      mappingParamsData: [],
      serviceCoreParamsData: [],
      businessParamsData: [],
      sourceParamsDataMap: {},
      targetParamsDataMap: {},
      mappingParamsDataMap: {},
      serviceCoreParamsDataMap: {},
      businessParamsDataMap: {},
      editedParams: [],
      editColumns: [
        {
          title: this.$t('can-shu-ming'),
          key: 'configName',
          slot: 'configName',
          width: 300
        },
        {
          title: this.$t('dang-qian-yun-hang-zhi'),
          key: 'configValue',
          slot: 'editedConfigValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-zhi'),
          key: 'currentCount',
          slot: 'editedCurrentCount'
        },
        {
          title: this.$t('shi-fou-zhong-qi'),
          render: (h, params) => h('div', {}, params.row.dynamic ? this.$t('bu-xu-yao-zhong-qi') : this.$t('xu-yao-zhong-qi'))
        }
      ]
    };
  },
  mounted() {
    this.jobId = this.$route.params.id;
    queryJobById({ jobId: this.jobId })
      .then((res) => {
        if (res.data.code === '1') {
          this.jobInfo = res.data.data;
        }
      });
    this.getTaskConfig();
    getConfigTagList()
      .then((res) => {
        if (res.data.code === '1') {
          this.tagList = res.data.data;
        }
      });
  },
  computed: {
    getParamsData() {
      return this[this.currentTab];
    }
  },
  methods: {
    handleClearKey() {
      this.searchKey.key = '';
      this.handleSearch(this.searchKey);
    },
    handleClearTag(tag) {
      this.searchKey.tag.map((item, index) => {
        if (item === tag) {
          this.searchKey.tag.splice(index, 1);
        }
        return null;
      });
      this.handleSearch(this.searchKey);
    },
    handleClearAll() {
      this.searchKey.key = '';
      this.searchKey.tag = [];
      this.getTaskConfig();
    },
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          const obj = JSON.parse(str);

          return Boolean(typeof obj === 'object' && obj);
        } catch (e) {
          return false;
        }
      }
    },
    handleShowJson(row, type, key) {
      if (type === 'edit') {
        this.currentMode = 'tree';
      } else {
        this.currentMode = 'view';
      }
      this.selectedRow = row;
      if (key) {
        if (!row[key]) {
          row[key] = row.configValue;
        }
        this.selectedJson = JSON.parse(row[key]);
        this.editJson = JSON.parse(row[key]);
      } else {
        this.selectedJson = JSON.parse(row.configValue);
        this.editJson = JSON.parse(row.configValue);
      }
      this.showJson = true;
    },
    handleSearch(searchKey) {
      getConfigByName({
        dataJobId: this.jobId,
        configName: searchKey.key,
        tagNameList: searchKey.tag
      })
        .then((res) => {
          this.generateData(res);
        });
    },
    getTaskConfig() {
      getTaskConfig({ dataJobId: this.jobId })
        .then((res) => {
          this.generateData(res);
        });
    },
    generateData(res) {
      if (res.data.code === '1') {
        this.sourceParamsData = [];
        this.targetParamsData = [];
        this.businessParamsData = [];
        this.serviceCoreParamsData = [];
        this.mappingParamsData = [];
        res.data.data.map((item) => {
          if (this.showTagList.indexOf(item.configTagType) === -1) {
            this.showTagList.push(item.configTagType);
          }
          if (item.configType === 'BUSINESS') {
            if (this.businessParamsDataMap[item.configName]) {
              item.currentCount = this.businessParamsDataMap[item.configName].currentCount;
            }
            this.businessParamsDataMap[item.configName] = item;

            this.businessParamsData.push(item);
          } else if (item.configType === 'SERVER_CORE') {
            if (this.serviceCoreParamsDataMap[item.configName]) {
              item.currentCount = this.serviceCoreParamsDataMap[item.configName].currentCount;
            }
            this.serviceCoreParamsDataMap[item.configName] = item;
            this.serviceCoreParamsData.push(item);
          } else if (item.configType === 'DATASOURCE') {
            if (item.endPointType === 'SOURCE') {
              if (this.sourceParamsDataMap[item.configName]) {
                item.currentCount = this.sourceParamsDataMap[item.configName].currentCount;
              }
              this.sourceParamsDataMap[item.configName] = item;

              this.sourceParamsData.push(item);
              this.sourceType = item.configType;
            } else if (item.endPointType === 'TARGET') {
              if (this.targetParamsDataMap[item.configName]) {
                item.currentCount = this.targetParamsDataMap[item.configName].currentCount;
              }
              this.targetParamsDataMap[item.configName] = item;

              this.targetParamsData.push(item);
              this.targetType = item.configType;
            }
          } else {
            if (this.mappingParamsDataMap[item.configName]) {
              item.currentCount = this.mappingParamsDataMap[item.configName].currentCount;
            }
            this.mappingParamsDataMap[item.configName] = item;
            this.mappingParamsData.push(item);
          }
          return null;
        });
      }
    },
    handleReturn() {
      this.$router.push({
        path: `/data/job/${this.jobId}`
      });
    },
    handleShowEditParams(row, data) {
      row.currentCount = row.count;
      this.selectedParam = row;
      this.selectedData = data;
      this.showParamsEdit = true;
    },
    handleEditParams() {
      this.showParamsEdit = false;
      this[this.selectedData][this.selectedParam._index].currentCount = this.selectedParam.currentCount;
      this[this.selectedData].push('');
      this[this.selectedData].pop();
    },
    handleShowConfirmEditParams() {
      this.editedParams = [];
      queryJobById({ jobId: this.jobId })
        .then((res) => {
          if (res.data.code === '1') {
            this.jobInfo = res.data.data;
            this.jobInfo.dataTasks.map((item) => {
              if (item.dataTaskType === this.jobInfo.dataTaskState
                || item.dataTaskType === 'INCREMENT'
                && this.jobInfo.dataTaskState === 'INCRE'
                || item.dataTaskType === 'INCREMENT'
                && this.jobInfo.dataTaskState === 'CATCH_UP') {
                if (item.dataTaskStatus === 'RUNNING') {
                  this.ifRunning = true;
                }
              }
              return null;
            });
          }
        });
      this.showParamsEditConfirm = true;
      Object.values(this.sourceParamsDataMap).forEach((item) => {
        if (item.needCreate || ((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue)) {
          this.editedParams.push(item);
        }
      });
      Object.values(this.targetParamsDataMap).forEach((item) => {
        if (item.needCreate || ((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue)) {
          this.editedParams.push(item);
        }
      });
      Object.values(this.serviceCoreParamsDataMap).forEach((item) => {
        if (item.needCreate || ((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue)) {
          this.editedParams.push(item);
        }
      });
      Object.values(this.businessParamsDataMap).forEach((item) => {
        if (item.needCreate || ((item.currentCount === 0 || item.currentCount) && item.currentCount !== item.configValue)) {
          this.editedParams.push(item);
        }
      });
    },
    handleConfirmEditParams() {
      this.showParamsEditConfirm = false;
      if (this.editedParams.length === 0) {
        this.$Modal.warning({
          title: this.$t('cao-zuo-yi-chang'),
          content: this.$t('qing-xiu-gai-xu-yao-sheng-xiao-de-can-shu')
        });
        return;
      }
      const config = _.cloneDeep(this.editedParams);

      config.forEach((item) => {
        item.configValue = item.currentCount;

        if (item.needCreate) {
          item.configValue = item.currentCount || item.defaultValue;
        }
      });

      console.log(config);
      updateTaskConfig(config)
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceParamsDataMap = {};
            this.targetParamsDataMap = {};
            this.mappingParamsDataMap = {};
            this.serviceCoreParamsDataMap = {};
            this.businessParamsDataMap = {};
            this.handleSearch(this.searchKey);
            this.editedParams = [];
            if (this.ifRunning) {
              restartJob({
                jobId: this.jobId,
                dataJobName: this.jobInfo.dataJobName
              })
                .then((response) => {
                  if (response.data.code === '1') {
                    this.$Message.success(this.$t('ren-wu-yi-shi-yong-zui-xin-pei-zhi-bing-zhong-qi-cheng-gong'));
                  }
                });
            } else {
              this.$Message.success(this.$t('pei-zhi-yi-xiu-gai-cheng-gong-gai-ren-wu-dang-qian-bu-shi-yun-hang-zhuang-tai-qing-shou-dong-zhong-qi-ren-wu-zhong-qi-hou-pei-zhi-hui-zi-dong-sheng-xiao'));
            }
          }
        });
    },
    handleEditCurrent(row) {
      const type = this.currentTab;
      const data = this[this.currentTab];
      data.map((item) => {
        if (item.configName === row.configName) {
          item.currentCount = this.currentValue;
        }
        return null;
      });
      this[type] = Object.assign([], this[type]);
      // row.currentCount = this.currentValue;
      this.currentValue = '';
      row.visible = false;
    },
    handlePopShow(row) {
      this.currentValue = row.count;
    },
    handleCancelEdit(row) {
      const type = this.currentTab;
      const data = this[this.currentTab];
      data.map((item) => {
        if (item.configName === row.configName) {
          item.currentCount = '';
        }
        return null;
      });
      this[type] = Object.assign([], this[type]);
    },
    handleCancelEditParams() {
      this.editedParams = [];
      this.showParamsEditConfirm = false;
      this.showJson = false;
      this.showParamsEdit = false;
    },
    onJsonChange(data) {
      this.editJson = data;
      this.selectedJson = data;
    },
    handleEditJson() {
      this.showJson = false;
      if (this.currentMode === 'tree') {
        this.currentValue = JSON.stringify(this.editJson);
        this.handleEditCurrent('businessParamsData', this.businessParamsData, this.selectedRow);
        this.editJson = {};
      } else {
        this.$Modal.warning({
          title: this.$t('xiu-gai-can-shu-ti-shi'),
          content: this.$t('qing-dian-ji-xiu-gai-tu-biao-jin-hang-xiu-gai')
        });
      }
    },
    handleCopyJson(data) {
      handleCopy(data);
      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    }
  }
};
</script>
<style lang="less">
.page-header {
  /*margin-top: 24px;*/
  /*margin-left: -16px;*/
  /*margin-right: -16px;*/

  .page-header-container {
    background: #fff;
    border-bottom: 1px solid #e8eaec;
  }

  .page-header-detail {
    display: flex;
  }

  .page-header-main {
    width: 100%;
    flex: 0 1 auto;

    .ivu-page-header-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .ivu-page-header-row {
    width: 100%;
  }

  .ivu-page-header-title {
    margin-bottom: 16px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    display: inline-block;
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
  }

  .ivu-page-header-content {
    margin-bottom: 16px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    font-size: 14px;
  }
}

.params-edit-wrapper {
  /*padding: 24px;*/
  /*background: #ffffff;*/
  margin-top: 10px;
  /*border: 1px solid #DADADA;*/
  height: 660px;
  /*overflow: auto;*/

  .ivu-tabs-nav-scroll {
    background-color: #ffffff;
    border-top: 1px solid #DADADA;
    border-left: 1px solid #DADADA;
    border-right: 1px solid #DADADA;
  }

  .iconfont {
    color: #8D95A6;
    cursor: pointer;
  }
}
</style>
