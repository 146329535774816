import Vue from 'vue';
import axios from 'axios';
import ViewUI from 'view-design';
import { permission } from '@directives/permission';
import eventBus from '@utils/eventBus';
import App from './App';
import router from './router';
import store from './store';
import PdButton from './components/ui/pdButton';
import 'view-design/dist/styles/iview.css';
import './cloudCanal.less';
import './iconfontJs';
// fontawesome
import 'font-awesome/css/font-awesome.css';
import './app.less';
import './assets/iconfontCss.css';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import i18n from './i18n';

require('dayjs/locale/zh-cn');

Vue.directive('permission', permission);
Vue.use(ViewUI, {
  capture: false,
  modal: {
    maskClosable: false
  },
  // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(eventBus);

Vue.prototype.$axios = axios.create({
  baseURL: '/cloudCanal/api/v1/'
});
Vue.component('pd-button', PdButton);
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

Vue.config.productionTip = false;

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App)
});
