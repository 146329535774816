<template>
  <div>
    <Table border stripe :max-height="tableHeight" size="small" :row-class-name="rowClassName"
           :columns="getTableColumns" :key="currentKey"
           :loading="loading"
           :data="showTableList[theDb]"
           @on-select="handleSelect"
           @on-select-cancel="handleSelectCancel"
           @on-select-all="handleSelectAll(selectedIndex,theDb,$event)"
           @on-select-all-cancel="handleSelectAllCancel(selectedIndex,theDb,$event)">
      <template #sourceTable="{ row }">
        <div :style="taskInfo.newData[db.sourceDb] && taskInfo.newData[db.sourceDb].selectedTables[row.sourceTable] ? 'color: green;font-weight: bold' :
        taskInfo.reduceData[db.sourceDb] && taskInfo.reduceData[db.sourceDb].selectedTables[row.sourceTable] ? 'color: red;font-weight: bold' : ''" v-if="taskInfo.processType === 'edit'">
          {{row.sourceTable}}
        </div>
        <div v-else>
          {{row.sourceTable}}
        </div>
      </template>
      <template slot-scope="{ row }" slot="action">
        <div style="text-align: left">
          <CheckboxGroup v-if="selectedTables[theDb]"
                         v-model="selectedTables[theDb][row.index].action"
                         @on-change="handleActionChange(selectedIndex,db,row,selectedTables[theDb][row.index].action)">
            <Checkbox v-for="action in DML" :key="action" style="width: 75px" :label="action" :disabled="tableDisabled || !selectedTables[theDb][row.index].selected">
              <span :style="`${
                row.originalAction && row.action.includes(action) && !row.originalAction.includes(action) ? 'color: green;font-weight: bold;' :
                  row.originalAction && !row.action.includes(action) && row.originalAction.includes(action) ? 'color: red;font-weight: bold;' : 'color: black'
              }`">{{action}}</span>
            </Checkbox>
            <div v-for="action in DDL" :key="action" style="display: inline-block">
              <Checkbox style="min-width: 75px" v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)"
                        :label="action" :disabled="tableDisabled || !selectedTables[theDb][row.index].selected">
                <span :style="`${
                row.originalAction && row.action.includes(action) && !row.originalAction.includes(action) ? 'color: green;font-weight: bold;' :
                  row.originalAction && !row.action.includes(action) && row.originalAction.includes(action) ? 'color: red;font-weight: bold;' : 'color: black'
              }`">{{action}}</span>
              </Checkbox>
            </div>
          </CheckboxGroup>
        </div>
      </template>
      <template slot-scope="{ row }" slot="sink">
        <div v-if="row.selected" style="display: flex;align-items: center;">
          <div v-if="selectIndex===row.index&&!row._disabled" style="flex: 1">
            <Select filterable v-if="selectedTables[theDb]&&row.selected"
                    v-model="selectedTables[theDb][row.index].sinkTable" :disabled="tableDisabled"
                    @on-change="handleSelectChange(selectedIndex,theDb,row,$event)"
                    :class="dbToCreateClass(db,row)" allow-create @on-create="handleCreate1"
                    style="width:100%" :transfer="true" ref="sink-select">
              <Option v-for="(item) in taskInfo.sinkTableList[theDb]" :value="item.tableName"
                      :key="item.tableName">{{ item.tableName }}
              </Option>
              <Option v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&
                    (selectedTables[theDb][row.index].sinkTable)&&
                    !containsTable(taskInfo.sinkTableList[theDb],getTargetName(theMappingRule[theMappingIndex],theDb,selectedTables[theDb][row.index],taskInfo))"
                      :value="getTargetName(theMappingRule[theMappingIndex],theDb,selectedTables[theDb][row.index],taskInfo)">
                {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetName(theMappingRule[theMappingIndex], theDb, selectedTables[theDb][row.index], taskInfo)]) }}
              </Option>
              <Option v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&!isMQ(taskInfo.sourceType)&&
                    migrationType==='table'&&(selectedTables[theDb][row.index].sinkTable)&&
                    !containsTable(taskInfo.sinkTableList[theDb],taskInfo.sourceInstanceId+'.'+theDb+'.'+selectedTables[theDb][row.index].sourceTable)"
                      :value="getTargetName(theMappingRule[theMappingIndex],theDb,selectedTables[theDb][row.index],taskInfo)">
                {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetName(theMappingRule[theMappingIndex], theDb, selectedTables[theDb][row.index], taskInfo)]) }}
              </Option>
              <Option v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&
                    migrationType==='db'&&
                    !topicName&&
                    (selectedTables[theDb][row.index].sinkTable)&&
                    !containsTable(taskInfo.sinkTableList[theDb],taskInfo.sourceInstanceId+'.'+theDb)"
                      :value="getTargetName(theMappingRule[theMappingIndex],theDb,selectedTables[theDb][row.index],taskInfo)">
                {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetName(theMappingRule[theMappingIndex], theDb, selectedTables[theDb][row.index], taskInfo)]) }}
              </Option>
              <Option v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&
                    !!topicName&&
                    (selectedTables[theDb][row.index].sinkTable)&&!containsTable(taskInfo.sinkTableList[theDb],topicName)"
                      :value="topicName">{{ $t('topicname-dai-chuang-jian', [topicName]) }}
              </Option>
            </Select>
            <Input size="small"
                   v-if="selectedTables[theDb]&&selectedTables[theDb][row.index].sinkTable==='cloudCanal_default'"
                   v-model="selectedTables[theDb][row.index].customSinkTable"
                   :class="dbToCreateClass(db,row)"
                   :placeholder="$t('zi-ding-yi-biao-ming')" style="width: 300px;margin-top: 5px"/>
          </div>
          <div @mouseover="displaySelect(row.index)" v-else style="flex: 1">
            <div style="display: flex;align-items: center;justify-content: space-between">
              <div>
                <div v-if="isExistedDb(theDb, selectedTables[theDb][row.index].sinkTable)">
                  {{selectedTables[theDb][row.index].sinkTable}}
                </div>
                <div v-else-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)===-1&&DataSourceGroup.mq.indexOf(taskInfo.sourceType)===-1&&
                    (selectedTables[theDb][row.index].sinkTable)&&
                    !containsTable(taskInfo.sinkTableList[theDb],getTargetName(theMappingRule[theMappingIndex],theDb,selectedTables[theDb][row.index],taskInfo))
                    && getTargetName(theMappingRule[theMappingIndex],theDb,selectedTables[theDb][row.index],taskInfo) === selectedTables[theDb][row.index].sinkTable"
                     style="color: #FF6E0D;">
                  {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetName(theMappingRule[theMappingIndex], theDb, selectedTables[theDb][row.index], taskInfo)]) }}
                </div>
                <div v-else-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&
                    migrationType==='table'&&(selectedTables[theDb][row.index].sinkTable)&&
                    !containsTable(taskInfo.sinkTableList[theDb],taskInfo.sourceInstanceId+'.'+theDb+'.'+selectedTables[theDb][row.index].sourceTable)"  style="color: #FF6E0D;">
                  {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetName(theMappingRule[theMappingIndex], theDb, selectedTables[theDb][row.index], taskInfo)]) }}
                </div>
                <div v-else-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&
                    migrationType==='db'&&
                    !topicName&&
                    (selectedTables[theDb][row.index].sinkTable)&&
                    !containsTable(taskInfo.sinkTableList[theDb],taskInfo.sourceInstanceId+'.'+theDb)"  style="color: #FF6E0D;">
                  {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetName(theMappingRule[theMappingIndex], theDb, selectedTables[theDb][row.index], taskInfo)]) }}
                </div>
                <div v-else-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&
                    !!topicName&&
                    (selectedTables[theDb][row.index].sinkTable)&&!containsTable(taskInfo.sinkTableList[theDb],topicName)"  style="color: #FF6E0D;">
                  {{ $t('topicname-dai-chuang-jian', [topicName]) }}
                </div>
                <div v-else style="color: #ccc">
                  {{ $t('qing-xuan-ze') }}
                </div>
              </div>
              <Icon type="ios-arrow-down" size="14" color="#808695"/>
            </div>
          </div>
          <Tooltip v-if="row.sinkTable !== row.originalSinkTable && taskInfo.processType === 'edit' && row.hasInJob" style="margin-left: 10px;" transfer>
            <Icon type="ios-information-circle-outline" />
            <div slot="content">
              {{row.originalSinkTable}}
            </div>
          </Tooltip>
        </div>
      </template>
      <template slot="shards" slot-scope="{row}">
        <div v-if="row.selected">
          <p style="margin-left: 7px" v-if="!row.needAutoCreated">{{ row.shards }}</p>
          <Input v-if="row.needAutoCreated" v-model="selectedTables[theDb][row.index].shards"
                 @change="handleChangeShards(selectedIndex,theDb,row,$event)"
                 :placeholder="$t('qing-shu-ru-fen-pian-shu')"></Input>
        </div>
      </template>
      <template slot="replication" slot-scope="{row}">
        <div v-if="row.selected">
          <p style="margin-left: 7px" v-if="!row.needAutoCreated">{{ row.replication }}</p>
          <Input v-if="row.needAutoCreated" v-model="selectedTables[theDb][row.index].replication"
                 @change="handleChangeReplication(selectedIndex,theDb,row,$event)"
                 :placeholder="$t('qing-shu-ru-fen-pian-fu-ben-shu')"></Input>
        </div>
      </template>
    </Table>
    <div class="page-footer-paging table-filter-footer"
         style="position: absolute;bottom: 2px;left: 0;width: 100%;">
      <Page size="small" :total="pageData[theDb]?pageData[theDb].total:0" show-elevator
            @on-change="handlePageChange(theDb,$event)"
            :page-size="pageData[theDb]?pageData[theDb].size:10"/>
    </div>
  </div>
</template>
<script>
import { isMQ } from '@utils';
import DataSourceGroup from '../../../../../views/dataSourceGroup';
import store from '../../../../../store';
import { getTargetName } from '../util';

export default {
  props: {
    taskInfo: Object,
    db: Object,
    loading: Boolean,
    showTableList: Object,
    handleSelect: Function,
    selectedIndex: Number,
    handleSelectCancel: Function,
    handleSelectAll: Function,
    handleSelectAllCancel: Function,
    handleActionChange: Function,
    selectedTables: Object,
    handleSelectChange: Function,
    topicName: String,
    handleChangeShards: Function,
    pageData: Object,
    handlePageChange: Function,
    handleChangeReplication: Function,
    containsTable: Function,
    handleChooseIndex: Function,
    migrationType: String,
    handleFocus: Function,
    remoteMethod: Function,
    handleBlur: Function,
    currentKey: Number,
    updateShowTableList: Function,
    theMappingRule: Array,
    theMappingIndex: Number,
    tableDisabled: Boolean
  },
  data() {
    return {
      DDL: ['CREATE', 'ALTER', 'RENAME', 'TRUNCATE'],
      DML: ['INSERT', 'UPDATE', 'DELETE'],
      selectIndex: -1,
      getTargetName,
      store,
      theDb: {},
      DataSourceGroup,
      tableHeight: 600,
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao'),
          slot: 'sourceTable',
          tooltip: true
        },
        {
          title: this.taskInfo.sinkType === 'RocketMQ' || this.taskInfo.sinkType === 'Kafka' ? this.$t('topic-ming-cheng') : this.$t('mu-biao-biao'),
          slot: 'sink'
        },
        {
          title: this.$t('action-guo-lv'),
          slot: 'action',
          width: 380,
          align: 'center'
        }
      ],
      tableColumnsOnlyFull: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao'),
          slot: 'sourceTable',
          tooltip: true
        },
        {
          title: this.taskInfo.sinkType === 'RocketMQ' || this.taskInfo.sinkType === 'Kafka' ? this.$t('topic-ming-cheng') : this.$t('mu-biao-biao'),
          slot: 'sink'
        }
      ],
      tableEsColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao'),
          slot: 'sourceTable',
          tooltip: true
        },
        {
          title: this.$t('mu-biao-suo-yin-ming-cheng'),
          slot: 'sink'
        },
        {
          title: this.$t('fen-pian-shu'),
          key: 'shards',
          width: 90,
          slot: 'shards'
        },
        {
          title: this.$t('fen-pian-fu-ben-shu'),
          key: 'replication',
          width: 120,
          slot: 'replication'
        },
        {
          title: this.$t('action-guo-lv'),
          slot: 'action',
          width: 380,
          align: 'center'
        }
      ],
      tableEsColumnsOnlyFull: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao'),
          slot: 'sourceTable',
          tooltip: true
        },
        {
          title: this.$t('mu-biao-suo-yin-ming-cheng'),
          slot: 'sink'
        },
        {
          title: this.$t('fen-pian-shu'),
          key: 'shards',
          width: 90,
          slot: 'shards'
        },
        {
          title: this.$t('fen-pian-fu-ben-shu'),
          key: 'replication',
          width: 120,
          slot: 'replication'
        }
      ],
      tableRedisColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao'),
          slot: 'sourceTable',
          tooltip: true
        },
        {
          title: this.$t('cache-qian-zhui'),
          key: 'sinkTable'
        },
        {
          title: this.$t('action-guo-lv'),
          slot: 'action',
          width: 380,
          align: 'center'
        }
      ],
      tableRedisColumnsOnlyFull: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao'),
          slot: 'sourceTable',
          tooltip: true
        },
        {
          title: this.$t('cache-qian-zhui'),
          key: 'sinkTable'
        }
      ]
    };
  },
  mounted() {
    let totalHeight = window.innerHeight;

    if (totalHeight < 720) {
      totalHeight = 720;
    }
    // eslint-disable-next-line no-undef
    $('.table-filter-container')
      .css('height', `${totalHeight - 300}px`);
    this.tableHeight = totalHeight - 465;
    if (this.taskInfo.sinkType === 'Kafka' || this.taskInfo.sinkType === 'RocketMQ') {
      this.tableHeight = totalHeight - 505;
    }
    if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
      this.theDb = this.db.sinkDb;
    } else {
      this.theDb = this.db.sourceDb;
    }
  },
  computed: {
    getTargetValue() {
      return (target) => {
        if (this.taskInfo.targetCaseSensitive === 'false') {
          return target.toLowerCase();
        }
        if (this.taskInfo) {
          if (this.taskInfo.commonRule === 'UpperCase') {
            return target.toUpperCase();
          }
          if (this.taskInfo.commonRule === 'LowerCase') {
            return target.toLowerCase();
          }
        }
        return target;
      };
    },
    getTableColumns() {
      if (this.taskInfo.type === 'CHECK' || this.taskInfo.type === 'REVISE' || this.taskInfo.type === 'STRUCT_MIGRATION' || this.taskInfo.type === 'MIGRATION'
        && !this.taskInfo.mode.synchronize || DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        if (this.taskInfo.sinkType === 'ElasticSearch') {
          return this.tableEsColumnsOnlyFull;
        }
        if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
          return this.tableRedisColumnsOnlyFull;
        }
        return this.tableColumnsOnlyFull;
      }
      if (this.taskInfo.sinkType === 'ElasticSearch') {
        return this.tableEsColumns;
      }
      if (DataSourceGroup.redis.includes(this.taskInfo.sinkType)) {
        return this.tableRedisColumns;
      }
      return this.tableColumns;
    },
    hasPk() {
      return (db, row) => {
        let theDb = db.sourceDb;

        if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          theDb = db.sinkDb;
        }

        return !this.selectedTables[theDb][row.index].selected
          || !this.selectedTables[theDb][row.index].isRds
          && !this.selectedTables[theDb][row.index].hasPk && !DataSourceGroup.mq.includes(this.taskInfo.sinkType);
      };
    },
    dbToCreateClass() {
      return (db, row) => {
        let theDb = db.sourceDb;

        if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
          theDb = db.sinkDb;
        }

        if (this.selectedTables[theDb][row.index].sinkTable && !this.containsTable(this.taskInfo.sinkTableList[theDb], this.selectedTables[theDb][row.index].sinkTable)) {
          return 'db-to-create';
        }
        return '';
      };
    }
  },
  methods: {
    isMQ,
    isExistedDb(theDb, name) {
      if (this.taskInfo.sinkTableList[theDb]) {
        return this.taskInfo.sinkTableList[theDb].findIndex((table) => table.tableName === name) > -1;
      }
      return false;
    },
    displaySelect(index) {
      this.selectIndex = index;
    },
    rowClassName(row) {
      if (row._isChecked) {
        return 'demo-table-info-row';
      }
      return '';
    },
    handleCreate1(data) {
      console.log('data', data);
    }
  },
  watch: {
    db(val) {
      if (DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
        this.theDb = val.sinkDb;
      } else {
        this.theDb = val.sourceDb;
      }
    }
  }

};
</script>
