<template>
  <div class="job-detail-basic">
    <div class="basic-info-content">
      <p><span>{{ $t('ren-wu-lei-xing-0') }}</span><span
        class="basic-info-value">{{ MappingUtil.jobType[jobData.dataJobType] }}</span></p>
      <p><span>{{ $t('chuang-jian-ren-0') }}</span><span class="basic-info-value">{{ jobData.username }}</span></p>
      <p><span>{{ $t('chuang-jian-shi-jian-0') }}</span><span
        class="basic-info-value">{{
          fecha.format(new Date(jobData.gmtCreated), 'YYYY-MM-DD HH:mm:ss')
        }}</span>
      </p>
      <p v-if="jobData.shotTermSync"><span>{{ $t('zeng-liang-tong-bu-0') }}</span>
        <span
          class="basic-info-value">{{ $t('zhi-fechaformatnew-datenew-datejobdatagmtcreatedgettime-jobdatashottermnum-24-3600-1000-yyyymmdd-hhmmss-jie-shu', [fecha.format(new Date(new Date(jobData.gmtCreated).getTime() + jobData.shotTermNum * 24 * 3600 * 1000), 'YYYY-MM-DD HH:mm:ss')]) }}</span>
      </p>
      <!--                            <p><span>DDL过滤规则：</span><span class="basic-info-value">{{jobData.businessConfig.enableFilterDDLStatement?'过滤':'全部同步'}}</span></p>-->
      <p><span>{{ $t('gui-ge') }}</span><span
        class="basic-info-value">{{
          taskList['FULL'] ? $t('quan-liang') + taskList['FULL'].fullJvmHeapMb / 1024 + 'G' : ''
        }}
              {{
          taskList['INCREMENT'] ? $t('zeng-liang') + taskList['INCREMENT'].incrementJvmHeapMb / 1024 + 'G' : ''
        }}
              {{
          taskList['CHECK'] ? $t('jiao-yan') + taskList['CHECK'].checkJvmHeapMb / 1024 + 'G' : ''
        }}
              {{taskList['REVISE']? $t('ding-zheng') + taskList['REVISE'].fullJvmHeapMb/1024+'G':''}}
            </span>
      </p>
      <p><span>{{ $t('ku-biao-ying-she') }}</span><span><a
        style="margin-left: 5px;color: #0BB9F8"
        @click="handleShowMapping">{{ $t('cha-kan') }}</a></span></p>
    </div>
    <div class="basic-info-content" v-if="jobData.havePackage">
      <p class="basic-info-title">{{ $t('dai-ma-chu-li-bao') }}</p>
      <p><span>{{ $t('bao-ming') }}</span><span
        class="basic-info-value">{{ activePackage.fileName }}</span></p>
      <p><span>{{ $t('miao-shu-0') }}</span><span class="basic-info-value">{{ activePackage.description }}</span></p>
      <p><span>{{ $t('chuang-jian-shi-jian-0') }}</span><span v-if="activePackage.gmtCreate"
        class="basic-info-value">{{
          fecha.format(new Date(activePackage.gmtCreate), 'YYYY-MM-DD HH:mm:ss')
        }}</span>
      </p>
      <p>
        <span>{{ $t('dai-ma-bao-guan-li') }}</span>
        <a @click="handleShowPackageList">{{ $t('cha-kan') }}</a>
      </p>
    </div>
    <div class="basic-info-content">
      <p class="basic-info-title">{{ $t('yuan-duan') }}</p>
      <p><span>{{ $t('shi-li-bei-zhu') }}</span><span
        class="basic-info-value">{{ jobData.sourceDsVO.instanceDesc }}</span></p>
      <p><span>{{ $t('di-yu') }}</span><span class="basic-info-value">{{ jobData.sourceDsVO.region }}</span></p>
      <p><span>{{ $t('lei-xing-0') }}</span><span class="basic-info-value">
                                <Tooltip style="cursor: pointer" placement="right"
                                         class="dataSource-icon" transfer
                                         :content="`${jobData.sourceDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                                         MappingUtil.aliyunType[jobData.sourceDsVO.dataSourceType]:jobData.sourceDsVO.dataSourceType}`">
                                    <DataSourceIcon :type="jobData.sourceDsVO.dataSourceType"
                                                    :instanceType="jobData.sourceDsVO.deployType"></DataSourceIcon>
                                </Tooltip>
                            </span></p>
      <div><span>{{ $t('shi-li-id-0') }}</span><span
        class="basic-info-value">{{ jobData.sourceDsVO.instanceId }}</span>
        <Poptip placement="right" width="680">
          <i @click="getDataSourceDetail" class="iconfont iconinfo"></i>
          <div slot="content" style="line-height: 24px">
            <DataSourceInDetail :dataSourceDetail="sourceDetail"></DataSourceInDetail>
          </div>
        </Poptip>
      </div>
      <p class="basic-info-item">
        <span>{{ $t('lian-jie-chuan') }}</span><div class="basic-info-value">
      <Tooltip transfer placement="top" :content="jobData.sourceDsVO.host">
        <p class="show-host-p">{{ jobData.sourceDsVO.host }}</p>
      </Tooltip>
      </div>
        <Icon class="copy-icon" type="ios-photos-outline"
              @click="handleCopy(jobData.sourceDsVO.host)"/>
      </p>
      <p><span>{{ $t('bu-shu-lei-xing-0') }}</span><span
        class="basic-info-value">{{
          jobData.sourceDsVO.deployType === 'SELF_MAINTENANCE' ? $t('zi-jian-shu-ju-ku-0') : $t('a-li-yun')
        }}</span>
      </p>
    </div>
    <div class="basic-info-content">
      <p class="basic-info-title">{{ $t('mu-biao-duan') }}</p>
      <p><span>{{ $t('shi-li-bei-zhu') }}</span><span
        class="basic-info-value">{{ jobData.targetDsVO.instanceDesc }}</span></p>
      <p><span>{{ $t('di-yu') }}</span><span class="basic-info-value">{{ jobData.targetDsVO.region }}</span></p>
      <p><span>{{ $t('lei-xing-0') }}</span><span class="basic-info-value">
                                <Tooltip style="cursor: pointer" placement="right"
                                         class="dataSource-icon" transfer
                                         :content="`${jobData.targetDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                                         MappingUtil.aliyunType[jobData.targetDsVO.dataSourceType]:jobData.targetDsVO.dataSourceType}`">
                                      <DataSourceIcon :type="jobData.targetDsVO.dataSourceType"
                                                      :instanceType="jobData.targetDsVO.deployType"></DataSourceIcon>
                                </Tooltip>
                            </span></p>
      <p><span>{{ $t('shi-li-id-0') }}</span><span class="basic-info-value">{{ jobData.targetDsVO.instanceId }}</span>
        <Poptip placement="right" width="680">
          <i @click="getDataSinkDetail" class="iconfont iconinfo"></i>
      <div slot="content" style="line-height: 24px">
        <DataSourceInDetail :dataSourceDetail="sinkDetail"></DataSourceInDetail>
      </div>
      </Poptip>
      </p>
      <p class="basic-info-item">
        <span>{{ $t('lian-jie-chuan') }}</span><div class="basic-info-value">
      <Tooltip transfer placement="top" :content="jobData.targetDsVO.host">
        <p class="show-host-p">{{ jobData.targetDsVO.host }}</p>
      </Tooltip></div>
        <Icon class="copy-icon" type="ios-photos-outline"
              @click="handleCopy(jobData.targetDsVO.host)"/>
      </p>
      <p><span>{{ $t('bu-shu-lei-xing-0') }}</span><span
        class="basic-info-value">{{
          jobData.targetDsVO.deployType === 'SELF_MAINTENANCE' ? $t('zi-jian-shu-ju-ku-0') : $t('a-li-yun')
        }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '@views/util';
import DataSourceIcon from '../../DataSourceIcon';
import DataSourceInDetail from '../../addDataSource/DataSourceInDetail';

export default {
  components: {
    DataSourceIcon,
    DataSourceInDetail
  },
  props: {
    jobData: Object,
    taskList: Object,
    handleShowMapping: Function,
    getDataSourceDetail: Function,
    sourceDetail: Object,
    sinkDetail: Object,
    getDataSinkDetail: Function,
    activePackage: Object,
    handleShowPackageList: Function
  },
  data() {
    return {
      MappingUtil,
      fecha
    };
  },
  methods: {
    handleCopy(value) {
      const aux = document.createElement('input');

      aux.setAttribute('value', value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);

      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    }
  }
};
</script>
<style lang="less" scoped>
.show-host-p{
  overflow: hidden;
  max-width: 180px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>
