<template>
  <div class="task-create-first-step">
    <Form ref="taskInfo" :model="taskInfo" label-position="right" :label-width="90"
          :rules="ruleValidate">
      <h4>{{ $t('bang-ding-ji-qun-ren-wu-yun-hang-ji-qun') }}</h4>
      <FormItem :label="$t('bang-ding-ji-qun')" prop="clusterId">
        <Select v-model="taskInfo.clusterId" style="width:280px" @on-change="handleChangeCluster"
                filterable>
          <Option v-for="(cluster) in taskInfo.clusterList" :value="cluster.id" :key="cluster.id"
                  :label="cluster.clusterDesc?cluster.clusterDesc:cluster.clusterName">
            <p>{{ cluster.clusterName }}</p>
            <p style="color:#ccc;margin: 5px 0">{{ cluster.clusterDesc }} <span
              style="margin-left: 8px">{{ cluster.runningCount }}/{{ cluster.workerCount }}</span>
            </p>
          </Option>
        </Select>
        <Tooltip placement="right-start">
          <Icon type="ios-help-circle-outline" style="font-size: 14px;margin-left: 5px"/>
          <div slot="content">
            <P>{{ $t('ren-wu-yun-hang-ji-qun') }}</p>
          </div>
        </Tooltip>
        <span v-if="this.currentCluster.runningCount===0"><span style="color: #FF6E0C"><i
          style="margin-left: 10px;margin-right: 8px"
          class="iconfont iconTIP"></i>{{ $t('gai-ji-qun-wu-cun-huo-ji-qi') }}</span><a
          :href="`/#/system/resource/${currentCluster.id}`">{{ $t('guan-li-ji-qi') }}</a></span>
      </FormItem>
      <h4>{{ $t('yuan-ku-he-mu-biao-ku-she-zhi') }}</h4>
      <Row :gutter="16">
        <Col span="12">
          <div>
            <DataSourceSetting type="source" :taskInfo="taskInfo"
                               :handleDeployTypeChange="handleDeployTypeChange"
                               :handleTypeChange="handleTypeChange" :handleDbChange="handleDbChange"
                               :hasTheDb="hasTheDb" :handleSchemaChange="handleSchemaChange"
                               :getSchemaList="getSchemaList" :store="store"
                               :handleRemoveMap="handleRemoveMap"
                               :containsDb="containsDb" :handleAddMap="handleAddMap"
                               :handleInstanceChange="handleInstanceChange"
                               :showSinkAddWhiteList="showSinkAddWhiteList"
                               :handleAddWhiteList="handleAddWhiteList"
                               :handleTestConnection="handleTestConnection"
                               :handleChangeNetType="handleChangeNetType" :dbMap="dbMap"
                               :handleTaskName="handleTaskName"
                               :test2="test2" :dsAttrs="dsAttrs"
                               :showSourceAddWhiteList="showSourceAddWhiteList"
                               :charsetList="sourceCharsetList"
                               :test1="test1"></DataSourceSetting>
          </div>
        </Col>
        <Col span="12">
          <div>
            <DataSourceSetting type="sink" :taskInfo="taskInfo"
                               :handleDeployTypeChange="handleDeployTypeChange"
                               :handleTypeChange="handleTypeChange" :handleDbChange="handleDbChange"
                               :hasTheDb="hasTheDb"
                               :getSchemaList="getSchemaList" :store="store"
                               :handleRemoveMap="handleRemoveMap"
                               :containsDb="containsDb" :handleAddMap="handleAddMap"
                               :handleInstanceChange="handleInstanceChange"
                               :showSinkAddWhiteList="showSinkAddWhiteList"
                               :handleAddWhiteList="handleAddWhiteList"
                               :handleTestConnection="handleTestConnection"
                               :handleChangeNetType="handleChangeNetType" :dbMap="dbMap"
                               :handleTaskName="handleTaskName"
                               :test2="test2" :dsAttrs="dsAttrs"
                               :showSourceAddWhiteList="showSourceAddWhiteList"
                               :charsetList="targetCharsetList"
                               :test1="test1"></DataSourceSetting>
          </div>
        </Col>
      </Row>
    </Form>
    <Form ref="dbMappingForm" :model="taskInfo" label-position="right" :rules="dsRuleValidate">
      <DbMapping
        v-if="DataSourceGroup.noDb.indexOf(this.taskInfo.sourceType)===-1&&DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType)===-1"
        :dbMap="dbMap" :handleDbChange="handleDbChange" :showEditMapping="showEditMapping"
        :hasSelectedTheDb="hasSelectedTheDb" ref="dbMapping"
        :updateShowEditMapping="updateShowEditMapping"
        :hasTheDb="hasTheDb" :taskInfo="taskInfo" :getSchemaList="getSchemaList" :store="store"
        :handleRemoveMap="handleRemoveMap" :handleAddMap="handleAddMap"
        :handleSchemaChange="handleSchemaChange"
        :containsDb="containsDb" :handleChangeSchema="handleChangeSchema"
        :currentSourceDb="currentSourceDb"
        :containsSchema="containsSchema"></DbMapping>
    </Form>
    <Modal v-model="showAddWhiteList" :title="$t('tian-jia-bai-ming-dan')" footer-hide>
      <div style="padding: 20px">
        <Progress :percent="currentPercentage" :stroke-width="20"
                  :status="currentAddWhiteListStatus==='SUCCESS'?'success':(currentAddWhiteListStatus==='FAILED'?'wrong':'active')"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
          {{
            currentAddWhiteListStatus === 'SUCCESS' ? $t('tian-jia-cheng-gong-0') : currentAddWhiteListStatus === 'FAILED' ? $t('tian-jia-shi-bai') : $t('tian-jia-bai-ming-dan-zhong')
          }}</p>
      </div>
    </Modal>
    <StToken ref="stToken"></StToken>
    <AliyunAKSK ref="aliyunAKSK" :nextStep="nextStep"></AliyunAKSK>
    <Modal v-model="showNoTableDb" :title="$t('chuang-jian-ren-wu-ti-shi')" footer-hide>
      <div>
        <p style="margin-bottom: 20px;font-weight: 500;">
          {{ $t('cloudcanal-mu-qian-bu-zhi-chi-qian-yi-kong-ku-yi-xia-ku-wei-kong-ku-biao-de-shu-ju-wei-0-shi-fou-xu-yao-zi-dong-ti-chu-yi-xia-ku-bing-ji-xu-chuang-jian-ren-wu') }}
        </p>
        <Card dis-hover style="max-height: 280px;overflow:auto;width: 100%">
          <span style="line-height: 24px;margin-right: 20px;display: inline-block"
                v-for="db in hasNoTableDb" :key="db">{{ db }}</span>
        </Card>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleRemoveNoDbTables">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  getDbList, listbycondition, testConnection, schemasTableCount, queryDsAttrs
} from '@services/api/datasource';
import {
  dsCharsetOptions, getMqOriginalDecodeMsgFormat,
  listDatasourceDeployType, oraInCrMode, rdbKeyConflictStrategy,
  supportedsourcedstype,
  supportedtargetdstype
} from '@services/api/constant';
import { addClusterWhiteList, queryWhiteListExist } from '@services/api/ailyunRds';
import { queryConsoleJob } from '@services/api/consoleJob';
import _ from 'lodash';
import { listClusterByCondition } from '@services/api/cluster';
import createJobMixin from '@components/function/mixin/createJobMixin';
import {
  isES,
  isHasSchema,
  isHudi,
  isKafka,
  isMongoDB,
  isMQ,
  isMySQL,
  isOracle,
  isPG,
  isPolar,
  isSchemaMapping, isSQLServer, isStarRocks,
  isTidDB,
  isTunnel
} from '@utils';
import Mapping from '@views/util';
import store from '../../../../store';
import StToken from '../../ApplyStToken';
import DbMapping from './originalConfig/DbMapping';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import DataSourceSetting from './originalConfig/DataSourceSetting';
import AliyunAKSK from '../../ApplyAKSK';

export default {
  // 源端命名为source,目标端命名为sink
  name: 'Originalconfig',
  mixins: [createJobMixin],
  props: {
    updateStep: Function,
    taskInfo: Object,
    deployType: String,
    updateLoading: Function,
    updateNextDisabled: Function
  },
  components: {
    StToken,
    DbMapping,
    DataSourceSetting,
    AliyunAKSK
  },
  created() {
    if (!this.taskInfo.originalConfigHistory) {
      this.listCluster('init');
    }
    this.getOraIncrMode();
    this.updateNextDisabled(false);

    // this.store = store;
  },
  // mounted() {
  //   this.getInitInitInfo();
  // },
  beforeDestroy() {
    clearInterval(this.querySourceAddWhiteListInterval);
    clearInterval(this.querySinkAddWhiteListInterval);
    clearInterval(this.getPercantage);
  },
  data() {
    return {
      strategies: [],
      showNoTableDb: false,
      hasNoTableDb: [],
      DataSourceGroup,
      currentCluster: '',
      currentRegion: '',
      currentPercentage: 0,
      showSourceAddWhiteList: false,
      showSinkAddWhiteList: false,
      showAddWhiteList: false,
      showEditMapping: false,
      currentAddWhiteListStatus: '',
      getPercantage: '',
      showType: 'query',
      showAkSkType: 'source',
      aliyunAk: '',
      aliyunSk: '',
      showManualAccount1: false,
      deployMode: 'CLOUD_PUBLIC',
      exeStartTime: new Date(),
      currentSourceDb: '',
      Mapping,
      store: {},
      test1: false,
      test2: false,
      showTest1: false,
      showTest2: false,
      sourceConnection: false,
      sinkConnection: false,
      hasSourceDb: true,
      showPreCheck: false,
      showAddDataSource: false,
      querySourceAddWhiteListInterval: '',
      querySinkAddWhiteListInterval: '',
      dataSourceTypes: [],
      sourceDataSourceTypes: [],
      sinkDataSourceTypes: [],
      dataSourceDeployTypes: [],
      addDataSourceType: 'source',
      sourceCharsetList: [],
      targetCharsetList: [],
      dsAttrs: {},
      goNext: true,
      dbMap: [
        {
          sourceDb: '',
          sinkDb: '',
          targetSchema: 'public',
          sourceSchema: 'public'
        }
      ],
      selectedDB: {
        source: {},
        sink: {}
      },
      ruleValidate: {
        sourceType: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-yuan-lei-xing'),
            trigger: 'change'
          }
        ],
        sourceInstance: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-ku-shi-li'),
            trigger: 'change'
          }
        ],
        sourceAccount: [
          {
            required: true,
            type: 'string',
            message: this.$t('qing-shu-ru-zhang-hao'),
            trigger: 'blur'
          }
        ],
        sourcePasswd: [
          {
            required: true,
            message: this.$t('qing-shu-ru-mi-ma'),
            trigger: 'change'
          }
        ],
        sinkType: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-yuan-lei-xing'),
            trigger: 'change'
          }
        ],
        sinkInstance: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-ku-shi-li'),
            trigger: 'change'
          }
        ],
        sinkAccount: [
          {
            required: true,
            type: 'string',
            message: this.$t('qing-shu-ru-zhang-hao'),
            trigger: 'blur'
          }
        ],
        sinkPasswd: [
          {
            required: true,
            message: this.$t('qing-shu-ru-mi-ma'),
            trigger: 'change'
          }
        ],
        sourceInstanceType: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-yuan-lei-xing'),
            trigger: 'change'
          }
        ],
        sinkInstanceType: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-yuan-lei-xing'),
            trigger: 'change'
          }
        ],
        sourceCharset: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-zi-fu-ji'),
            trigger: 'change'
          }
        ],
        targetCharset: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-zi-fu-ji'),
            trigger: 'change'
          }
        ],
        dstMqDefaultTopic: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-mo-ren-topic'),
            trigger: 'change'
          }
        ],
        clusterId: [
          {
            validator: (rule, value, callback) => {
              if (!this.taskInfo.clusterId) {
                return callback(new Error(this.$t('qing-xuan-ze-bang-ding-ji-qun')));
              }
              return callback();
            },
            trigger: 'change'
          }
        ],
        sourceInstanceId: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-yuan'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.taskInfo.sourceConnection) {
                return callback(new Error(this.$t('qing-dui-shu-ju-yuan-jin-hang-ce-shi-lian-jie')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        sinkInstanceId: [
          {
            required: true,
            message: this.$t('qing-xuan-ze-shu-ju-yuan'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.taskInfo.sinkConnection) {
                return callback(new Error(this.$t('qing-dui-shu-ju-yuan-jin-hang-ce-shi-lian-jie')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ],
        consumerGroupId: [
          {
            required: true,
            message: this.$t('qing-tian-xie-consumer-group'),
            trigger: 'blur'
          }
        ],
        srcRabbitMqVhost: [
          {
            required: true,
            message: this.$t('qing-tian-xie-rabbitmqvhost'),
            trigger: 'blur'
          }
        ],
        dstRabbitMqVhost: [
          {
            required: true,
            message: this.$t('qing-tian-xie-rabbitmqvhost'),
            trigger: 'blur'
          }
        ],
        dstRabbitExchange: [
          {
            required: true,
            message: this.$t('qing-tian-xie-dstrabbitexchange'),
            trigger: 'blur'
          }
        ],
        hasDb: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let hasDbs = true;

              this.taskInfo.dbMap.forEach((item) => {
                if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
                  if (!item.sinkDb) {
                    hasDbs = false;
                  }
                } else if (DataSourceGroup.noDb.includes(this.taskInfo.sinkType)) {
                  if (!item.sourceDb) {
                    hasDbs = false;
                  }
                } else if (DataSourceGroup.oracle.includes(this.taskInfo.sinkType)) {
                  console.log('hello world1');
                  if ((!item.sourceDb || !item.sinkDb || !item.targetSchema)) {
                    hasDbs = false;
                  }
                } else if (!item.sourceDb || !item.sinkDb) {
                  hasDbs = false;
                }
              });
              if (!hasDbs) {
                return callback(new Error(this.$t('qing-zheng-que-xuan-ze-shu-ju-ku-xin-xi')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ]
      },
      dsRuleValidate: {
        hasDb: [
          {
            validator: (rule, value, callback) => {
              let hasDbs = true;

              this.taskInfo.dbMap.forEach((item) => {
                if (DataSourceGroup.noDb.includes(this.taskInfo.sourceType)) {
                  if (!item.sinkDb) {
                    hasDbs = false;
                  }
                } else if (DataSourceGroup.noDb.includes(this.taskInfo.sinkType)) {
                  if (!item.sourceDb) {
                    hasDbs = false;
                  }
                } else if (DataSourceGroup.oracle.includes(this.taskInfo.sinkType)) {
                  console.log('hello world2', item);
                  if (!item.sourceDb || !item.sinkDb || !item.targetSchema) {
                    hasDbs = false;
                  }
                } else if (!item.sourceDb || !item.sinkDb) {
                  hasDbs = false;
                }
              });
              if (!hasDbs) {
                return callback(new Error(this.$t('qing-zheng-que-xuan-ze-shu-ju-ku-xin-xi')));
              }
              return callback();
            },
            trigger: 'blur'
          }
        ]
      },
      nextStep: null
    };
  },
  methods: {
    listCluster(type) {
      listClusterByCondition({})
        .then((res) => {
          if (res.data.success) {
            const clusterList = res.data.data;
            this.taskInfo.clusterList = clusterList;
            for (let i = 0; i < clusterList.length; i++) {
              const cluster = clusterList[i];
              if (cluster.runningCount > 0) {
                this.taskInfo.clusterId = cluster.id;
                break;
              }
            }
            this.init(type);
          }
        });
    },
    init(type) {
      if (!this.sourceConnection || !this.sinkConnection) {
        this.listDatasourceDeployType(type, 'init');
      }
      this.getInitInitInfo(type);
    },
    getInitInitInfo() {
      if (this.taskInfo.dbMap.length === 1) {
        if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType) || DataSourceGroup.sqlServer.includes(this.taskInfo.sourceType)) {
          this.taskInfo.dbMap = [
            {
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'dbo',
              sourceSchema: 'dbo'
            }
          ];
        } else {
          this.taskInfo.dbMap = [
            {
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'public',
              sourceSchema: 'public'
            }
          ];
        }
      }

      this.getCharsetList('source');
      this.getCharsetList('sink');
    },
    handleTaskName(name) {
      this.taskInfo.taskName = name;
    },
    listDatasourceDeployType(type, status = 'change') {
      listDatasourceDeployType()
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.dataSourceDeployTypes = res.data.data;
            this.handleChangeCluster(type, status);
          }
        });
    },
    getSourceDataSourceList() {
      const deployType = this.taskInfo.sourceInstanceType;
      const type = this.taskInfo.sourceType;

      listbycondition({
        type: this.taskInfo.sourceType,
        deployType: this.taskInfo.sourceInstanceType,
        lifeCycleState: 'CREATED',
        hostType: this.taskInfo.sourceHostType
      })
        .then((res) => {
          if (res.data.code === '1' && deployType === this.taskInfo.sourceInstanceType && type === this.taskInfo.sourceType) {
            this.taskInfo.sourceInstanceList = res.data.data;
            if (store.state.jobData && this.taskInfo.sourceDataSourceId) {
              this.handleTestConnection(1);
            }
          }
        });
    },
    getSinkDataSourceList(operatorType) {
      const deployType = this.taskInfo.sinkInstanceType;
      const type = this.taskInfo.sinkType;

      // console.log('operatorType', operatorType);

      listbycondition({
        type: this.taskInfo.sinkType,
        deployType: this.taskInfo.sinkInstanceType,
        lifeCycleState: 'CREATED',
        hostType: this.taskInfo.targetHostType
      })
        .then((res) => {
          if (res.data.code === '1' && deployType === this.taskInfo.sinkInstanceType && type === this.taskInfo.sinkType) {
            this.taskInfo.sinkInstanceList = res.data.data;
            if (store.state.jobData && this.taskInfo.targetDataSourceId) {
              this.handleTestConnection(2);
              if (operatorType === 'init') {
                // console.log('test1');
                const jobData = store.state.jobData;
                this.taskInfo.sinkInstanceId = jobData.targetDsVO.instanceId;
                this.taskInfo.sinkPublicHost = jobData.targetDsVO.publicHost;
                this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkPrivateHost}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
                  this.taskInfo.sinkInstanceId}`;
              }
            }
          }
        });
    },
    handleDeployTypeChange(type, operateType, status) {
      if (type === 'source') {
        supportedsourcedstype({ deployType: this.taskInfo.sourceInstanceType })
          .then((res) => {
            if (res.data.code === '1') {
              this.taskInfo.sourceDataSourceTypes = res.data.data;
              const supportDs = [];

              Object.keys(res.data.data)
                .forEach((item) => {
                  if (res.data.data[item]) {
                    supportDs.push(item);
                  }
                });

              if (supportDs.length && !supportDs.includes(this.taskInfo.sourceType)) {
                this.taskInfo.sourceType = supportDs[0];
              }
              this.taskInfo.sourceInstanceValue = `${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourceDataSourceId}|${this.taskInfo.sourcePrivateHost}|${this.taskInfo.sourcePublicHost}|${
                this.taskInfo.sourceInstanceId}`;
              this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkPrivateHost}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
                this.taskInfo.sinkInstanceId}`;
              if (status !== 'init') {
                if (this.currentCluster.cloudOrIdcName === 'SELF_MAINTENANCE'
                  && this.taskInfo.sourceInstanceType !== 'SELF_MAINTENANCE' || this.currentCluster.cloudOrIdcName === 'ALIBABA_CLOUD'
                  && this.taskInfo.sourceInstanceType !== 'ALIBABA_CLOUD_HOSTED') {
                  this.taskInfo.sourceHostType = 'PUBLIC';
                } else {
                  this.taskInfo.sourceHostType = 'PRIVATE';
                }
              }

              this.getSourceDataSourceList();
              this.handleDeployTypeChange('sink', operateType, status);
            }
          });
        this.taskInfo.sourceDbList = [];
        this.taskInfo.showSourceAddWhiteList = false;
      } else {
        if (this.taskInfo.sourceType) {
          supportedtargetdstype({
            deployType: this.taskInfo.sinkInstanceType,
            sourceType: this.taskInfo.sourceType
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.taskInfo.sinkDataSourceTypes = res.data.data;
                const supportDs = [];

                Object.keys(res.data.data)
                  .forEach((item) => {
                    if (res.data.data[item]) {
                      supportDs.push(item);
                    }
                  });

                if (supportDs.length && !supportDs.includes(this.taskInfo.sinkType)) {
                  this.taskInfo.sinkType = supportDs[0];
                }

                this.taskInfo.sinkInstanceValue = `${this.taskInfo.sinkPrivateHost}|${this.taskInfo.targetDataSourceId}|${this.taskInfo.sinkPrivateHost}|${this.taskInfo.sinkPublicHost}|${
                  this.taskInfo.sinkInstanceId}`;
                if (status !== 'init') {
                  if (this.currentCluster.cloudOrIdcName === 'SELF_MAINTENANCE'
                    && this.taskInfo.sinkInstanceType !== 'SELF_MAINTENANCE' || this.currentCluster.cloudOrIdcName === 'ALIBABA_CLOUD'
                    && this.taskInfo.sinkInstanceType !== 'ALIBABA_CLOUD_HOSTED') {
                    this.taskInfo.targetHostType = 'PUBLIC';
                  } else {
                    this.taskInfo.targetHostType = 'PRIVATE';
                  }
                }

                // this.getSinkDataSourceList();
                this.handleTypeChange('sink', operateType);
              }
            });
          this.taskInfo.sinkDbList = [];
          this.taskInfo.showSinkAddWhiteList = false;
        }
      }
      if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType) || DataSourceGroup.sqlServer.includes(this.taskInfo.sourceType)) {
        this.taskInfo.dbMap = [
          {
            sourceDb: '',
            sinkDb: '',
            targetSchema: 'dbo',
            sourceSchema: 'dbo'
          }
        ];
      } else {
        this.taskInfo.dbMap = [
          {
            sourceDb: '',
            sinkDb: '',
            targetSchema: 'public',
            sourceSchema: 'public'
          }
        ];
      }
    },
    handleShowPreCheck() {
      this.updateLoading(true);

      this.$refs.taskInfo.validate((val) => {
        if (val) {
          this.$refs.dbMappingForm.validate((val1) => {
            if (val1) {
              try {
                this.goNext = true;
                let ifStandard = true;
                const sourceDbAndSchema = {};
                const targetDbAndSchema = {};
                const
                  that = this;

                this.taskInfo.dbMap.forEach((item) => {
                  try {
                    if (item.sourceDb.includes('.') || item.sourceSchema.includes('.') || item.sinkDb.includes('.') || item.sourceDb.includes('.')) {
                      this.$Modal.warning({
                        title: this.$t('chuang-jian-ren-wu-yi-chang-ti-shi'),
                        render: (h) => h('div', [
                          h('div', this.$t('bu-zhi-chi-qian-yi-dang-qian-xuan-ze-de-ku-nin-suo-xuan-ze-de-ku-ming-zhong-bao-han-bu-zhi-chi-de-ying-wen-zi-fu-0')),
                          h('div', this.$t('qing-zhong-xin-she-zhi-yao-xuan-ze-de-ku'))
                        ])
                      });
                      ifStandard = false;
                    }
                    if (targetDbAndSchema[item.sinkDb] && targetDbAndSchema[item.sinkDb] !== item.targetSchema && !(isMySQL(this.taskInfo.sourceType) && isSQLServer(this.taskInfo.sinkType))) {
                      console.log(1);
                      this.$Modal.warning({
                        title: this.$t('chuang-jian-ren-wu-yi-chang-ti-shi'),
                        content: this.$t('mu-qian-zan-bu-zhi-chi-duo-ku-qian-yi-xia-tong-ku-bu-tong-schema-de-qing-kuang-qing-chai-fen-cheng-duo-ge-ren-wu')
                      });
                      ifStandard = false;
                    } else {
                      targetDbAndSchema[item.sinkDb] = item.targetSchema;
                    }
                    if (sourceDbAndSchema[item.sourceDb] && sourceDbAndSchema[item.sourceDb] !== item.sourceSchema) {
                      console.log(2);
                      this.$Modal.warning({
                        title: this.$t('chuang-jian-ren-wu-yi-chang-ti-shi'),
                        content: this.$t('mu-qian-zan-bu-zhi-chi-duo-ku-qian-yi-xia-tong-ku-bu-tong-schema-de-qing-kuang-qing-chai-fen-cheng-duo-ge-ren-wu')
                      });
                      ifStandard = false;
                    } else {
                      sourceDbAndSchema[item.sourceDb] = item.sourceSchema;
                    }
                    if (DataSourceGroup.noDb.includes(this.taskInfo.sinkType)) {
                      item.needAutoCreated = false;
                      if (isSchemaMapping(this.taskInfo.sourceType)) {
                        item.schemaAutoCreate = false;
                      }
                    } else {
                      item.needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, item.sinkDb);
                      if (isSchemaMapping(this.taskInfo.sourceType)) {
                        item.schemaAutoCreate = !this.containsDb(this.taskInfo.sinkDbList, item.sinkDb);
                      }
                    }
                  } catch (e) {
                    console.log('validate schema', e);
                  }
                });
                if (!ifStandard) {
                  this.updateLoading(false);

                  return;
                }
                // this.taskInfo.dbMap = this.dbMap;
                if (DataSourceGroup.mq.indexOf(this.taskInfo.sourceType) === -1
                  && DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) === -1) {
                  for (const db of this.taskInfo.dbMap) {
                    if (!db.sourceDb || !db.sinkDb) {
                      this.$Modal.warning({
                        title: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-xin-xi'),
                        content: this.$t('qing-dian-ji-ce-shi-lian-jie-hou-xuan-ze-wan-zheng-de-yuan-shu-ju-ku-he-mu-biao-shu-ju-ku')
                      });
                      this.updateLoading(false);
                      return;
                    }
                  }
                } else if (this.taskInfo.dbMap.length < 0) {
                  this.$Modal.warning({
                    title: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-xin-xi'),
                    content: this.$t('qing-zhi-shao-xuan-ze-yi-ge-ku')
                  });
                  this.updateLoading(false);
                  return;
                }
                if (this.goNext) {
                  this.hasNoTableDb = [];

                  try {
                    const selectedSchemas = [];
                    this.taskInfo.dbMap.forEach((item) => {
                      selectedSchemas.push({
                        dbName: item.sourceDb,
                        schema: DataSourceGroup.hasSchema.includes(this.taskInfo.sourceType) ? item.sourceSchema : null
                      });
                    });
                    if (!DataSourceGroup.mq.includes(this.taskInfo.sourceType)) {
                      schemasTableCount({
                        dataSourceId: this.taskInfo.sourceDataSourceId,
                        clusterId: this.taskInfo.clusterId,
                        hostType: this.taskInfo.sourceHostType,
                        selectedSchemas
                      }).then((res) => {
                        if (res.data.code === '1') {
                          const responseData = res.data.data;
                          Object.keys(responseData).forEach((db) => {
                            if (responseData[db] === 0) {
                              this.hasNoTableDb.push(JSON.parse(db).dbName);
                            }
                          });
                          if (this.hasNoTableDb.length > 0) {
                            if (this.hasNoTableDb.length === this.taskInfo.dbMap.length) {
                              this.$Modal.warning({
                                title: this.$t('ren-wu-chuang-jian-ti-shi'),
                                content: this.$t('dang-qian-suo-xuan-ku-jun-wei-kong-ku-biao-de-shu-liang-wei-0-bu-zhi-chi-qian-yi-qing-zhong-xin-xuan-ze')
                              });
                            } else {
                              this.showNoTableDb = true;
                            }
                          } else {
                            setTimeout(() => {
                              if (that.goNext) {
                                that.updateStep(1);
                              }
                            }, 500);
                          }
                        }
                        this.updateLoading(false);
                      }).catch(() => {
                        this.updateLoading(false);
                      }).finally(() => {
                        this.updateLoading(false);
                      });
                    } else {
                      setTimeout(() => {
                        if (that.goNext) {
                          that.updateStep(1);
                        }
                      }, 100);
                      this.updateLoading(false);
                    }
                  } catch (e) {
                    console.log('e', e);
                  }
                }
              } catch (e) {
                console.log('catch error after validate', e);
              }
            } else {
              this.updateLoading(false);
            }
          });
        } else {
          this.updateLoading(false);
        }
      });
    },
    querySourceWhiteList() {
      const ids = [];

      ids.push(this.taskInfo.clusterId);
      queryWhiteListExist({
        dataSourceId: this.taskInfo.sourceDataSourceId,
        dataSourceType: this.taskInfo.sourceType,
        clusterIds: ids,
        dataSourceRegion: this.currentRegion,
        whiteListAddType: this.taskInfo.sourceHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.showSourceAddWhiteList = res.data.data.length > 0;
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
            this.nextStep = this.querySourceWhiteList;
          }
        });
    },
    handleInstanceChange(type, data) {
      if (type === 'source') {
        this.taskInfo.sourceDbList = [];
        if (data) {
          this.taskInfo.sourceInstance = data.split('|')[0];
          this.taskInfo.sourceDataSourceId = data.split('|')[1];
          this.taskInfo.sourcePrivateHost = data.split('|')[2];
          this.taskInfo.sourceInstanceId = data.split('|')[4];
          if (data.split('|')[3] === 'null') {
            this.taskInfo.sourcePublicHost = '';
          } else {
            this.taskInfo.sourcePublicHost = data.split('|')[3];
          }
          this.querySourceWhiteList();
        }
        this.taskInfo.showSourceConnection = false;
      } else {
        this.taskInfo.sinkDbList = [];
        if (data) {
          this.taskInfo.sinkInstance = data.split('|')[0];
          this.taskInfo.targetDataSourceId = data.split('|')[1];
          this.taskInfo.sinkPrivateHost = data.split('|')[2];
          this.taskInfo.sinkInstanceId = data.split('|')[4];
          if (data.split('|')[3] === 'null') {
            this.taskInfo.sinkPublicHost = '';
          } else {
            this.taskInfo.sinkPublicHost = data.split('|')[3];
          }
          this.querySinkWhiteList();
        }
        this.taskInfo.showSinkConnection = false;
      }
      this.taskInfo.functionalConfigHistory = false;
    },
    querySinkWhiteList() {
      const ids = [];

      ids.push(this.taskInfo.clusterId);
      queryWhiteListExist({
        dataSourceId: this.taskInfo.targetDataSourceId,
        dataSourceType: this.taskInfo.sinkType,
        clusterIds: ids,
        dataSourceRegion: this.currentRegion,
        whiteListAddType: this.taskInfo.targetHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.taskInfo.showSinkAddWhiteList = res.data.data.length > 0;
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
            this.nextStep = this.querySourceWhiteList;
          }
        });
    },
    getCharsetList(type) {
      if (type === 'source') {
        if (this.taskInfo.sourceType) {
          dsCharsetOptions({ dataSourceType: this.taskInfo.sourceType })
            .then((res) => {
              if (res.data.code === '1') {
                this.taskInfo.sourceCharsetList = res.data.data.dsCharsetOptions;

                res.data.data.dsCharsetOptions.forEach((charset) => {
                  if (charset.defaultChecked) {
                    this.taskInfo.sourceCharset = charset.dsCharset;
                  }
                });
              }
            });
        }
      } else {
        if (this.taskInfo.sinkType) {
          dsCharsetOptions({ dataSourceType: this.taskInfo.sinkType })
            .then((res) => {
              if (res.data.code === '1') {
                this.taskInfo.targetCharsetList = res.data.data.dsCharsetOptions;

                res.data.data.dsCharsetOptions.forEach((charset) => {
                  if (charset.defaultChecked) {
                    this.taskInfo.targetCharset = charset.dsCharset;
                  }
                });
              }
            });
        }
      }
    },
    emptyTaskInfo() {
      this.taskInfo.firstToTableFilter = false;
      this.taskInfo.firstToCleanData = false;
    },
    handleDbChange(type, index, value) {
      this.emptyTaskInfo();
      console.log(value);
      // if (this.hasSelectedTheDb(value)) {
      //     return;
      // }
      const { sourceType, sinkType } = this.taskInfo;
      if (type === 'source') {
        if (this.taskInfo.targetCaseSensitive === 'false') {
          let hasSame = false;

          if (value) {
            if (DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) === -1 || DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) > -1) {
              this.taskInfo.sinkDbList.forEach((sinkDb) => {
                if (sinkDb.dbName.toUpperCase() === value.toUpperCase()) {
                  this.taskInfo.dbMap[index].sinkDb = sinkDb.dbName.toLowerCase();

                  hasSame = true;
                }
              });
              if (!hasSame) {
                this.taskInfo.dbMap[index].sinkDb = value.toLowerCase();
                if (DataSourceGroup.mongo.includes(this.taskInfo.sourceType) || isTunnel(this.taskInfo.sourceType)) {
                  this.taskInfo.dbMap[index].sinkDb = '';
                }

                if (isHudi(sinkType)) {
                  this.taskInfo.dbMap[index].sinkDb = 'hudi';
                  this.taskInfo.sinkDbList.forEach((sinkDb) => {
                    if (sinkDb.dbName === 'hudi') {
                      this.taskInfo.dbMap[index].targetSchema = value.toLowerCase();
                    }
                  });
                }
              }
            }
          }
        } else {
          if (!(isOracle(sourceType) && !isHasSchema(sinkType))) {
            this.taskInfo.dbMap[index].sinkDb = value;
          }

          if (isOracle(sourceType) && isMQ(sinkType)) {
            this.taskInfo.dbMap[index].sinkDb = value;
          }

          if ((isMongoDB(sourceType) && !isMongoDB(sinkType)) || isTunnel(sourceType)) {
            this.taskInfo.dbMap[index].sinkDb = '';
            this.taskInfo.sinkDbList.forEach((sinkDb) => {
              if (sinkDb.dbName.toUpperCase() === value.toUpperCase()) {
                this.taskInfo.dbMap[index].sinkDb = sinkDb.dbName.toLowerCase();
              }
            });
          }

          if (isSchemaMapping(sourceType)) {
            this.taskInfo.dbMap[index].sinkDb = '';
          }
        }
        if (this.taskInfo.sinkConnection) {
          this.taskInfo.dbMap[index].needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, value);
          if (DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1) {
            this.taskInfo.dbMap[index].needAutoCreated = false;
          }
        } else {
          this.taskInfo.dbMap[index].needAutoCreated = false;
        }
      } else {
        this.taskInfo.dbMap[index].sinkDb = value;

        this.taskInfo.dbMap[index].needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, value);
      }
    },
    handleAddMap() {
      if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType) || DataSourceGroup.sqlServer.includes(this.taskInfo.sourceType)) {
        this.taskInfo.dbMap.push({
          sourceDb: '',
          sinkDb: '',
          targetSchema: 'dbo',
          sourceSchema: 'dbo'
        });
      } else {
        this.taskInfo.dbMap.push({
          sourceDb: '',
          sinkDb: '',
          targetSchema: 'public',
          sourceSchema: 'public'
        });
      }
    },
    handleRemoveMap(i) {
      this.taskInfo.dbMap.splice(i, 1);
    },
    handleTestConnection(n) {
      this.emptyTaskInfo();
      if (n === 1) {
        // this.test1 = true;
        if (this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost) {
          this.taskInfo.sourceConnectionLoading = true;
          this.taskInfo.showSourceConnection = false;
          testConnection({
            host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
            privateHost: this.taskInfo.sourcePrivateHost,
            publicHost: this.taskInfo.sourcePublicHost,
            hostType: this.taskInfo.sourceHostType,
            type: this.taskInfo.sourceType,
            userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
            && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
            dataSourceId: this.taskInfo.sourceDataSourceId,
            clusterId: this.taskInfo.clusterId,
            specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword
          })
            .then((res) => {
              this.taskInfo.sourceConnectionLoading = false;
              this.taskInfo.showSourceConnection = true;
              if (res.data.code === '1') {
                this.taskInfo.sourceConnection = true;
                this.queryDsAttrs('source');
                getDbList({
                  host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
                  privateHost: this.taskInfo.sourcePrivateHost,
                  publicHost: this.taskInfo.sourcePublicHost,
                  hostType: this.taskInfo.sourceHostType,
                  type: this.taskInfo.sourceType,
                  userName: DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1
                  && this.taskInfo.sourceAccountRole ? `${this.taskInfo.sourceAccount} as SYSDBA` : this.taskInfo.sourceAccount,
                  dataSourceId: this.taskInfo.sourceDataSourceId,
                  clusterId: this.taskInfo.clusterId
                })
                  .then((response) => {
                    this.taskInfo.sourceDbList = response.data.data;
                    if (store.state.jobData) {
                      this.parseExistSchemaData(store.state.jobData.schemaData);
                      // this.taskInfo.dbMap = _.cloneDeep(this.taskInfo.dbMap);
                    }
                    const that = this;

                    this.$nextTick(() => {
                      // eslint-disable-next-line no-unused-expressions
                      ({ ...that.taskInfo });
                    });
                  });
              } else {
                this.taskInfo.sourceConnection = false;
              }
            })
            .catch(() => {
              this.taskInfo.sourceConnection = false;
            });
        }
      } else if (n === 2) {
        if (this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost) {
          this.taskInfo.sinkConnectionLoading = true;
          this.taskInfo.showSinkConnection = false;
          testConnection({
            host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
            privateHost: this.taskInfo.sinkPrivateHost,
            publicHost: this.taskInfo.sinkPublicHost,
            hostType: this.taskInfo.targetHostType,
            type: this.taskInfo.sinkType,
            userName: this.taskInfo.sinkAccount,
            password: this.taskInfo.sinkPasswd,
            dataSourceId: this.taskInfo.targetDataSourceId,
            clusterId: this.taskInfo.clusterId
          })
            .then((res) => {
              this.taskInfo.sinkConnectionLoading = false;
              this.taskInfo.showSinkConnection = true;
              if (res.data.code === '1') {
                this.taskInfo.sinkConnection = true;
                this.queryDsAttrs('sink');
                if (this.taskInfo.sinkType !== 'Kafka' && this.taskInfo.sinkType !== 'RocketMQ' && this.taskInfo.sinkType !== 'ElasticSearch') {
                  getDbList({
                    host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
                    privateHost: this.taskInfo.sinkPrivateHost,
                    publicHost: this.taskInfo.sinkPublicHost,
                    hostType: this.taskInfo.targetHostType,
                    type: this.taskInfo.sinkType,
                    userName: this.taskInfo.sinkAccount,
                    dataSourceId: this.taskInfo.targetDataSourceId,
                    clusterId: this.taskInfo.clusterId
                  })
                    .then((response) => {
                      this.taskInfo.sinkDbList = response.data.data;
                      this.taskInfo.dbMap[0].sinkDb = this.taskInfo.dbMap[0].sourceDb;
                      this.taskInfo.dbMap[0].needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, this.taskInfo.dbMap[0].sourceDb);
                      if (DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1) {
                        this.taskInfo.dbMap[0].needAutoCreated = false;
                      }
                      if (store.state.jobData) {
                        this.parseExistSchemaData(store.state.jobData.schemaData);
                        // this.taskInfo.dbMap = _.cloneDeep(this.taskInfo.dbMap);
                      }
                    });
                }
              } else {
                this.taskInfo.sinkConnection = false;
              }
            })
            .catch(() => {
              this.taskInfo.sinkConnection = false;
            });
        }
      }
      store.commit('updateCompareSelectedDbs', []);
      if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType) || DataSourceGroup.sqlServer.includes(this.taskInfo.sourceType)) {
        this.taskInfo.dbMap.forEach((db) => {
          db.sourceSchema = 'dbo';
          db.targetSchema = 'dbo';
        });
      }
    },
    queryDsAttrs(type) {
      if (type === 'source') {
        queryDsAttrs({
          host: this.taskInfo.sourceHostType === 'PUBLIC' ? this.taskInfo.sourcePublicHost : this.taskInfo.sourcePrivateHost,
          privateHost: this.taskInfo.sourcePrivateHost,
          publicHost: this.taskInfo.sourcePublicHost,
          hostType: this.taskInfo.sourceHostType,
          type: this.taskInfo.sourceType,
          dataSourceId: this.taskInfo.sourceDataSourceId,
          clusterId: this.taskInfo.clusterId,
          specifiedUserPassword: this.taskInfo.sourceSpecifiedUserPassword
        }).then((res) => {
          if (res.data.code === '1') {
            this.dsAttrs = res.data.data;
            this.taskInfo.srcRabbitMqVhost = 'cloudcanal';
            this.taskInfo.srcRabbitExchange = this.taskInfo.sourceInstanceId;
          }
        });
      } else {
        queryDsAttrs({
          host: this.taskInfo.targetHostType === 'PUBLIC' ? this.taskInfo.sinkPublicHost : this.taskInfo.sinkPrivateHost,
          privateHost: this.taskInfo.sinkPrivateHost,
          publicHost: this.taskInfo.sinkPublicHost,
          hostType: this.taskInfo.targetHostType,
          type: this.taskInfo.sinkType,
          userName: this.taskInfo.sinkAccount,
          dataSourceId: this.taskInfo.targetDataSourceId,
          clusterId: this.taskInfo.clusterId
        }).then((res) => {
          if (res.data.code === '1') {
            this.dsAttrs = res.data.data;
            this.taskInfo.dstRabbitMqVhost = 'cloudcanal';
            this.taskInfo.dstRabbitExchange = this.taskInfo.sinkInstanceId;
          }
        });
      }
    },
    hasSelectedTheDb(item, type = 'sink') {
      for (const db of this.taskInfo.dbMap) {
        if (type === 'source' && item === db.sourceDb) {
          return true;
        }
        if (type === 'sink' && item === db.sinkDb) {
          return true;
        }
      }

      return false;
    },
    hasTheDb(item) {
      for (let i = 0; i < this.taskInfo.dbMap.length; i++) {
        if (item && item === this.taskInfo.dbMap[i].sourceDb) {
          return true;
        }
      }
      return false;
    },
    async getKeyConflictStrategy(dataSourceType) {
      const res = await rdbKeyConflictStrategy({
        dataSourceType,
        endPointType: 'TARGET'
      });

      if (res.data.success) {
        this.taskInfo.keyConflictStrategy = res.data.data ? res.data.data.defaultStrategy : null;
        this.taskInfo.keyConflictStrategyList = res.data.data ? res.data.data.strategies : [];
      }
    },
    async handleTypeChange(type, operatorType) {
      this.taskInfo.mappingMode = 'advanced';

      if (this.taskInfo.dbMap.length === 1) {
        if (DataSourceGroup.sqlServer.includes(this.taskInfo.sinkType) || DataSourceGroup.sqlServer.includes(this.taskInfo.sourceType)) {
          this.taskInfo.dbMap = [
            {
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'dbo',
              sourceSchema: 'dbo'
            }
          ];
        } else {
          this.taskInfo.dbMap = [
            {
              sourceDb: '',
              sinkDb: '',
              targetSchema: 'public',
              sourceSchema: 'public'
            }
          ];
        }
      }

      if (type === 'sink') {
        const { sinkType } = this.taskInfo;
        if (operatorType !== 'init' && operatorType !== undefined) {
          this.taskInfo.sinkInstanceId = '';
          this.taskInfo.sinkInstance = '';
          this.taskInfo.sinkInstanceValue = '';
          if (store.state.jobData) {
            this.taskInfo.targetDataSourceId = '';
          }
        }

        this.getSinkDataSourceList(operatorType);
        // 目标端的pg\gp默认大小写不敏感
        if ((isPG(sinkType) && !isTunnel(sinkType)) || isTidDB(sinkType)) {
          this.taskInfo.targetCaseSensitive = 'false';
        } else {
          this.taskInfo.targetCaseSensitive = 'true';
        }

        if (isPG(sinkType) || isMySQL(sinkType) || isPolar(sinkType)) {
          this.taskInfo.targetTimeDefaultStrategy = 'IS_NULL';
        } else {
          this.taskInfo.targetTimeDefaultStrategy = null;
        }

        this.taskInfo.sinkExtraSetting = isES(this.taskInfo.sinkType) || isStarRocks(this.taskInfo.sinkType) || isKafka(this.taskInfo.sinkType);
        // this.$refs.dbMapping.handleEmptySinkQuery();
        this.taskInfo.showSinkConnection = false;
        await this.getKeyConflictStrategy(sinkType);
      } else if (type === 'source') {
        console.log('type change', operatorType, 'source');
        this.taskInfo.sourceInstanceId = '';
        this.taskInfo.sourceInstance = '';
        this.taskInfo.sourceInstanceValue = '';
        if (operatorType !== 'init' && operatorType !== undefined && store.state.jobData) {
          this.taskInfo.sourceDataSourceId = '';
        }

        await this.handleTypeChange('sink');
        this.getSourceDataSourceList();
        this.handleDeployTypeChange('sink', operatorType);
        this.taskInfo.showSourceConnection = false;

        this.taskInfo.sourceExtraSetting = isOracle(this.taskInfo.sourceType);
      }
      this.taskInfo.sinkDbList = [];
      this.taskInfo[`${type}Connection`] = false;

      this.getCharsetList(type);
    },
    async getOraIncrMode() {
      const res = await oraInCrMode();
      if (res.data.success) {
        this.taskInfo.oraIncrModeList = res.data.data;
        res.data.data.forEach((mode) => {
          if (mode.defaultCheck) {
            this.taskInfo.oraIncrMode = mode.oraInceMode;
          }
        });
      }
    },
    handleAddWhiteList(type) {
      this.showType = 'add';
      if (!type) {
        // eslint-disable-next-line no-param-reassign
        type = 'source';
      }
      this.addClusterWhiteList(type);
    },
    addClusterWhiteList(type) {
      this.currentPercentage = 0;
      if (type === 'source') {
        // this.sourceDisabled = true;
        const ids = [];

        ids.push(this.taskInfo.clusterId);
        addClusterWhiteList({
          dataSourceId: this.taskInfo.sourceDataSourceId,
          dataSourceType: this.taskInfo.sourceType,
          clusterIds: ids,
          dataSourceRegion: this.currentRegion,
          whiteListAddType: this.taskInfo.sourceHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
        })
          .then((res) => {
            if (res.data.code === '1') {
              if (this.dataIsNaN(res.data.data)) {
                this.showAddWhiteList = true;
                const that = this;

                this.querySourceAddWhiteListInterval = setInterval(() => {
                  queryConsoleJob({ consoleJobId: res.data.data })
                    .then((response) => {
                      if (res.data.code === '1') {
                        that.currentAddWhiteListStatus = response.data.data.taskState;
                        if (response.data.data.taskState === 'SUCCESS') {
                          clearInterval(that.querySourceAddWhiteListInterval);
                          // that.sourceDisabled = false;
                          that.taskInfo.showSourceAddWhiteList = false;
                          setTimeout(() => {
                            that.showAddWhiteList = false;
                          }, 500);
                        } else if (response.data.data.taskState === 'FAILED') {
                          clearInterval(that.querySourceAddWhiteListInterval);
                          // that.sourceDisabled = false;
                        }
                      }
                    })
                    .catch(() => {
                      // that.sourceDisabled = false;
                    });
                }, 2000);
              } else {
                this.$Modal.success({
                  title: this.$t('zheng-zai-tian-jia-bai-ming-dan'),
                  content: this.$t('zheng-zai-tian-jia-bai-ming-dan-qing-deng-dai-pian-ke-zhi-dao-ce-shi-lian-jie-an-niu-liang-qi')
                });
              }
            } else if (res.data.code === '6028') {
              this.$refs.stToken.handleShowAkSk();
            } else if (res.data.code === '6047') {
              this.$refs.aliyunAKSK.handleShowAkSk();
            }
            // this.sourceDisabled = false;
            this.showAddWhiteList = false;
          })
          .catch(() => {
            // this.sourceDisabled = false;
            this.showAddWhiteList = false;
          });
      } else {
        // this.sinkDisabled = true;
        const ids = [];

        ids.push(this.taskInfo.clusterId);
        addClusterWhiteList({
          dataSourceId: this.taskInfo.targetDataSourceId,
          dataSourceType: this.taskInfo.sinkType,
          clusterIds: ids,
          dataSourceRegion: this.currentRegion,
          whiteListAddType: this.taskInfo.targetHostType === 'PRIVATE' ? 'PRIVATE_IP_ONLY' : 'PUBLIC_IP_ONLY'
        })
          .then((res) => {
            if (res.data.code === '1') {
              const that = this;

              if (this.dataIsNaN(res.data.data)) {
                this.showAddWhiteList = true;
                this.querySinkAddWhiteListInterval = setInterval(() => {
                  queryConsoleJob({ consoleJobId: res.data.data })
                    .then((response) => {
                      if (res.data.code === '1') {
                        that.currentAddWhiteListStatus = res.data.data.taskState;
                        if (response.data.data.taskState === 'SUCCESS') {
                          // that.sinkDisabled = false;
                          that.taskInfo.showSinkAddWhiteList = false;
                          clearInterval(that.querySinkAddWhiteListInterval);
                          setTimeout(() => {
                            that.showAddWhiteList = false;
                          }, 500);
                        } else if (res.data.data.taskState === 'FAILED') {
                          // that.sinkDisabled = false;
                          clearInterval(that.querySinkAddWhiteListInterval);
                        }
                      }
                    })
                    .catch(() => {
                      // that.sinkDisabled = false;
                    });
                }, 2000);
              } else {
                this.$Message.success(this.$t('zheng-zai-tian-jia-bai-ming-dan'));
              }
            } else if (res.data.code === '6028') {
              this.$refs.stToken.handleShowAkSk();
            } else if (res.data.code === '6047') {
              this.$refs.aliyunAKSK.handleShowAkSk();
            }
            // this.sinkDisabled = false;
          })
          .catch(() => {
            // this.sinkDisabled = false;
          });
      }
      const that = this;

      this.getPercantage = setInterval(() => {
        if (that.currentAddWhiteListStatus === 'SUCCESS') {
          that.currentPercentage = 100;
          clearInterval(that.getPercantage);
          setTimeout(() => {
            that.showAddWhiteList = false;
          }, 500);
        } else if (that.currentAddWhiteListStatus === 'FAILED') {
          clearInterval(that.getPercantage);
        } else if (that.currentPercentage < 90) {
          that.currentPercentage += Math.floor(Math.random() * 3);
        } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
          that.currentPercentage += Math.floor(Math.random() * 1.5);
        } else if (that.currentPercentage >= 97) {
          that.currentPercentage = 99;
        }
      }, 200);
    },
    handleChangeCluster(type, status) {
      this.taskInfo.clusterList.forEach((cluster) => {
        if (cluster.id === this.taskInfo.clusterId) {
          this.currentRegion = cluster.region;
          this.currentCluster = cluster;
          if (this.currentCluster.cloudOrIdcName === 'ALIBABA_CLOUD') {
            this.taskInfo.sourceInstanceType = 'ALIBABA_CLOUD_HOSTED';
            this.taskInfo.sinkInstanceType = 'ALIBABA_CLOUD_HOSTED';
          } else {
            this.taskInfo.sourceInstanceType = this.currentCluster.cloudOrIdcName;
            this.taskInfo.sinkInstanceType = this.currentCluster.cloudOrIdcName;
            if (store.state.jobData) {
              this.taskInfo.sourceInstanceType = store.state.jobData.sourceDsVO.deployType;
              this.taskInfo.sinkInstanceType = store.state.jobData.targetDsVO.deployType;
            }
          }
        }
      });

      this.handleDeployTypeChange('source', undefined, status);
      this.handleDeployTypeChange('sink', type, status);
    },
    dataIsNaN(value) {
      return typeof value === 'number' && !Number.isNaN(value);
    },
    handleChangeNetType(type) {
      if (type === 'source') {
        this.taskInfo.sourceInstanceId = '';
        this.taskInfo.sourceInstanceValue = '';
        this.taskInfo.showSourceAddWhiteList = false;
        this.getSourceDataSourceList();
      } else {
        this.taskInfo.sinkInstanceId = '';
        this.taskInfo.sinkInstanceValue = '';
        this.taskInfo.showSinkAddWhiteList = false;
        this.getSinkDataSourceList();
      }
    },
    isPg(type) {
      return DataSourceGroup.pg.indexOf(type) > -1;
    },
    handleChangeSchema(db, data) {
      this.emptyTaskInfo();
      const {
        sourceType,
        sinkType
      } = this.taskInfo;

      if (isOracle(sourceType) && this.isPg(sinkType) && this.taskInfo.targetCaseSensitive === 'false') {
        db.targetSchema = data.toLowerCase();
      } else {
        db.targetSchema = data;
      }

      if (DataSourceGroup.hasSchema.includes(sinkType)) {
        db.schemaAutoCreate = this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb) && this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb)
          .indexOf(data) === -1;
      } else {
        db.schemaAutoCreate = false;
        db.needAutoCreated = !this.containsDb(this.taskInfo.sinkDbList, data);
      }
      if (DataSourceGroup.oracle.indexOf(this.taskInfo.sourceType) > -1 && DataSourceGroup.hasSchema.indexOf(this.taskInfo.sinkType) === -1) {
        db.sinkDb = data;
      }
    },
    handleSchemaChange(db, index) {
      this.emptyTaskInfo();
      if (isSchemaMapping(this.taskInfo.sourceType)) {
        const { sourceSchema } = this.taskInfo.dbMap[index];
        this.taskInfo.dbMap[index].sinkDb = this.taskInfo.targetCaseSensitive === 'false' ? sourceSchema.toLowerCase() : sourceSchema;
        this.taskInfo.dbMap[index].targetSchema = this.taskInfo.targetCaseSensitive === 'false' ? sourceSchema.toLowerCase() : sourceSchema;
      }
      if (this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb)) {
        this.taskInfo.dbMap[index].schemaAutoCreate = this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb) && !this.getSchemaList(this.taskInfo.sinkDbList, db.sinkDb)
          .includes(db.targetSchema);
      } else {
        this.taskInfo.dbMap[index].schemaAutoCreate = true;
      }

      if (!isHasSchema(this.taskInfo.sinkType)) {
        this.taskInfo.dbMap[index].schemaAutoCreate = false;
      }
    },
    handleRemoveNoDbTables() {
      this.hasNoTableDb.forEach((db) => {
        this.taskInfo.dbMap.forEach((item, index) => {
          if (item.sourceDb === db) {
            this.taskInfo.dbMap.splice(index, 1);
          }
        });
        if (this.$refs.dbMapping) {
          this.taskInfo.treeData.forEach((item) => {
            if (item.title === db) {
              item.checked = false;
            }
          });
        }
      });
      if (this.$refs.dbMapping) {
        this.$refs.dbMapping.filteredData = _.cloneDeep(this.taskInfo.treeData);
      }

      this.showNoTableDb = false;
      setTimeout(() => {
        this.updateStep(1);
      }, 500);
    },
    handleCancel() {
      this.showNoTableDb = false;
    },
    updateShowEditMapping(data) {
      this.showEditMapping = data;
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'taskInfo.sourceDbList': function () {
      this.taskInfo.treeData = [];
      this.taskInfo.sourceDbList.forEach((db) => {
        this.taskInfo.treeData.push({
          title: db.dbName,
          checked: false,
          render: (h, { data }) => {
            if (data.checked) {
              return h('span', {
                class: 'db-mapping-item'
              }, [
                h('span', {}, db.dbName),
                h('span', {
                  style: {
                    marginLeft: '16px'
                  }
                }, data.sinkDb ? this.$t('mu-biao-ku-datasinkdb', [data.sinkDb]) : ''),
                h('Icon', {
                  props: {
                    type: 'md-create'
                  },
                  style: {
                    marginLeft: '16px'
                  },
                  class: 'edit-mapping-icon',
                  on: {
                    click: () => {
                      this.showEditMapping = true;
                      this.currentSourceDb = db.dbName;
                      this.currentSinkDb = data.sinkDb || db.dbName;
                      this.batchlyEdit = false;
                    }
                  }
                })
              ]);
            }
            return h('span', [
              h('span', {}, db.dbName),
              h('span', {}, data.sinkDb ? this.$t('mu-biao-ku-datasinkdb', [data.sinkDb]) : '')
            ]);
          }
        });
      });
    },
    // eslint-disable-next-line func-names
    'taskInfo.sinkDbList': function () {
      this.taskInfo.dbMap.forEach((item) => {
        item.needAutoCreated = !(DataSourceGroup.noDb.indexOf(this.taskInfo.sinkType) > -1);
        this.taskInfo.sinkDbList.forEach((db) => {
          if (item.sinkDb === db.dbName) {
            item.needAutoCreated = false;
          }
        });
      });
    },
    deep: true
  }
};
</script>
<style lang="less">
.task-create-first-step {
  padding: 20px;

  .datasource-icon {
    font-size: 18px;
  }
}

.arrow-between-card {
  text-align: center;
  margin-top: 200px;
  font-size: 38px;
}

.show-manual-account {
  margin-top: 20px;
  margin-bottom: 12px;
}

.show-manual-account-container {
  padding: 16px;
  border: 1px solid #EDEDED;
  width: 600px;
  background-color: #FAFAFA;
  margin-bottom: 20px;
  position: relative;

  .cancel-manual-btn {
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
    font-size: 16px;
    z-index: 2;
  }
}
</style>
