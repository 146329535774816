<template>
  <div>
    <Modal
      v-model="showMapping"
      :title="$t('jobdatadatajobname-ren-wu-de-ku-biao-ying-she', [jobData.dataJobName])"
      width="1200px"
      footer-hide
      :closable="false"
    >
      <div>
        <div style="height: 560px;overflow: auto;" v-if="showMapping" class="show-mapping-modal">
          <ConfigMapping :sourceSchema="sourceSchema" :getTargetData="getTargetData"
                         :getAddedData="getAddedData" :trueColumnData="trueColumnData"
                         :selectedIndex="selectedIndex" :mappingDef="mappingDef"
                         :getMappingCount="getMappingCount"
                         :showTableMapping="showTableMapping" :sourceTable="sourceTable"
                         :handlePageChange="handlePageChange"
                         :handleChangeTable="handleChangeTable" :jobData="jobData"
                         :mqSchema="mqSchema" :handleShowWhereCondition="handleShowWhereCondition"
                         :targetSchema="targetSchema"></ConfigMapping>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleChangeBinlogCancel">{{ $t('guan-bi') }}
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showResetPosition"
      :title="$t('hui-su-wei-dian')"
      footer-hide
      :closable="false"
    >
      <div>
        <Alert type="warning" style="margin-bottom: 20px">
          <div style="color: #333;line-height: 22px">
            <ul>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('gai-cao-zuo') }}
                <span class="warn-font">{{ $t('bu-ke-ni') }}</span>
                {{ $t('qing-jin-shen-cao-zuo') }}
              </li>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('hui-su-wei-dian-hou-jiang-zi-dong-zhong-qi-ren-wu') }}
              </li>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('hui-su-wei-dian-de-shi-jian-ru-guo-da-yu-dang-qian-de-wei-dian-shi-jian-ke-neng-you') }}
                <strong>{{ $t('shu-ju-diu-shi-feng-xian-0') }}</strong>
              </li>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('ru-xu-qing-kong-wei-dian-cong-dang-qian-shi-jian-kai-shi-xiao-fei-qing-shi-yong-qing-chu-wei-dian') }}
              </li>
            </ul>
          </div>
        </Alert>
        <p style="margin-bottom: 16px" v-if="posTypeList.length>1">
          <span style="margin-right: 10px">{{ $t('wei-dian-lei-xing') }}</span>
          <Select style="width: 200px;display: inline-block" v-model="posType" @on-change="handleChangePositionType">
            <Option v-for="type in posTypeList" :key="type" :value="type">{{MappingUtil.posType[type]||type}}</Option>
          </Select>
        </p>
        <div v-if="posType.includes('TIMESTAMP_POS')" style="margin-bottom:10px;">
          <span style="margin-right: 10px">{{ $t('xuan-ze-shi-jian-chuo') }}</span>
          <DatePicker :editable="false" :options="startTimeOptions"
                      v-model="theBinlogTime"
                      @on-change="updatePositionInfo(theBinlogTime, posType)"
                      type="datetime" style="width: 200px">
          </DatePicker>
          <Poptip trigger="hover">
            <Icon type="ios-help-circle-outline" size="20" style="margin-left: 10px"></Icon>
            <div slot="content">
              {{ $t('qing-que-bao-tian-xie-de-shi-jian-dian-yi-hou-bao-han-yi-shang-zhi-ding-de-binlog-file') }}
            </div>
          </Poptip>
        </div>
        <div v-if="posType.includes('GTID_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-gtid') }}</span>
          <Input v-model="gtid" style="width: 200px" @on-change="updatePositionInfo(gtid, posType)" />
        </div>
        <div v-if="posType.includes('LOG_FILE_POS')">
          <p style="margin-bottom: 16px">
            <span style="margin-right: 10px">{{ $t('tian-xie-wen-jian-ming-cheng') }}</span>
            <Input v-model="theLogFile" style="width: 200px" @on-change="updatePositionInfo(theLogFile, posType, 'file')" />
          </p>
          <p>
            <span style="margin-right: 10px">{{ $t('tian-xie-wen-jian-wei-dian') }}</span>
            <Input v-model="logFilePosition" style="width: 200px" @on-change="updatePositionInfo(logFilePosition, posType, 'position')" />
          </p>
        </div>
        <div v-if="posType.includes('LSN_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-lsn') }}</span>
          <Input v-model="lsn" style="width: 200px" @on-change="updatePositionInfo(lsn, posType)" />
        </div>
        <div v-if="posType.includes('SCN_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-scn') }}</span>
          <Input v-model="scn" style="width: 200px" @on-change="updatePositionInfo(scn, posType)" />
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" :loading="positionLoading" :disabled="!posType || (posType.includes('TIMESTAMP_POS')&&!theBinlogTime)"
                     @click="handleResetPosition('', false, posType)">{{ $t('que-ding') }}
          </pd-button>
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleChangeBinlogCancel">{{ $t('qu-xiao') }}
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showPartition" :title="$t('fen-qu-she-zhi')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80" v-if="partition.partition[0]">
          <FormItem :label="$t('fen-qu-jian')">
            {{ partition.partition[0].key }}
          </FormItem>
          <FormItem :label="$t('fen-qu-fang-fa')">
            {{ partition.partition[0].func }}
          </FormItem>
          <FormItem :label="$t('wen-jian-ge-shi')">
            {{ partition.fileFormat }}
          </FormItem>
          <FormItem :label="$t('ya-suo-fang-shi')">
            {{ partition.compressType }}
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Modal footer-hide v-model="showWhereCondition" draggable :title="$t('where-tiao-jian')">
      <div>
        <div style="word-break: break-all">{{ currentWhereCondition }}</div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancelDelete">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelDelete">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditJobDesc"
      :title="$t('xiu-gai-ren-wu-miao-shu')"
      footer-hide
      :closable="false"
    >
      <div>
        <Form label-position="right" :label-width="100">
          <FormItem :label="$t('shi-li-id-1')">
            {{ selectedJob.dataJobName }}
          </FormItem>
          <FormItem :label="$t('miao-shu')">
            <Input type="textarea" v-model="selectedJob.dataJobDesc"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEdit">{{$t('que-ren')}}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditJob"
      :title="$t('xiu-gai-ding-yue')"
      footer-hide
      width="1200px"
      :closable="false"
    >
      <div>
        <EditJob :updateSourceSchema="updateSourceSchema" :handleCancelEdit="handleCancelEdit"
                 :jobData="jobData" :showEditJob="showEditJob" :queryJobSchema="queryJobSchema"
                 :sourceSchema="sourceSchema" :mappingDef="mappingDef"
                 :mqSchemaTemplate="mqSchemaTemplate" :targetSchema="targetSchema"></EditJob>
      </div>
    </Modal>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '@views/util';
import EditJob from '../../createJob/EditJob';
import ConfigMapping from '../ConfigMapping';
import DataJobDetailMixin from '../../../mixin/dataJobDetailMixin';

export default {
  mixins: [DataJobDetailMixin],
  components: {
    EditJob,
    ConfigMapping
  },
  props: {
    showMapping: Boolean,
    jobData: Object,
    getAddedData: Function,
    selectedIndex: Number,
    mappingDef: Array,
    sourceSchema: Array,
    getMappingCount: Function,
    showTableMapping: Object,
    sourceTable: Object,
    showResetPosition: Boolean,
    binlogTime: Date,
    handleResetPosition: Function,
    handleChangeBinlogCancel: Function,
    showPartition: Boolean,
    partition: Object,
    showWhereCondition: Boolean,
    showEditJobDesc: Boolean,
    selectedJob: Object,
    handleConfirmEdit: Function,
    showConfirm: Boolean,
    handleCancelDelete: Function,
    showLog: Boolean,
    handleSelectLog: Function,
    logLoading: Boolean,
    logPreLoading: Boolean,
    logNextLoading: Boolean,
    handleShowLog: Function,
    logData: Array,
    showEditJob: Boolean,
    handleCancelEdit: Function,
    showClearPosition: Boolean,
    currentWhereCondition: String,
    taskPosition: Object,
    handleChangeTable: Function,
    updatePositionInfo: Function,
    selectedLog: Object,
    handlePageChange: Function,
    updateLogFileName: Function,
    updateSourceSchema: Function,
    mqSchema: Object,
    targetSchema: Array,
    handleShowWhereCondition: Function,
    positionLoading: Boolean,
    mqSchemaTemplate: String,
    queryJobSchema: Object,
    posTypeList: Array,
    trueColumnData: Object
  },
  data() {
    return {
      fecha,
      MappingUtil,
      startTimeOptions: {
        disabledDate: (date) => date && date.valueOf() > new Date(this.taskPosition.positionTimestamp)
          || date.valueOf() < new Date(new Date(this.taskPosition.positionTimestamp).getTime() - 7 * 24 * 3600 * 1000)
      },
      verifyCode: '',
      logFileName: '',
      theBinlogTime: '',
      posType: '',
      gtid: '',
      theLogFile: '',
      logFilePosition: '',
      lsn: '',
      scn: ''
    };
  },
  computed: {},
  methods: {
    handleChangePositionType() {
      if (this.posType.includes('TIMESTAMP_POS')) {
        this.updatePositionInfo(this.theBinlogTime, this.posType);
      }
    }
  },
  watch: {
    taskPosition(val) {
      this.theBinlogTime = new Date(val.positionTimestamp);
    },
    posTypeList(val) {
      if (val && val.length) {
        this.posType = val[0];
      }
    }
  }
};
</script>
