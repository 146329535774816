<template>
  <div class="task-list" v-if="showPage">
    <div v-if="!showGroup">
      <PageHeader :instanceList="instanceList" :hasInterval="hasInterval"
                  :handleInterval="handleInterval"
                  :loading="refreshLoading" :updateBatchInTable="updateBatchInTable"
                  :batchEditInTable="batchEdit"
                  :handleChangeMode="handleChangeMode" :handleSearch="handleJobList" :mode="mode"
                  :handleClassify="handleClassify"></PageHeader>
      <div :class="`job-info-container ${batchEdit?'margin-more':''}`"
           v-if="jobData.length>0&&mode==='card'&&!classifyType">
        <Row :gutter="10">
          <Col :span="getFitSpan" v-for="row of jobData" :key="row.id">
            <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData"
                         :handleShowGroup="handleShowGroup"
                         :getProgressStatus="getProgressStatus"
                         :handleConfirmEdit="handleConfirmEdit" :handleReplay="handleReplay"
                         :handleDetachIncreTask="handleDetachIncreTask"
                         :handleAttachIncreTask="handleAttachIncreTask"
                         :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                         :handleStopSchedule="handleStopSchedule"
                         :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                         :handleDelete="handleDelete"
                         :handleRestart="handleRestart" :handleStartFsm="handleStartFsm"
                         :handleStop="handleStop" :dataJobInfo="row"
                         v-if="row.isParent" :handleBatchEdit="handleBatchEdit"></JobInfoCard>
          </Col>
        </Row>
      </div>
      <div class="task-list-none" v-if="jobData.length===0&&mode==='card'&&show&&!classifyType">
        <NoDataJob :handleGoWorker="handleGoWorker" :handleGoDatasource="handleGoDatasource"
                   :handleGoDataJob="handleGoDataJob"></NoDataJob>
      </div>
      <ClassifyDashboard :handleShowHistory="handleShowHistory" v-if="classifyType"
                         :loadingData="loadingData" :classifyData="classifyData"
                         :classifyType="classifyType" :handleShowGroup="handleShowGroup"
                         :getProgressStatus="getProgressStatus"
                         :handleDetachIncreTask="handleDetachIncreTask"
                         :handleAttachIncreTask="handleAttachIncreTask"
                         :handleConfirmEdit="handleConfirmEdit"
                         :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                         :handleReplay="handleReplay"
                         :handleStopSchedule="handleStopSchedule"
                         :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
                         :handleDelete="handleDelete" :handleRestart="handleRestart"
                         :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                         :handleBatchEdit="handleBatchEdit"></ClassifyDashboard>
      <JobList :updateAllBatchList="updateAllBatchList" :handleCancelAllBatch="handleCancelAllBatch"
               :handleSelectBatch="handleSelectBatch" :handleCancelBatch="handleCancelBatch"
               :handleShowHistory="handleShowHistory" :handleTableSort="handleTableSort"
               :handleReplay="handleReplay"
               :getFilters="getFilters" :batchEditInTable="batchEdit"
               :getProgressStatus="getProgressStatus"
               :handleConfirmEdit="handleConfirmEdit" :showData="jobData" :getJobList="getJobList"
               :handleDetachIncreTask="handleDetachIncreTask"
               :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
               :handleStopSchedule="handleStopSchedule"
               :handleAttachIncreTask="handleAttachIncreTask"
               :handleResumSchedule="handleResumSchedule" :handleStart="handleStart"
               :handleDelete="handleDelete"
               :handleRestart="handleRestart" :handleStartFsm="handleStartFsm"
               :handleStop="handleStop" v-if="mode==='table'&&!classifyType"></JobList>
    </div>
    <PageFooter v-if="jobData.length>0" class="data-job-footer" :batchEditBtns="batchEditBtns"
                :handleBatchEdit="handleBatchEditConfirm"
                :total="total" :handlePageChange="handlePageChange" :batchEdit="batchEdit"
                :handlePageSizeChange="handlePageSizeChange"
                :size="size"></PageFooter>
    <DataJobListModal :getJobList="getJobList" :showConfirm="showConfirm"
                      :showScheduleHistory="showScheduleHistory"
                      :showBatchConfirm="showBatchConfirm"
                      :scheduleHistoryColumn="scheduleHistoryColumn"
                      :handleCloseHistory="handleCloseHistory"
                      :scheduleHistoryData="scheduleHistoryData" :batchEditList="batchEditList"
                      :handleCancelDelete="handleCancelDelete"
                      :selectedJobRow="selectedJobRow" :batchType="batchType"
                      :checkAllGroup="checkAllGroup"
                      :handleBeginBatchEdit="handleBeginBatchEdit"
                      :jobId="jobId" type="jobList"></DataJobListModal>
  </div>
</template>
<script>
import {
  // getJobList
  getJobListByPage
} from '@services/api/job';
import { listbycondition } from '@services/api/datasource';
import _ from 'lodash';
import { listUserLicense } from '@services/api/userLicense';
import { getUserQuota } from '@services/api/resourceQuota';
import PageHeader from '@components/function/dataJob/dataJobList/PageHeader';
import PageFooter from '@components/function/dataJob/dataJobList/PageFooter';
import JobInfoCard from '@components/function/dataJob/dataJobList/JobInfoCard';
import JobList from '@components/function/dataJob/dataJobList/JobList';
import ClassifyDashboard from '@components/function/dataJob/dataJobList/ClassifyDashboard';
import NoDataJob from '@components/function/dataJob/dataJobList/NoDataJob';
import DataJobListModal from '@components/function/dataJob/dataJobList/modal/DataJobListModal';
import MyMixin from '@components/function/mixin/dataJobListMixin';
import DataJobDetaibMix from '@components/function/mixin/dataJobDetailMixin';
import Mapping from '../util';
import store from '../../store/index';

const TABLE_SORT_MAP = {
  dataTasks: 'PROCESS',
  gmtCreated: 'GMT_CREATE'
};

export default {
  mixins: [MyMixin, DataJobDetaibMix],
  components: {
    PageHeader,
    PageFooter,
    JobInfoCard,
    JobList,
    ClassifyDashboard,
    NoDataJob,
    DataJobListModal
  },
  data() {
    return {
      classifyData: {},
      classifyType: '',
      showGroup: false,
      groupData: '',
      show: false,
      batchType: '',
      showBatchConfirm: false,
      batchEditInTable: false,
      batchEdit: false,
      batchEditList: [],
      batchEditBtns: [],
      checkAllGroup: [],
      mode: 'table',
      store,
      searchType: 'dataJobDesc',
      instanceList: [],
      startInterval: '',
      restartInterval: '',
      stopInterval: '',
      showPage: true,
      dataJobDesc: '',
      Mapping,
      sortDelay: 'normal',
      sortTime: 'normal',
      statusTime: 10000,
      scheduleHistoryColumn: [],
      scheduleHistoryData: [],
      filterValue: '',
      hasInterval: false,
      myInterval: '',
      refreshLoading: false,
      showData: [],
      pagingData: [],
      page: 1,
      size: 10,
      total: 0,
      showDataJobListInterval: {},
      searchData: {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0
      },
      showAllSearch: false,
      jobData: []
    };
  },
  // created() {
  //   const theMode = localStorage.getItem('cc-data-job-mode');
  //
  //   if (theMode) {
  //     this.mode = theMode;
  //   }
  // },
  mounted() {
    this.getDataSourceList();
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
    clearInterval(this.startInterval);
    clearInterval(this.restartInterval);
    clearInterval(this.stopInterval);
  },
  computed: {},
  methods: {
    handleChangeMode(mode) {
      this.mode = mode;
      this.classifyType = '';
      this.batchEditList = [];
      this.batchEditInTable = false;
      this.batchEdit = false;
      this.batchEditBtns = [];
      this.getFilters('');
      localStorage.setItem('cc-data-job-mode', this.mode);
    },
    getDataSourceList() {
      listbycondition({})
        .then((res) => {
          if (res.data.code === '1') {
            this.instanceList = res.data.data;
          }
        });
    },
    handleRefresh(data) {
      this.getJobList(data);
    },
    getJobList(data) {
      if (data) {
        this.searchData = { ...this.searchData, ...data };
      }
      this.refreshLoading = true;
      const searchData = _.cloneDeep(this.searchData);
      if (!searchData.orderType) {
        searchData.orderType = 'GMT_CREATE';
      }
      searchData.pageSize = this.size;
      searchData.pageNum = this.page;

      if (searchData.dataJobType === 'all') {
        searchData.dataJobType = '';
      }
      this.loading = true;
      getJobListByPage(searchData)
        .then((res) => {
          if (res.data.code === '1') {
            this.batchEditBtns = [];
            this.jobData = res.data.data.jobVos;
            this.total = res.data.data.allJobCounts;
            const errorList = [];
            const errorCount = [];

            this.jobData.forEach((item, index) => {
              try {
                if (item.dataTaskState !== 'COMPLETE') {
                  item.dataTasks.map((task) => {
                    if (task.dataTaskType === item.dataTaskState) {
                      item.currentStatus = task.dataTaskStatus;
                    } else if (task.dataTaskType === 'INCREMENT' && item.dataTaskState === 'CATCH_UP') {
                      if (task.dataTaskStatus === 'RUNNING') {
                        if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                          task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                        } else {
                          task.trueDelay = task.dataDelayMs;
                        }
                        item.trueDelay = task.trueDelay;
                      }
                      item.currentStatus = task.dataTaskStatus;
                    } else if (task.dataTaskType === 'INCREMENT' && item.dataTaskState === 'INCRE') {
                      if (task.dataTaskStatus === 'RUNNING') {
                        if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                          task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                        } else {
                          task.trueDelay = task.dataDelayMs;
                        }
                        item.trueDelay = task.trueDelay;
                      }
                      item.currentStatus = task.dataTaskStatus;
                    }
                    return null;
                  });
                }
                const type = Mapping.taskSateToTaskType[item.dataTaskState];

                if (this.getDataTask(item.dataTasks)[type] && this.getDataTask(item.dataTasks)[type].dataTaskStatus !== 'STOP'
                  && this.getDataTask(item.dataTasks)[type].healthLevel !== 'Health') {
                  item.trueSatus = 'error';
                  errorList.push(item);
                  errorCount.push(index);
                }
                item._showChildren = true;
                if (item.childJobs) {
                  item.childJobs.forEach((job) => {
                    job.isChild = true;
                    job.parentDataJobId = item.dataJobId;
                    if (job.dataTaskState !== 'COMPLETE') {
                      job.dataTasks.map((childTask) => {
                        if (childTask.dataTaskType === job.dataTaskState) {
                          job.currentStatus = childTask.dataTaskStatus;
                        } else if (childTask.dataTaskType === 'INCREMENT' && job.dataTaskState === 'CATCH_UP') {
                          if (childTask.dataTaskStatus === 'RUNNING') {
                            if (JSON.parse(childTask.taskPosition).delayMillis >= 60000) {
                              childTask.trueDelay = JSON.parse(childTask.taskPosition).delayMillis;
                            } else {
                              childTask.trueDelay = childTask.dataDelayMs;
                            }
                            job.trueDelay = childTask.trueDelay;
                          }
                          job.currentStatus = childTask.dataTaskStatus;
                        } else if (childTask.dataTaskType === 'INCREMENT' && job.dataTaskState === 'INCRE') {
                          if (childTask.dataTaskStatus === 'RUNNING') {
                            if (JSON.parse(childTask.taskPosition).delayMillis >= 60000) {
                              childTask.trueDelay = JSON.parse(childTask.taskPosition).delayMillis;
                            } else {
                              childTask.trueDelay = childTask.dataDelayMs;
                            }
                            job.trueDelay = childTask.trueDelay;
                          }
                          job.currentStatus = childTask.dataTaskStatus;
                        }
                        return null;
                      });
                    }
                  });
                }
                // 判断是否是子任务
                item.children = _.cloneDeep(item.childJobs);
                item.isParent = true;
                return null;
              } catch (e) {
                console.log('e', e);
              }
            });
            this.showPage = true;
          }
          this.refreshLoading = false;
          this.show = true;
          this.loading = false;
        })
        .catch(() => {
          this.refreshLoading = false;
          this.loading = false;
        });
    },
    handleJobList(data) {
      this.getJobList(data);
    },
    getCurrentState(row) {
      return Mapping.taskState[row.dataTaskState];
    },
    handlePageChange(page) {
      this.page = page;
      this.getJobList();
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.getJobList();
    },
    handleEditJobDesc(row) {
      this.dataJobDesc = row.dataJobDesc;
    },
    getCheckExpression(expression) {
      const weekMap = {
        1: this.$t('ri'),
        2: this.$t('yi'),
        3: this.$t('er'),
        4: this.$t('san'),
        5: this.$t('si'),
        6: this.$t('wu'),
        7: this.$t('liu')
      };
      const expressionArr = expression.split(' ');

      if (expressionArr[5] === '?') {
        if (expressionArr[2] === '*') {
          // return `每小时 ${expressionArr[1] < 10 ? '0' + expressionArr[1] : expressionArr[1]} 分执行一次`;
          return this.$t('mei-xiao-shi-zhi-hang-yi-ci');
        }
        const dayStr = expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1];
        return this.$t('mei-tian-expressionarr2daystr-zhi-hang-yi-ci', [expressionArr[2], dayStr]);
      }
      const weekStr = expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1];
      return this.$t('mei-zhou-weekmapexpressionarr5-expressionarr2weekstr-zhi-hang-yi-ci', [weekMap[expressionArr[5]], expressionArr[2], weekStr]);
    },
    handleInterval(data) {
      const that = this;

      if (!data) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.getJobList();
        }, 20000);
      }
    },
    handleCancelEdit(row) {
      row.showEditDesc = false;
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0
      };
    },
    getFilters(value) {
      this.filterValue = value;
    },
    handleTableSort(data) {
      this.searchData.orderType = TABLE_SORT_MAP[data.key];
      this.searchData.orderAsc = (data.order === 'asc');
      this.page = 1;
      let userId = '';
      let sort = {};

      if (localStorage.getItem('userInfo')) {
        userId = JSON.parse(localStorage.getItem('userInfo')).id;
      }

      if (data.key === 'gmtCreated') {
        this.sortTime = data.order;
        if (localStorage.getItem(`job_list_sort_${userId}`)) {
          sort = JSON.parse(localStorage.getItem(`job_list_sort_${userId}`));
        }

        sort.sortTime = data.order;
        localStorage.setItem(`job_list_sort_${userId}`, JSON.stringify(sort));
        this.getJobList();
      } else if (data.key === 'dataTasks') {
        this.sortDelay = data.order;
        if (localStorage.getItem(`job_list_sort_${userId}`)) {
          sort = JSON.parse(localStorage.getItem(`job_list_sort_${userId}`));
        }

        sort.sortDelay = data.order;
        localStorage.setItem(`job_list_sort_${userId}`, JSON.stringify(sort));
        this.getJobList();
      }
    },
    handleClassify(classifyType) {
      this.classifyType = classifyType;
      if (classifyType === 'status') {
        const normalList = [];
        const abnormalList = [];
        const stopList = [];
        const
          finishList = [];

        this.jobData.forEach((item) => {
          const type = Mapping.taskSateToTaskType[item.dataTaskState];

          if (type) {
            if (!this.getDataTask(item.dataTasks)[type]) {
              normalList.push(item);
            } else if (this.getDataTask(item.dataTasks)[type].dataTaskStatus === 'STOP') {
              stopList.push(item);
            } else if (this.getDataTask(item.dataTasks)[type].healthLevel === 'Health') {
              normalList.push(item);
            } else {
              abnormalList.push(item);
            }
          } else if (item.dataTaskState === 'COMPLETE') {
            finishList.push(item);
          } else {
            normalList.push(item);
          }
          return null;
        });
        this.classifyData.normalList = normalList;
        this.classifyData.abnormalList = abnormalList;
        this.classifyData.stopList = stopList;
        this.classifyData.finishList = finishList;
      } else if (classifyType === 'state') {
        const buildStruct = [];
        const fullList = [];
        const incrementList = [];
        const checkList = [];
        const
          finishList = [];

        this.jobData.map((item) => {
          if (item.dataTaskState === 'BUILD_STRUCT') {
            buildStruct.push(item);
          } else if (item.dataTaskState === 'FULL') {
            fullList.push(item);
          } else if (item.dataTaskState === 'INCRE' || item.dataTaskState === 'CATCH_UP') {
            incrementList.push(item);
          } else if (item.dataTaskState === 'CHECK') {
            checkList.push(item);
          } else if (item.dataTaskState === 'COMPLETE') {
            finishList.push(item);
          }
          return null;
        });
        this.classifyData.buildStruct = buildStruct;
        this.classifyData.fullList = fullList;
        this.classifyData.incrementList = incrementList;
        this.classifyData.checkList = checkList;
        this.classifyData.finishList = finishList;
      } else if (classifyType === 'type') {
        const migrationList = [];
        const syncList = [];
        const structList = [];
        const
          checkList = [];

        this.jobData.map((item) => {
          if (item.dataJobType === 'MIGRATION') {
            migrationList.push(item);
          } else if (item.dataJobType === 'SYNC') {
            syncList.push(item);
          } else if (item.dataJobType === 'CHECK') {
            checkList.push(item);
          } else if (item.dataJobType === 'STRUCT_MIGRATION') {
            structList.push(item);
          }
          return null;
        });
        this.classifyData.migrationList = migrationList;
        this.classifyData.syncList = syncList;
        this.classifyData.structList = structList;
        this.classifyData.checkList = checkList;
      }
    },
    handleGoWorker() {
      getUserQuota()
        .then((res) => {
          if (res.data.code === '1') {
            res.data.data.map((item) => {
              if (item.quotaType === 'WORKER_COUNT') {
                if (item.used >= item.quota) {
                  this.$Modal.warning({
                    title: this.$t('e-du-bu-zu'),
                    content: this.$t('nin-dang-qian-de-ji-qiedu-bu-zu-qing-quahrefsystemusercenter-ge-ren-zhong-xinashen-qingedu')
                  });
                } else {
                  this.$router.push({ path: '/system/resource' });
                }
              }
              return null;
            });
          }
        });
    },
    handleGoDatasource() {
      getUserQuota()
        .then((res) => {
          if (res.data.code === '1') {
            res.data.data.map((item) => {
              if (item.quotaType === 'DATA_SOURCE_COUNT') {
                if (item.used >= item.quota) {
                  this.$Modal.warning({
                    title: this.$t('e-du-bu-zu'),
                    content: this.$t('nin-dang-qian-de-shu-ju-yuanedu-bu-zu-qing-quahrefsystemusercenter-ge-ren-zhong-xinashen-qingedu')
                  });
                } else {
                  this.$router.push({ path: '/data/dataSource' });
                }
              }
              return null;
            });
          }
        });
    },
    handleGoDataJob() {
      getUserQuota()
        .then((res) => {
          if (res.data.code === '1') {
            res.data.data.map((item) => {
              if (item.quotaType === 'DATA_JOB_COUNT') {
                if (item.used >= item.quota) {
                  this.$Modal.warning({
                    title: this.$t('e-du-bu-zu'),
                    content: this.$t('nin-dang-qian-de-ren-wu-shuedu-bu-zu-qing-quahrefsystemusercenter-ge-ren-zhong-xinashen-qingedu')
                  });
                } else {
                  listUserLicense()
                    .then((res1) => {
                      if (res1.data.code === '1') {
                        let hasResource = false;

                        res1.data.data.map((item1) => {
                          if (item1.amount > 0 || item1.amount === -10000) {
                            hasResource = true;
                          }
                          return null;
                        });
                        if (hasResource) {
                          this.$router.push({ path: '/data/job/create/process' });
                        } else {
                          this.$Modal.warning({
                            title: this.$t('zi-yuan-bu-zu'),
                            content: this.$t('nin-dang-qian-de-zi-yuan-bu-zu-qing-quahrefsystemusercenter-ge-ren-zhong-xinachong-zhi-hou-zai-chuang-jian-ren-wu')
                          });
                        }
                      }
                    });
                }
              }
              return null;
            });
          }
        });
    },
    handleUserCenter() {
      this.$router.push({ path: '/system/userCenter' });
    },
    handleCloseHistory() {
      this.showScheduleHistory = false;
    }
  }
};
</script>
<style lang="less">
.task-list-body {
  padding: 24px;
  background: #ffffff;
  margin-top: 16px;

  .demo-table-info-row {
    .ivu-table-cell-slot {
      display: inline-block;
      vertical-align: middle;
    }

    .ivu-table-cell-tree {
      display: none;
    }

    .ivu-table-cell-tree-level {
      display: none;
    }
  }

  .ivu-progress-inner-text {
    color: #17233d;
  }
}

.job-edit-desc-a {
  opacity: 0;
}

.ivu-table-row :hover .job-edit-desc-a {
  opacity: 1;
}

.task-step-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.job-info-container {
  margin-top: 16px;
  margin-bottom: 56px;
}

.margin-more {
  margin-bottom: 116px;
}

.data-job-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
</style>
