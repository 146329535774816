<template>
  <div style="padding-bottom: 74px">
    <Breadcrumb style="margin-bottom: 14px">
      <BreadcrumbItem>{{ $t('yuan-shu-ju-jian-suo') }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="page-header-container">
      <Form label-position="left" inline>
        <FormItem>
          <Select v-model="searchInfo.schemaQueryType" style="width: 220px" @on-change="handleChangeQueryType">
            <Option v-for="type of queryTypes" :value="type.schemaQueryType" :key="type.schemaQueryType">{{type.i18nName}}</Option>
          </Select>
        </FormItem>
        <FormItem v-if="searchInfo.schemaQueryType === 'SCHEMA_QUERY_TARGET_FROM_SOURCE'">
          <Input v-model="searchInfo.transferObjName" :placeholder="$t('qing-shu-ru-yuan-biao-ming-cheng')" style="width: 280px;" />
        </FormItem>
        <FormItem>
          <Select v-model="searchInfo.filterType" style="width: 220px" @on-change="handleChangeFilterType">
            <Option v-for="type of filterTypes" :value="type.resourceFilterType" :key="type.resourceFilterType">{{type.i18nName}}</Option>
          </Select>
        </FormItem>
        <FormItem v-if="searchInfo.filterType==='RESOURCE_FILTER_TYPE_DATE_JOB'">
          <Select filterable style="width: 250px" v-model="searchInfo.dataJobId">
            <Option v-for="(dataJob) in dataJobList"
                    :value="dataJob.dataJobId"
                    :key="dataJob.dataJobId"
                    :label="dataJob.dataJobDesc?`${dataJob.dataJobName} (${dataJob.dataJobDesc})`:`${dataJob.dataJobName}`">
              <p>{{ dataJob.dataJobName }}</p>
              <p style="margin-top:5px;color:#ccc">{{ dataJob.dataJobDesc?dataJob.dataJobDesc:$t('zan-wu-miao-shu') }}</p>
            </Option>
          </Select>
        </FormItem>
        <FormItem v-if="searchInfo.filterType==='RESOURCE_FILTER_TYPE_SRC_DS'">
          <Select filterable style="width: 250px" v-model="searchInfo.srcDsId">
            <Option v-for="(instance) in instanceList"
                    :value="instance.id"
                    :key="instance.instanceId"
                    :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
              <p>{{ instance.instanceId }}</p>
              <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
            </Option>
          </Select>
        </FormItem>
        <FormItem v-if="searchInfo.filterType==='RESOURCE_FILTER_TYPE_DST_DS'">
          <Select filterable style="width: 250px" v-model="searchInfo.dstDsId">
            <Option v-for="(instance) in instanceList"
                    :value="instance.id"
                    :key="instance.instanceId"
                    :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
              <p>{{ instance.instanceId }}</p>
              <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
            </Option>
          </Select>
        </FormItem>
        <FormItem>
          <pd-button type="primary" @click="handleSearch" :loading="loading">{{ $t('cha-xun') }}</pd-button>
        </FormItem>
      </Form>
    </div>
    <Table style="margin-top: 20px" size="small" :columns="metaColumns" :data="metaData" border>
      <template slot="dataJob" slot-scope="{row}">
        <a @click="handleGoDataJob(row.dataJobId)">{{row.dataJobName}}/{{row.dataJobDesc}}</a>
      </template>
<!--      <template slot="srcTransferObjName" slot-scope="{row}">-->
<!--        <div>{{row.srcDb?`${row.srcDb}/`:''}}{{row.srcSchema?`${row.srcSchema}/`:''}}{{row.srcTransferObjName}}</div>-->
<!--      </template>-->
      <template slot="dstTransferObjName" slot-scope="{row}">
        <div>{{row.dstDb?`${row.dstDb}/`:''}}{{row.dstSchema?`${row.dstSchema}/`:''}}{{row.dstTransferObjName}}</div>
      </template>
      <template slot="specificKeys" slot-scope="{row}">
        <Tooltip :title="$t('mu-biao-zhu-jian')" placement="top-start" transfer>
          <p class="schema-setting-info">{{row.specifiedPks?JSON.parse(row.specifiedPks).join(','):''}}</p>
          <div slot="content">
            <p>{{row.specifiedPks?JSON.parse(row.specifiedPks).join(','):''}}</p>
          </div>
        </Tooltip>
      </template>
      <template slot="filter" slot-scope="{row}">
        <Tooltip :title="$t('guo-lv-tiao-jian')" placement="top-start" transfer>
          <p class="schema-setting-info">{{row.filterExpr}}</p>
          <div slot="content">
            <p>{{row.filterExpr}}</p>
          </div>
        </Tooltip>
      </template>
      <template slot="config" slot-scope="{row}">
        <a @click="handleShowConfig(row)">{{ $t('cha-kan') }}</a>
      </template>
    </Table>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <pd-button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px" @click="handlePre"><Icon type="ios-arrow-back" style="font-size: 16px"/>{{ $t('shang-yi-ye') }}</pd-button>
        <span style="margin: 0 10px">{{ $t('di-page-ye', [page]) }}</span>
        <pd-button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px" @click="handleNext">
          <Icon type="ios-arrow-forward" style="font-size: 16px"/>{{ $t('xia-yi-ye') }}</pd-button>
      </div>
    </div>
    <Modal
      v-model="showConfig"
      :title="$t('cha-kan-selectedrowdatajobname-de-te-shu-pei-zhi', [selectedRow.dataJobName])"
      width="1200px"
      style="max-height: 450px"
    >
      <div style="max-height: 500px;overflow: auto">
        <Table style="margin-top: 20px" size="small" :columns="configColumns" :data="selectedRow.configData" border></Table>
      </div>
    </Modal>
  </div>
</template>
<script>
import { schemaQueryTypes, schemaFilterTypes } from '@services/api/constant';
import { querySchema } from '@services/api/schema';
import { listbycondition } from '@services/api/datasource';
import { listSimpleForSearch } from '@services/api/job';

export default {
  name: 'MetaCenter',
  beforeRouteEnter(to, from, next) {
    if (from.name === 'task/id') {
      next((vm) => vm.queryOldData());
    } else {
      next();
    }
  },
  mounted() {
    this.schemaQueryTypes();
    this.schemaFilterTypes();
    this.getDataSourceList();
    this.listSimpleForSearch();
  },
  data() {
    return {
      showConfig: false,
      showParams: false,
      searchInfo: {
        schemaQueryType: '',
        transferObjName: '',
        specialType: '',
        pageSize: 10,
        startId: 0,
        filterType: 'RESOURCE_FILTER_TYPE_NONE',
        dataJobId: '',
        srcDsId: '',
        dstDsId: ''
      },
      loading: false,
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      noMoreData: false,
      queryTypes: [],
      filterTypes: [],
      instanceList: [],
      dataJobList: [],
      selectedRow: {},
      metaColumns: [
        {
          title: this.$t('yuan-biao-ming-cheng'),
          key: 'srcFullTransferObjName',
          minWidth: 240
        },
        {
          title: this.$t('mu-biao-biao-ming-cheng'),
          key: 'dstFullTransferObjName',
          minWidth: 240
        },
        {
          title: this.$t('suo-shu-ren-wu'),
          key: 'dataJob',
          slot: 'dataJob',
          width: 280
        },
        {
          title: this.$t('yuan-shu-ju-yuan-lei-xing'),
          key: 'srcDsType',
          width: 140
        },
        {
          title: this.$t('mu-biao-shu-ju-yuan-lei-xing'),
          key: 'dstDsType',
          width: 140
        }
      ],
      metaData: [],
      configColumns: [
        {
          title: this.$t('pei-zhi-ming-cheng'),
          key: 'configName'
        },
        {
          title: this.$t('pei-zhi-zhi'),
          key: 'configValue'
        }
      ]
    };
  },
  methods: {
    queryOldData() {
      const params = this.$store.state.metaCenterSearchParams;
      if (params) {
        this.searchInfo = params.searchInfo;
        this.firstId = params.firstId;
        this.lastId = params.lastId;
        this.prevFirst = params.prevFirst;
        this.page = params.page;
        this.noMoreData = params.noMoreData;
        this.querySchema();
      }
    },
    getDataSourceList() {
      listbycondition({})
        .then((res) => {
          if (res.data.code === '1') {
            this.instanceList = res.data.data;
          }
        });
    },
    handleShowConfig(row) {
      this.selectedRow = row;
      this.selectedRow.configData = [];
      if (row.specifiedPks) {
        this.selectedRow.configData.push({
          configName: this.$t('she-zhi-mu-biao-zhu-jian'),
          configValue: row.specifiedPks
        });
      }
      if (row.filterExpr) {
        this.selectedRow.configData.push({
          configName: this.$t('she-zhi-shu-ju-guo-lv-tiao-jian'),
          configValue: row.filterExpr
        });
      }
      this.showConfig = true;
    },
    handleShowParams(row) {
      this.selectedRow = row;
      this.showParams = true;
    },
    async listSimpleForSearch() {
      const res = await listSimpleForSearch();
      if (res.data.success) {
        this.dataJobList = res.data.data;
      }
    },
    async schemaQueryTypes() {
      const res = await schemaQueryTypes();
      if (res.data.success) {
        this.queryTypes = res.data.data;
        this.queryTypes.forEach((type => {
          if (type.defaultCheck) {
            this.searchInfo.schemaQueryType = type.schemaQueryType;
          }
        }));
      }
    },
    async schemaFilterTypes() {
      const res = await schemaFilterTypes();
      if (res.data.success) {
        this.filterTypes = res.data.data;
      }
    },
    handleSearch() {
      this.page = 1;
      this.firstId = 0;
      this.lastId = 0;
      this.prevFirst = [];
      this.searchInfo.startId = 0;
      this.metaData = [];
      this.querySchema();
    },
    async querySchema(type) {
      this.loading = true;
      if (this.searchInfo.schemaQueryType === 'SCHEMA_QUERY_TARGET_FROM_SOURCE') {
        this.metaColumns = [
          {
            title: this.$t('yuan-biao-ming-cheng'),
            key: 'srcFullTransferObjName',
            minWidth: 240
          },
          {
            title: this.$t('mu-biao-biao-ming-cheng'),
            key: 'dstFullTransferObjName',
            minWidth: 240
          },
          {
            title: this.$t('suo-shu-ren-wu'),
            key: 'dataJob',
            slot: 'dataJob',
            width: 280
          },
          {
            title: this.$t('yuan-shu-ju-yuan-lei-xing'),
            key: 'srcDsType',
            width: 140
          },
          {
            title: this.$t('mu-biao-shu-ju-yuan-lei-xing'),
            key: 'dstDsType',
            width: 140
          }
        ];
      } else if (this.searchInfo.schemaQueryType === 'SCHEMA_QUERY_FILTER_EXPR') {
        this.metaColumns = [
          {
            title: this.$t('yuan-biao-ming-cheng'),
            key: 'srcFullTransferObjName',
            minWidth: 240
          },
          {
            title: this.$t('mu-biao-biao-ming-cheng'),
            key: 'dstFullTransferObjName',
            minWidth: 240
          },
          {
            title: this.$t('suo-shu-ren-wu'),
            key: 'dataJob',
            slot: 'dataJob',
            width: 280
          },
          {
            title: this.$t('yuan-shu-ju-yuan-lei-xing'),
            key: 'srcDsType',
            width: 140
          },
          {
            title: this.$t('mu-biao-shu-ju-yuan-lei-xing'),
            key: 'dstDsType',
            width: 140
          },
          {
            title: this.$t('guo-lv-tiao-jian'),
            key: 'filter',
            slot: 'filter',
            minWidth: 240
          }
        ];
      } else if (this.searchInfo.schemaQueryType === 'SCHEMA_QUERY_SPECIFIED_KEYS') {
        this.metaColumns = [
          {
            title: this.$t('yuan-biao-ming-cheng'),
            key: 'srcFullTransferObjName',
            minWidth: 240
          },
          {
            title: this.$t('mu-biao-biao-ming-cheng'),
            key: 'dstFullTransferObjName',
            minWidth: 240
          },
          {
            title: this.$t('suo-shu-ren-wu'),
            key: 'dataJob',
            slot: 'dataJob',
            width: 280
          },
          {
            title: this.$t('yuan-shu-ju-yuan-lei-xing'),
            key: 'srcDsType',
            width: 140
          },
          {
            title: this.$t('mu-biao-shu-ju-yuan-lei-xing'),
            key: 'dstDsType',
            width: 140
          },
          {
            title: this.$t('mu-biao-zhu-jian'),
            key: 'specificKeys',
            slot: 'specificKeys',
            minWidth: 240
          }
        ];
      }
      const {
        searchInfo, firstId, lastId, prevFirst, page, noMoreData
      } = this;
      this.$store.commit('updateMetaCenterSearchParam', {
        searchInfo, firstId, lastId, prevFirst, page, noMoreData
      });
      const res = await querySchema({
        schemaQueryType: this.searchInfo.schemaQueryType,
        transferObjName: this.searchInfo.transferObjName,
        dataJobId: this.searchInfo.dataJobId,
        srcDsId: this.searchInfo.srcDsId,
        dstDsId: this.searchInfo.dstDsId,
        pageSize: this.searchInfo.pageSize,
        startId: this.searchInfo.startId
      });
      if (res.data.success) {
        this.metaData = res.data.data;
        if (type === 'next') {
          if (!this.prevFirst[this.page - 1]) {
            this.prevFirst.push(this.firstId);
          }
        }
        if (this.metaData.length > 0) {
          this.firstId = this.metaData[0].id;
          this.lastId = this.metaData[this.metaData.length - 1].id;
        }
        this.noMoreData = res.data.data.length < this.searchInfo.pageSize;
      }
      this.loading = false;
    },
    handleChangeQueryType() {
      this.searchInfo.transferObjName = '';
    },
    handleChangeFilterType() {
      this.searchInfo.srcDsId = '';
      this.searchInfo.dstDsId = '';
      this.searchInfo.dataJobId = '';
    },
    handleGoDataJob(jobId) {
      this.$router.push({
        path: `/data/job/${jobId}`
      });
    },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchInfo.startId = startId;
      this.querySchema('prev');
    },
    handleNext() {
      this.searchInfo.startId = this.lastId;
      this.querySchema('next');
      this.page++;
    }
  }
};
</script>
<style lang="less" scoped>
.schema-setting-info{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 260px;
}
</style>
