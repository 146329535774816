<template>
  <div>
    <!--                <svg class="icon svg-icon" aria-hidden="true">-->
    <!--                    <use xlink:href="#icon-zanwushujuimg"></use>-->
    <!--                </svg>-->
    <!--                <p>您还没有相关任务，<a @click="handleAddTask">创建任务</a></p>-->
    <div class="no-job-guide-container">
      <div class="no-job-guide">
        <img src="@asset/guide1.svg" alt="">
        <p class="no-job-guide-title">{{ $t('tian-jia-ji-qi') }}</p>
        <div style="margin: 10px 0 12px 0">{{ $t('tian-jia-nin-de-zhuan-shu-ji-qi-dao-cloudcanal-ji-qun-zhong') }}</div>
        <pd-button type="primary" ghost @click="handleGoWorker">{{ $t('tian-jia-ji-qi') }}</pd-button>
      </div>
      <div class="no-job-guide-arrow">
        <i class="iconfont iconjiantou2"></i>
      </div>
      <div class="no-job-guide">
        <img src="@asset/guide3.svg" :alt="$t('tian-jia-shu-ju-yuan')"/>
        <p class="no-job-guide-title">{{ $t('tian-jia-shu-ju-yuan') }}</p>
        <div style="margin: 10px 0 12px 0">{{ $t('tian-jia-xu-yao-jin-hang-shu-ju-tong-bu-qian-yi-de-shu-ju-yuan') }}</div>
        <pd-button type="primary" ghost @click="handleGoDatasource">{{ $t('tian-jia-shu-ju-yuan') }}</pd-button>
      </div>
      <div class="no-job-guide-arrow">
        <i class="iconfont iconjiantou2"></i>
      </div>
      <div class="no-job-guide">
        <img src="@asset/guide2.svg" :alt="$t('chuang-jian-ren-wu')">
        <p class="no-job-guide-title">{{ $t('chuang-jian-ren-wu') }}</p>
        <div style="margin: 10px 0 12px 0">{{ $t('shi-yong-nin-de-zhuan-shu-ji-qi-jin-hang-shu-ju-qian-yi-tong-bu') }}</div>
        <pd-button type="primary" ghost @click="handleGoDataJob">{{ $t('chuang-jian-ren-wu') }}</pd-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    handleGoWorker: Function,
    handleGoDatasource: Function,
    handleGoDataJob: Function
  }
};
</script>
