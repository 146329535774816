<template>
  <div class="user-center">
    <p class="user-center-title">{{ $t('zhang-hu-xin-xi') }}</p>
    <div style="position: absolute;right: 0;top: -6px">
      <pd-button :loading="loading" type="default" style="margin-right: 6px" @click="handleSearch">
        {{ $t('shua-xin') }}
      </pd-button>
    </div>
    <div>
      <Row :gutter="20">
        <Col :span="17">
          <div style="margin-top:16px;position: relative">
            <div class="user-account-title" style="margin-bottom: 15px" v-if="ifSass">{{ $t('wo-de-zi-yuan') }}
<!--              <a style="color: #333333;font-size: 14px;font-family: PingFangSC-Regular,serif;font-weight: 400;float: right;"-->
<!--                 @click="goExChangeHistory">兑换记录</a>-->
            </div>
            <Row :gutter="16" v-if="ifSass">
              <Col :span="6">
                <div class="resource-basic">
                  <div class="resource-content">
                    <span class="resource-logo" style="background-color: #FFEFD6"><i
                        class="iconfont iconxingzhuang"></i></span>
                    <div style="display: inline-block;vertical-align: middle">
                      <div class="resource-basic-title">{{ $t('zeng-liang-tong-bu-shi-chang') }}</div>
                      <div class="resource-basic-count">
                        {{ resourceData.incrementDuration &lt;= 0 ? resourceData.incrementDuration === -10000 ? $t('bu-xian') : resourceData.incrementDuration : formatHour(resourceData.incrementDuration) }}
                      </div>
                    </div>
                    <!--                                            <pd-button type="primary" ghost @click="handleGoLicenseSet(licenseUrl.INCREMENT_SYNC_DURATION)">增加资源</pd-button>-->
                  </div>
                </div>
              </Col>
              <Col :span="6">
                <div class="resource-basic">
                  <div class="resource-content">
                    <span class="resource-logo" style="background-color: #E0ECFF"><i
                        class="iconfont iconquanliangqianyisvg"></i></span>
                    <div style="display: inline-block;vertical-align: middle">
                      <div class="resource-basic-title">{{ $t('quan-liang-qian-yi-ci-shu') }}</div>
                      <div class="resource-basic-count">
                        {{ resourceData.fullTransferCount === -10000 ? $t('bu-xian') : resourceData.fullTransferCount + $t('ci') }}
                      </div>
                    </div>
                    <!--                                            <pd-button type="primary" ghost @click="handleGoLicenseSet(licenseUrl.FULL_TRANSFER_COUNT)">增加资源</pd-button>-->
                  </div>
                </div>
              </Col>
              <Col :span="6">
                <div class="resource-basic">
                  <div class="resource-content">
                    <span class="resource-logo" style="background-color: #E1F8DC"><i
                        class="iconfont iconqianyi"></i></span>
                    <div style="display: inline-block;vertical-align: middle">
                      <div class="resource-basic-title">{{ $t('jie-gou-qian-yi-ci-shu') }}</div>
                      <div class="resource-basic-count">
                        {{ resourceData.structTransferCount === -10000 ? $t('bu-xian') : resourceData.structTransferCount + $t('ci') }}
                      </div>
                    </div>
                    <!--                                            <pd-button type="primary" ghost @click="handleGoLicenseSet(licenseUrl.STRUCT_TRANSFER_COUNT)">增加资源</pd-button>-->
                  </div>
                </div>
              </Col>
              <Col :span="6">
                <div class="resource-basic">
                  <div class="resource-content">
                    <span class="resource-logo" style="background-color: #DFE0FF"><i
                        class="iconfont iconjiaoyan"></i></span>
                    <div style="display: inline-block;vertical-align: middle">
                      <div class="resource-basic-title">{{ $t('quan-liang-xiao-yan-ci-shu') }}</div>
                      <div class="resource-basic-count">
                        {{ resourceData.fullCheckCount === -10000 ? $t('bu-xian') : resourceData.fullCheckCount + $t('ci') }}
                      </div>
                    </div>
                    <!--                                            <pd-button type="primary" ghost @click="handleGoLicenseSet(licenseUrl.FULL_CHECK_COUNT)">增加资源</pd-button>-->
                  </div>
                </div>
              </Col>
            </Row>
            <div style="margin-top: 20px">
              <div class="user-account-title" style="margin-bottom: 8px">
                <div>{{ $t('yong-hu-she-zhi') }}</div>
                <Button type="primary" @click="handleSubmitUserConfig" size="small">{{ $t('bao-cun') }}</Button>
              </div>
              <user-config-params-edit ref="userConfigParamsEdit"/>
            </div>
            <div style="margin-top: 20px">
              <div class="user-account-title" style="margin-bottom: 8px">
                <div>{{ $t('guan-zhu-lie-biao') }}</div>
                <Button @click="handleRefreshFollowedList" size="small">{{ $t('shua-xin') }}</Button>
              </div>
              <followed-job-list ref="followedJobList"/>
            </div>
          </div>
        </Col>
        <Col :span="7">
          <div class="user-center-wrapper user-center-wrapper-sp" v-if="ifSass">
            <div style="margin-bottom: 8px;font-size: 16px;font-family: PingFangSC-Semibold,serif;font-weight: 500">{{ $t('xu-ke-zheng-dui-huan') }}
            </div>
            <Input v-model="applyCode"></Input>
            <div>
              <pd-button @click="handleApplyLicense" class="user-center-wrapper-sp-btn">{{ $t('dui-huan') }}</pd-button>
            </div>
          </div>
          <div class="user-center-wrapper" style="padding-top: 0">
            <p><span class="user-center-label">{{ $t('you-xiang') }}</span><span class="user-center-value">{{ userInfo.email }}</span>
              <pd-button size="small" @click="handleUpdateUserInfo('email')">{{ $t('geng-huan') }}</pd-button>
            </p>
            <p><span class="user-center-label">{{ $t('shou-ji-hao') }}</span><span class="user-center-value">{{ userInfo.phone }}</span>
              <pd-button size="small" @click="handleUpdateUserInfo('phone')">{{ $t('geng-huan') }}</pd-button>
            </p>
            <p><span class="user-center-label">{{ $t('deng-lu-mi-ma') }}</span><span class="user-center-value">*******</span>
              <pd-button size="small" @click="handleShowPassword">{{ $t('geng-huan') }}</pd-button>
            </p>
            <p><span class="user-center-label">{{ $t('gong-si-ming-cheng') }}</span><span>{{ userInfo.company }}</span></p>
            <p><span class="user-center-label">{{ $t('zhu-ce-shi-jian') }}</span><span>{{ getCreateTime }}</span></p>
            <p><span class="user-center-label">{{ $t('shang-ci-geng-xin') }}</span><span>{{ getUpdateTime }}</span></p>
            <p>
              <a style="font-size: 14px;margin-right: 20px;" @click="handleShowFetchAKSK">{{ $t('huo-qu-aksk') }}</a>
              <a style="font-size: 14px;margin-right: 20px;" @click="handleVerifyEmail">{{ $t('yan-zheng-you-xiang-fu-wu-qi') }}</a>
              <a style="font-size: 14px" @click="handleVerifyIm">{{ $t('yan-zheng-im-gao-jing') }}</a>
            </p>
          </div>
          <div class="user-center-wrapper" style="margin-top: 20px" v-if="ifSass">
            <Table stripe :columns="guotaColumn" :data="quotaList"></Table>
          </div>
        </Col>
      </Row>
    </div>
    <Modal
        v-model="showEditPassword"
        :title="$t('xiu-gai-mi-ma')"
        width="400px"
        footer-hide
    >
      <div>
        <Form label-position="right" :label-width="80" :rules="editPasswordRule">
          <FormItem :label="$t('xin-mi-ma')" props="password">
            <Poptip trigger="focus" placement="right-start">
              <Input style="width: 280px" type="password" v-model="password" password
                     :placeholder="$t('qing-shu-ru-mi-ma')" @on-change="handleCheckPassword"/>
              <div slot="content">
                <ul class="password-check">
                  <li>
                    <Icon :type="pwLength?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                    <span>{{ $t('chang-du-wei-832-ge-zi-fu') }}</span></li>
                  <li>
                    <Icon :type="pwContain?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                    <span>{{ $t('mi-ma-bu-neng-bao-han-shou-ji-hao') }}</span></li>
                  <li>
                    <Icon :type="pwFormat?'ios-checkmark-circle-outline':'ios-close-circle-outline'"/>
                    <span>{{ $t('bi-xu-bao-han-ying-wen-he-shu-zi') }}</span></li>
                </ul>
              </div>
            </Poptip>
          </FormItem>
          <FormItem :label="$t('que-ren-mi-ma')" props="passwordAgain">
            <Poptip trigger="focus" placement="right-start">
              <Input style="width: 280px" type="password" v-model="passwordAgain" password
                     :placeholder="$t('qing-zai-ci-shu-ru-mi-ma')" @on-change="handleCheckPasswordAgain"/>
              <div slot="content">
                <div v-if="!pwConfirm" style="color: #ed4014;font-size: 12px;line-height: 20px">
                  {{ $t('qing-zai-ci-shu-ru-xiang-tong-de-mi-ma') }}
                </div>
                <div v-if="pwConfirm" style="color: #19be6b;font-size: 12px;">
                  <Icon type="md-checkmark" style="margin-right: 5px"/>
                  {{ $t('qing-zai-ci-shu-ru-xiang-tong-de-mi-ma') }}
                </div>
              </div>
            </Poptip>
          </FormItem>
          <FormItem :label="$t('yan-zheng-ma')" props="verifyCode">
            <Input style="width: 140px" type="text" v-model="verifyCode"
                   :placeholder="$t('qing-shu-ru-duan-xin-yan-zheng-ma')"/>
            <pd-button style="margin-left: 10px" :disabled="!sendcodeDisabled" type="primary" ghost
                       @click="handleVerify">
              {{ sendcodeDisabled ? $t('huo-qu-duan-xin-yan-zheng-ma') : $t('zai-ci-huo-qu') + sendCodeAgainTime + "s)" }}
            </pd-button>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEdit">{{ $t('bao-cun') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <verify-code-modal
      :visible="showEditUserInfo"
      :title="$t('xiu-gai-updateuserinfotypephone-shou-ji-hao-you-xiang', [updateUserInfoType===`phone`?$t('shou-ji-hao'):$t('you-xiang')])"
      :verify-code-type="updateUserInfoType==='phone'?isVerifyPhone?'VERIFY_OLD_PHONE':'UPDATE_USER_PHONE':'UPDATE_USER_EMAIL'"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmUpdateUserInfo"
      :has-next-step="isVerifyPhone"
      :new-phone="newPhone"
      :phone-number="updateUserInfo.phone"
      ref="clear-position-modal"
      :width="580"
    >
      <div slot="content">
        <Form label-position="right" :label-width="60">
          <FormItem :label="$t('shou-ji-hao-0')" v-if="updateUserInfoType==='phone' && !isVerifyPhone">
            <Input v-model="updateUserInfo.phone"/>
          </FormItem>
          <FormItem :label="$t('updateuserinfotypephone-yuan-shou-ji-hao-shou-ji-hao-0', [updateUserInfoType===`phone`?$t('yuan-shou-ji-hao'):$t('shou-ji-hao-0')])" prop="phone" v-if="isVerifyPhone || updateUserInfoType!=='phone'">
            <Input v-model="userInfo.phone" disabled/>
          </FormItem>
          <FormItem :label="$t('you-xiang-0')" v-if="updateUserInfoType==='email'">
            <Input v-model="updateUserInfo.email"/>
          </FormItem>
        </Form>
      </div>
    </verify-code-modal>
    <verify-code-modal
      :visible="showFetchAKSK"
      :title="$t('huo-qu-aksk')"
      verify-code-type="FETCH_USER_AK_SK"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmFetchAKSK"
      ref="clear-position-modal"
      :width="580"
    >
      <div slot="content">
        <h3 style="margin-bottom: 20px">{{ $t('wei-bao-zheng-an-quan-xing-qing-shu-ru-yan-zheng-ma-lai-huo-qu-aksk') }}</h3>
      </div>
    </verify-code-modal>
    <Modal
      v-model="showAKSK"
      title="AK/SK"
      width="600px"
      footer-hide
    >
      <div>
        <h3 style="margin-bottom: 20px">{{ $t('wei-bao-zheng-nin-de-zhang-hao-an-quan-qing-wu-bi-bao-guan-hao-nin-de-aksk') }}</h3>
        <Form label-position="right" :label-width="80">
          <FormItem label="accessKey：">
            <p>{{akskInfo.accessKey}}</p>
          </FormItem>
          <FormItem label="secretKey：">
            <p>{{akskInfo.secretKey}}</p>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCopy(JSON.stringify(akskInfo))">{{ $t('fu-zhi') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { sendCodeInLoginState } from '@services/api/verify';
import {
  queryLoginUser,
  updatePassword,
  queryUserAkSk,
  getCurrUserConfigs, updateUserPhone, updateUserEmail, checkVerifyCode
} from '@services/api/user';
import { logout } from '@services/api/auth';
import {
  listAllConfigs,
  updateDingDingConfigs,
  verifyIm,
  verifyMail
} from '@services/api/systemConfig';
import fecha from 'fecha';
import { applyStsToken, invalidStsToken } from '@services/api/stToken';
import { listUserLicense, applyLicense } from '@services/api/userLicense';
import { getUserQuota } from '@services/api/resourceQuota';
import { defaultLicenseTypeUrl, defaultDisplayLicenseSet, getGlobalSystemConfig } from '@services/api/constant';
import { formatHour } from '@components/util';
import VerifyCodeModal from '@components/modal/VerifyCodeModal';
import store from '@store';
import UserConfigParamsEdit from '@views/system/UserConfigParamsEdit';
import FollowedJobList from '@views/system/FollowedJobList';
import Mapping from '../util';

export default {
  components: { FollowedJobList, UserConfigParamsEdit, VerifyCodeModal },
  data() {
    return {
      updateUserInfoType: '',
      ifSass: false,
      loading: false,
      editEmail: false,
      isVerifyPhone: false,
      newPhone: false,
      showEditUserInfo: false,
      showFetchAKSK: false,
      showAKSK: false,
      akskInfo: {},
      formatHour,
      updateUserInfo: {
        phone: '',
        email: ''
      },
      resourceData: {
        fullCheckCount: 0,
        fullTransferCount: 0,
        incrementDuration: 0,
        structTransferCount: 0
      },
      quotaData: {
        workerCount: 0,
        workerCountUsed: 0,
        dataJobCount: 0,
        dataSourceCount: 0,
        dataJobCountUsed: 0,
        dataSourceCountUsed: 0
      },
      applyCode: '',
      ifEdit: true,
      showTest: false,
      connection: false,
      showEditPassword: false,
      showSmtp: false,
      pwLength: false,
      pwContain: false,
      pwFormat: false,
      pwConfirm: false,
      verifyCode: '',
      password: '',
      passwordAgain: '',
      sendcodeDisabled: true,
      sendCodeAgainTime: 60,
      userInfo: '',
      systemForm: {
        EMAIL_HOST_KEY: '',
        EMAIL_PORT_KEY: '465',
        EMAIL_USERNAME_KEY: '',
        EMAIL_PASSWORD_KEY: '',
        DINGDING_URL_TOKEN_KEY: '',
        EMAIL_FROM_KEY: ''
      },
      alarmSetting: {},
      setList: [],
      licenseUrl: {},
      aliyunAk: '',
      aliyunSk: '',
      emailList: [],
      emailSuffix: ['qq.com', 'sina.com', '163.com', 'sohu.com', '126.com'],
      smtpList: {
        'qq.com': 'smtp.qq.com',
        'sina.com': 'smtp.sina.com.cn',
        '163.com': 'smtp.163.com',
        'sohu.com': 'smtp.sohu.com',
        '126.com': 'smtp.126.com'
      },
      smtpPort: {
        'qq.com': '465',
        'sina.com': '25',
        '163.com': '465',
        'sohu.com': '110',
        '126.com': '25'
      },
      configKeyMap: {
        EMAIL_HOST_KEY: 'spring.mail.host',
        EMAIL_PORT_KEY: 'spring.mail.port',
        EMAIL_USERNAME_KEY: 'spring.mail.username',
        EMAIL_PASSWORD_KEY: 'spring.mail.password',
        EMAIL_FROM_KEY: 'spring.mail.properties.from',
        DINGDING_URL_TOKEN_KEY: 'console.config.alert.dingtalk.alerturl'
      },
      editPasswordRule: {
        password: [
          { required: true, message: 'The name cannot be empty', trigger: 'blur' }
        ],
        passwordAgain: [
          { required: true, message: 'The name cannot be empty', trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: 'The verifyCode cannot be empty', trigger: 'blur' }
        ]
      },
      setMetaColumn: [
        {
          title: this.$t('tao-can-ming-cheng'),
          key: 'licenseSetMeta'
        },
        {
          title: this.$t('nei-rong'),
          slot: 'licenseContent'
        },
        {
          title: this.$t('mu-lu-jia-ge'),
          width: 120,
          render: (h, params) => h('div', {}, this.$t('thisgetlicensepriceparamsrowlicensemetas-yuan', [this.getLicensePrice(params.row.licenseMetas)]))
        }
      ],
      guotaColumn: [
        {
          title: this.$t('xian-zhi-xiang-mu'),
          key: 'description',
          minWidth: 160
        },
        {
          title: this.$t('yi-yong-shu-liang'),
          key: 'used',
          minWidth: 80
        },
        {
          title: this.$t('zong-shu'),
          key: 'quota',
          minWidth: 80
        }
      ],
      quotaList: [],
      userConfigList: [],
      userConfigs: {}
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getUserInfo();
    getGlobalSystemConfig()
      .then((res2) => {
        if (res2.data.code === '1') {
          this.globalSystemConfig = res2.data.data;
          this.$store.commit('getGlobalConfig', res2.data.data);
          console.log('store.state.globalConfig', store.state.globalConfig);
          this.ifSass = store.state.globalConfig.saas;
          if (this.ifSass) {
            defaultLicenseTypeUrl().then((res) => {
              if (res.data.code === '1') {
                this.licenseUrl = res.data.data;
              }
            });
            defaultDisplayLicenseSet().then((res) => {
              if (res.data.code === '1') {
                this.setList = res.data.data;
              }
            });
            this.getUserQuota();
            this.listAllConfigs();
            this.getListUserLicense();
          }
        }
      });
  },
  mounted() {
    this.listAllConfigs();
    this.getListUserLicense();
    this.getUserQuota();
    this.getUserConfig();
  },
  computed: {
    getCreateTime() {
      if (this.userInfo.gmtCreate) {
        return fecha.format(new Date(this.userInfo.gmtCreate), 'YYYY-MM-DD HH:mm:ss');
      }
      return '';
    },
    getUpdateTime() {
      if (this.userInfo.gmtModified) {
        return fecha.format(new Date(this.userInfo.gmtModified), 'YYYY-MM-DD HH:mm:ss');
      }
      return '';
    }
  },
  methods: {
    async handleSubmitUserConfig() {
      await this.$refs.userConfigParamsEdit.showUserConfigModal();
    },
    async handleRefreshFollowedList() {
      await this.$refs.followedJobList.getFollowedJobs();
    },
    async getUserConfig() {
      const res = await getCurrUserConfigs();
      if (res.data.success) {
        this.userConfigList = res.data.data;
        this.userConfigList.forEach((item) => {
          this.userConfigs[item.configName] = item.configValue;
        });
      }
    },
    handleSearch() {
      if (this.ifSass) {
        this.listAllConfigs();
        this.getListUserLicense();
        this.getUserQuota();
      }
      this.getUserInfo();
    },
    getUserInfo() {
      queryLoginUser().then((res) => {
        if (res.data.code === '1') {
          this.userInfo = res.data.data;
        }
      });
    },
    getListUserLicense() {
      listUserLicense().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            if (item.licenseType === 'FULL_TRANSFER_COUNT') {
              this.resourceData.fullTransferCount = item.amount;
            } else if (item.licenseType === 'STRUCT_TRANSFER_COUNT') {
              this.resourceData.structTransferCount = item.amount;
            } else if (item.licenseType === 'FULL_CHECK_COUNT') {
              this.resourceData.fullCheckCount = item.amount;
            } else if (item.licenseType === 'INCREMENT_SYNC_DURATION') {
              this.resourceData.incrementDuration = item.amount;
            }
            return null;
          });
        }
      });
    },
    getUserQuota() {
      getUserQuota().then((res) => {
        if (res.data.code === '1') {
          res.data.data.map((item) => {
            this.quotaList = res.data.data;
            if (item.quotaType === 'WORKER_COUNT') {
              this.quotaData.workerCount = item.quota;
              this.quotaData.workerCountUsed = item.used;
            } else if (item.quotaType === 'DATA_JOB_COUNT') {
              this.quotaData.dataJobCount = item.quota;
              this.quotaData.dataJobCountUsed = item.used;
            } else if (item.quotaType === 'DATA_SOURCE_COUNT') {
              this.quotaData.dataSourceCount = item.quota;
              this.quotaData.dataSourceCountUsed = item.used;
            }
            return null;
          });
        }
      });
    },
    listAllConfigs() {
      this.loading = true;
      listAllConfigs().then((res) => {
        this.loading = false;
        if (res.data.code === '1') {
          this.alarmSetting.emailAddress = res.data.data.emailAddress;
          this.alarmSetting.phoneNumber = res.data.data.phoneNumber;
          res.data.data.systemConfigVOList.map((item) => {
            if (item.configName === 'spring.mail.host') {
              this.systemForm.EMAIL_HOST_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.port') {
              this.systemForm.EMAIL_PORT_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.username') {
              this.systemForm.EMAIL_USERNAME_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.password') {
              // this.systemForm.EMAIL_PASSWORD_KEY = item.configValue;
            }
            if (item.configName === 'console.config.alert.dingtalk.alerturl') {
              this.systemForm.DINGDING_URL_TOKEN_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.properties.from') {
              this.systemForm.EMAIL_FROM_KEY = item.configValue;
            }
            if (this.systemForm.EMAIL_FROM_KEY) {
              this.systemForm.EMAIL_USERNAME_KEY = this.systemForm.EMAIL_FROM_KEY;
            } else if (this.systemForm.EMAIL_USERNAME_KEY) {
              this.systemForm.EMAIL_FROM_KEY = this.systemForm.EMAIL_USERNAME_KEY;
            }
            return null;
          });
          this.handleShowStmp();
        }
      });
    },
    handleShowPassword() {
      // this.showEditPassword = true;
      this.$router.push({ path: '/login/reset' });
      window.location.reload();
    },
    handleUpdateUserInfo(type) {
      this.updateUserInfoType = type;
      this.showEditUserInfo = true;
      this.updateUserInfo.phone = this.userInfo.phone;
      this.updateUserInfo.email = this.userInfo.email;
      if (type === 'phone') {
        this.isVerifyPhone = true;
      }
    },
    handleConfirmEdit() {
      if (!this.password) {
        this.$Modal.warning({
          title: this.$t('xiu-gai-mi-ma-ti-shi'),
          content: this.$t('mi-ma-bu-neng-wei-kong')
        });
      } else if (!this.pwConfirm) {
        this.$Modal.warning({
          title: this.$t('xiu-gai-mi-ma-ti-shi'),
          content: this.$t('liang-ci-shu-ru-de-mi-ma-bu-xiang-tong')
        });
      } else if (!this.verifyCode) {
        this.$Modal.warning({
          title: this.$t('xiu-gai-mi-ma-ti-shi'),
          content: this.$t('qing-shu-ru-yan-zheng-ma')
        });
      } else if (!this.pwLength || !this.pwContain || !this.pwFormat) {
        this.$Modal.warning({
          title: this.$t('zhu-ce-yi-chang'),
          content: this.$t('mi-ma-ge-shi-you-wu-qing-zhong-xin-tian-xie')
        });
      } else {
        this.showEditPassword = false;
        updatePassword({
          id: this.userInfo.id,
          password: this.password,
          verifyCode: this.verifyCode,
          phone: this.userInfo.phone
        }).then((res) => {
          if (res.data.code === '1') {
            logout().then((response) => {
              if (response.data.code !== '1') {
                this.$Modal.error({
                  title: 'error',
                  content: this.$t('deng-chu-cuo-wu')
                });
              } else {
                this.$router.push({ path: '/login' });
                window.location.reload();
              }
            });
          }
        });
      }
    },
    handleCancelEdit() {
      this.verifyCode = '';
      this.password = '';
      this.passwordAgain = '';
      // this.ifEdit = false;
      this.showEditPassword = false;
      this.editEmail = false;
      this.showEditUserInfo = false;
      this.showFetchAKSK = false;
      this.showAKSK = false;
      this.isVerifyPhone = false;
    },
    handleShowFetchAKSK() {
      this.showFetchAKSK = true;
    },
    handleConfirmFetchAKSK(verifyCode) {
      queryUserAkSk({
        verifyCode
      }).then((res) => {
        if (res.data.code === '1') {
          this.showFetchAKSK = false;
          this.akskInfo = res.data.data;
          this.showAKSK = true;
        }
      });
    },
    handleConfirmUpdateUserInfo(verifyCode) {
      if (this.isVerifyPhone) {
        const data = {
          verifyCode
        };
        checkVerifyCode(data).then((res) => {
          if (res.data.code === '1') {
            this.isVerifyPhone = false;
            this.userInfo.verifyCode = '';
            // this.$refs.verifyCount.counting = false;
            this.newPhone = true;
          }
        });
      } else {
        const postFunc = this.updateUserInfoType === 'phone' ? updateUserPhone : updateUserEmail;
        postFunc({
          phone: this.updateUserInfo.phone,
          email: this.updateUserInfo.email,
          verifyCode
        }).then((res) => {
          if (res.data.code === '1') {
            this.$Message.success(this.$t('xiu-gai-cheng-gong-0'));
            this.showEditUserInfo = false;
            setTimeout(() => {
              this.getUserInfo();
            }, 500);
          }
        });
      }
    },
    handleVerify() {
      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      const that = this;

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: 'RESET_PASSWORD'
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('fa-song-cheng-gong'));
        } else {
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
          this.$Modal.error({
            title: 'ERROR',
            content: `${res.data.msg}`
          });
        }
      }).catch((res) => {
        this.sendcodeDisabled = true;
        this.sendCodeAgainTime = 60;
        clearInterval(this.sendCodeAgain);
        this.$Modal.error({
          title: 'ERROR',
          content: `${res.data.msg}`
        });
      });
    },
    handleCheckPasswordAgain() {
      this.pwConfirm = Boolean(this.password && this.password === this.passwordAgain);
    },
    updateDingDingConfigs() {
      const list = [];

      for (const key in this.systemForm) {
        if (key !== 'EMAIL_PASSWORD_KEY') {
          list.push({
            configName: this.configKeyMap[key],
            configValue: this.systemForm[key]
          });
        } else if (this.systemForm[key]) {
          list.push({
            configName: this.configKeyMap[key],
            configValue: this.systemForm[key]
          });
        }
      }
      list.push({
        configName: this.configKeyMap.EMAIL_FROM_KEY,
        configValue: this.systemForm.EMAIL_USERNAME_KEY
      });
      updateDingDingConfigs(list).then((res) => {
        if (res.data.code === '1') {
          this.listAllConfigs();
          this.$Message.success(this.$t('xiu-gai-cheng-gong-0'));
          this.editEmail = false;
        }
        // this.ifEdit = false;
      });
    },
    handleFillEmail(value) {
      this.emailList = [];
      if (value.indexOf('@') < 0) {
        this.emailSuffix.map((item) => {
          this.emailList.push(`${value}@${item}`);
          return null;
        });
      }
      this.handleShowStmp();
    },
    handleShowStmp() {
      if (this.systemForm.EMAIL_USERNAME_KEY) {
        const list = this.systemForm.EMAIL_USERNAME_KEY.split('@');

        if (list.length > 1) {
          if (this.emailSuffix.indexOf(list[1]) < 0) {
            this.showSmtp = true;
          } else {
            this.showSmtp = false;
            this.systemForm.EMAIL_HOST_KEY = this.smtpList[list[1]];
            this.systemForm.EMAIL_PORT_KEY = this.smtpPort[list[1]];
          }
        } else {
          this.showSmtp = false;
        }
      } else {
        this.showSmtp = false;
      }
    },
    handleVerifyEmail() {
      verifyMail().then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('yan-zheng-you-xiang-fu-wu-qi-cheng-gong'));
        }
      });
    },
    handleVerifyIm() {
      verifyIm().then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('yan-zheng-im-gao-jing-cheng-gong'));
        }
      });
    },
    handleShowEdit() {
      this.ifEdit = true;
    },
    handleApplyStToken() {
      invalidStsToken().then((res) => {
        if (res.data.code === '1') {
          applyStsToken({
            userAk: this.aliyunAk,
            userSk: this.aliyunSk
          }).then((res1) => {
            if (res1.data.code === '1') {
              this.$Message.success(this.$t('cao-zuo-cheng-gong'));
              this.aliyunAk = '';
              this.aliyunSk = '';
            }
          });
        }
      });
    },
    handleCleanStToken() {
      invalidStsToken().then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('cao-zuo-cheng-gong'));
        }
      });
    },
    handleCheckPassword() {
      this.pwLength = this.password.length >= 8 && this.password.length <= 32;
      this.pwContain = this.password.indexOf(this.userInfo.phone) === -1;

      const pattern = /(?=.*[0-9])(?=.*[a-zA-Z])/;

      this.pwFormat = pattern.test(this.password);
    },
    handleApplyLicense() {
      applyLicense({ license: this.applyCode }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('dui-huan-cheng-gong'));
          this.applyCode = '';
          this.listAllConfigs();
          this.getListUserLicense();
          this.getUserQuota();
        }
      });
    },
    handleGoLicenseSet(url) {
      window.open(url);
    },
    getLicenseType(key) {
      const value = key.substring(14, key.length - 2);
      const list = value.split(', ');
      const map = {};

      list.map((item) => {
        const kv = item.split('=');

        map[kv[0]] = kv[1];
        return null;
      });
      return Mapping.licenseTypeDefault[map.licenseType];
    },
    getLicenseCount(key) {
      const value = key.substring(14, key.length - 2);
      const list = value.split(', ');
      const map = {};

      list.map((item) => {
        const kv = item.split('=');

        map[kv[0]] = kv[1];
        return null;
      });
      if (map.licenseType === 'INCREMENT_SYNC_DURATION') {
        return this.$t('paramsrowamount-24-tian', [map.amount / 24]);
      }
      return this.$t('mapamount-ci', [map.amount]);
    },
    getLicensePrice(data) {
      let totalPrice = 0;

      Object.keys(data).map((key) => {
        const value = key.substring(14, key.length - 2);
        const list = value.split(', ');
        const map = {};

        list.map((item) => {
          const kv = item.split('=');

          map[kv[0]] = kv[1];
          return null;
        });
        totalPrice = map.price * data[key];
        return null;
      });
      return totalPrice;
    },
    handleEditEmail() {
      this.editEmail = true;
    },
    goExChangeHistory() {
      this.$router.push({ path: '/system/exchangeHistory' });
    },
    handleCopy(value) {
      const aux = document.createElement('input');

      aux.setAttribute('value', value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);

      this.$Message.success(this.$t('fu-zhi-cheng-gong-0'));
    }
  }
};
</script>
<style lang="less">
.user-center-title {
  font-size: 16px;
  line-height: 16px;
  padding-left: 8px;
  color: #333333;
  font-family: PingFangSC-Semibold,serif;
  font-weight: 500;
  border-left: 3px solid #535C70;
  margin-bottom: 6px;
}

.user-center-wrapper {
  margin-top: 16px;
  padding: 20px 40px;
  background-color: #ffffff;
  border: 1px solid #DADADA;

  & > p {
    line-height: 36px;
    font-size: 16px;
    padding: 12px 0;
    border-bottom: 1px solid #DADADA;

    .user-center-label {
      font-family: PingFangSC-Semibold,serif;
      font-weight: 500;
      display: inline-block;
      width: 104px;
    }

    .user-center-value {
      margin-right: 10px;
      display: inline-block;
    }
  }

  .ivu-table td, .ivu-table th {
    height: 40px;
  }
}

.user-center-wrapper-sp {
  background-color: #ECECEC;
  margin-bottom: 20px;

  .ivu-input {
    height: 40px;
    line-height: 40px;
  }

  .user-center-wrapper-sp-btn {
    background-color: #FFA30E;
    color: #ffffff;
    margin-top: 16px;
    background-image: none;
    border: none;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Semibold,serif;
    font-weight: 500;

    &:hover {
      background-color: #FFA30E !important;
      background-image: none;
    }
  }
}

/*.ivu-form-item:last-child{*/
/*    margin-bottom: 0;*/
/*}*/
.system-setting-title {
  font-family: PingFangSC-Semibold,serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.user-center {
  position: relative;

  .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #0BB9F8;
    font-family: PingFangSC-Semibold,serif;
  }

  .ivu-table th {
    background-color: #F5F5F5;
  }
}

.password-check {
  li {
    font-size: 12px;
    line-height: 25px;
    color: #808695;

    i {
      margin-right: 5px;
      font-size: 14px;
    }

    .ivu-icon-ios-close-circle-outline {
      color: #ed4014;
    }

    .ivu-icon-ios-checkmark-circle-outline {
      color: #19be6b;
    }
  }
}

.resource-basic {
  height: 132px;
  width: 100%;
  /*padding: 20px 60px;*/
  padding-top: 32px;
  /*border-radius: 4px;*/
  background-color: #ffffff;
  position: relative;
  box-shadow: 1px 1px 5px 0 rgba(197, 197, 197, 0.5);

  .resource-content {
    width: 200px;
    margin: 0 auto;
    /*padding-left: 96px;*/

    .license-set-url {
      cursor: pointer;
      font-family: PingFangSC-Medium,serif;
      font-weight: 500;
      margin-bottom: 30px;

      &:hover {
        color: #0BB9F8;
      }

      &:active {
        color: #0087C7;
      }
    }
  }

  .resource-basic-title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .resource-basic-count {
    font-size: 24px;
    font-family: PingFangSC-Semibold,serif;
    font-weight: 500;
    /*margin-bottom: 16px;*/
  }

  .resource-logo {
    /*position: absolute;*/
    font-size: 32px;
    /*left: 20px;*/
    /*top:37px;*/
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    margin-right: 16px;

    .iconfont {
      font-size: 32px;
    }

    .iconxingzhuang {
      color: #FFAC25;
    }

    .iconquanliangqianyisvg {
      color: #66A2FF;
    }

    .iconqianyi {
      color: #67CD51;
    }

    .iconjiaoyan {
      color: #838AFF;
    }
  }
}

.resource-suggest {
  background-color: #fff2de;
  height: 580px;
  overflow: hidden;

  .iconfont {
    color: #F1DAB6;
    font-size: 180px;
    right: -50px;
    bottom: -40px;
  }
}

.quota-basic {
  height: 125px;
  background-color: #DEF3FC;
  border-radius: 4px;
  padding: 20px 26px;

  .quota-basic-title {
    font-size: 16px;
    margin-bottom: 7px;
  }

  .quota-use-count {
    font-size: 36px;
    font-family: PingFangSC-Semibold,serif;
    margin-right: 10px;
  }

  .quota-total-count {
    font-size: 24px;
    font-family: PingFangSC-Semibold,serif;
    margin-top: 20px;
  }
}

.user-account-title {
  font-size: 16px;
  font-family: PingFangSC-Semibold,serif;
  margin-bottom: 10px;
  border: 1px solid #DADADA;
  background-color: #EEEEEE;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-quota-count {
  font-family: PingFangSC-Medium,serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}

.user-quota-title {
  color: #888888;
  text-align: center;
}

.license-set-detail-item {
  height: 40px;
  line-height: 40px;

  span {
    width: 100px;
    display: inline-block;
  }
}
</style>
