<template>
  <div>
    <DataSourceHeader :handleSearch="getDataSourceList" :searchKey="searchKey"
                      :handleShowAddDataSource="handleShowAddDataSource" :handleChangeSearchType="handleChangeSearchType"
                      :refreshLoading="refreshLoading"></DataSourceHeader>
    <div class="data-source-container">
      <div style="margin-top: 16px">
        <Table size="small" border :columns="dataSourceColumn" :data="showData">
          <template slot-scope="{ row }" slot="instanceId">
            <div style="position:relative;">
              <span>{{ row.instanceId }}</span>
              <Tooltip placement="right" class="alarm-icon" transfer :content="$t('cun-zai-yi-chang-de-hou-tai-ren-wu-qing-dian-ji-chu-li')"
                       v-if="row.consoleTaskState==='FAILED'">
                <span style="display: inline-block" @click="handleGoConsoleJob(row)"><i
                  class="iconfont iconyibuforce"></i></span>
              </Tooltip>
              <Poptip placement="right" width="680" class="show-datasource-info-icon" transfer>
                <i @click="getDataSourceDetail(row)" class="iconfont iconinfo"></i>
                <div slot="content" style="line-height: 24px">
                  <DataSourceInDetail :dataSourceDetail="sourceDetail"></DataSourceInDetail>
                </div>
              </Poptip>
            </div>
          </template>
          <template slot-scope="{ row }" slot="action">
            {{ '' }}
            <span v-if="row.lifeCycleState!=='CREATED'"
                  style="cursor: not-allowed;color: #BABDC5;margin-right: 16px">{{ $t('tian-jia-bai-ming-dan') }}</span>
            <a v-if="row.deployType!=='SELF_MAINTENANCE'&&row.lifeCycleState==='CREATED'"
               style="margin-right: 16px"
               @click="handleShowAddWhiteList(row)">{{ $t('tian-jia-bai-ming-dan') }}</a>
            <Dropdown transfer trigger="click">
              <a>
                {{ $t('geng-duo') }}
                <Icon type="ios-arrow-down"></Icon>
              </a>
              <DropdownMenu slot="list">
                <DropdownItem>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">{{ $t('ce-shi-lian-jie') }}</span>
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleShowTestConnectionModal(row)">{{ $t('ce-shi-lian-jie') }}</a>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">{{ $t('xiu-gai-wai-wang-di-zhi') }}</span>
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleUpdatePublicHost(row)">{{ $t('xiu-gai-wai-wang-di-zhi') }}</a>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleUpdatePrivateHost(row)">{{ $t('xiu-gai-nei-wang-di-zhi') }}</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">{{ $t('xiu-gai-nei-wang-di-zhi') }}</span>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleEditAccount(row)">{{ $t('xiu-gai-zhang-hao') }}</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">{{ $t('xiu-gai-zhang-hao') }}</span>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleUpdateAKSK(row)">{{ $t('xiu-gaiali-yun-aksk') }}</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">{{ $t('xiu-gaiali-yun-aksk') }}</span>
                </DropdownItem>
                <DropdownItem>
                  <a @click="handleKvConfigs(row)"
                     style="width: 100%;display: inline-block">{{ $t('cha-kan-pei-zhi') }}</a>
                </DropdownItem>
                <DropdownItem :disabled="row.lifeCycleState!=='CREATED'">
                  <a v-if="row.lifeCycleState==='CREATED'" style="width: 100%;display: inline-block"
                     @click="handleDeleteAccount(row)">{{ $t('shan-chu-zhang-hao') }}</a>
                  <span v-if="row.lifeCycleState!=='CREATED'"
                        style="cursor: not-allowed;color: #BABDC5">{{ $t('shan-chu-zhang-hao') }}</span>
                </DropdownItem>
                <DropdownItem>
                  <a @click="handleDeleteConfirm(row)"
                     style="width: 100%;display: inline-block">{{ $t('shan-chu-1') }}</a>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
          <template slot-scope="{ row }" slot="host">
            <div class="host-type">
              <p v-if="row.privateHost"><span class="host-type-label">{{ $t('nei') }}</span>{{ row.privateHost }}
              </p>
              <p v-if="row.publicHost" style="margin-top: 3px"><span
                class="host-type-label">{{ $t('wai') }}</span>{{ row.publicHost }}</p>
            </div>
          </template>
          <template slot-scope="{ row }" slot="dataSourceType">
            <div>
              <Tooltip style="margin-left: 10px;font-size: 24px;cursor: pointer" placement="right"
                       class="dataSource-icon" transfer
                       :content="`${row.deployType==='ALIBABA_CLOUD_HOSTED'?Mapping.aliyunType[row.dataSourceType]:row.dataSourceType}`">
                <DataSourceIcon :type="row.dataSourceType"
                                :instanceType="row.deployType"></DataSourceIcon>
              </Tooltip>
            </div>
          </template>
          <template slot-scope="{ row }" slot="deployType">
            <div>
              <span>{{ row.deployType === 'SELF_MAINTENANCE' ? $t('zi-jian-shu-ju-ku-0') : $t('a-li-yun') }}</span>
            </div>
          </template>
          <template slot-scope="{ row }" slot="instanceDesc">
            <div style="position: relative">
              <Tooltip :content="row.instanceDesc" placement="right" transfer>
                <span class="datasource-desc-content">{{ row.instanceDesc }}</span>
              </Tooltip>
              <i style="position: absolute;right: 5px;top: 0" @click="handleEditDataSourceDesc(row)"
                 class="iconfont iconbianji"></i>
            </div>
          </template>
        </Table>
      </div>
    </div>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
              :page-size="size"
              @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <StToken ref="stToken"></StToken>
    <AliyunAKSK ref="aliyunAKSK"></AliyunAKSK>
    <Modal
      v-model="showDeleteDataSourceConfirm"
      :title="$t('shan-chu-yuan-shu-ju-yuan-que-ren')"
      @on-ok="deleteDataSource"
      footer-hide
    >
      <div>
        <p>{{ $t('que-ren-yao-shan-chu-ming-wei-selectedrowinstancedesc-selectedrowinstanceid-de-shu-ju-yuan-ma', [selectedRow.instanceDesc, selectedRow.instanceId]) }}</p>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="deleteDataSource">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditDesc"
      :title="$t('xiu-gai-shu-ju-yuan-miao-shu')"
      footer-hide
      width="400px"
    >
      <div>
        <p>
          {{ $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-miao-shu-wei', [selectedRow.instanceId]) }}
        </p>
        <Input v-model="instanceDesc" style="width: 280px;margin-top: 20px"/>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditDesc">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditAccount"
      :title="$t('xiu-gai-zhang-hao')"
      footer-hide
      width="530px"
    >
      <div>
        <Form label-position="right" :label-width="80" style="margin-top: 10px" :model="accountInfo"
              :rules="accountInfoValidate"
              ref="account-info-form">
          <FormItem :label="$t('ren-zheng-fang-shi')" key="securityType">
            <Select v-model="accountInfo.securityType" style="width: 280px">
              <Option v-for="security in securitySetting" :value="security.securityType"
                      :key="security.securityType">{{ Mapping.securityType[security.securityType] }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('zhang-hao')" prop="account" key="account"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needUserName">
            <Input v-model="accountInfo.account" style="width: 280px"></Input>
          </FormItem>
          <FormItem :label="$t('mi-ma')" prop="password" key="password"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needPassword">
            <Input type="password" :placeholder="$t('mo-ren-bu-zhan-shi-dang-qian-mi-ma-qing-shu-ru-xin-mi-ma')" password
                   autocomplete="new-password"
                   v-model="accountInfo.password" style="width: 280px"></Input>
          </FormItem>
          <FormItem :label="$t('ssl-pei-zhi-wen-jian')" prop="securityFile" key="securityFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needTlsFile">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile1"/>
            <span style="margin-left: 10px;color: rgb(128, 134, 149)"></span>
          </FormItem>
          <FormItem :label="$t('kerberos-pei-zhi-wen-jian')" prop="securityFile" key="securityFile2"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needKrb5File">
            <input @change="handleFileChange" type="file" name="uploadfile" id="uploadfile"/>
          </FormItem>
          <FormItem :label="$t('keytab-wen-jian')" prop="secretFile" key="secretFile"
                    v-if="securitySettingObj[accountInfo.securityType] && securitySettingObj[accountInfo.securityType].needKeyTabFile">
            <input @change="handleKeyTabFileChange" type="file" name="uploadKeytabFile"
                   id="uploadKeytabFile"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmEditAccount">{{ $t('bao-cun') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdateAKSK"
      :title="$t('xiu-gaiali-yun-aksk')"
      footer-hide
      width="450px"
    >
      <div>
        <p style="color: #888888;margin-left: 8px;">{{ $t('gaiali-yun-assk-jin-yong-yu-shu-ju-tong-bu-shi-la-qu-bei-fen-ri-zhi-wen-jian-wei-bi-tian-xiang') }}</p>
        <Form label-position="right" :label-width="120" style="margin-top: 10px"
              ref="account-aksk-form" :model="accountAkSk" :rules="accountAkSkValidate">
          <FormItem label="AccessKey ID" prop="accessKey">
            <Input v-model="accountAkSk.accessKey" style="width: 280px" autocomplete="new-password"></Input>
          </FormItem>
          <FormItem label="AccessKey Secret" prop="secretKey">
            <Input type="password" password v-model="accountAkSk.secretKey"
                   style="width: 280px" autocomplete="new-password"></Input>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmUpdateAKSK">{{ $t('bao-cun') }}</pd-button>
          <!--                    <pd-button type="error" ghost @click="handleDeleteAccount">删除</pd-button>-->
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showAddWhiteList"
      :title="$t('tian-jia-bai-ming-dan')"
      footer-hide
      width="430px"
    >
      <div>
        <Alert style="margin-bottom: 20px" type="warning" show-icon>{{ $t('she-ding-fang-wen-ji-qi-tian-jia-ji-qi-dao-shu-ju-yuan-bai-ming-dan') }}</Alert>
        <Form label-position="right" :label-width="70" style="margin-top: 10px">
          <FormItem :label="$t('xuan-ze-ji-qi')">
            <div class="add-white-list-container">
              <SelectCluster :row="selectedRow" ref="selectCluster"
                             :handleChangeType="handleChangeType"></SelectCluster>
            </div>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer">
          <pd-button type="primary" @click="confirmAddWhiteList">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showAddWhiteListProgress" :title="$t('tian-jia-bai-ming-dan')" footer-hide>
      <div style="padding: 20px">
        <Progress :percent="currentPercentage" :stroke-width="20"
                  :status="currentAddWhiteListStatus==='SUCCESS'?'success':(currentAddWhiteListStatus==='FAILED'?'wrong':'active')"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
          {{
            currentAddWhiteListStatus === 'SUCCESS' ? $t('tian-jia-cheng-gong-0') : currentAddWhiteListStatus === 'FAILED' ? $t('tian-jia-shi-bai') : $t('tian-jia-bai-ming-dan-zhong')
          }}</p>
      </div>
    </Modal>
    <Modal
      v-model="showEditPublicHost"
      :title="$t('xiu-gai-shu-ju-yuan-wai-wang-di-zhi')"
      footer-hide
      width="620px"
      :closable="false"
    >
      <div>
        <p>
          {{ showUpdateHttpHost ? $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-wai-wang-client-di-zhi-wei', [selectedRow.instanceId]) : $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-wai-wang-di-zhi-wei', [selectedRow.instanceId]) }}
        </p>
        <div style="margin-top: 20px">
          <Input v-model="publicHost" :placeholder="$t('ip-huo-yu-ming-duan-kou')" style="width: 460px"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditPublicHost">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditPrivateHost"
      :title="$t('xiu-gai-shu-ju-yuan-nei-wang-di-zhi')"
      footer-hide
      width="620px"
      :closable="false"
    >
      <div>
        <p>
          {{ showUpdateHttpHost ? $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-nei-wang-client-di-zhi-wei', [selectedRow.instanceId]) : $t('xiu-gai-id-wei-selectedrowinstanceid-de-shu-ju-yuan-de-nei-wang-di-zhi-wei', [selectedRow.instanceId]) }}
        </p>
        <div style="margin-top: 20px">
          <Input v-model="privateHost" style="width: 460px" :placeholder="$t('ip-huo-yu-ming-duan-kou')"/>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEditPrivateHost">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal :title="$t('pei-zhi')" v-model="showKvConfigsModal" :width="1000" footer-hide>
      <config-params-edit :ds-kv-configs="dsKvConfigs"/>
      <div class="footer" style="margin-top: 10px;text-align: center;">
        <Button @click="handleCloseModal" style="margin-right: 20px;">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleUpsertConfigs">{{ $t('xiu-gai') }}</Button>
      </div>
    </Modal>
    <test-connection-modal :visible="showTestConnectionModal" :datasource="selectedRow" :handle-close-modal="handleCloseTestConnectionModal" type="dataSourceList"/>
  </div>
</template>
<script>
import {
  deleteAccount,
  deleteDataSource,
  listbycondition,
  queryById, queryDsConfig,
  updateAliyunRdsAkSk,
  updateDataSourceDesc,
  updateDataSourceHost,
  updatePublicHost, upsertDsConfig
} from '@services/api/datasource';
import { dsSecurityOption, listFilterDsTypes } from '@services/api/constant';
import _ from 'lodash';
import { addClusterWhiteList } from '@services/api/ailyunRds';
import { queryConsoleJob } from '@services/api/consoleJob';
import { getUserQuota } from '@services/api/resourceQuota';
import DataSourceIcon from '@components/function/DataSourceIcon';
import DataSourceHeader from '@components/function/addDataSource/DataSourceHeader';
import DataSourceInDetail from '@components/function/addDataSource/DataSourceInDetail';
import SelectCluster from '@components/function/addDataSource/SelectCluster';
import StToken from '@components/function/ApplyStToken';
import AliyunAKSK from '@components/function/ApplyAKSK';
import ConfigParamsEdit from '@views/system/ConfigParamsEdit';
import { updateAccountAndPassword } from '@services/api/upload';
import { isOracle } from '@utils';
import store from '../../store/index';
import Mapping from '../util';
import TestConnectionModal from '../../components/function/addDataSource/TestConnectionModal';

export default {
  components: {
    ConfigParamsEdit,
    TestConnectionModal,
    DataSourceIcon,
    DataSourceHeader,
    DataSourceInDetail,
    SelectCluster,
    StToken,
    AliyunAKSK
  },
  mounted() {
    this.listDataSourceTypes();
    // this.getDataSourceList();
  },
  data() {
    return {
      showKvConfigsModal: false,
      dsKvConfigs: [],
      showTestConnectionModal: false,
      securitySetting: [],
      securitySettingObj: {},
      store,
      publicHost: '',
      privateHost: '',
      publicHttpHost: '',
      privateHttpHost: '',
      sid: '',
      publicSid: '',
      showEditPublicHost: false,
      showEditPrivateHost: false,
      currentAddWhiteListStatus: '',
      currentPercentage: 0,
      showAddWhiteListProgress: false,
      showAddWhiteList: false,
      showEditAccount: false,
      showUpdateAKSK: false,
      accountInfo: {
        account: '',
        password: '',
        securityType: '',
        securityFile: '',
        secretFile: ''
      },
      accountAkSk: {
        accessKey: '',
        secretKey: ''
      },
      accountAkSkValidate: {
        accessKey: [
          {
            required: true,
            message: this.$t('accesskey-bu-neng-wei-kong')
          }
        ],
        secretKey: [
          {
            required: true,
            message: this.$t('secretkey-bu-neng-wei-kong')
          }
        ]
      },
      accountInfoValidate: {
        account: [
          {
            required: true,
            message: this.$t('zhang-hao-bu-neng-wei-kong')
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('mi-ma-bu-neng-wei-kong')
          }
        ],
        securityFile: [
          {
            required: true,
            message: this.$t('ssl-pei-zhi-wen-jian-bu-neng-wei-kong-0')
          }
        ],
        secretFile: [
          {
            required: true,
            message: this.$t('keytab-wen-jian-bu-neng-wei-kong')
          }
        ]
      },
      sourceDetail: {},
      Mapping,
      regionFilters: [],
      showEditDesc: false,
      instanceDesc: '',
      selectedRow: {},
      refreshLoading: false,
      showAddDataSource: false,
      showDeleteDataSourceConfirm: false,
      dataSourceTypes: [],
      page: 1,
      size: 20,
      total: 0,
      addDataSourceForm: {
        host: '',
        type: 'MySQL',
        region: 'hangzhou',
        role: 'MASTER',
        instanceType: 'SELF_MAINTENANCE',
        sid: ''
      },
      searchKey: {
        host: '',
        region: '',
        dbType: 'all'
      },
      dataSourceColumn: [
        {
          title: this.$t('shu-ju-yuan-id'),
          key: 'instanceId',
          slot: 'instanceId',
          width: 230
        },
        {
          title: this.$t('shu-ju-yuan-miao-shu'),
          key: 'instanceDesc',
          minWidth: 280,
          slot: 'instanceDesc'
        },
        {
          title: 'Host',
          key: 'host',
          minWidth: 520,
          slot: 'host'
        },
        {
          title: this.$t('lei-xing'),
          key: 'dataSourceType',
          width: 80,
          slot: 'dataSourceType'
        },
        {
          title: this.$t('chuang-jian-zhuang-tai'),
          key: 'lifeCycleState',
          width: 110,
          render: (h, params) => h('div', { style: { color: params.row.lifeCycleState === 'CREATED' ? '#52C41A' : '#FF1815' } }, Mapping.createStatus[params.row.lifeCycleState])
        },
        {
          title: this.$t('bu-shu-lei-xing'),
          key: 'deployType',
          width: 150,
          slot: 'deployType',
          renderHeader: (h) => h('span', [
            h('span', {}, this.$t('bu-shu-lei-xing')),
            h('Tooltip', {
              props: {
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('a',
                {
                  style: {
                    color: '#333'
                  }
                }, [
                  h('icon', {
                    props: {
                      type: 'md-help-circle'
                    },
                    style: {
                      marginLeft: '5px'
                    }
                  })
                ]),
              h('div', {
                slot: 'content'
              }, [
                h('p', {}, this.$t('zi-jian-shu-ju-ku-shi-yong-hu-zi-ji-bu-shu-de-shu-ju-ku-shi-li')),
                h('p', {}, this.$t('a-li-yun-shi-yong-hu-zaiali-yun-shang-gou-mai-de-shu-ju-ku-shi-li'))
              ])
            ])
          ])
        },
        {
          title: this.$t('ban-ben-hao'),
          key: 'version',
          width: 180
        },
        {
          title: this.$t('qu-yu-0'),
          key: 'region',
          width: 100,
          render: (h, params) => h('div', {}, Mapping.region[params.row.region]),
          filters: this.regionFilters
        },
        {
          title: this.$t('suo-you-zhe-0'),
          key: 'owner',
          width: 120
        },
        {
          title: this.$t('cao-zuo'),
          key: '',
          slot: 'action',
          width: 220,
          fixed: 'right'
        }
      ],
      dataSourceData: [],
      showData: [],
      pagingData: [],
      addDataSourceRule: {
        host: [
          {
            required: true,
            message: 'The host cannot be empty',
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: 'The type cannot be empty',
            trigger: 'change'
          }
        ],
        role: [
          {
            required: true,
            type: 'string',
            message: 'The role cannot be empty',
            trigger: 'change'
          }
        ],
        region: [
          {
            required: true,
            type: 'string',
            message: 'The region cannot be empty',
            trigger: 'change'
          }
        ],
        instanceType: [
          {
            required: true,
            message: 'Please select type',
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    showUpdateHttpHost() {
      return ['StarRocks', 'Doris', 'SelectDB'].includes(this.selectedRow.dataSourceType);
    }
  },
  methods: {
    isOracle,
    handleCloseModal() {
      this.showKvConfigsModal = false;
    },
    async handleUpsertConfigs() {
      const updateConfigs = {};
      const needCreateConfigs = {};

      this.dsKvConfigs.forEach((config) => {
        const {
          needCreated, defaultValue, configValue, currentCount, configName
        } = config;

        if (needCreated) {
          needCreateConfigs[configName] = currentCount || defaultValue;
        } else {
          if (currentCount && currentCount !== configValue) {
            updateConfigs[configName] = currentCount;
          }
        }
      });

      const res = await upsertDsConfig({
        dataSourceId: this.selectedRow.id,
        needCreateConfigs,
        updateConfigs
      });

      if (res.data.success) {
        this.showKvConfigsModal = false;
        this.$Message.success(this.$t('xiu-gai-cheng-gong'));
      } else {
        this.$Message.error(this.$t('xiu-gai-shi-bai'));
      }
    },
    async handleKvConfigs(row) {
      this.selectedRow = row;
      const res = await queryDsConfig({
        dataSourceId: row.id
      });

      if (res.data.success) {
        this.dsKvConfigs = res.data.data;
        this.showKvConfigsModal = true;
      }
    },
    handleKeyTabFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.secretFile = file;
        this.$refs['account-info-form'].validateField('secretFile');
      }
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024) {
          return false;
        }
        this.accountInfo.securityFile = file;
        this.$refs['account-info-form'].validateField('securityFile');
      }
    },
    handleRefresh() {
      this.getDataSourceList();
    },
    getDataSourceList(searchKey) {
      searchKey = this.searchKey;
      this.refreshLoading = true;
      let type = null; let hostType = null; let
        deployType = null;

      if (searchKey && searchKey.dbType !== 'all') {
        type = searchKey.dbType;
      }
      if (searchKey && searchKey.hostType !== 'all') {
        hostType = searchKey.hostType;
      }
      if (searchKey && searchKey.deployType !== 'all') {
        deployType = searchKey.deployType;
      }
      listbycondition({
        type,
        deployType,
        hostType,
        instanceIdLike: searchKey ? searchKey.instanceIdLike : null,
        dataSourceDescLike: searchKey ? searchKey.dataSourceDescLike : null,
        dsHostLike: searchKey ? searchKey.dsHostLike : null,
        dataSourceId: searchKey ? searchKey.dataSourceId : null
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.dataSourceData = res.data.data;
            this.pagingData = _.cloneDeep(this.dataSourceData);
            this.total = this.dataSourceData.length;
            this.showData = this.dataSourceData.slice((this.page - 1) * this.size, this.page * this.size);
            this.showData.map((item) => {
              item.showEditDesc = false;
              return null;
            });
          }
          this.refreshLoading = false;
        })
        .catch(() => {
          this.refreshLoading = false;
        });
    },
    handleShowAddDataSource() {
      getUserQuota()
        .then((res) => {
          if (res.data.code === '1') {
            res.data.data.map((item) => {
              if (item.quotaType === 'DATA_SOURCE_COUNT') {
                if (item.used >= item.quota) {
                  this.$Modal.warning({
                    title: this.$t('e-du-bu-zu'),
                    content: this.$t('nin-dang-qian-de-shu-ju-yuanedu-bu-zu-qing-quahrefsystemusercenter-ge-ren-zhong-xinashen-qingedu-0')
                  });
                } else {
                  this.$router.push({ path: '/data/dataSource/add' });
                }
              }
              return null;
            });
          }
        });
    },
    guid() {
      let num = '';

      for (let i = 0; i < 16; i++) {
        num += Math.floor(Math.random() * 10);
      }
      return num;
    },
    deleteDataSource() {
      this.showDeleteDataSourceConfirm = false;
      deleteDataSource({ dataSourceId: this.selectedRow.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.getDataSourceList();
            this.$Message.success(this.$t('shan-chu-cheng-gong'));
          }
        });
    },
    handleDeleteConfirm(row) {
      this.selectedRow = row;
      this.showDeleteDataSourceConfirm = true;
    },
    listDataSourceTypes() {
      listFilterDsTypes({})
        .then((res) => {
          if (res.data.code === '1') {
            this.dataSourceTypes = res.data.data;
          }
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.pagingData.slice((this.page - 1) * this.size, this.page * this.size);
      this.showData.map((item) => {
        item.showEditDesc = false;
        return null;
      });
    },
    handleConfirmEditDesc() {
      this.showEditDesc = false;
      updateDataSourceDesc({
        dataSourceId: this.selectedRow.id,
        instanceDesc: this.instanceDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.getDataSourceList();
            this.$Message.success(this.$t('xiu-gai-cheng-gong-0'));
          }
        });
    },
    handleCancelEdit() {
      this.publicHost = '';
      this.publicHttpHost = '';
      this.privateHost = '';
      this.privateHttpHost = '';
      this.showEditDesc = false;
      this.showEditAccount = false;
      this.showAddWhiteList = false;
      this.showEditPrivateHost = false;
      this.showEditPublicHost = false;
      this.showDeleteDataSourceConfirm = false;
      store.state.selectedCluster.selectedCluster = {};
      this.showUpdateAKSK = false;
    },
    handleEditDataSourceDesc(row) {
      this.instanceDesc = row.instanceDesc;
      this.selectedRow = row;
      this.showEditDesc = true;
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.handlePageChange(1);
    },
    getDataSourceDetail(row, security = false) {
      queryById({ dataSourceId: row.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDetail = res.data.data;
            this.accountInfo.account = this.sourceDetail.accountName;
            this.accountInfo.securityType = this.sourceDetail.securityType;

            if (security) {
              console.log(res.data.data);
              const {
                deployType,
                dataSourceType
              } = res.data.data;
              dsSecurityOption({
                deployEnvType: deployType,
                dataSourceType
              })
                .then((res2) => {
                  if (res2.data.code === '1') {
                    this.securitySetting = res2.data.data.securityOptions;
                    const obj = {};
                    res2.data.data.securityOptions.forEach((s) => {
                      obj[s.securityType] = s;
                    });
                    this.securitySettingObj = obj;
                  }
                });
            }
          }
        });
    },
    confirmEditAccount() {
      this.$refs['account-info-form'].validate((valid) => {
        console.log(valid);
        if (valid) {
          const formData = new FormData();
          const datasourceUpdateData = {
            dataSourceId: this.sourceDetail.id,
            userName: this.accountInfo.account,
            password: this.accountInfo.password,
            securityType: this.accountInfo.securityType
          };
          formData.append('DataSourceUpdateData', JSON.stringify(datasourceUpdateData));
          formData.append('securityFile', this.accountInfo.securityFile);
          formData.append('secretFile', this.accountInfo.secretFile);
          console.log(formData.get('DataSourceUpdateData'));
          updateAccountAndPassword(formData)
            .then((res) => {
              if (res.data.code === '1') {
                this.showEditAccount = false;
                this.getDataSourceList();
                this.accountInfo = {
                  account: '',
                  password: '',
                  securityType: ''
                };
                this.$Message.success(this.$t('xiu-gai-cheng-gong-0'));
              }
            });
        }
      });
    },
    confirmUpdateAKSK() {
      this.$refs['account-aksk-form'].validate((valid) => {
        if (valid) {
          updateAliyunRdsAkSk({
            dataSourceId: this.sourceDetail.id,
            accessKey: this.accountAkSk.accessKey,
            secretKey: this.accountAkSk.secretKey
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.showUpdateAKSK = false;
                this.getDataSourceList();
                this.accountAkSk = {
                  secretKey: '',
                  accessKey: ''
                };
                this.$Message.success(this.$t('xiu-gai-cheng-gong-0'));
              }
            });
        }
      });
    },
    handleEditAccount(row) {
      this.showEditAccount = true;
      this.sourceDetail = row;
      this.getDataSourceDetail(row, true);
    },
    handleUpdateAKSK(row) {
      this.showUpdateAKSK = true;
      this.sourceDetail = row;
    },
    handleDeleteAccount(row) {
      this.accountInfo.account = '';
      this.accountInfo.password = '';
      this.accountInfo.securityType = '';
      this.sourceDetail = row;
      this.$Modal.confirm({
        title: this.$t('shan-chu-shu-ju-yuan-zhang-hao-que-ren'),
        content: this.$t('p-que-ren-yao-shan-chu-rowinstanceid-de-zhang-hao-ma-p', [row.instanceId]),
        onOk: () => {
          deleteAccount({ dataSourceId: row.id })
            .then((res) => {
              if (res.data.code === '1') {
                this.$Message.success(this.$t('shan-chu-zhang-hao-cheng-gong'));
                this.getDataSourceList();
              }
            });
        },
        onCancel: () => {
        }
      });
    },
    handleShowAddWhiteList(row) {
      this.showAddWhiteList = true;
      this.selectedRow = row;
      if (this.selectedRow.publicHost) {
        this.selectedRow.whiteListAddType = 'PUBLIC_IP_ONLY';
      } else {
        this.selectedRow.whiteListAddType = 'PRIVATE_IP_ONLY';
      }
    },
    handleChangeType(type) {
      this.selectedRow.whiteListAddType = type;
    },
    confirmAddWhiteList() {
      this.showAddWhiteList = false;
      this.currentPercentage = 0;
      this.currentAddWhiteListStatus = '';
      const ids = [];
      const
        that = this;

      store.state.selectedCluster[this.selectedRow.instanceId].map((item) => {
        ids.push(item.id);
        return null;
      });
      addClusterWhiteList({
        dataSourceId: this.selectedRow.id,
        dataSourceType: this.selectedRow.dataSourceType,
        clusterIds: ids,
        whiteListAddType: this.selectedRow.whiteListAddType,
        dataSourceRegion: this.selectedRow.region
      })
        .then((res) => {
          if (res.data.code === '1' && this.dataIsNaN(res.data.data)) {
            this.showAddWhiteListProgress = true;

            this.querySourceAddWhiteListInterval = setInterval(() => {
              queryConsoleJob({ consoleJobId: res.data.data })
                .then((response) => {
                  if (res.data.code === '1') {
                    that.currentAddWhiteListStatus = response.data.data.taskState;
                    if (response.data.data.taskState === 'SUCCESS') {
                      clearInterval(that.querySourceAddWhiteListInterval);
                      that.sourceDisabled = false;
                      setTimeout(() => {
                        that.showAddWhiteListProgress = false;
                      }, 500);
                    } else if (response.data.data.taskState === 'FAILED') {
                      clearInterval(that.querySourceAddWhiteListInterval);
                      that.sourceDisabled = false;
                    }
                  }
                });
            }, 3000);
            store.state.selectedCluster.selectedCluster = {};
          } else if (res.data.code === '6028') {
            this.$refs.stToken.handleShowAkSk();
          } else if (res.data.code === '6047') {
            this.$refs.aliyunAKSK.handleShowAkSk();
          }
        });

      this.getPercantage = setInterval(() => {
        if (that.currentAddWhiteListStatus === 'SUCCESS') {
          that.currentPercentage = 100;
          clearInterval(that.getPercantage);
          setTimeout(() => {
            that.showAddWhiteList = false;
          }, 500);
        } else if (that.currentAddWhiteListStatus === 'FAILED') {
          clearInterval(that.getPercantage);
        } else if (that.currentPercentage < 90) {
          that.currentPercentage += Math.floor(Math.random() * 3);
        } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
          that.currentPercentage += Math.floor(Math.random() * 1.5);
        } else if (that.currentPercentage >= 97) {
          that.currentPercentage = 99;
        }
      }, 200);
    },
    handleCloseTestConnectionModal() {
      this.showTestConnectionModal = false;
    },
    handleShowTestConnectionModal(row) {
      this.showTestConnectionModal = true;
      this.selectedRow = row;
    },
    handleUpdatePublicHost(row) {
      this.showEditPublicHost = true;
      this.selectedRow = row;
      this.publicHost = row.publicHost;
      if (row.extraVO) {
        this.publicHttpHost = row.extraVO.publicStarRocksHttpHost;
      }
    },
    handleUpdatePrivateHost(row) {
      this.showEditPrivateHost = true;
      this.selectedRow = row;
      this.privateHost = row.privateHost;
      if (row.extraVO) {
        this.privateHttpHost = row.extraVO.privateStarRocksHttpHost;
      }
    },
    handleConfirmEditPublicHost() {
      updatePublicHost({
        dataSourceId: this.selectedRow.id,
        publicHost: this.publicHost,
        publicHttpHost: this.publicHttpHost
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.publicHost = '';
            this.publicHttpHost = '';
            this.showEditPublicHost = false;
            this.getDataSourceList();
            this.$Message.success(this.$t('xiu-gai-cheng-gong'));
          }
        });
    },
    handleConfirmEditPrivateHost() {
      updateDataSourceHost({
        dataSourceId: this.selectedRow.id,
        privateHost: this.privateHost,
        privateHttpHost: this.privateHttpHost
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.privateHost = '';
            this.privateHttpHost = '';
            this.showEditPrivateHost = false;
            this.getDataSourceList();
            this.$Message.success(this.$t('xiu-gai-cheng-gong'));
          }
        });
    },
    handleGoConsoleJob(row) {
      this.$router.push({ path: `/system/state/task/${row.consoleJobId}` });
    },
    dataIsNaN(value) {
      return typeof value === 'number' && !Number.isNaN(value);
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {};
    }
  }
};
</script>
<style lang="less">
.data-source-container {
  position: relative;
  margin-top: 16px;
  margin-bottom: 74px;

  .iconfont {
    color: #8D95A6;
    cursor: pointer;
    font-size: 12px;
  }

  .show-datasource-info-icon {
    color: #0BB9F8;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: -6px;
    cursor: pointer;
  }

  .datasource-desc-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 200px;
  }
}

.add-white-list-container {
  width: 280px;
  border: 1px solid #DADADA;
  padding: 0 12px;
  border-radius: 4px;
}

.host-type {
  padding: 12px 0;
}

.host-type-label {
  font-size: 12px;
  color: #333;
  background-color: #DEEFFF;
  display: inline-block;
  //width: 16px;
  height: 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 16px;
  margin-right: 4px;
}

.alarm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  /*border-radius: 50%;*/
  /*background-color: #FF6E0D;*/
  color: #FF6E0D;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-left: 4px;

  .iconyibuforce {
    font-size: 14px;
    color: #FF6E0D;
  }
}
</style>
