<template>
  <div>
    <Table v-if="isMongoDB(sourceType)" stripe border size="small" :height="tableHeight" :row-class-name="rowClassName" :loading="loading" :columns="getTableColumns"  :data="getTableData(table)">

    </Table>
    <Table v-else stripe border size="small" :height="tableHeight" :row-class-name="rowClassName" :loading="loading"
           :columns="getTableColumns"
           :data="getTableData(table)" :span-method="handleSpan"
           @on-select="handleOnSelect(table,index,$event)"
           @on-select-cancel="handleOnSelectCancel(table,index,$event)"
           @on-select-all="handleOnSelectAll(table,index,$event)"
           @on-select-all-cancel="handleOnSelectAllCancel(table,index,$event)">
      <template slot="sourceColumn" slot-scope="{ row }">
          <span
            v-if="taskInfo.processType==='edit'"
            :style="row.hasInJob && !row._checked ? 'color: red;font-weight: bold;' : !row.hasInJob && row._checked ? 'color: green;font-weight: bold' : 'color: black'">
            {{row.sourceColumn}}
          </span>
          <span v-else>
            {{row.sourceColumn}}
          </span>
          <Icon type="ios-key" v-if="row.isPk" style="color: #ff9900;margin-left: 5px;"/>
        <Tooltip transfer v-if="row.isId && DataSourceGroup.es.indexOf(sinkType) > -1" >
          <div class="_id">
            _id
          </div>
          <div slot="content">
            <div>
              {{ $t('elasticsearch-zhong-id-yong-yu-wei-yi-shi-bie-yi-ge-wen-dang-cloudcanal-yun-xu-yong-hu-zhi-ding-yuan-duan-de-mou-ge-lie-zuo-wei-id-qu-zhi-de-lai-yuan-ru-guo-yong-hu-bu-zhi-ding-ze-mo-ren-shi-yong-yuan-duan-zhu-jian-lie-de-qu-zhi-zuo-wei-xie-ru-dui-duan-id-de-qu-zhi-zai-shi-yong-cloudcanal-zi-ding-yi-dai-ma-gou-jian-kuan-biao-jin-hang-duo-biao-join-de-chang-jing-xia-jiang-id-she-zhi-wei-yuan-duan-duo-biao-de-guan-lian-lie-dui-duo-biao-join-fei-chang-you-yong') }}
            </div>
            <a href="https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-id-field.html" target="_blank">{{ $t('can-kao-guan-fang-zi-liao-id-field-shuo-ming') }} </a>
          </div>
        </Tooltip>
      </template>
      <template slot-scope="{ index, row }" slot="sink">
        <div style="display: flex;align-items: center;" v-if="row.selected">
          <div style="flex: 1">
            <Select
              v-if="selectedIndex===index&&!row._disabled&&table&&selectedColumns[table.sourceDb]&&selectedColumns[table.sourceDb][table.sourceTable]"
              @on-change="handleSinkColumnChange(table,row,$event)"
              v-model="selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn"
              :class="dbToCreateClass(table,row)" filterable
              style="width:100%" :transfer="true">
              <Option
                v-if="taskInfo.customPkgFile || havePackage"
                value="clougence_cloudcanal_filter_after_mapping_column" key="-1" :label="$t('jin-ding-yue-bu-xie-ru-dui-duan')">
                <Tooltip transfer :content="$t('ding-yue-de-yuan-duan-lie-de-zhi-hui-zai-zi-ding-yi-dai-ma-zhong-chuan-di-gei-yong-hu-dan-shi-gai-lie-de-zhi-bu-ying-she-mu-biao-duan-de-ren-he-lie-ye-bu-hui-xie-ru-mu-biao-duan')" placement="right">
                  <p>{{ $t('jin-ding-yue-bu-xie-ru-dui-duan') }}</p>
                </Tooltip>
              </Option>
              <Option
                v-for="(item,i) in taskInfo.sinkColumns[table.sinkDb?table.sinkDb:table.targetDb][table.sinkTable?table.sinkTable:table.targetTable]"
                :value="item.columnName" :key="i">{{ item.columnName }}
              </Option>
              <Option
                v-if="(selectedColumns[table.sourceDb][table.sourceTable]&&
              selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn)&&
              !hasTheSameColumn(getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn) &&
              taskInfo.sinkColumns[table.sinkDb?table.sinkDb:table.targetDb][table.sinkTable?table.sinkTable:table.targetTable]) &&
              table.needAutoCreated"
                :value="getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn)">
                {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn)]) }}
              </Option>
            </Select>
            <div v-else @mouseover="displaySelect(index)">
              <div style="display: flex;align-items: center;justify-content: space-between">
                <div>
                  <div
                    style="color: #FF6E0D"
                    v-if="showNeedAutoCreatedSinkColumn(row)">
                    {{ $t('gettargetvalueselectedcolumnstablesourcedbtablesourcetablerowindexsourcecolumn-dai-chuang-jian', [getTargetValue(selectedColumns[table.sourceDb][table.sourceTable][row.index].sourceColumn)]) }}
                  </div>
                  <div v-else>
                    {{DataSourceGroup.columnMapping[selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn] ||
                  selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn}}
                  </div>
                </div>
                <Icon type="ios-arrow-down" size="14" color="#808695" v-if="!row._disabled"/>
              </div>
            </div>
          </div>
          <Tooltip v-if="row.sinkColumn !== row.originalSinkColumn && taskInfo.processType === 'edit' && row.originalSinkColumn" style="margin-left: 10px;" transfer>
            <Icon type="ios-information-circle-outline" />
            <div slot="content">
              {{row.originalSinkColumn}}
            </div>
          </Tooltip>
        </div>
        <div v-if="row.columnDisabled">
          <i style="margin-right: 4px;vertical-align: middle"
             class="iconfont iconTIP"></i> {{row.filterDesc}}
        </div>
      </template>
      <template slot-scope="{ row }" slot="sinkType">
        {{
          table &&
          selectedColumns[table.sourceDb][table.sourceTable] ?
            selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType : ''
        }}
        <Tooltip v-if="table &&
        selectedColumns[table.sourceDb][table.sourceTable]&&
        !selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkType"
                 :content="$t('dang-qian-mu-biao-shu-ju-ku-bu-zhi-chi-gai-lei-xing')" placement="bottom" transfer>
          <p style="color: #bbbbbb"><i style="margin-right: 4px;vertical-align: middle"
                                       class="iconfont iconTIP"></i>{{ $t('bu-zhi-chi-de-lei-xing') }}</p>
        </Tooltip>
      </template>
      <template slot-scope="{ row }" slot="sinkIsNullable">
        {{
          table &&
          selectedColumns[table.sourceDb][table.sourceTable] ?
            selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkIsNullable : ''
        }}
      </template>
      <template slot="indexType" slot-scope="{row,index}">
        <div>
                        <span style="margin-left: 7px"
                              v-if="(row.sinkType!=='TEXT'&&row.sinkType!=='KEYWORD'&&row.sourceType.toUpperCase()!=='JSON'&&row.sourceType.toUpperCase()!=='LONGTEXT')||!row.needAutoCreated">{{
                            row.sinkType
                          }}</span>
          <Select
            v-if="row.needAutoCreated&&row.sinkType&&row.sourceType.toUpperCase()!=='JSON'&&row.sourceType.toUpperCase()!=='LONGTEXT'&&(row.sinkType==='TEXT'||row.sinkType==='KEYWORD')"
            style="width:120px;" v-model="row.sinkType" :transfer="true"
            @on-change="handleChangeIndexType(row,index,table,$event)">
            <Option value="TEXT">TEXT</Option>
            <Option value="KEYWORD">KEYWORD</Option>
          </Select>
          <Select
            v-if="row.needAutoCreated&&(row.sourceType.toUpperCase()==='JSON'||row.sourceType.toUpperCase()==='LONGTEXT')"
            style="width:120px;"
            v-model="row.sinkType" :transfer="true"
            @on-change="handleChangeIndexType(row,index,table,$event)">
            <Option value="OBJECT">OBJECT</Option>
            <Option value="TEXT">TEXT</Option>
            <Option value="NESTED">NESTED</Option>
          </Select>
        </div>
      </template>
      <template slot="ifKey" slot-scope="{row, index}">
        <div>
          <Checkbox @on-change="handleChangeIndex(row,index)"
                    :disabled="!row.needAutoCreated||esDisableIndexType.indexOf(row.sinkType)>-1"
                    v-model="row.isIndex"></Checkbox>
        </div>
      </template>
      <template slot="columnValue" slot-scope="{row}">
        <div>
          <Input v-model="row.columnValue"/>
        </div>
      </template>
      <template slot="action" slot-scope="{row,index}">
        <div>
          <i style="color: #FF1815;cursor: pointer" @click="handleDeleteColumn(row,index)"
             v-if="row.isVirtual" class="iconfont iconDEL-R"></i>
        </div>
      </template>
      <template slot="analyzer" slot-scope="{row}">
        <Select v-if="row.sinkType==='TEXT'&&row.needAutoCreated"
                @on-change="handleChangeAnalyzer(row,index,table,$event)" style="width:120px;"
                v-model="row.analyzer" :transfer="true" filterable>
          <Option v-for="esType in esTypeList" :key="esType" :value="esType">{{esType}}</Option>
        </Select>
        <span v-if="row.sinkType==='TEXT'&&!row.needAutoCreated">{{row.analyzer}}</span>
      </template>
      <template slot="mqSink">
        <div>
          <pre :key="mqSchemaKey">{{ mqSchema }}</pre>
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import { isMongoDB } from '@utils';
import DataSourceGroup from '../../../../../views/dataSourceGroup';
import store from '../../../../../store/index';

export default {
  props: {
    tableHeight: Number,
    rowClassName: Function,
    handleSelected: Function,
    handleSelectCancel: Function,
    handleAllSelected: Function,
    handleCancelSelectAll: Function,
    handleSinkColumnChange: Function,
    hasTheSameColumn: Function,
    handleChangeIndexType: Function,
    handleChangeAnalyzer: Function,
    sourceType: String,
    sinkType: String,
    table: Object,
    selectedColumns: Object,
    mqSchema: String,
    index: Number,
    targetCaseSensitive: String,
    handleDeleteColumn: Function,
    taskInfo: Object,
    mqSchemaKey: Number,
    esTypeList: Array,
    loading: Boolean,
    havePackage: Boolean,
    getTargetValue: Function
  },
  data() {
    return {
      store,
      selectedIndex: -1,
      DataSourceGroup,
      esDisableIndexType: ['binary', 'geo_point', 'object'],
      esJsonValueType: ['VARCHAR', 'TEXT', 'JSON'],
      cleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao-zi-duan'),
          key: 'sourceColumn',
          slot: 'sourceColumn'
          // render: (h, params) => {
          //   if (params.row.isPk) {
          //     return h('div', [
          //       h('span', {}, params.row.sourceColumn),
          //       h('Icon', { props: { type: 'ios-key' }, style: { marginLeft: '5px', color: '#ff9900' } })
          //     ]);
          //   }
          //   return h('div', {}, params.row.sourceColumn);
          // }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing'),
          key: 'sourceType',
          width: 120
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 110
        },
        {
          title: this.$t('mu-biao-biao-zi-duan'),
          slot: 'sink'
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing'),
          key: 'sinkType',
          width: 130,
          slot: 'sinkType'
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          slot: 'sinkIsNullable',
          width: 110
        }
      ],
      mongoCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('mu-biao-biao-zi-duan'),
          key: 'sinkColumn'
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing'),
          key: 'sinkType',
          width: 130
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 110
        }
      ],
      mqCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao-zi-duan'),
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', { props: { type: 'ios-key' }, style: { marginLeft: '5px', color: '#ff9900' } })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing'),
          key: 'sourceType',
          width: 150
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        }
      ],
      esCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao-zi-duan'),
          key: 'sourceColumn',
          slot: 'sourceColumn'
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('mu-biao-suo-yin-zi-duan'),
          slot: 'sink'
        },
        {
          title: this.$t('lei-xing'),
          slot: 'indexType',
          width: 150
        },
        {
          title: this.$t('shi-fou-suo-yin'),
          slot: 'ifKey',
          width: 100
        },
        {
          title: this.$t('fen-ci-qi'),
          slot: 'analyzer',
          width: 150,
          renderHeader: (h) => h('div', [
            h('span', {}, this.$t('fen-ci-qi')),
            h('Tooltip', {
              props: {
                content: this.$t('fei-nei-zhi-fen-xi-qi-qing-que-bao-es-fu-wu-duan-yi-jing-an-zhuang-fen-xi-qi-cha-jian'),
                placement: 'right',
                transfer: true
              },
              style: {
                cursor: 'pointer',
                marginLeft: '6px',
                fontSize: '14px'
              }
            }, [
              h('Icon', {
                props: {
                  type: 'ios-information-circle'
                }
              })
            ])
          ])
        }
      ],
      esExistCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('yuan-biao-zi-duan'),
          key: 'sourceColumn',
          slot: 'sourceColumn'
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('mu-biao-suo-yin-zi-duan'),
          slot: 'sink'
        },
        {
          title: this.$t('lei-xing'),
          slot: 'indexType',
          width: 150
        },
        {
          title: this.$t('fen-ci-qi'),
          slot: 'analyzer',
          width: 150,
          renderHeader: (h) => h('div', [
            h('span', {}, this.$t('fen-ci-qi')),
            h('Tooltip', {
              props: {
                content: this.$t('fei-nei-zhi-fen-xi-qi-qing-que-bao-es-fu-wu-duan-yi-jing-an-zhuang-fen-xi-qi-cha-jian'),
                placement: 'right',
                transfer: true
              },
              style: {
                cursor: 'pointer',
                marginLeft: '6px',
                fontSize: '14px'
              }
            }, [
              h('Icon', {
                props: {
                  type: 'ios-information-circle'
                }
              })
            ])
          ])
        }
      ],
      mqToRdbCleanDataColumn: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('mu-biao-biao-zi-duan'),
          key: 'sinkColumn'
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing'),
          key: 'sinkType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 120
        }
      ]
    };
  },
  computed: {
    getTableColumns() {
      if (DataSourceGroup.mq.indexOf(this.sourceType) > -1) {
        return this.mqToRdbCleanDataColumn;
      }
      if (DataSourceGroup.es.indexOf(this.sinkType) > -1) {
        if (this.table.needAutoCreated) {
          return this.esCleanDataColumn;
        }
        return this.esExistCleanDataColumn;
      }
      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1 || DataSourceGroup.redis.indexOf(this.sinkType) > -1) {
        return this.mqCleanDataColumn;
      }
      if (isMongoDB(this.sourceType)) {
        return this.mongoCleanDataColumn;
      }
      return this.cleanDataColumn;
    },
    dbToCreateClass() {
      return (table, row) => {
        if (this.selectedColumns[table.sourceDb][table.sourceTable]
                    && this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn
                    && this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn !== 'clougence_cloudcanal_filter_after_mapping_column'
                    && !this.hasTheSameColumn(this.selectedColumns[table.sourceDb][table.sourceTable][row.index].sinkColumn,
                      this.taskInfo.sinkColumns[table.sinkDb
                        ? table.sinkDb : table.targetDb] && this.taskInfo.sinkColumns[table.sinkDb
                        ? table.sinkDb : table.targetDb][table.sinkTable ? table.sinkTable : table.targetTable])) {
          return 'db-to-create';
        }
        return '';
      };
    },
    getTableData() {
      return (table) => {
        if (table && table.sourceDb) {
          if (DataSourceGroup.mq.indexOf(this.sourceType) > -1 || isMongoDB(this.sourceType)) {
            return table && this.selectedColumns[table.sinkDb] ? this.selectedColumns[table.sinkDb][table.sinkTable] : [];
          }
          return table ? this.selectedColumns[table.sourceDb][table.sourceTable] : [];
        } if (table && table.sinkDb) {
          if (DataSourceGroup.mq.indexOf(this.sourceType) > -1) {
            return table && this.selectedColumns[table.sinkDb] ? this.selectedColumns[table.sinkDb][table.sinkTable] : [];
          }
        }
      };
    }
  },
  methods: {
    isMongoDB,
    showNeedAutoCreatedSinkColumn(row) {
      const {
        sourceDb, sourceTable, sinkDb, targetDb, sinkTable, targetTable, needAutoCreated
      } = this.table;
      const { index } = row;
      return (this.selectedColumns[sourceDb][sourceTable] && this.selectedColumns[sourceDb][sourceTable][index].sinkColumn)
      && !this.hasTheSameColumn(this.getTargetValue(this.selectedColumns[sourceDb][sourceTable][index].sourceColumn), this.taskInfo.sinkColumns[sinkDb || targetDb][sinkTable || targetTable])
      && this.getTargetValue(this.selectedColumns[sourceDb][sourceTable][index].sourceColumn) === this.selectedColumns[sourceDb][sourceTable][index].sinkColumn
      && needAutoCreated;
    },
    handleOnSelect(table, index, $event) {
      this.selectedIndex = -1;
      this.handleSelected(table, index, $event);
    },
    handleOnSelectCancel(table, index, $event) {
      this.selectedIndex = -1;
      this.handleSelectCancel(table, index, $event);
    },
    handleOnSelectAll(table, index, $event) {
      this.selectedIndex = -1;
      this.handleAllSelected(table, index, $event);
    },
    handleOnSelectAllCancel(table, index, $event) {
      this.selectedIndex = -1;
      this.handleCancelSelectAll(table, index, $event);
    },
    displaySelect(index) {
      this.selectedIndex = index;
    },
    handleSpan(columnData) {
      if (DataSourceGroup.mq.indexOf(this.sinkType) > -1) {
        if (this.table.sourceDb) {
          if (columnData.rowIndex === 0 && columnData.columnIndex === 4) {
            return {
              rowspan: this.table.sourceDb ? this.selectedColumns[this.table.sourceDb][this.table.sourceTable].length : 0,
              colspan: 1
            };
          } if (columnData.rowIndex !== 0 && columnData.columnIndex === 4) {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    },
    handleChangeJsonValue(row, index) {
      this.selectedColumns[this.table.sourceDb][this.table.sourceTable][index].jsonValue = row.jsonValue;
    },
    handleChangeIndex(row, index) {
      console.log(row, index);
      this.selectedColumns[this.table.sourceDb][this.table.sourceTable][index].isIndex = row.isIndex;
    }
  }
};
</script>

<style lang="less" scoped>
._id {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #ccc;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  zoom:.6;
  color: #ccc;
  margin-left: 5px;
}
</style>
