<template>
    <div>
        <Breadcrumb>
            <BreadcrumbItem to="/data/job/list">{{ $t('ren-wu-guan-li') }}</BreadcrumbItem>
            <BreadcrumbItem :to="`/data/job/${jobId}`">{{ $t('ren-wu-xiang-qing') }}</BreadcrumbItem>
            <BreadcrumbItem>{{ $t('ren-wu-jian-kong') }}</BreadcrumbItem>
        </Breadcrumb>
        <div class="container-wrapper">
            <div class="clearfix" style="margin-bottom:10px;position: relative">
                <p style="font-size: 18px;font-weight: 500;display: inline-block;margin-left: 10px;"><a
                        @click="handleJobDetail">{{jobData.dataJobName}} <span v-if="jobData.dataJobDesc">-</span>
                    {{jobData.dataJobDesc}}</a></p>
                <div style="float: right">
                    <!--                    <pd-button @click="handleInterval">{{hasInterval?"取消10s定时刷新":"10s定时刷新"}}</pd-button>-->
                    <Timepicker style="display: inline-block;margin-right: 10px" ref="timepicker" :time="time"></Timepicker>
                    <pd-button :loading="refreshLoading" @click="handleRefresh">
                        <Icon type="md-refresh"/>
                        {{ $t('shua-xin') }}
                    </pd-button>
                </div>
                <div style="float:right;margin-right:20px;line-height: 30px;">
                </div>
                <div style="margin-top: 20px">
                  <Tabs v-model="filterOthers.panelType" @on-click="handleTabChange">
                    <TabPane v-for="(config,index) in this.metricConfigs" :label="config.title" :name="index.toString()" :key="index"></TabPane>
                  </Tabs>
                  <div ref="grid"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '@components/function/monitor/core/core';
import _ from 'lodash';
import $ from 'jquery';
import ReactDom, { render } from 'react-dom';
import React from 'react';
import { EventEmitter } from 'eventemitter3';
import Timepicker from '@components/function/monitor/metric/timepicker';
import Time from '@components/function/monitor/utils/time';
import { Config, PanelConfig } from '@components/function/monitor/config';
// eslint-disable-next-line import/no-cycle
import Grid from '@components/function/monitor/metric/grid';
import '@components/function/monitor/monitor.less';
import { listWorker } from '@services/api/worker';
import { queryJobById } from '@services/api/job';
import { queryTaskMonitorConfig } from '@services/api/monitor';

export default {
  components: {
    Timepicker
  },
  created() {
    const that = this;

    if (this.$route.params.jobId) {
      this.jobId = this.$route.params.jobId;
      this.filters.jobId = this.jobId;
      this.taskId = this.$route.params.taskId;
      this.filters.taskId = this.taskId;
      queryTaskMonitorConfig({
        dataJobId: this.jobId,
        dataTaskId: this.taskId
      }).then((res) => {
        this.metricConfigs = res.data.data;
      });
    }

    setTimeout(() => {
      that.init();
    }, 500);
  },
  mounted() {
    if (this.filters.jobId) {
      queryJobById({ jobId: this.filters.jobId }).then((res) => {
        if (res.data.code === '1') {
          this.jobData = res.data.data;
          // 如果全量已经跑完，默认展示时间为跑全量的时间
          if (this.getDataTask(this.jobData.dataTasks).FULL.dataTaskStatus === 'COMPLETE') {
            const taskPosition = JSON.parse(this.getDataTask(this.jobData.dataTasks).FULL.taskPosition);

            this.time.setTime({
              from: new Date(taskPosition.gmtCreate),
              to: new Date(taskPosition.gmtModified)
            });
            this.$refs.timepicker.$emit('to_refresh');
          }
        }
      });
    }
    this.emitter = new EventEmitter();
    if (!this.filters.jobId) {
      this.filterOthers.panelType = '0';
    }
    this.emitter.on('layout', (layout) => {
      this.panel.options = this.panel.options || {};
      this.panel.options.layout = layout;

      const model = _.cloneDeep(this.panel);

      model.options = JSON.stringify(model.options);
      if (this.filters.jobId) {
        this.init();
        this.handleRender();

        this.emitter.on('refresh', () => {
          this.updateConfigs();
        });
        this.$refs.timepicker.$on('refresh', () => {
          this.handleRender();
        });

        this.time.events.on('changed', () => {
          this.$refs.timepicker.$emit('to_refresh');
        });
      } else {
        this.init();
        this.handleRender();

        this.emitter.on('refresh', () => {
          this.updateConfigs();
        });
        this.$refs.timepicker.$on('refresh', () => {
          this.handleRender();
        });

        this.time.events.on('changed', () => {
          this.$refs.timepicker.$emit('to_refresh');
        });
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
  },
  data() {
    return {
      metricConfigs: [],
      jobData: {},
      taskId: 0,
      refreshLoading: false,
      hasInterval: false,
      inited: false,
      myInterval: '',
      time: new Time(),
      metrics: [],
      metric: null,
      configs: [],
      servers: [],
      dcs: {},
      clusterList: [],
      clusterId: 0,
      workerList: [],
      jobList: [],
      currentDataState: '',
      jobId: 0,
      filters: {
        instance: '',
        jobId: '',
        taskId: ''
      },
      configFile: Config.mysql,
      panelType: '0',
      panel: {},
      filterInstances: [],
      filterOthers: {
        database: '',
        filterInstances: [{
          ip: '127.0.0.1'
        }],
        panelType: '0',
        dbOnInstance: {}
      }
    };
  },
  methods: {
    init() {
      this.inited = false;

      const model = PanelConfig.mysql.panel;

      if (model.options) {
        try {
          model.options = JSON.parse(model.options);
        } catch (e) {
          model.options = _.cloneDeep(model);
        }
      }

      model.options = model.options || {};
      model.options.layout = model.options.layout || [];

      this.panel = model;

      this.updateConfigs();
    },
    updateConfigs() {
      if (this.filterOthers.panelType || this.filterOthers.panelType === 0) {
        const configs = this.metricConfigs[parseInt(this.filterOthers.panelType, 10)].config;

        _.forEach(configs, (c) => {
          try {
            c.options = JSON.parse(c.options);
            // eslint-disable-next-line no-empty
          } catch (e) {
          }

          if (!this.filters.instance && this.filters.jobId) {
            c.options.queries.map((query) => {
              if (query.metricNamePattern.indexOf('sum(') > -1) {
                let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
                const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

                a = a.replace('\'', '');

                query.metricNamePattern = `${a}{job_id="${this.filters.jobId}",task_id="${this.taskId}"}) by${b}`;
              } else if (query.metricNamePattern.indexOf('increase') > -1) {
                let a = query.metricNamePattern.split('[')[0].split('{')[0];

                a = a.replace('\'', '');
                query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
              } else {
                let a = query.metricNamePattern.split('{')[0];

                a = a.replace('\'', '');
                query.metricNamePattern = a;
                query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
              }
              return null;
            });
          }
        });

        render(
          React.createElement(Grid, {
            emitter: this.emitter,
            time: this.time,
            panels: configs,
            width: $(this.$refs.grid).width(),
            panel: this.panel,
            filters: this.filters,
            filterOthers: this.filterOthers,
            resourceType: 'DATA_JOB',
            dataJobId: this.jobId,
            dataTaskId: this.taskId
          }),
          this.$refs.grid
        );

        this.configs = configs;
        this.inited = true;
      }
      // })
    },
    handleRender() {
      if (this.emitter) {
        this.updateConfigs();
        this.emitter.emit('render');
      }
    },
    handleClusterChange() {
      listWorker({ clusterId: this.clusterId }).then((res) => {
        if (res.data.code === '1') {
          this.filterOthers.filterInstances = res.data.data;
        }
      });
    },
    handleJobChange(data) {
      if (data) {
        this.filters.jobId = data.toString();
        this.init();
        this.handleRender();
      }
    },
    handleInterval() {
      const that = this;

      if (this.hasInterval) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.handleRender();
        }, 10000);
      }
    },
    handleRefresh() {
      this.handleRender();
    },
    handleJobDetail() {
      this.$router.push({ path: `/data/job/${this.jobId}` });
    },
    getDataTask(taskList) {
      const dataTasks = {};

      taskList.map((item) => {
        dataTasks[item.dataTaskType] = item;
        return null;
      });
      return dataTasks;
    },
    handleTabChange() {
      ReactDom.unmountComponentAtNode(this.$refs.grid);
      this.emitter = new EventEmitter();
      this.init();
    }
  }
};
</script>
