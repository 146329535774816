import Vue from 'vue';
import VueRouter from 'vue-router';
import System from './system/index';
import Monitor from './monitor/index';
import Alarm from './alarm/index';
import Data from './data/index';
import WebHome from '../views/WebHome';
import PermissionDenied from '../views/PermissionDenied';
import UserException from '../views/UserException';
import ResetProcess from '../components/function/webHome/ResetProcess';
import Home from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: WebHome
  },
  {
    path: '/permission/denied',
    name: '/permission/denied',
    component: PermissionDenied
  },
  {
    path: '/permission/exception',
    name: '/permission/exception',
    component: UserException
  },
  {
    path: '/login/reset',
    name: '/login/reset',
    component: ResetProcess
  },
  {
    path: '/',
    component: Home,
    redirect: '/data/job/list',
    children: [].concat(
      System,
      Monitor,
      Alarm,
      Data,
    )
  }
];

const router = new VueRouter({
  // mode: 'history',
  routes
});

export default router;
