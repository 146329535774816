import { render, staticRenderFns } from "./ResetProcess.vue?vue&type=template&id=4fc4fa8f&scoped=true&"
import script from "./ResetProcess.vue?vue&type=script&lang=js&"
export * from "./ResetProcess.vue?vue&type=script&lang=js&"
import style0 from "./ResetProcess.vue?vue&type=style&index=0&id=4fc4fa8f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc4fa8f",
  null
  
)

export default component.exports