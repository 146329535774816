<template>
  <div class="task-group-container">
    <Breadcrumb>
      <BreadcrumbItem><span style="cursor: pointer" @click="handleGoJobList">{{ $t('ren-wu-guan-li') }}</span></BreadcrumbItem>
      <BreadcrumbItem>{{ $t('datajobinfodatajobname-de-guan-lian-ren-wu', [dataJobInfo.dataJobName]) }}</BreadcrumbItem>
    </Breadcrumb>
    <div style="position: absolute;right: 0;top: -6px">
      <pd-button :loading="loading" type="default" style="margin-right: 6px" @click="handleSearch">
        {{ $t('shua-xin') }}
      </pd-button>
    </div>
    <div style="margin-top:14px">
      <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData" :handleShowGroup="handleShowGroup"
                   :getProgressStatus="getProgressStatus" :handleConfirmEdit="handleConfirmEdit"
                   :handleDetachIncreTask="handleDetachIncreTask" :handleAttachIncreTask="handleAttachIncreTask"
                   :handleStartTimeScheduleJob="handleStartTimeScheduleJob" :handleStopSchedule="handleStopSchedule"
                   :handleResumSchedule="handleResumSchedule" :handleStart="handleStart" :handleDelete="handleDelete"
                   :handleRestart="handleRestart" :handleStartFsm="handleStartFsm" :handleStop="handleStop"
                   :dataJobInfo="dataJobInfo" :handleBatchEdit="handleBatchEdit" :showParent="true"></JobInfoCard>
    </div>
    <div>
      <p class="sub-jobs-title"><span class="warn-point" style="background-color: #333"></span>{{ $t('guan-lian-ren-wu') }} <span
          style="margin-left: 10px;font-weight: 500">{{ dataJobInfo.childJobs ? dataJobInfo.childJobs.length :  $t('0')}}</span>
      </p>
      <Row :gutter="10">
        <Col :span="getFitSpan" v-for="row of dataJobInfo.childJobs" :key="row.id">
          <JobInfoCard :handleShowHistory="handleShowHistory" :loadingData="loadingData"
                       :handleShowGroup="handleShowGroup" :getProgressStatus="getProgressStatus"
                       :handleDetachIncreTask="handleDetachIncreTask" :handleAttachIncreTask="handleAttachIncreTask"
                       :handleConfirmEdit="handleConfirmEdit" :handleStartTimeScheduleJob="handleStartTimeScheduleJob"
                       :handleStopSchedule="handleStopSchedule" :handleResumSchedule="handleResumSchedule"
                       :handleStart="handleStart" :handleDelete="handleDelete" :handleRestart="handleRestart"
                       :handleStartFsm="handleStartFsm" :handleStop="handleStop" :dataJobInfo="row"
                       :handleBatchEdit="handleBatchEdit" :subJob="true"></JobInfoCard>
        </Col>
      </Row>
    </div>
    <div class="page-footer-container">
      <div class="page-footer-batch" v-if="batchEdit">
        <pd-button :disabled="batchEditBtns.indexOf('restart')===-1" type="primary" @click="handleBatchEdit('restart')">
          {{ $t('chong-qi') }}
        </pd-button>
        <pd-button :disabled="batchEditBtns.indexOf('stop')===-1" type="primary" @click="handleBatchEdit('stop')">{{ $t('ting-zhi') }}
        </pd-button>
        <pd-button :disabled="batchEditBtns.indexOf('start')===-1" type="primary" @click="handleBatchEdit('start')">{{ $t('qi-dong') }}
        </pd-button>
        <pd-button :disabled="batchEditBtns.indexOf('fsmStart')===-1" type="primary"
                   @click="handleBatchEdit('fsmStart')">{{ $t('chu-ci-shou-dong-qi-dong') }}
        </pd-button>
      </div>
    </div>
    <DataJobListModal :getJobData="getJobData" :showConfirm="showConfirm" :showScheduleHistory="showScheduleHistory"
                      :showBatchConfirm="showBatchConfirm" :scheduleHistoryColumn="scheduleHistoryColumn"
                      :scheduleHistoryData="scheduleHistoryData" :batchEditList="batchEditList"
                      :handleCancelDelete="handleCancelDelete" :selectedJobRow="selectedJobRow" :batchType="batchType"
                      :checkAllGroup="checkAllGroup" :handleBeginBatchEdit="handleBeginBatchEdit" :handleCloseHistory="handleCloseHistory"
                      :jobId="jobId" :dataJobInfo="dataJobInfo" type="taskGroup"></DataJobListModal>
  </div>
</template>
<script>
import {
  queryJobById
} from '@services/api/job';
import JobInfoCard from '@components/function/dataJob/dataJobList/JobInfoCard';
import DataJobMix from '@components/function/mixin/dataJobListMixin';
import DataJobListModal from '@components/function/dataJob/dataJobList/modal/DataJobListModal';
import DataJobDetaibMix from '@components/function/mixin/dataJobDetailMixin';

export default {
  mixins: [DataJobMix, DataJobDetaibMix],
  components: { JobInfoCard, DataJobListModal },
  created() {
    this.dataJobId = parseInt(this.$route.params.id, 10);
    this.getJobData();
  },
  data() {
    return {
      dataJobId: 0,
      dataJobInfo: {},
      type: 'group'
    };
  },
  computed: {},
  methods: {
    handleSearch() {
      this.getJobData();
    },
    getJobData() {
      this.loading = true;
      queryJobById({ jobId: this.dataJobId }).then((res) => {
        this.loading = false;
        if (res.data.code === '1') {
          this.dataJobInfo = res.data.data;
          if (this.dataJobInfo.childJobs) {
            this.dataJobInfo.childJobs.map((task) => {
              task.dataTasks.map((item) => {
                if (item.dataTaskType === 'INCREMENT' && (task.dataTaskState === 'CATCH_UP' || task.dataTaskState === 'INCRE')) {
                  if (task.dataTaskStatus === 'RUNNING') {
                    if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                      task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                    } else {
                      task.trueDelay = task.dataDelayMs;
                    }
                    item.trueDelay = task.trueDelay;
                  }
                  task.currentStatus = item.dataTaskStatus;
                } else if (item.dataTaskType === task.dataTaskState) {
                  task.currentStatus = item.dataTaskStatus;
                }
                return null;
              });
              return null;
            });
          }
          if (this.dataJobInfo.dataTaskState !== 'COMPLETE') {
            this.dataJobInfo.dataTasks.map((task) => {
              if (task.dataTaskType === this.dataJobInfo.dataTaskState) {
                this.dataJobInfo.currentStatus = task.dataTaskStatus;
              } else if (task.dataTaskType === 'INCREMENT' && this.dataJobInfo.dataTaskState === 'CATCH_UP') {
                if (task.dataTaskStatus === 'RUNNING') {
                  if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                    task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                  } else {
                    task.trueDelay = task.dataDelayMs;
                  }
                  this.dataJobInfo.trueDelay = task.trueDelay;
                }
                this.dataJobInfo.currentStatus = task.dataTaskStatus;
              } else if (task.dataTaskType === 'INCREMENT' && this.dataJobInfo.dataTaskState === 'INCRE') {
                if (task.dataTaskStatus === 'RUNNING') {
                  if (JSON.parse(task.taskPosition).delayMillis >= 60000) {
                    task.trueDelay = JSON.parse(task.taskPosition).delayMillis;
                  } else {
                    task.trueDelay = task.dataDelayMs;
                  }
                  this.dataJobInfo.trueDelay = task.trueDelay;
                }
                this.dataJobInfo.currentStatus = task.dataTaskStatus;
              }
              return null;
            });
          }
        }
      });
    },
    handleGoJobList() {
      this.$router.push({ path: '/data/job/list' });
    },
    handleCloseHistory() {
      this.showScheduleHistory = false;
    }
  }
};
</script>
<style lang="less" scoped>
.sub-jobs-title {
  margin-bottom: 10px;
  background-color: #E4E4E4;
  padding: 4px 16px;
  border-radius: 13px;
  max-width: 140px;
}

.task-group-container {
  position: relative;
}

.page-footer-container {
  width: 100%;
  vertical-align: middle;

  .page-footer-batch {
    background: #FFFFFF;
    height: 60px;
    line-height: 58px;
    padding: 0 16px;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;

    button {
      margin-right: 8px;
    }
  }

  .page-footer-shadow {
    box-shadow: 6px 2px 23px rgba(197, 197, 197, 0.5);
  }
}
</style>
