<template>
  <div class="followed-job-list">
    <Table :columns="columns" :data="followedJobList" size="small" border @on-sort-change="handleTableSort">
      <template slot="instanceId" slot-scope="{ row }">
        <router-link
          :to="`/data/job/${row.dataJobId}${row.parentDataJobId ? `?parentDataJobId=${row.parentDataJobId}` : ''}`"
          style="margin-right: 10px;">
          {{ row.dataJobName }} / {{ row.dataJobDesc }}
        </router-link>
        <Tooltip placement="right" class="dataSource-icon" transfer
                 :content="`${row.sourceDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                           Mapping.aliyunType[row.sourceDsVO.dataSourceType]:
                           row.sourceDsVO.dataSourceType}->${row.targetDsVO.deployType==='ALIBABA_CLOUD_HOSTED'?
                           Mapping.aliyunType[row.targetDsVO.dataSourceType]:row.targetDsVO.dataSourceType}`">
          <data-source-icon class="job-list-datasource job-list-source"
                            :type="row.sourceDsVO.dataSourceType"
                            :instanceType="row.sourceDsVO.deployType"></data-source-icon>

          <data-source-icon class="job-list-datasource job-list-sink"
                            :type="row.targetDsVO.dataSourceType"
                            :instanceType="row.targetDsVO.deployType"></data-source-icon>
        </Tooltip>
      </template>
      <template slot="dataJobType" slot-scope="{ row }">
        {{ Mapping.jobType[row.dataJobType] }}
      </template>
<!--      <template slot="status" slot-scope="{ row }">-->
<!--        <div v-if="Mapping.taskSateToTaskType[row.dataTaskState]">-->
<!--          <div v-if="!getDataTask(row.dataTasks)[Mapping.taskSateToTaskType[row.dataTaskState]]">-->
<!--            <status-icon text="暂停" color="#FFA30E"/>-->
<!--          </div>-->
<!--          <div v-else-if="getDataTask(row.dataTasks)[Mapping.taskSateToTaskType[row.dataTaskState]].dataTaskStatus === 'STOP'">-->
<!--            <status-icon text="暂停" color="#FFA30E"/>-->
<!--          </div>-->
<!--          <div v-else-if="getDataTask(row.dataTasks)[Mapping.taskSateToTaskType[row.dataTaskState]].healthLevel === 'Health'">-->
<!--            <status-icon text="正常" color="#52C41A"/>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <status-icon text="异常" color="#FF1815"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else-if="row.dataTaskState === 'COMPLETE'">-->
<!--          <status-icon text="完成" color="#CCCCCC"/>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <status-icon text="正常" color="#52C41A"/>-->
<!--        </div>-->
<!--      </template>-->
    </Table>
    <Page
      :total="total"
      size="small"
      :current.sync="searchParam.pageNum"
      style="margin-top: 10px;"
      show-sizer
      :page-size="searchParam.pageSize"
      :page-size-opts="[5,10]"
      @on-change="handlePageChange"
      @on-page-size-change="handlePageSizeChange"
    />
  </div>
</template>

<script>
import fecha from 'fecha';
import { followedJob } from '@services/api/job';
import Mapping from '@views/util';
import DataSourceIcon from '@components/function/DataSourceIcon';

export default {
  name: 'FollowedJobList',
  components: { DataSourceIcon },
  data() {
    return {
      Mapping,
      followedJobList: [],
      users: [],
      total: 0,
      searchParam: {
        orderType: 'GMT_CREATE',
        orderAsc: null,
        specifiedUid: '',
        pageNum: 1,
        pageSize: 5
      },
      columns: []
    };
  },
  created() {
    this.getFollowedJobs();
  },
  methods: {
    handleTableSort(data) {
      this.searchParam.orderAsc = (data.order === 'asc');
      this.getFollowedJobs();
    },
    handlePageChange(page) {
      this.searchParam.pageNum = page;
      this.getFollowedJobs();
    },
    handlePageSizeChange(pageSize) {
      this.searchParam.pageSize = pageSize;
      this.getFollowedJobs();
    },
    async getFollowedJobs() {
      const res = await followedJob(this.searchParam);

      if (res.data.success) {
        this.followedJobList = res.data.data.jobVos;
        this.total = res.data.data.allJobCounts;
        const users = [];
        res.data.data.uidUsernameVos
          .forEach((user) => {
            users.push({
              value: user.uid,
              label: user.username
            });
          });
        this.columns = [
          {
            title: this.$t('ren-wu-id-miao-shu'),
            slot: 'instanceId'
          },
          {
            title: this.$t('lei-xing'),
            slot: 'dataJobType',
            width: 200
          },
          {
            title: this.$t('chuang-jian-shi-jian'),
            key: 'gmtCreated',
            sortable: 'custom',
            width: 200,
            render: (h, params) => h('div', {}, fecha.format(new Date(params.row.gmtCreated), 'YYYY-MM-DD HH:mm:ss'))
          },
          {
            title: this.$t('chuang-jian-ren'),
            key: 'username',
            width: 150,
            filters: users,
            filterMultiple: false,
            filterRemote: (value) => {
              this.searchParam.specifiedUid = value.length ? value[0] : '';
              this.searchParam.pageNum = 1;
              this.getFollowedJobs();
            }
          }
        ];
      }
    },
    getDataTask(taskList) {
      const dataTasks = {};

      taskList.forEach((item) => {
        dataTasks[item.dataTaskType] = item;
      });
      return dataTasks;
    }
  }
};
</script>

<style scoped>

</style>
