<template>
  <div>
    <div class="table-filter-nav-item-arrow"></div>
    <div class="table-filter-nav-item-db">
      <p><i class="iconfont iconshujuku"></i>{{ db.sourceDb }}<span v-if="DataSourceGroup.hasSchema.includes(sourceType)">.{{ db.sourceSchema }}</span></p>
      <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow">
      <p :class="db.needAutoCreated||db.schemaAutoCreate?'db-to-create':''">
        <i
          :class="`iconfont ${db.needAutoCreated||db.schemaAutoCreate?'icondaijianKU':'iconshujuku'}`"></i>
        <span>{{ db.sinkDb }}</span>
        <span v-if="DataSourceGroup.hasSchema.includes(sinkType)">.{{ db.targetSchema }}</span></p>
      <p class="chosed-count">{{ $t('yi-xuan-zhong') }}
        <span :class="getSelectedInfo(db.sourceDb).selectedCount<(selectedTables[db.sourceDb]?
              selectedTables[db.sourceDb].length:0)?'warn-count':''"
              style="text-align: center;display: inline-block;">
                {{ getSelectedInfo(db.sourceDb).selectedCount }}</span>
        {{ $t('selectedtablesdbsourcedb-selectedtablesdbsourcedblength-0-zhang-biao', [selectedTables[db.sourceDb] ? selectedTables[db.sourceDb].length : 0]) }}
      </p>
      <a v-if="DataSourceGroup.ddlFilter.includes(taskInfo.sourceType) && !JSON.parse(taskInfo.ddl) && ((this.taskInfo.type === 'MIGRATION'
        && this.taskInfo.mode.synchronize)||this.taskInfo.type === 'SYNC')" @click="handleShowFilterDDL" style="position: absolute; right: 10px;top: 60px">{{ $t('action-guo-lv') }}</a>
    </div>
    <Modal
      v-model="showDdlFilter"
      :title="$t('action-guo-lv')"
      :closable="false"
      footer-hide
    >
      <div>
        <p style="margin-bottom: 10px">{{ $t('gai-ku-xu-yao-tong-bu-yi-xia-action') }}</p>
        <CheckboxGroup  v-model="ddlAction">
          <Checkbox label="INSERT">
            INSERT
          </Checkbox>
          <Checkbox label="UPDATE">
            UPDATE
          </Checkbox>
          <Checkbox label="DELETE">
            DELETE
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="CREATE">
            CREATE
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="ALTER">
            ALTER
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="RENAME">
            RENAME
          </Checkbox>
          <Checkbox v-if="!JSON.parse(taskInfo.ddl) && DataSourceGroup.ddlFilter.includes(taskInfo.sourceType)" label="TRUNCATE">
            TRUNCATE
          </Checkbox>
        </CheckboxGroup>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmFilterDDL">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import DataSourceGroup from '../../../../views/dataSourceGroup';

export default {
  props: {
    db: Object,
    getSelectedInfo: Function,
    selectedTables: Object,
    sourceType: String,
    sinkType: String,
    taskInfo: Object
  },
  data() {
    return {
      showDdlFilter: false,
      ddlAction: [],
      DataSourceGroup
    };
  },
  methods: {
    handleShowFilterDDL() {
      this.showDdlFilter = true;
      this.ddlAction = this.db.actions;
    },
    handleCancel() {
      this.showDdlFilter = false;
    },
    handleConfirmFilterDDL() {
      this.showDdlFilter = false;
      this.handleDbActions(this.db, this.ddlAction);
    },
    handleDbActions(db, actions) {
      db.actions = actions;
      db.selectedTables.forEach((table) => {
        if (table.selected) {
          table.action = actions;
        }
      });
    }
  }
};
</script>
