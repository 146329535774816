<template>
  <div>
    <div @click="handleGetColumnByTable(table,index)"
         :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
         v-for="(table,index) in showTableList" :key="index">
      <div class="table-filter-nav-item-db">
        <i class="iconfont iconjiantou"></i>
        <img class="table-filter-nav-item-arrow" src="~@asset/jiantou01.png" alt="arrow">
        <p><i class="iconfont iconbiao"></i>{{ table.sourceDb }}.{{ table.sourceTable }}</p>
        <p style="word-break: break-all" :class="table.needAutoCreated?'db-to-create':''">
          <i :class="`iconfont ${table.needAutoCreated?'iconbiao':'iconbiao'}`"></i>
          <span><span
            v-if="table.sinkDb">{{ table.sinkDb }}.</span>{{ table.sinkTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.sinkTable }}</span>
          <span v-for="field in table.suffixFields||[]"
                :key="field"><span>:{</span>{{ field }}<span>}</span></span>
        </p>
        <div class="chosed-count" style="display: flex;justify-content: space-between;padding-right: 20px;">
          <div>
            {{ $t('yi-xuan-zhong') }}
            <span :class="getSelectedInfo(table.sourceDb,table.sourceTable).selectedCount<
                  (selectedColumns[table.sourceDb][table.sourceTable]?selectedColumns[table.sourceDb][table.sourceTable].length:0)?'warn-count':''"
                  style="text-align: center;display: inline-block;">
                    {{ getSelectedInfo(table.sourceDb, table.sourceTable).selectedCount }}</span>
            {{ $t('selectedcolumnstablesourcedbtablesourcetable-selectedcolumnstablesourcedbtablesourcetablelength-0-ge-lie', [selectedColumns[table.sourceDb][table.sourceTable] ? selectedColumns[table.sourceDb][table.sourceTable].length : 0]) }}
          </div>
          <Dropdown transfer trigger="click">
            <a href="javascript:void(0)">
              {{ $t('cao-zuo') }}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem v-if="DataSourceGroup.redis.includes(sinkType)">
                <a style="color: #333333"
                   @click="handleSetRedisKey(table)">
                  {{ $t('she-zhi-redis-key') }}</a>
              </DropdownItem>
              <DropdownItem>
                <a style="color: #333333;display: block"
                   :class="getWhereCondition(table)?'has-where-table':''"
                   @click="handleFilter(table)">
                  {{ $t('shu-ju-guo-lv-tiao-jian') }}</a>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '@views/dataSourceGroup';

export default {
  props: {
    handleGetColumnByTable: Function,
    selectedIndex: Number,
    showTableList: Array,
    getSelectedInfo: Function,
    getWhereCondition: Function,
    handleShowVirtualColumn: Function,
    handleFilter: Function,
    selectedColumns: Object,
    sourceType: String,
    sinkType: String,
    handleSetDrdsPartition: Function,
    uniqList: Object,
    handleConfirmChangePk: Function,
    handleChangePk: Function,
    theType: String,
    handleSetRedisKey: Function
  },
  data() {
    return {
      showChangePk: false,
      selectedTable: {},
      DataSourceGroup
    };
  }
};
</script>
