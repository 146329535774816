<template>
  <div>
    <TaskInfoHeader :taskInfo="taskInfo" :structMigration="structMigration" v-if="taskInfo.processType === 'create'"></TaskInfoHeader>
    <Tabs v-model="tab" @on-click="handleTabClick" v-else>
      <TabPane name="new" :label="$t('xin-zeng-de-ku-biao')" v-if="taskInfo.taskInfoNewData.length"></TabPane>
      <TabPane name="reduce" :label="$t('ti-chu-de-ku-biao')" v-if="taskInfo.taskInfoReduceData.length"></TabPane>
      <TabPane name="update" :label="$t('geng-xin-de-ku-biao')" v-if="taskInfo.taskInfoUpdateData.length"></TabPane>
    </Tabs>
    <div>
      <div v-for="(db,index) in taskInfoData" :key="index">
        <div class="task-db-mapping-title">
          <i class="iconfont iconshujuku"></i>{{ db.sourceDb }}<span
          v-if="DataSourceGroup.hasSchema.indexOf(taskInfo.sourceType)>-1">.{{
            db.sourceSchema
          }}</span>
          <span class="mapping-host">（{{taskInfo.sourceHostType === 'PRIVATE' ? taskInfo.sourcePrivateHost : taskInfo.sourcePublicHost}}）</span>
          <Icon type="md-arrow-forward"/>
          <span :class="db.needAutoCreated||db.schemaAutoCreate?'db-to-create':''"><i
            :class="`iconfont ${db.needAutoCreated?'icondaijianKU':'iconshujuku'}`"></i><span><span
            v-if="getDbKey(db)">{{ getDbKey(db) }}</span> <span
            v-if="DataSourceGroup.hasSchema.indexOf(taskInfo.sinkType)>-1">.{{
              db.targetSchema
            }}</span><span
            v-if="getDbKey(db)">({{
              taskInfo.targetHostType === 'PRIVATE' ? taskInfo.sinkPrivateHost : taskInfo.sinkPublicHost
            }})</span>
            <span v-if="!getDbKey(db)">{{ taskInfo.sinkInstance }}</span></span></span><i
          v-if="getDbKey(db)&&!sameItem(getDbKey(db),db.sourceDb)" class="iconfont iconyingshe"></i>
          <span class="task-db-mapping-info">
                                            {{ $t('yi-xuan-zhong') }}<span
            :class="getSelectedInfo(db.selectedTables).count<db.selectedTables.length?'warn-count':''">
            {{ getSelectedInfo(db.selectedTables).count }}</span>{{ $t('dbselectedtableslength-zhang-biao-dai-chuang-jian', [db.selectedTables.length]) }}<span
            :class="getSelectedInfo(db.selectedTables).needToAutoCreated>0?'warn-count':''">{{
              getSelectedInfo(db.selectedTables).needToAutoCreated
            }}</span>{{ $t('cun-zai-ying-she') }}<span
            :class="getSelectedInfo(db.selectedTables).mappingCount>0?'warn-count':''">{{
              getSelectedInfo(db.selectedTables).mappingCount
            }}</span>
                                        </span>
        </div>
        <div class="task-db-mapping-content">
          <Collapse v-model="panelNum"
                    v-if="!taskInfo.showLoading[getDbKey(db)]&&dbPage[getDbKey(db)]&&dbPage[getDbKey(db)].showTableList">
            <Panel :name="`${db.sourceDb+i}`" v-for="(table,i) in getShowTableList(db)"
                   :key="db.sourceDb+i">
                                                <span>
                                                  <i :class="`iconfont ${taskInfo.sourceType==='RocketMQ'?'icontopic':'iconbiao'}`"></i>
                                                  {{table.sourceTable }}
                                                  <span>
                                                    <Icon type="md-arrow-forward"/>
                                                  </span>
                                                  <span
                                                    :class="table.needAutoCreated?'db-to-create':''"
                                                  >
                                                    <i :class="`iconfont ${taskInfo.sinkType==='RocketMQ'?'icontopic':table.needAutoCreated?'icondaijianBIAO':'iconbiao'}`"></i>
                                                    <span>
                                                      {{table.sinkTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.sinkTable }}
                                                    </span>
                                                  </span>
                                                  <span v-if="commonSelectColumns[getDbKey(db)]&&commonSelectColumns[getDbKey(db)][table.sinkTable]
                                                  &&commonSelectColumns[getDbKey(db)][table.sinkTable].length">
                                                    <i v-if="table.sinkTable&&!sameItem(table.sinkTable,table.sourceTable)" class="iconfont iconyingshe"></i>
                                                    <span
                                                      style="margin-left: 5px;position: absolute;right: 16px;top: 0">
                                                        {{ $t('yi-xuan-zhong') }}<span
                                                      :class="getSelectColumns(db.selectedColumns,table).count<
                                                        db.selectedColumns[table.sinkTable]?db.selectedColumns[table.sinkTable].length:0?
                                                        'warn-count':''">{{
                                                        getSelectColumns(db.selectedColumns, table).count
                                                      }}</span>
                                                      {{ $t('dbselectedcolumnstablesinktable-dbselectedcolumnstablesinktablelength-0-ge-lie-dai-chuang-jian', [`${db.selectedColumns[table.sinkTable] ? db.selectedColumns[table.sinkTable].length: 0}${$t('ge-lie-dai-chuang-jian')}`]) }}
                                                        <span
                                                          :class="getSelectColumns(db.selectedColumns,table).needToAutoCreated>0?'warn-count':''">
                                                          {{
                                                            getSelectColumns(db.selectedColumns, table).needToAutoCreated
                                                          }}</span>
                                                        {{ $t('cun-zai-ying-she') }}
                                                        <span
                                                          :class="getSelectColumns(db.selectedColumns,table).mappingCount>0?'warn-count':''">
                                                          {{
                                                            getSelectColumns(db.selectedColumns, table).mappingCount
                                                          }}</span>
                                                        <span
                                                          v-if="DataSourceGroup.mq.indexOf(taskInfo.sinkType)>-1&&table.partitions">{{ $t('fen-qu-shu-0') }} <span
                                                          class="warn-count">{{
                                                            table.partitions
                                                          }}</span></span>
                                                        <span
                                                          v-if="DataSourceGroup.es.indexOf(taskInfo.sinkType)>-1">{{ $t('fen-pian-shu-0') }} <span
                                                          class="warn-count">{{
                                                            table.shards
                                                          }}</span>{{ $t('fen-pian-fu-ben-shu-0') }}<span
                                                          class="warn-count">{{
                                                            table.replication
                                                          }}</span></span>
                                                        <span style="margin-left: 5px"
                                                              v-if="!(taskInfo.type==='CHECK'||taskInfo.type==='STRUCT_MIGRATION'||taskInfo.type==='MIGRATION'&&!taskInfo.mode.synchronize)">
                                                            <span class="job-info-action"
                                                                  :style="getTableActionStyle(table.action,'i')">I</span>
                                                            <span class="job-info-action"
                                                                  :style="getTableActionStyle(table.action,'u')">U</span>
                                                            <span class="job-info-action"
                                                                  :style="getTableActionStyle(table.action,'d')">D</span>
                                                            <a style="margin-left: 10px"
                                                               :disabled="!table.whereCondition"
                                                               @click.stop="handleShowWhere(table,db.selectedColumns)">{{
                                                                !table.whereCondition ? $t('wei-she-zhi-where-tiao-jian') : $t('yi-she-zhi-where-tiao-jian')
                                                              }}</a>
                                                            <a style="margin-left: 10px"
                                                               :disabled="!table.partitionData"
                                                               v-if="taskInfo.sinkType === 'Hive'"
                                                               @click.stop="handleShowPartition(table,db.selectedColumns)">{{
                                                                !table.partitionData ? $t('wei-she-zhi-fen-qu-jian') : $t('yi-she-zhi-fen-qu-jian')
                                                              }}</a>
                                                        </span>
                                                    </span></span>
                                                </span>
              <div slot="content">
                <Table border stripe size="small" :columns="getTableColumns"
                       :data="commonSelectColumns[getDbKey(db)][table.sinkTable]"></Table>
              </div>
            </Panel>
          </Collapse>
          <Page v-if="dbPage[getDbKey(db)]" style="margin-top: 10px;text-align: center"
                :total="dbPage[getDbKey(db)].total" show-total
                @on-change="handlePageChange(getDbKey(db),$event)"/>
        </div>
      </div>
    </div>
    <Modal v-model="showWhereCondition" :title="$t('where-tiao-jian')">
      <div style="word-break: break-all">
        {{ whereCondition }}
      </div>
    </Modal>
    <Modal v-model="showPartition" :title="$t('fen-qu-she-zhi')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80" v-if="partition.partition[0]">
          <FormItem :label="$t('fen-qu-jian')">
            {{ partition.partition[0].key }}
          </FormItem>
          <FormItem :label="$t('fen-qu-fang-fa')">
            {{ partition.partition[0].func }}
          </FormItem>
          <FormItem :label="$t('wen-jian-ge-shi')">
            {{ partition.fileFormat }}
          </FormItem>
          <FormItem :label="$t('ya-suo-fang-shi')">
            {{ partition.compressType }}
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import { isKafka, isMongoDB } from '@utils';
import { editJobInitialSyncSwitch } from '@services/api/constant';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import TaskInfoHeader from './taskInfo/TaskInfoHeader';

export default {
  props: {
    taskInfo: Object
  },
  components: { TaskInfoHeader },
  created() {
    if ((isMongoDB(this.taskInfo.sourceType) && isMongoDB(this.taskInfo.sinkType)) || (isKafka(this.taskInfo.sourceType) && isKafka(this.taskInfo.sinkType))) {
      this.generateTaskInfoData();
    }
    if (this.taskInfo.processType === 'edit') {
      this.editJobInitialSyncSwitch();
      this.taskInfoNewData = this.taskInfo.taskInfoNewData;
      this.taskInfoReduceData = this.taskInfo.taskInfoReduceData;
      this.taskInfoUpdateData = this.taskInfo.taskInfoUpdateData;

      if (this.taskInfo.taskInfoNewData.length) {
        this.tab = 'new';
        this.taskInfoData = this.taskInfoNewData;
      }

      if (this.taskInfo.taskInfoReduceData.length) {
        this.tab = 'reduce';
        this.taskInfoData = this.taskInfoReduceData;
      }

      if (this.taskInfo.taskInfoUpdateData.length) {
        this.tab = 'update';
        this.taskInfoData = this.taskInfoUpdateData;
      }

      this.generateDbPageData();
    } else {
      this.taskInfoData = this.taskInfo.dbMap;
      this.taskInfo.dbMap.forEach((item) => {
        this.taskInfo.showLoading[this.getDbKey(item)] = true;
        this.initData(item);

        const taskInfo = this.taskInfo;

        taskInfo.dbMap.forEach((db) => {
          if (db.needAutoCreated) {
            this.structMigration = true;
          }
          db.selectedTables.forEach((table) => {
            if (table.selected) {
              if (table.needAutoCreated) {
                this.structMigration = true;
              }
              if (!db.selectedColumns) {
                db.selectedColumns = [];
              }
              if (db.selectedColumns[table.sinkTable]) {
                db.selectedColumns[table.sinkTable].forEach((column) => {
                  if (column.selected) {
                    if (column.needAutoCreated) {
                      this.structMigration = true;
                    }
                  }
                });
              }
            }
          });
        });
      });
    }
  },
  data() {
    return {
      taskInfoData: [],
      tab: 'new',
      DataSourceGroup,
      showWhereCondition: false,
      showPartition: false,
      structMigration: false,
      whereCondition: '',
      selectedColumns: {},
      selectedTables: {},
      panelNum: '',
      dbPage: {},
      partition: { partition: [] },
      filterTable: {},
      tableColumn: [
        {
          title: this.$t('yuan-zi-duan'),
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('mu-biao-zi-duan'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sinkType',
          width: 160
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 120
        }
      ],
      mqTableColumn: [
        {
          title: this.$t('yuan-zi-duan'),
          key: 'sourceColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sourceColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sourceColumn);
          }
        },
        {
          title: this.$t('yuan-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sourceType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sourceIsNullable',
          width: 120
        },
        {
          title: this.$t('xiao-xi-lie-ming-cheng'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        }
      ],
      tableMqColumn: [
        {
          title: this.$t('xiao-xi-lie-ming-cheng'),
          key: 'sinkColumn'
        },
        {
          title: this.$t('mu-biao-zi-duan'),
          key: 'sinkColumn',
          render: (h, params) => {
            if (params.row.isPk) {
              return h('div', [
                h('span', {}, params.row.sinkColumn),
                h('Icon', {
                  props: { type: 'ios-key' },
                  style: {
                    marginLeft: '5px',
                    color: '#ff9900'
                  }
                })
              ]);
            }
            return h('div', {}, params.row.sinkColumn);
          }
        },
        {
          title: this.$t('mu-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sinkType',
          width: 180
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 120
        }
      ],
      esTableColumn: [
        {
          title: this.$t('mu-biao-suo-yin-zi-duan'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        },
        {
          title: this.$t('lei-xing'),
          key: 'sinkType',
          width: 120
        },
        {
          title: this.$t('shi-fou-suo-yin'),
          key: 'isIndex',
          width: 120,
          render: (h, params) => h('div', {}, params.row.isIndex ? this.$t('shi') : '')
        },
        {
          title: this.$t('fen-ci-qi'),
          key: 'analyzer',
          width: 120
        }
      ],
      mongoTableColumn: [
        {
          title: this.$t('mu-biao-zi-duan'),
          key: 'sinkColumn',
          render: (h, param) => {
            if (param.row.needAutoCreated) {
              return h('div', {
                style: {
                  color: '#FF6E0D'
                }
              }, param.row.sinkColumn);
            }
            if (param.row.sinkColumn && !this.sameItem(param.row.sinkColumn, param.row.sourceColumn)) {
              return h('div', {
                style: 'color:#0BB9F8;!important;'
              }, this.$t('paramrowsinkcolumn-lie-cun-zai-ying-she', [param.row.sinkColumn]));
            }
            return h('div', {}, param.row.sinkColumn);
          }
        },
        {
          title: this.$t('mu-biao-biao-zi-duan-lei-xing-ji-chang-du'),
          key: 'sinkType',
          width: 160
        },
        {
          title: this.$t('neng-fou-wei-null'),
          key: 'sinkIsNullable',
          width: 120
        }
      ],
      commonSelectColumns: {}
    };
  },
  computed: {
    getShowTableList() {
      return (db) => {
        const list = [];

        this.dbPage[this.getDbKey(db)].showTableList.forEach((table) => {
          if (table.selected) {
            list.push(table);
          }
        });
        return list;
      };
    },
    getTableActionStyle() {
      return (action, type) => {
        let formatAction = 'color:#c5c8ce';

        action.forEach((item) => {
          if (item === 'INSERT' && type === 'i') {
            formatAction = 'color:#19be6b';
          } else if (item === 'UPDATE' && type === 'u') {
            formatAction = 'color:#19be6b';
          } else if (item === 'DELETE' && type === 'd') {
            formatAction = 'color:#19be6b';
          }
        });
        return formatAction;
      };
    },
    getSelectColumns() {
      return (obj, table) => {
        let count = 0;
        let mappingCount = 0;
        let
          needToAutoCreated = 0;

        if (obj) {
          Object.keys(obj)
            .forEach((item) => {
              if (item === table.sinkTable) {
                obj[item].forEach((i) => {
                  if (i.selected) {
                    count++;
                    if (i.needAutoCreated) {
                      needToAutoCreated++;
                    }

                    if (i.sinkColumn && i.sourceColumn !== i.sinkColumn) {
                      mappingCount++;
                    }
                  }
                });
              }
            });
        }
        return {
          count,
          needToAutoCreated,
          mappingCount
        };
      };
    },
    getSelectedInfo() {
      return (list) => {
        let count = 0;
        let mappingCount = 0;
        let
          needToAutoCreated = 0;

        if (list) {
          list.forEach((item) => {
            if (item.selected) {
              count++;
              if (item.needAutoCreated) {
                needToAutoCreated++;
              }
              if (this.taskInfo.targetCaseSensitive === 'false') {
                if (item.sinkTable && item.sourceTable.toUpperCase() !== item.sinkTable.toUpperCase()) {
                  mappingCount++;
                }
              } else if (this.taskInfo.commonRule === 'UpperCase') {
                if (item.sinkTable && item.sourceTable.toUpperCase() !== item.sinkTable.toUpperCase()) {
                  mappingCount++;
                }
              } else if (this.taskInfo.commonRule === 'LowerCase') {
                if (item.sinkTable && item.sourceTable.toLowerCase() !== item.sinkTable.toLowerCase()) {
                  mappingCount++;
                }
              } else if (item.sinkTable && item.sourceTable !== item.sinkTable) {
                mappingCount++;
              }
            }
          });
        }
        return {
          count,
          needToAutoCreated,
          mappingCount
        };
      };
    },
    sameItem() {
      return (sink, source) => {
        let hasSame = false;

        if (this.taskInfo.targetCaseSensitive === 'false') {
          if (sink.toUpperCase() === source.toUpperCase()) {
            hasSame = true;
          }
        } else if (this.taskInfo.commonRule === 'UpperCase') {
          if (sink.toUpperCase() === source.toUpperCase()) {
            hasSame = true;
          }
        } else if (this.taskInfo.commonRule === 'LowerCase') {
          if (sink.toLowerCase() === source.toLowerCase()) {
            hasSame = true;
          }
        } else if (sink === source) {
          hasSame = true;
        }
        return hasSame;
      };
    },
    getTableColumns() {
      const { sinkType, sourceType } = this.taskInfo;
      if (DataSourceGroup.es.indexOf(sinkType) > -1) {
        return this.esTableColumn;
      }
      if (DataSourceGroup.mq.indexOf(sinkType) > -1) {
        return this.mqTableColumn;
      }
      if (DataSourceGroup.mq.indexOf(sourceType) > -1) {
        return this.tableMqColumn;
      }
      if (isMongoDB(sourceType)) {
        return this.mongoTableColumn;
      }
      return this.tableColumn;
    }
  },
  methods: {
    generateTaskInfoData() {
      this.taskInfo.taskInfoNewData = [];
      this.taskInfo.taskInfoUpdateData = [];
      this.taskInfo.taskInfoReduceData = [];
      Object.keys(this.taskInfo.reduceData)
        .forEach((dbName) => {
          const db = this.taskInfo.reduceData[dbName];
          // console.log(dbName, db);
          let dbData = {};
          // console.log(this.taskInfo.reduceData[db.sourceDb], this.taskInfo.reduceData, db.sourceDb);
          if (this.taskInfo.reduceData[db.sourceDb]) {
            dbData = {
              ...db,
              selectedTables: []
            };
            Object.values(db.selectedTables)
              .forEach((table) => {
                dbData.selectedTables.push(table);
              });
            // dbData.selectedTables = db.selectedTables.filter((table) => this.taskInfo.reduceData[db.sourceDb][table.sourceTable]);
            this.taskInfo.taskInfoReduceData.push(dbData);
          }
        });

      this.taskInfo.dbMap.forEach((db) => {
        let dbData = {};
        if (this.taskInfo.newData[db.sourceDb]) {
          dbData = { ...db };
          dbData.selectedTables = db.selectedTables.filter((table) => this.taskInfo.newData[db.sourceDb].selectedTables[table.sourceTable]);
          this.taskInfo.taskInfoNewData.push(dbData);
        }
      });

      const dbMap = _.cloneDeep(this.taskInfo.dbMap);

      this.taskInfo.taskInfoUpdateData = dbMap.filter((db) => {
        if (db.new) {
          return false;
        }
        db.selectedTables = db.selectedTables.filter((table) => {
          if (!table.hasInJob && table._checked) {
            return false;
          }
          let edit = false;
          if (table.edit || (isKafka(this.taskInfo.sinkType) && !isKafka(this.taskInfo.sourceType) && table.hasInJob && table.originalPartitions !== table.partitions)
            || (table.hasInJob && table.whereCondition !== table.originalWhereCondition) || (table.hasInJob && table.targetWhereCondition !== table.originalTargetWhereCondition)
            || (DataSourceGroup.redis.includes(this.taskInfo.sinkType) && !_.isEqual(table.suffixFields, table.originalSuffixFields))) {
            db.edit = true;
            edit = true;
          }

          const tableName = table.sourceTable;
          if (db.selectedColumns && db.selectedColumns[tableName] && !db.new) {
            db.selectedColumns[tableName].forEach((column) => {
              if (this.isColumnEdited(column) && !isMongoDB(this.taskInfo.sourceType)) {
                edit = true;
                column.edit = true;
                db.edit = true;
              }
            });
          }
          return edit;
        });

        return db.edit;
      });

      return this.taskInfo.taskInfoNewData.length || this.taskInfo.taskInfoUpdateData.length || this.taskInfo.taskInfoReduceData.length;
    },
    getDbKey(item) {
      return isMongoDB(this.taskInfo.sourceType) ? item.sourceDb : item.sinkDb;
    },
    editJobInitialSyncSwitch() {
      editJobInitialSyncSwitch({
        srcDsType: this.taskInfo.sourceType,
        dstDsType: this.taskInfo.sinkType
      }).then((response) => {
        if (response.data.code === '1') {
          this.taskInfo.showChooseInitialSync = response.data.data;
        }
      });
    },
    handleTabClick(name) {
      switch (name) {
        case 'new':
          this.taskInfoData = this.taskInfoNewData;
          break;
        case 'update':
          this.taskInfoData = this.taskInfoUpdateData;
          break;
        case 'reduce':
          this.taskInfoData = this.taskInfoReduceData;
          break;
        default:
          break;
      }
      // this.taskInfoData = name === 'new' ? this.taskInfoNewData : this.taskInfoReduceData;
      this.generateDbPageData();
    },
    generateDbPageData() {
      this.dbPage = {};
      this.commonSelectColumns = {};
      this.taskInfoData.forEach((item) => {
        this.taskInfo.showLoading[item.sourceDb] = true;

        this.initData(item);

        this.taskInfoData.forEach((db) => {
          if (db.needAutoCreated) {
            this.structMigration = true;
          }
          db.selectedTables.forEach((table) => {
            if (table.selected) {
              if (table.needAutoCreated) {
                this.structMigration = true;
              }
              if (db.selectedColumns && db.selectedColumns[table.sourceTable]) {
                db.selectedColumns[table.sourceTable].forEach((column) => {
                  if (column.selected) {
                    if (column.needAutoCreated) {
                      this.structMigration = true;
                    }
                  }
                });
              }
            }
          });
        });
      });
    },
    handlePageChange(db, page) {
      this.dbPage[db].page = page;
      this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
      this.dbPage = { ...this.dbPage };
    },
    handleGetSelectedColumns(dbName) {
      const commonSelectEdColumns = {};

      if (this.selectedColumns[dbName]) {
        Object.keys(this.selectedColumns[dbName])
          .forEach((item) => {
            if (this.selectedColumns[dbName][item] instanceof Array) {
              this.selectedColumns[dbName][item].forEach((column, i) => {
                if (commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb]) {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                } else {
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb] = {};
                  commonSelectEdColumns[this.selectedColumns[dbName][item][i].sourceDb][this.selectedColumns[dbName][item][i].sourceTable] = this.selectedColumns[dbName][item];
                }
              });
            }
          });
      }
      this.taskInfo.dbMap.forEach((item) => {
        if (commonSelectEdColumns[this.getDbKey(item)]) {
          item.selectedColumns = commonSelectEdColumns[this.getDbKey(item)];
        }
      });
    },
    initData(item) {
      let total = 0;
      const that = this;
      const selectedTables = [];

      if (item.selectedTables) {
        item.selectedTables.forEach((selectedTable) => {
          if (selectedTable.selected) {
            total++;
            selectedTables.push(selectedTable);
          }
        });
      }
      const dbKey = this.getDbKey(item);
      that.dbPage[dbKey] = {
        total,
        page: 1,
        pageSize: 10,
        showTableList: selectedTables.slice(0, 10),
        filteredTableList: _.cloneDeep(selectedTables),
        tableList: selectedTables
      };
      that.commonSelectColumns[dbKey] = {};

      if (item.selectedColumns) {
        Object.keys(item.selectedColumns)
          .forEach((key) => {
            if (!that.commonSelectColumns[dbKey][key]) {
              that.commonSelectColumns[dbKey][key] = [];
            }
            const trueSelectedColumns = [];

            item.selectedColumns[key].forEach((column) => {
              if (column.selected) {
                trueSelectedColumns.push(column);
              }
            });
            that.commonSelectColumns[dbKey][key] = trueSelectedColumns;
          });
      }

      that.dbPage = { ...that.dbPage };
      that.taskInfo.showLoading[this.getDbKey(item)] = false;
    },
    handleShowWhere(table, columns) {
      this.showWhereCondition = true;
      this.whereCondition = `select ${this.getColumns(table.sinkdb, table.sinkTable, columns)} from ${table.sinkTable} where ${table.whereCondition}`;
    },
    handleShowPartition(table) {
      this.showPartition = true;
      this.partition = table.partitionData;
    },
    getColumns(db, table, column) {
      let columns = '';

      column[table].forEach((item, index) => {
        if (index !== column[table].length - 1) {
          columns += `${item.sourceColumn},`;
        } else {
          columns += item.sourceColumn;
        }
      });
      return columns;
    },
    handleTableFilter(db) {
      if (this.filterTable.filterTableName) {
        this.dbPage[db].filteredTableList = [];
        this.dbPage[db].tableList.forEach((item) => {
          if (item.sourceTable.indexOf(this.filterTable.filterTableName) > -1) {
            this.dbPage[db].filteredTableList.push(item);
          }
        });
        this.dbPage[db].total = this.dbPage[db].filteredTableList.length;
        this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
        this.dbPage = { ...this.dbPage };
      } else {
        this.dbPage[db].filteredTableList = _.cloneDeep(this.dbPage[db].tableList);
        this.dbPage[db].total = this.dbPage[db].filteredTableList.length;
        this.dbPage[db].showTableList = this.dbPage[db].filteredTableList.slice(this.dbPage[db].pageSize * (this.dbPage[db].page - 1), this.dbPage[db].pageSize * this.dbPage[db].page);
        this.dbPage = { ...this.dbPage };
      }
    }
  }
};
</script>
<style lang="less" scoped>
.task-info-db-map {
  .ivu-card-head {
    padding: 14px 16px 14px 0;
  }
}

.job-info-action {
  display: inline-block;
  width: 10px;
  font-weight: bold;
  text-align: center;
  color: #19be6b;
}

.task-db-mapping-title {
  background-color: #CCEFFF;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #DADADA;
  padding: 0 16px;

  .task-db-mapping-info {
    float: right;
  }
}

.task-db-mapping-content {
  padding: 10px;
}

.task-db-mapping-title .iconfont, .task-db-mapping-content .iconfont {
  margin-right: 10px;
}

.iconyingshe {
  margin-left: 6px;
  color: #0BB9F8;
}
</style>
