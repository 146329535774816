<template>
  <div class="virtual-column-container" v-if="virtualColumnData.length>0">
    <p class="title">
      {{ $t('xu-ni-lie') }}
    </p>
    <AddVirtualColumn :handleDeleteVirtual="handleDeleteVirtual" :selectedTable="selectedTable"
                      :virtualList="virtualColumnData"></AddVirtualColumn>
  </div>
</template>
<script>
import AddVirtualColumn from './AddVirtualColumn';

export default {
  components: { AddVirtualColumn },
  props: {
    selectedTable: Object,
    virtualColumnData: Array,
    handleDeleteVirtual: Function
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.virtual-column-container {
  margin-left: 400px;

  .title {
    font-size: 14px;
    font-weight: 500;
    font-family: PingFangSC-Medium, serif;
    padding: 10px 16px;
  }
}
</style>
