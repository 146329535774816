import ConsoleList from '@views/monitor/ConsoleList';
import ConsoleMonitorGraph from '@views/monitor/ConsoleMonitorGraph';
import ExceptionLog from '../../views/monitor/ExceptionLog';
import JobMonitorDashboard from '../../views/monitor/JobMonitorDashboard';
import MonitorTest from '../../views/monitor/WorkerMonitorGraph';
import MonitorJobGraph from '../../views/monitor/MonitorJobGraph';
import MonitorDetail from '../../views/monitor/MonitorDetail';
import MonitorJobFullGraph from '../../views/monitor/MonitorJobFullGraph';

export default [
  {
    path: '/monitor/consolelist',
    name: '/monitor/consolelist',
    component: ConsoleList
  },
  {
    path: '/monitor/console/graph',
    name: '/monitor/console/graph',
    component: ConsoleMonitorGraph
  },
  {
    path: '/monitor/exception',
    name: '/monitor/exception',
    component: ExceptionLog
  },
  {
    path: '/monitor/job/dashboard',
    name: '/monitor/job/dashboard',
    component: JobMonitorDashboard
  },
  {
    path: '/monitor/worker/graph',
    name: '/monitor/worker/graph',
    component: MonitorTest
  },
  {
    path: '/monitor/job/graph/:jobId/:taskId',
    name: '/monitor/job/graph/jobId/taskId',
    component: MonitorJobGraph
  },
  {
    path: '/monitor/job/full/graph/:jobId/:taskId',
    name: '/monitor/job/full/graph/jobId/taskId',
    component: MonitorJobFullGraph
  },
  {
    path: '/monitor/job/graph',
    name: '/monitor/job/graph',
    component: MonitorJobGraph
  },
  {
    path: '/monitor/detail/:type',
    name: '/monitor/detail/type',
    component: MonitorDetail
  }
];
