<template>
  <div class="edit-job-container">
    <DbMapping
      v-if="!DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)&&!DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)"
      :selectedIndex="selectedIndex"
      :sourceSchema="sourceSchema" :handleChangeSelectDb="handleChangeSelectDb"
      :handleDeleteDb="handleDeleteDb" :newAddDb="newAddDb" :handleAddNewDb="handleAddNewDb"
      :reduceData="reduceData" :jobData="jobData" :getTargetData="getTargetData"
      :handleConfirmNewDb="handleConfirmNewDb" :handleCancelAddDb="handleCancelAddDb"
      :hasSelectedTheDb="hasSelectedTheDb"
      :getSchemaList="getSchemaList" :sourceDbList="sourceDbList"
      :targetDbList="targetDbList"></DbMapping>
    <DbMq v-if="DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)"
          :selectedIndex="selectedIndex"
          :sourceSchema="sourceSchema" :handleChangeSelectDb="handleChangeSelectDb"
          :handleDeleteDb="handleDeleteDb" :newAddDb="newAddDb" :handleAddNewDb="handleAddNewDb"
          :reduceData="reduceData" :jobData="jobData" :getTargetData="getTargetData"
          :handleConfirmNewDb="handleConfirmNewDb" :handleCancelAddDb="handleCancelAddDb"
          :hasSelectedTheDb="hasSelectedTheDb"
          :getSchemaList="getSchemaList" :sourceDbList="sourceDbList"
          :targetDbList="targetDbList"></DbMq>
    <MqDb v-if="DataSourceGroup.mq.includes(jobData.sourceDsVO.dataSourceType)"
          :selectedIndex="selectedIndex"
          :sourceSchema="targetSchema" :handleChangeSelectDb="handleChangeSelectDb"
          :handleDeleteDb="handleDeleteDb" :newAddDb="newAddDb" :handleAddNewDb="handleAddNewDb"
          :reduceData="reduceData" :jobData="jobData" :getTargetData="getTargetData"
          :handleConfirmNewDb="handleConfirmNewDb" :handleCancelAddDb="handleCancelAddDb"
          :hasSelectedTheDb="hasSelectedTheDb"
          :getSchemaList="getSchemaList" :sourceDbList="sourceDbList"
          :targetDbList="targetDbList"></MqDb>
    <div class="edit-table-container table-filter-container">
      <div slot="content">
        <div class="table-filter-container-search" style="position: relative"
             v-if="selectDb && filterTable[selectDb.db || selectDb]">
          <Select prefix="ios-search" @on-change="handleChangeFilterType(selectDb,$event)"
                  style="width:160px;margin-right: 10px"
                  v-model="filterTable[selectDb.db || selectDb].tableFilterType">
            <Option value="autoCreated">{{ $t('dai-chuang-jian-biao') }}</Option>
            <Option value="noCreate">{{ $t('yi-cun-zai-biao') }}</Option>
            <Option value="hasPk">{{ $t('you-zhu-jian-biao') }}</Option>
            <Option value="noPk">{{ $t('wu-zhu-jian-biao') }}</Option>
            <Option value="hasInJob">{{ $t('yi-zai-ren-wu-zhong-de-biao') }}</Option>
            <Option value="notInJob">{{ $t('wei-zai-ren-wu-zhong-de-biao') }}</Option>
          </Select>
          <div style="display: inline-block"
               v-if="selectDb && filterTable[selectDb.db || selectDb].tableFilterType==='column'">
            <Input v-model="filterTable[selectDb.db || selectDb].tableFilterColumn" :placeholder="$t('qing-tian-ru-shai-xuan-biao-de-lie-ming')"
                   style="width: 200px"/>
            <pd-button :loading="loading" style="margin-left: 5px" type="primary" size="small">{{ $t('que-ding') }}
            </pd-button>
          </div>
          <Input prefix="ios-search" v-model="filterTable[selectDb.db || selectDb].tableFilterName"
                 :placeholder="$t('shai-xuan-biao')"
                 style="width: 200px" @on-change="handleTableFilter(selectDb,'',selectDb)"/>
          <div style="margin-top: 10px" v-if="filterList.length>0">
            <span>{{ $t('jian-suo-xiang') }}</span>
            <span class="table-filter-filter-span" v-for="(filter,i) in filterList"
                  :key="i">{{ Mapping.filterType[filter] }}<Icon type="md-close-circle"
                                                                 @click="handleDeleteFilter(i)"/></span>
            <span class="table-filter-filter-clear" @click="handleClearFilter">{{ $t('qing-chu') }}</span>
          </div>
          <Button v-if="DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)"
                  style="float: right" type="primary" ghost
                  @click="handleSetTopic">{{ $t('pi-liang-she-zhi-topic-ming-cheng') }}
          </Button>
          <div style="float: right;display: inline-block;margin-top: 6px">
            <span style="margin-right: 5px;font-weight: 500">{{ $t('action-pi-liang-gou-xuan') }}</span>
              <Checkbox label="INSERT" @on-change="handleBatchActionFilter('INSERT', $event)" v-model="filterTable[selectDb.db || selectDb].tableFilterAction.INSERT">
                INSERT
              </Checkbox>
              <Checkbox label="UPDATE" @on-change="handleBatchActionFilter('UPDATE', $event)" v-model="filterTable[selectDb.db || selectDb].tableFilterAction.UPDATE">
                UPDATE
              </Checkbox>
              <Checkbox label="DELETE" @on-change="handleBatchActionFilter('DELETE', $event)" v-model="filterTable[selectDb.db || selectDb].tableFilterAction.DELETE">
                DELETE
              </Checkbox>
          </div>
        </div>
        <Table border max-height="420" size="small" :row-class-name="rowClassName"
               :columns="tableColumns"
               :loading="loading"
               :data="selectDb ? showTableList[selectDb.db || selectDb] : []">
          <template slot-scope="{ row }" slot="action">
            <CheckboxGroup v-model="row.action" @on-change="handleActionFilter(row)"
            >
              <Checkbox label="INSERT" :disabled="row.hasInJob || !row.selected">
                INSERT
              </Checkbox>
              <Checkbox label="UPDATE" :disabled="row.hasInJob || !row.selected">
                UPDATE
              </Checkbox>
              <Checkbox label="DELETE" :disabled="row.hasInJob || !row.selected">
                DELETE
              </Checkbox>
            </CheckboxGroup>
          </template>
          <template slot-scope="{ row,index }" slot="sink">
            <p v-if="row.hasInJob">{{ row.targetTable }}</p>
            <Select filterable v-if="selectedTables[selectDb.db || selectDb]&&!row.hasInJob"
                    :disabled="!row.selected"
                    size="small" v-model="showTableList[selectDb.db || selectDb][index].targetTable"
                    @on-change="handleChangeTarget(row,index,$event)"
                    :class="dbToCreateClass(row)"
                    style="width:100%" :transfer="true">
              <Option v-for="(item,index) in targetTableList" :value="item.tableName"
                      :key="item+index">{{ item.tableName }}
              </Option>
              <Option
                v-if="(row.tableName)&&!containsTable(targetTableList,row.tableName)&&DataSourceGroup.oracle.indexOf(jobData.targetDsVO.dataSourceType)>-1"
                :value="row.tableName.toUpperCase()">{{ $t('rowtablenametouppercase-dai-chuang-jian', [row.tableName.toUpperCase()]) }}
              </Option>
              <Option
                v-if="(row.tableName)&&!containsTable(targetTableList,row.tableName)&&DataSourceGroup.pg.indexOf(jobData.targetDsVO.dataSourceType)>-1&&!targetCaseSensitive"
                :value="row.tableName.toLowerCase()">{{ $t('rowtablenametolowercase-dai-chuang-jian', [row.tableName.toLowerCase()]) }}
              </Option>
              <Option
                v-if="(row.tableName)&&!containsTable(targetTableList,row.tableName)&&(DataSourceGroup.oracle.indexOf(jobData.targetDsVO.dataSourceType)===-1&&
                  DataSourceGroup.mq.indexOf(jobData.targetDsVO.dataSourceType)===-1||(DataSourceGroup.pg.indexOf(jobData.targetDsVO.dataSourceType)===-1&&
                  targetCaseSensitive))"
                :value="row.tableName">{{ $t('rowtablename-dai-chuang-jian', [row.tableName]) }}
              </Option>
              <Option
                v-if="DataSourceGroup.mq.indexOf(jobData.targetDsVO.dataSourceType)>-1&&(row.tableName)&&
                  !containsTable(targetTableList, getTargetName(theMappingRule[0],selectDb, row, jobData.sourceDsVO, 'edit'))"
                :value="getTargetName(theMappingRule[0],selectDb, row, jobData.sourceDsVO, 'edit')">
                {{ $t('gettargetnamethemappingrule0-selectdb-row-jobdatasourcedsvo-edit-dai-chuang-jian', [getTargetName(theMappingRule[0], selectDb, row, jobData.sourceDsVO, 'edit')]) }}
              </Option>
            </Select>
          </template>
          <template slot="editHeader">
          </template>
          <template slot="edit" slot-scope="{row,index}">
            <div>
              <i style="color: #51D300;cursor: pointer"
                 v-if="(!row.hasInJob&&!row.selected)||(row.hasInJob&&reduceContains(row))"
                 class="iconfont iconadd-g"
                 @click="handleAddTable(row,index)"></i>
              <i style="color: #FF1815;cursor: pointer"
                 v-if="(row.hasInJob||row.selected)&&!reduceContains(row)"
                 class="iconfont iconDEL-R" @click="handleReduceTable(row,index)"></i>
            </div>
          </template>
        </Table>
        <div class="page-footer-paging table-filter-footer"
             style="position: absolute;bottom: 2px;left: 0;width: 100%;">
          <Page size="small" :total="pageData[selectDb.db || selectDb]? pageData[selectDb.db || selectDb].total:0" show-elevator
                @on-change="handlePageChange(selectDb,$event)" :key="showEditJob"
                :page-size="pageData[selectDb.db || selectDb]?pageData[selectDb.db || selectDb].size:10" v-if="selectDb"/>
        </div>
      </div>
    </div>
    <Modal
      v-model="showSetTopic"
      :title="$t('setdefaultvalue-she-zhi-mo-ren-topicdatasourcegroupmqincludesjobdatatargetdsvodatasourcetype-pi-liang-she-zhi-topic-ming-cheng-pi-liang-she-zhi-mu-biao-biao-0', [setDefaultValue?`设置默认topic`:DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)?`批量设置Topic名称`:`批量设置目标表`])"
      :closable="false"
      footer-hide
    >
      <div>
        <Form :label-width="80">
          <FormItem
            :label="$t('datasourcegroupmqincludesjobdatatargetdsvodatasourcetypetopic-ming-cheng-mu-biao-biao-0', [DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)?`Topic名称`:`目标表`])">
            <Select v-model="currentTopicName"
                    :placeholder="$t('datasourcegroupmqincludesjobdatatargetdsvodatasourcetype-qing-xuan-ze-topic-ming-cheng-qing-xuan-ze-mu-biao-biao', [DataSourceGroup.mq.includes(jobData.targetDsVO.dataSourceType)?`请选择Topic名称`:`请选择目标表`])"
                    filterable>
              <Option v-for="(topic,index) of targetTableList"
                      :value="topic.tableName" :key="index">{{ topic.tableName }}
              </Option>
            </Select>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmSetTopic">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import { getDbList, getTableList } from '@services/api/datasource';
import { tableMappingRule } from '@services/api/constant';
import Mapping from '@views/util';
import DataSourceGroup from '../../../../views/dataSourceGroup';
import DataJobDetailMixin from '../../mixin/dataJobDetailMixin';
import { getTargetName } from './util';
import DbMapping from './editJob/DbMapping';
import MqDb from './editJob/MqDb';
import DbMq from './editJob/DbMq';

export default {
  mixins: [DataJobDetailMixin],
  components: {
    DbMapping,
    MqDb,
    DbMq
  },
  props: {
    editJobData: Object,
    jobData: Object,
    showEditJob: Boolean,
    sourceSchema: Array,
    mappingDef: Array,
    updateLoading: Function,
    updateSourceSchema: Function,
    targetSchema: Array,
    queryJobSchema: Object
  },
  created() {
    if (this.editJobData.currentStep < this.editJobData.maxStep) {
      Object.assign(this, this.editJobData.one);
    }

    this.targetCaseSensitive = this.jobData.dstDsCaseSensitive;
  },
  data() {
    return {
      selectAllObj: {},
      checkAllGroup: ['INSERT', 'UPDATE', 'DELETE'],
      getTargetName,
      DataSourceGroup,
      selectedIndex: 0,
      selectDb: '',
      selectTargetName: '',
      filterTable: {},
      showList: {},
      pageData: {},
      showTableList: {},
      selectedTables: {},
      filterList: [],
      Mapping,
      loading: false,
      sourceDbList: [],
      targetDbList: [],
      sourceTableList: [],
      targetTableList: [],
      newAddDb: [],
      newData: {},
      reduceData: {},
      targetCaseSensitive: true,
      showSetTopic: false,
      currentTopicName: '',
      setDefaultValue: false,
      oldDbs: new Set(),
      topicName: '',
      tableColumns: [
        {
          title: this.$t('yuan-biao'),
          key: 'tableName'
        },
        {
          title: this.$t('mu-biao-biao'),
          slot: 'sink'
        },
        {
          title: this.$t('action-guo-lv'),
          slot: 'action',
          width: 300,
          align: 'center'
        },
        {
          title: this.$t('cao-zuo'),
          width: 120,
          align: 'center',
          renderHeader: (h) => h('div', {
            style: {
              display: 'flex',
              alignItems: 'center'
            }
          }, [
            h('div', {
              style: {
                marginRight: '5px'
              }
            }, this.$t('cao-zuo')),
            h('div', {
              style: {
                cursor: 'pointer',
                color: '#0BB9F8'
              },
              on: {
                click: () => (this.selectAllObj[this.selectDb ? this.selectDb.db || this.selectDb : ''] ? this.handleSelectAllCancel() : this.handleSelectAll())
              }
            }, this.selectAllObj[this.selectDb ? this.selectDb.db || this.selectDb : ''] ? this.$t('qu-xiao-quan-xuan') : this.$t('quan-xuan'))
          ]),
          slot: 'edit'
        }
      ],
      theMappingRule: []
    };
  },
  computed: {
    reduceContains() {
      return (row) => {
        let hasSame = false;

        const db = this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb];

        if (db && db.tableList) {
          db.tableList.map((item) => {
            if (item.sourceTable === row.tableName) {
              hasSame = true;
            }
            return null;
          });
        }
        return hasSame;
      };
    },
    dbToCreateClass() {
      return (row) => {
        if (this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][row._index].targetTable
          && !this.containsTable(this.targetTableList, this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][row._index].targetTable)) {
          return 'db-to-create';
        }
        return '';
      };
    }
  },
  methods: {
    handleSelectAll() {
      this.selectAllObj[this.selectDb.db || this.selectDb] = true;
      const tables = this.selectDb ? this.showList[this.selectDb.db || this.selectDb] : [];
      tables.forEach((row, index) => {
        if (!row.hasInJob && !row.selected) {
          this.handleAddAllTable(row, index);
        }
      });
    },
    handleSelectAllCancel() {
      this.selectAllObj[this.selectDb.db || this.selectDb] = false;
      const tables = this.selectDb ? this.showList[this.selectDb.db || this.selectDb] : [];
      tables.forEach((row, index) => {
        if (!row.hasInJob && row.selected) {
          this.handleReduceAllTable(row, index);
        }
      });
    },
    hasSelectedTheDb(item, type = 'sink') {
      for (const db of this.newAddDb) {
        if (type === 'source' && item === db.sourceDb) {
          return true;
        }
        if (type === 'sink' && item === db.sinkDb) {
          return true;
        }
      }

      for (const schema of this.sourceSchema) {
        if (type === 'source' && item === schema.db) {
          return true;
        }
        if (type === 'sink' && item === this.getTargetData(DataSourceGroup.hasSchema.indexOf(this.jobData.targetDsVO.dataSourceType) > -1
          ? 'DB_SCHEMA' : 'DB_DB', schema)
          .split('.')[0]) {
          return true;
        }
      }

      return false;
    },
    handleChangeFilterType(db, type) {
      db = db.db || db;
      if (this.filterTable[db].tableFilterType) {
        if (this.filterList.indexOf(type) === -1) {
          this.filterList.push(type);
        }
      }
      this.filterTable[db].tableFilterType = type;
      this.filterTable = { ...this.filterTable };

      const value = this.filterTable[db].tableFilterName;

      if (value || type) {
        this.handleTableFilter(db, db.sourceSchema, db);
      } else {
        this.showList[db] = _.cloneDeep(this.selectedTables[db]);
        this.pageData[db] = {
          page: 1,
          size: 20,
          total: this.showList[db].length
        };
        this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
      }
      // }
    },
    handleTableFilter(db, schema, database) {
      const value = this.filterTable[db.db ? db.db : db].tableFilterName;

      let finalList = [];

      this.showList[db.db ? db.db : db] = [];
      finalList = this.selectedTables[db.db ? db.db : db].filter((item) => Boolean((this.filterList.indexOf('autoCreated') > -1 ? item.needAutoCreated : true)
        && (this.filterList.indexOf('noCreate') > -1 ? !item.needAutoCreated : true)
        && (this.filterList.indexOf('hasPk') > -1 ? item.hasPk : true)
        && (this.filterList.indexOf('noPk') > -1 ? !item.hasPk : true)
        && (this.filterList.indexOf('hasInJob') > -1 ? item.hasInJob : true)
        && (this.filterList.indexOf('notInJob') > -1 ? !item.hasInJob : true)
        && (this.filterList.indexOf('added') > -1 ? item.selected : true)
        && (this.filterList.indexOf('reduced') > -1 ? !item.selected : true)
        && (value ? item.tableName.toLowerCase().indexOf(value.toLowerCase()) > -1 : true)));
      this.showList[db.db ? db.db : db] = finalList;
      if (this.filterList.indexOf('differPk') > -1) {
        this.filterByDifferPk(database, finalList);
      }

      if (this.filterList.indexOf('column') > -1) {
        this.filterByColumn(db, schema, finalList);
      }

      this.showList = { ...this.showList };
      this.pageData[db.db ? db.db : db].page = 1;
      this.pageData[db.db ? db.db : db].total = this.showList[db.db ? db.db : db].length;
      this.showTableList[db.db ? db.db : db] = this.showList[db.db ? db.db : db].slice((this.pageData[db.db ? db.db : db].page - 1) * this.pageData[db.db ? db.db : db].size,
        this.pageData[db.db ? db.db : db].page * this.pageData[db.db ? db.db : db].size);
      this.showTableList = { ...this.showTableList };
    },
    handleBatchActionFilter(act, checked) {
      this.editJobData.oneChange = true;
      if (this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.forEach((table) => {
          if (!table.hasInJob && table.selected) {
            const { action } = table;
            if (action.includes(act)) {
              action.splice(action.findIndex((a) => a === act), 1);
            } else {
              action.push(act);
            }
            console.log(action);
            table.action = action;
          }
        });
      }
      this.filterTable[this.selectDb.db || this.selectDb].tableFilterAction[act] = checked;
      this.showList[this.selectDb.db || this.selectDb].forEach((table) => {
        if (!table.hasInJob && table.selected) {
          const { action } = table;
          if (action.includes(act)) {
            action.splice(action.findIndex((a) => a === act), 1);
          } else {
            action.push(act);
          }
          table.action = action;
        }
      });

      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
    },
    handleActionFilter(row) {
      this.editJobData.oneChange = true;
      console.log(row.action);
      if (this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.forEach((table) => {
          if (row.tableName === table.sourceTable) {
            table.action = row.action;
          }
        });
      }
      this.showList[this.selectDb.db || this.selectDb].forEach((table) => {
        if (table.tableName === row.tableName) {
          table.action = row.action;
        }
      });
    },
    handleDeleteFilter(i) {
      this.filterList.splice(i, 1);
      this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterType = '';
      this.handleTableFilter(this.selectDb, '', this.selectDb);
    },
    handleClearFilter() {
      this.filterList = [];
      this.filterTable[this.selectDb].tableFilterType = '';
      this.handleTableFilter(this.selectDb, '', this.selectDb);
    },
    rowClassName(row) {
      if (row.hasInJob) {
        return 'has-in-job-table';
      }
      return '';
    },
    containsTable(list, table) {
      let hasSame = false;

      list.map((item) => {
        if (item.tableName === table) {
          hasSame = true;
        }
        if (DataSourceGroup.oracle.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
          if (item.tableName === table.toUpperCase()) {
            hasSame = true;
          }
        }
        return null;
      });
      return hasSame;
    },
    handlePageChange(db, page) {
      db = db.db || db;
      this.pageData[db].page = page;
      this.showTableList[db] = this.showList[db].slice((this.pageData[db].page - 1) * this.pageData[db].size, this.pageData[db].page * this.pageData[db].size);
      this.showTableList = { ...this.showTableList };
    },
    getDbList() {
      getDbList({
        host: this.jobData.sourceDsVO.host,
        privateHost: this.jobData.sourceDsVO.privateHost,
        publicHost: this.jobData.sourceDsVO.publicHost,
        hostType: this.jobData.sourceDsVO.hostType,
        type: this.jobData.sourceDsVO.dataSourceType,
        dataSourceId: this.jobData.sourceDsVO.id,
        referenceDataJobId: this.jobData.dataJobId,
        referenceDsEndPointType: 'SOURCE'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDbList = res.data.data;
          }
        });
      getDbList({
        host: this.jobData.targetDsVO.host,
        privateHost: this.jobData.targetDsVO.privateHost,
        publicHost: this.jobData.targetDsVO.publicHost,
        hostType: this.jobData.targetDsVO.hostType,
        type: this.jobData.targetDsVO.dataSourceType,
        dataSourceId: this.jobData.targetDsVO.id,
        referenceDataJobId: this.jobData.dataJobId,
        referenceDsEndPointType: 'TARGET'
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.targetDbList = res.data.data;
          }
        });
    },
    handleAddNewDb() {
      if (this.newAddDb.length) {
        return;
      }
      this.newAddDb.push({
        sourceDb: '',
        targetDb: '',
        sourceSchema: '',
        targetSchema: ''
      });
    },
    handleCancelAddDb() {
      this.newAddDb.pop();
    },
    handleConfirmNewDb() {
      this.editJobData.oneChange = true;
      const selectedNewAddDb = this.newAddDb[this.newAddDb.length - 1];
      let error = false;
      if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
        if (!selectedNewAddDb.sourceDb) {
          error = true;
        }
        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1 && !selectedNewAddDb.sourceSchema) {
          error = true;
        }
      } else if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        if (!selectedNewAddDb.targetDb) {
          error = true;
        }
        if (DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1 && !selectedNewAddDb.targetSchema) {
          error = true;
        }
      } else {
        if (!selectedNewAddDb.sourceDb || !selectedNewAddDb.targetDb) {
          error = true;
        }

        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1 && !selectedNewAddDb.sourceSchema) {
          error = true;
        }

        if (DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1 && !selectedNewAddDb.targetSchema) {
          error = true;
        }
      }

      if (error) {
        this.$Modal.error({
          title: this.$t('tian-jia-ku'),
          content: this.$t('qing-tian-xie-wan-zheng-qie-zheng-que-de-yuan-ku-he-mu-biao-ku-xin-xi')
        });
        return;
      }
      this.newData[this.newAddDb[this.newAddDb.length - 1].sourceDb] = {};
      this.newData[this.newAddDb[this.newAddDb.length - 1].sourceDb].needAutoCreated = false;
      this.newData[this.newAddDb[this.newAddDb.length - 1].sourceDb].targetDb = this.newAddDb[this.newAddDb.length - 1].targetDb;
      this.newData[this.newAddDb[this.newAddDb.length - 1].sourceDb].targetSchema = this.newAddDb[this.newAddDb.length - 1].targetSchema;
      this.sourceSchema.push({
        db: this.newAddDb[this.newAddDb.length - 1].sourceDb,
        tables: [],
        targetAutoCreate: false
      });
      if (DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
        this.mappingDef.map((item) => {
          if (item.method === 'DB_SCHEMA') {
            const mapping1 = {
              value: this.newAddDb[this.newAddDb.length - 1].sourceDb
            };
            const
              mapping2 = {
                parent: {
                  value: this.newAddDb[this.newAddDb.length - 1].targetDb
                },
                value: this.newAddDb[this.newAddDb.length - 1].targetSchema
              };

            item.serializeMapping[JSON.stringify(mapping1)] = JSON.stringify(mapping2);
          }
          return null;
        });
      } else if (this.newAddDb[this.newAddDb.length - 1].sourceDb !== this.newAddDb[this.newAddDb.length - 1].targetDb) {
        this.mappingDef.map((item) => {
          if (item.method === 'DB_DB') {
            const mapping1 = {
              value: this.newAddDb[this.newAddDb.length - 1].sourceDb
            };
            const
              mapping2 = {
                value: this.newAddDb[this.newAddDb.length - 1].targetDb
              };

            item.serializeMapping[JSON.stringify(mapping1)] = JSON.stringify(mapping2);
          }
          return null;
        });
      }
      this.getTables(this.sourceSchema.length - 1, this.newAddDb[this.newAddDb.length - 1], 'newAdd');
      this.newAddDb.pop();
    },
    handleAddAllTable(row, index) {
      this.editJobData.oneChange = true;
      if (row.hasPk || this.jobData.sourceDsVO.deployType === 'ALIBABA_CLOUD_HOSTED'
          && this.jobData.targetDsVO.deployType === 'ALIBABA_CLOUD_HOSTED'
          || DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action = ['INSERT', 'DELETE', 'UPDATE'];
      } else {
        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action = ['INSERT', 'DELETE', 'UPDATE'];
      }
      if (DataSourceGroup.es.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = (`cc_${this.jobData.sourceDsVO.instanceId}_${this.selectDb.db
          ? this.selectDb.db : this.selectDb}`
          ? (this.selectDb.db ? this.selectDb.db : this.selectDb) : `${this.selectDb.db ? this.selectDb.db : this.selectDb}_${row.tableName}`).toLowerCase();
      } else if (DataSourceGroup.mq.indexOf(this.jobData.targetDsVO.dataSourceType) > -1 || DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
        const sinkTable = getTargetName(this.theMappingRule[0], this.selectDb, row, this.jobData.sourceDsVO, 'edit');

        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = sinkTable;
        if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
          let hasSame = false;
          let
            theTopic = {};

          this.targetTableList.map((topic) => {
            if (topic.tableName === sinkTable) {
              hasSame = true;
              theTopic = topic;
            }
            return null;
          });
          if (hasSame) {
            this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions = theTopic.mqTopicPartitions;
          } else {
            this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions = 4;
          }
        }
      } else if (DataSourceGroup.oracle.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = row.tableName.toUpperCase();
      } else {
        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = row.tableName;
      }
      if (this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
        if (!this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList) {
          this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList = [];
        }
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.push({
          sourceTable: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].tableName,
          targetTable: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable,
          action: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action,
          sourceDb: this.selectDb.db || this.selectDb,
          targetDb: this.selectTargetName,
          partitions: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions,
          sourceSchema: this.jobData.sourceDsVO.dbSchemaRelation
            ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db
              ? this.selectDb.db : this.selectDb] : '',
          targetSchema: this.selectTargetSchema,
          needAutoCreated: !this.containsTable(this.targetTableList, this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable)
        });
      } else {
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb] = {};
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList = [];
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.push({
          sourceTable: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].tableName,
          targetTable: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable,
          action: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action,
          sourceDb: this.selectDb.db || this.selectDb,
          targetDb: this.selectTargetName,
          partitions: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions,
          sourceSchema: this.jobData.sourceDsVO.dbSchemaRelation
            ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db
              ? this.selectDb.db : this.selectDb] : '',
          targetSchema: this.selectTargetSchema,
          needAutoCreated: !this.containsTable(this.targetTableList, this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable)
        });
      }
      this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].targetDb = this.selectTargetName;
      this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].sourceDb = this.selectDb;
      this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].targetSchema = row.targetSchema;
      this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].sourceSchema = this.jobData.sourceDsVO.dbSchemaRelation
        ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db
          ? this.selectDb.db : this.selectDb] : '';
      this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].selected = true;
      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
    },
    handleAddTable(row, index) {
      this.editJobData.oneChange = true;
      if (!row.hasInJob) {
        if (row.hasPk || this.jobData.sourceDsVO.deployType === 'ALIBABA_CLOUD_HOSTED'
          && this.jobData.targetDsVO.deployType === 'ALIBABA_CLOUD_HOSTED'
          || DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action = ['INSERT', 'DELETE', 'UPDATE'];
        } else {
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action = ['INSERT', 'DELETE', 'UPDATE'];
        }
        if (DataSourceGroup.es.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = (`cc_${this.jobData.sourceDsVO.instanceId}_${this.selectDb.db
            ? this.selectDb.db : this.selectDb}`
            ? (this.selectDb.db ? this.selectDb.db : this.selectDb) : `${this.selectDb.db ? this.selectDb.db : this.selectDb}_${row.tableName}`).toLowerCase();
        } else if (DataSourceGroup.mq.indexOf(this.jobData.targetDsVO.dataSourceType) > -1 || DataSourceGroup.pg.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
          const sinkTable = getTargetName(this.theMappingRule[0], this.selectDb, row, this.jobData.sourceDsVO, 'edit');

          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = sinkTable;
          if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
            let hasSame = false;
            let
              theTopic = {};

            this.targetTableList.map((topic) => {
              if (topic.tableName === sinkTable) {
                hasSame = true;
                theTopic = topic;
              }
              return null;
            });
            if (hasSame) {
              this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions = theTopic.mqTopicPartitions;
            } else {
              this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions = 4;
            }
          }
        } else if (DataSourceGroup.oracle.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = row.tableName.toUpperCase();
        } else {
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = row.tableName;
        }
        if (this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
          if (!this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList) {
            this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList = [];
          }
          this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.push({
            sourceTable: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].tableName,
            targetTable: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable,
            action: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action,
            sourceDb: this.selectDb.db || this.selectDb,
            targetDb: this.selectTargetName,
            partitions: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions,
            sourceSchema: this.jobData.sourceDsVO.dbSchemaRelation
              ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db
                ? this.selectDb.db : this.selectDb] : '',
            targetSchema: this.selectTargetSchema,
            needAutoCreated: !this.containsTable(this.targetTableList, this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable)
          });
        } else {
          this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb] = {};
          this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList = [];
          this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.push({
            sourceTable: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].tableName,
            targetTable: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable,
            action: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action,
            sourceDb: this.selectDb.db || this.selectDb,
            targetDb: this.selectTargetName,
            partitions: this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions,
            sourceSchema: this.jobData.sourceDsVO.dbSchemaRelation
              ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db
                ? this.selectDb.db : this.selectDb] : '',
            targetSchema: this.selectTargetSchema,
            needAutoCreated: !this.containsTable(this.targetTableList, this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable)
          });
        }
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].targetDb = this.selectTargetName;
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].sourceDb = this.selectDb;
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].targetSchema = row.targetSchema;
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].sourceSchema = this.jobData.sourceDsVO.dbSchemaRelation
          ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db
            ? this.selectDb.db : this.selectDb] : '';
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].selected = true;
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
      } else {
        this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.map((item, i) => {
          if (item.sourceTable === row.tableName) {
            this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.splice(i, 1);
          }
          return null;
        });
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
      }
    },
    handleReduceAllTable(row, index) {
      this.editJobData.oneChange = true;
      this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action = [];
      this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = '';
      this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.map((item, i) => {
        if (item.sourceTable === row.tableName) {
          this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.splice(i, 1);
        }
        return null;
      });
      this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].selected = false;
      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
    },
    handleReduceTable(row, index) {
      this.editJobData.oneChange = true;
      if (!row.hasInJob) {
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].action = [];
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = '';
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.map((item, i) => {
          if (item.sourceTable === row.tableName) {
            this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.splice(i, 1);
          }
          return null;
        });
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].selected = false;
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
        this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
      } else {
        let hasInJobListLength = 0;
        let error = false;
        this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb].forEach((item) => {
          if (item.hasInJob) {
            hasInJobListLength++;
          }
        });
        console.log(hasInJobListLength);
        if (!this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
          if (hasInJobListLength === 1) {
            error = true;
          }
          if (!error) {
            this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb] = {};
            if (!this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList) {
              this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList = [];
              this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.push({
                sourceTable: row.tableName,
                targetTable: row.targetTable,
                action: row.action,
                sourceDb: this.selectDb.db ? this.selectDb.db : this.selectDb,
                targetDb: this.selectTargetName,
                sourceSchema: this.jobData.sourceDsVO.dbSchemaRelation ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db ? this.selectDb.db : this.selectDb] : '',
                targetSchema: this.selectTargetSchema,
                needAutoCreated: !this.containsTable(this.targetTableList, this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].tableName)
              });
            }
          }
        } else {
          if (this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.length + 1 === hasInJobListLength) {
            error = true;
          }
          if (!error) {
            this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.push({
              sourceTable: row.tableName,
              targetTable: row.targetTable,
              action: row.action,
              sourceDb: this.selectDb.db ? this.selectDb.db : this.selectDb,
              targetDb: this.selectTargetName,
              sourceSchema: this.jobData.sourceDsVO.dbSchemaRelation ? JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue)[this.selectDb.db ? this.selectDb.db : this.selectDb] : '',
              targetSchema: this.selectTargetSchema,
              needAutoCreated: !this.containsTable(this.targetTableList, this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].tableName)
            });
          }
        }

        if (error) {
          this.$Modal.error({
            title: this.$t('shan-chu-biao'),
            content: this.$t('qing-zhi-shao-bao-liu-yi-ge-biao')
          });
        } else {
          this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].targetDb = this.selectTargetName;
          this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].sourceDb = this.selectDb.db ? this.selectDb.db : this.selectDb;
          this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].targetSchema = this.selectTargetSchema;
          this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].sourceSchema = '';
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
          this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
        }
      }
    },
    handleChangeTarget(row, index, data) {
      this.editJobData.oneChange = true;
      if (this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
        this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.map((item) => {
          if (item.sourceTable === row.tableName) {
            if (data) {
              item.targetTable = data;

              item.needAutoCreated = !this.containsTable(this.targetTableList, data);

              if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
                let hasSame = false;
                let
                  theTopic = {};

                this.targetTableList.map((topic) => {
                  if (topic.tableName === data) {
                    hasSame = true;
                    theTopic = topic;
                  }
                  return null;
                });
                if (hasSame) {
                  item.partitions = theTopic.mqTopicPartitions;

                  this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions = theTopic.mqTopicPartitions;
                } else {
                  item.partitions = 4;

                  this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].partitions = 4;
                }
              }

              this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb][index].targetTable = data;

              this.showTableList = { ...this.showTableList };
            }
          }
          return null;
        });
      }
    },
    getTables(index, db, type) {
      this.sourceTableList = [];
      this.targetTableList = [];
      this.selectedIndex = index;
      const selectDb = db.sourceDb ? db.sourceDb : db;
      this.selectDb = selectDb;
      this.selectTargetName = db.targetDb;
      if (DataSourceGroup.hasSchema.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
        if (this.getTargetData('DB_SCHEMA', selectDb)) {
          this.selectTargetSchema = this.getTargetData('DB_SCHEMA', selectDb)
            .split('.')[1];
        }
      } else if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
        if (this.getTargetData('SCHEMA_DB', selectDb)) {
          this.selectTargetSchema = this.getTargetData('SCHEMA_DB', selectDb)
            .split('.')[1];
        }
      } else {
        this.selectTargetSchema = db.targetSchema;
      }
      let schema = '';

      if (type !== 'newAdd') {
        if (DataSourceGroup.hasSchema.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
          this.selectTargetName = this.getTargetData('DB_SCHEMA', selectDb)
            .split('.')[0];
          schema = this.getTargetData('DB_SCHEMA', selectDb)
            .split('.')[1];
        } else if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.selectTargetName = this.getTargetData('SCHEMA_DB', selectDb)
            .split('.')[0];
          schema = this.getTargetData('SCHEMA_DB', selectDb)
            .split('.')[1];
        } else {
          this.selectTargetName = this.getTargetData('DB_DB', selectDb);
        }
      } else {
        schema = db.targetSchema;
      }
      getTableList({
        host: this.jobData.sourceDsVO.host,
        privateHost: this.jobData.sourceDsVO.privateHost,
        publicHost: this.jobData.sourceDsVO.publicHost,
        hostType: this.jobData.sourceDsVO.hostType,
        type: this.jobData.sourceDsVO.dataSourceType,
        dbName: DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType) ? null : this.selectDb.db ? this.selectDb.db : this.selectDb,
        schema,
        tableSchema: schema,
        dataSourceId: this.jobData.sourceDsVO.id,
        referenceDataJobId: this.jobData.dataJobId,
        referenceDsEndPointType: 'SOURCE'
      })
        .then((res) => {
          if (res.data.code === '1') {
            const newTables = [];
            const oldTables = [];
            const needDeletedTables = [];

            this.sourceTableList = res.data.data;
            this.sourceTableList.map((table) => {
              if (DataSourceGroup.mq.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
                this.mappingDef.map((item) => {
                  Object.keys(item.serializeMapping)
                    .map((mappingKey) => {
                      this.oldDbs.add(JSON.parse(mappingKey).parent.value);
                      return null;
                    });
                  return null;
                });

                this.mappingDef.map((item) => {
                  if (item.method === 'TABLE_TOPIC') {
                    const key = {
                      parent: {
                        value: this.selectDb.db ? this.selectDb.db : this.selectDb
                      },
                      value: table.tableName
                    };

                    if (item.serializeMapping[JSON.stringify(key)]) {
                      table.hasInJob = true;
                      table.targetTable = JSON.parse(item.serializeMapping[JSON.stringify(key)]).value;
                      if (this.selectDb.tables) {
                        this.selectDb.tables.map((item1) => {
                          if (item1.table === table.tableName) {
                            table.action = item1.actions;
                          }
                          return null;
                        });
                      }
                      oldTables.push(table);
                    } else {
                      if (this.oldDbs.has(this.selectDb.db ? this.selectDb.db : this.selectDb)) {
                        if (item.commonGenRule === 'DEFAULT_TOPIC') {
                          table.hasInJob = true;
                          table.targetTable = this.queryJobSchema.defaultTopic;
                          table.action = ['INSERT', 'UPDATE', 'DELETE'];
                          oldTables.push(table);
                        }
                      }
                    }
                  }
                  return null;
                });
              } else if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
                this.mappingDef.map((item) => {
                  if (item.method === 'TOPIC_TABLE') {
                    const key = {
                      parent: {
                        value: this.selectDb.db ? this.selectDb.db : this.selectDb
                      },
                      value: table.tableName
                    };

                    if (item.serializeMapping[JSON.stringify(key)]) {
                      table.hasInJob = true;
                      table.targetTable = JSON.parse(item.serializeMapping[JSON.stringify(key)]).value;
                      if (this.selectDb.tables) {
                        this.selectDb.tables.map((item1) => {
                          if (item1.table === table.tableName) {
                            table.action = item1.actions;
                          }
                          return null;
                        });
                      }
                      oldTables.push(table);
                    }
                  }
                  return null;
                });
              } else if (this.selectDb.tables) {
                this.selectDb.tables.map((item) => {
                  if (item.table === table.tableName) {
                    table.hasInJob = true;
                    table.targetTable = this.getTargetData('TABLE_TABLE', db, item);
                    table.action = item.actions;
                    table.targetSchema = schema;
                    oldTables.push(table);
                  }
                  return null;
                });
              } else if (this.selectDb.schemas) {
                this.selectDb.schemas[0].tables.map((item) => {
                  if (item.table === table.tableName) {
                    table.hasInJob = true;
                    table.targetTable = this.getTargetData('TABLE_TABLE', db, item);
                    table.action = item.actions;
                    table.targetSchema = schema;
                    oldTables.push(table);
                  }
                  return null;
                });
              }
              return null;
            });
            if (this.selectDb.tables) {
              this.selectDb.tables.map((item) => {
                let hasSame = false;

                oldTables.map((old) => {
                  if (old.tableName === item.table) {
                    hasSame = true;
                  }
                  return null;
                });
                if (!hasSame) {
                  const theTable = {};

                  theTable.dbName = this.selectDb.db ? this.selectDb.db : this.selectDb;
                  theTable.hasInJob = true;
                  theTable.tableName = item.table;
                  theTable.targetTable = this.getTargetData('TABLE_TABLE', db, item);
                  theTable.action = item.actions;
                  theTable.targetSchema = schema;
                  theTable.needToDelete = true;
                  needDeletedTables.push(theTable);
                }
                return null;
              });
            }
            this.sourceTableList.map((item) => {
              if (!item.hasInJob) {
                item.targetSchema = schema;
                newTables.push(item);
              }
              return null;
            });
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb] = {};
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterName = '';
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterCreate = ['autoCreated', 'noCreate'];
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterAction = {
              INSERT: true,
              UPDATE: true,
              DELETE: true
            };
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterType = '';
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterPk = ['hasPk', 'noPk'];
            this.filterTable[this.selectDb.db ? this.selectDb.db : this.selectDb].tableFilterColumn = '';
            this.selectedTables[this.selectDb.db ? this.selectDb.db : this.selectDb] = newTables.concat(oldTables, needDeletedTables);
            this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb] = _.cloneDeep(this.selectedTables[this.selectDb.db ? this.selectDb.db : this.selectDb]);
            this.pageData[this.selectDb.db ? this.selectDb.db : this.selectDb] = {
              page: 1,
              size: 20,
              total: this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb] ? this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb].length : 0
            };
            this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb] = this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb]
              ? this.showList[this.selectDb.db ? this.selectDb.db : this.selectDb].slice((this.pageData[this.selectDb.db ? this.selectDb.db : this.selectDb].page - 1) * this.pageData[this.selectDb.db
                ? this.selectDb.db : this.selectDb].size,
              this.pageData[this.selectDb.db ? this.selectDb.db : this.selectDb].page * this.pageData[this.selectDb.db ? this.selectDb.db : this.selectDb].size) : [];
            this.showTableList = { ...this.showTableList };
            getTableList({
              host: this.jobData.targetDsVO.host,
              privateHost: this.jobData.targetDsVO.privateHost,
              publicHost: this.jobData.targetDsVO.publicHost,
              hostType: this.jobData.targetDsVO.hostType,
              type: this.jobData.targetDsVO.dataSourceType,
              dbName: this.selectTargetName,
              schema,
              tableSchema: schema,
              dataSourceId: this.jobData.targetDsVO.id,
              referenceDataJobId: this.jobData.dataJobId,
              referenceDsEndPointType: 'TARGET'
            })
              .then((res1) => {
                if (res1.data.code === '1') {
                  this.targetTableList = res1.data.data;

                  if (this.selectDb && this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
                    this.newData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.map((newData) => {
                      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].map((table) => {
                        if (newData.sourceTable === table.tableName) {
                          table.targetTable = newData.targetTable;
                          table.action = newData.action;
                          table.selected = true;
                        }
                        return null;
                      });
                      return null;
                    });
                    this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
                    this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
                  }
                  if (this.selectDb && this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb]) {
                    this.reduceData[this.selectDb.db ? this.selectDb.db : this.selectDb].tableList.map((reduceData) => {
                      this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].map((table) => {
                        if (reduceData.sourceTable === table.tableName) {
                          table.selected = false;
                        }
                        return null;
                      });
                      return null;
                    });
                    this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].push('');
                    this.showTableList[this.selectDb.db ? this.selectDb.db : this.selectDb].pop();
                  }
                }
                this.updateLoading(false);
              })
              .catch(() => {
                this.updateLoading(false);
              });
          } else {
            this.updateLoading(false);
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },
    handleChangeSelectDb(db, index) {
      this.getTables(index, db);
    },
    handleDeleteDb(db) {
      console.log(db);
      this.editJobData.oneChange = true;
      let deleteDb = 0;

      Object.keys(this.reduceData)
        .map((item) => {
          if (!item.tableList) {
            deleteDb++;
          }
          return null;
        });
      if (this.sourceSchema.length - deleteDb > 1) {
        if (this.newData[db.db]) {
          delete this.newData[db.db];
          this.sourceSchema = this.sourceSchema.filter((schema) => schema.db !== db.db);
          this.selectedIndex = 0;
          this.selectDb = this.sourceSchema[0];
        } else {
          this.reduceData[db.db] = {};
          this.reduceData[db.db] = db;
          this.reduceData[db.db].deleteAll = true;
          if (DataSourceGroup.hasSchema.indexOf(this.jobData.targetDsVO.dataSourceType) > -1) {
            if (this.getTargetData('DB_SCHEMA', db)) {
              this.reduceData[db.db].targetDb = this.getTargetData('DB_SCHEMA', db)
                .split('.')[0];
              this.reduceData[db.db].targetSchema = this.getTargetData('DB_SCHEMA', db)
                .split('.')[1];
            }
          } else {
            this.reduceData[db.db].targetDb = this.getTargetData('DB_DB', db);
          }
          this.reduceData = { ...this.reduceData };
          this.sourceSchema.map((item, index) => {
            if (!(this.reduceData[item] && !this.reduceData[item].tableList)) {
              this.getTables(index, item);
            }
            return null;
          });
        }
      } else {
        this.$Modal.warning({
          title: this.$t('xiu-gai-ding-yue-ti-shi'),
          content: this.$t('qing-zhi-shao-bao-liu-yi-ge-ku')
        });
      }
    },
    getSchemaList(dbList, db) {
      let list = [];

      dbList.map((item) => {
        if (item.dbName === db) {
          list = item.schemas;
        }
        return null;
      });
      return list;
    },
    getMappingRule() {
      tableMappingRule({
        sourceDsId: this.jobData.sourceDsVO.id,
        targetDsId: this.jobData.targetDsVO.id,
        targetSensitive: this.targetCaseSensitive,
        clusterId: this.jobData.clusterId,
        sourceHost: this.jobData.sourceDsVO.host,
        targetHost: this.jobData.targetDsVO.host
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.theMappingRule = res.data.data;
          }
        });
    },
    handleSetTopic(type) {
      this.currentTopicName = '';
      this.showSetTopic = true;
      if (type === 'default') {
        this.setDefaultValue = true;
      } else {
        this.setDefaultValue = false;
      }
    },
    handleConfirmSetTopic() {
      this.editJobData.oneChange = true;
      this.topicName = this.currentTopicName;
      let partitions = 4;
      let theDb = this.selectDb.db || this.selectDb;

      if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        theDb = this.selectDb.db || this.selectDb;
      }

      if (this.newData[this.selectDb.db || this.selectDb].tableList) {
        if (DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType)) {
          let hasSame = false;
          let
            theTopic = null;

          this.targetTableList.map((topic) => {
            if (topic.tableName === this.topicName) {
              hasSame = true;
              theTopic = topic;
            }
            return null;
          });

          if (hasSame) {
            partitions = theTopic.mqTopicPartitions;
          }
        }
        this.newData[this.selectDb.db || this.selectDb].tableList.map((item) => {
          item.sinkTable = this.topicName;
          item.needAutoCreated = !this.containsTable(this.targetTableList, item.sinkTable);
          item.partitions = partitions;
          return null;
        });
        this.selectedTables[theDb].map((item) => {
          item.sinkTable = this.topicName;
          item.needAutoCreated = !this.containsTable(this.targetTableList, item.sinkTable);
          item.partitions = partitions;
          this.newData[this.selectDb.db || this.selectDb].tableList.map((newTable) => {
            if (newTable.sourceTable === item.tableName) {
              item.selected = true;
              item.action = newTable.action;
              item.targetTable = this.topicName;
            }
            return null;
          });
          return null;
        });
        this.showList[theDb] = _.cloneDeep(this.selectedTables[theDb]);
        this.pageData[theDb] = {
          page: 1,
          size: 20,
          total: this.showList[theDb].length
        };
        this.showTableList[theDb] = this.showList[theDb].slice((this.pageData[theDb].page - 1) * this.pageData[theDb].size,
          this.pageData[theDb].page * this.pageData[theDb].size);
      }
      this.showSetTopic = false;
    },
    handleCancel() {
      this.showSetTopic = false;
    }
  },
  watch: {
    editJobData(newVal) {
      console.log(newVal);
      if (newVal.currentStep < newVal.maxStep) {
        this.$data = newVal.one;
      }
    },
    jobData() {
      this.selectIndex = 0;
      this.selectDb = this.sourceSchema[0];
    },
    showEditJob(val) {
      if (val) {
        this.updateLoading(true);
        this.getDbList();
        this.getTables(0, this.sourceSchema[0]);
        this.getMappingRule();
      }
    }
  }
};
</script>
<style lang="less">
.edit-job-container {
  border: 1px solid #DADADA;

  .edit-db-container {
    width: 280px;
    float: left;
  }

  .edit-table-container {
    margin-left: 280px;
  }
}

.edit-table-container {
  width: 886px;
  height: 573px;
}

.table-filter-container {
  margin-left: 280px;
  border-left: 1px solid #DADADA;
  overflow: auto;
  position: relative;

  .table-filter-container-search {
    padding: 14px 20px;
    background-color: #FAFAFA;
  }

  .ivu-table-wrapper-with-border {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .table-filter-footer {
    margin-top: 50px;
    border-top: 1px solid #DADADA;
    background-color: #FAFAFA;
  }
}

.add-db-item {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #DADADA;
}

.new-add-db-item {
  padding: 10px 14px;
  border-bottom: 1px solid #DADADA;
}

.table-filter-filter-span {
  background-color: #FFE1E1;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 8px;

  .ivu-icon {
    margin-left: 6px;
    cursor: pointer;
  }
}

.table-filter-filter-clear {
  margin-left: 6px;
  cursor: pointer;
}
</style>
